import React from "react"
import { useTrackView } from "../../../utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { PiiText } from "#/services/tracking"
import { Checkbox, FormControlLabel, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { usePrivacyNoticeUrl, useTermsAndConditionsUrl } from "#/utils/hooks/legal-notice-urls"

interface AcknowledgeTermsProps {
  readPolicy: boolean
  setReadPolicy: (newValue: boolean) => void
  readTerms: boolean
  setReadTerms: (newValue: boolean) => void
  firstName?: string
  preferredName?: string
  onComplete: () => void
  onBack: () => void
  employeeId: number
}

export function AcknowledgeTerms({
  readPolicy,
  setReadPolicy,
  readTerms,
  firstName,
  preferredName,
  setReadTerms,
  onBack,
  onComplete,
  employeeId
}: AcknowledgeTermsProps): JSX.Element {
  useTrackView("AcknowledgeTermsRegFlow", "Acknowledge terms screen")
  const theme = useTheme()
  const intl = useIntl()
  const privacyNoticeUrl = usePrivacyNoticeUrl()
  const termsUrl = useTermsAndConditionsUrl()

  return (
    <>
      <RegistrationFlowTitle
        title={intl.formatMessage({ defaultMessage: "Terms and Conditions and Privacy Notice" })}
      />
      <RegistrationFlowLayout
        header={
          <FormattedMessage
            defaultMessage="Thanks, <PiiText>{name}</PiiText>."
            values={{
              PiiText: (text) => <PiiText>{text}</PiiText>,
              name: preferredName || firstName
            }}
          />
        }
        description={
          <>
            <Typography variant="h3" component="h2">
              <FormattedMessage defaultMessage="Before we continue, we want to make sure that you’re aware of our terms and how we’ll use your personal info." />
            </Typography>
            <Stack
              spacing={theme.spacing(theme.tokens.spacing.lg)}
              marginTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
            >
              <FormControlLabel
                required
                label={
                  <FormattedMessage
                    defaultMessage="I have read and accept the <link>Terms and Conditions</link>."
                    values={{
                      link: (linkText) => (
                        <Link href={termsUrl} target="_blank">
                          {linkText}
                        </Link>
                      )
                    }}
                  />
                }
                control={<Checkbox checked={readTerms} onChange={(e) => setReadTerms(e.target.checked)} />}
              />
              <FormControlLabel
                required
                label={
                  <FormattedMessage
                    defaultMessage="I have read and accept the <link>Privacy Notice</link> and agree to the processing of sensitive personal data."
                    values={{
                      link: (linkText) => (
                        <Link href={privacyNoticeUrl} target="_blank">
                          {linkText}
                        </Link>
                      )
                    }}
                  />
                }
                control={<Checkbox checked={readPolicy} onChange={(e) => setReadPolicy(e.target.checked)} />}
              />
            </Stack>
          </>
        }
        interactiveElements={
          <RegistrationButtonFooter
            onBack={onBack}
            continueButton={
              <ContinueButton onClick={onComplete} disabled={!readTerms || !readPolicy}>
                <FormattedMessage defaultMessage="Continue" />
              </ContinueButton>
            }
          />
        }
        employeeId={employeeId}
      />
    </>
  )
}
