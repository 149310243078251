import { DbgPaths } from "../../../../../utils/Paths"
import React, { FC } from "react"
import { Spacer } from "../../../atoms/Atoms"
import { FormattedMessage } from "react-intl"
import { Link, UnorderedList, UnorderedListItem } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "#/redux/reducers/benefitConfiguration"
import useBenefit from "../../../../dynamic-content/hooks/useGetBenefit"
import { getCountryCode } from "#/redux/reducers/companyInfo"
import useCompanyComplianceConfigLineItemFilter from "../../hooks/useGetCompanyComplianceConfigLineItemFilter"
import { CategorySections } from "../PayingForCareModal"
import { Benefit } from "#/lib/carrot-api/types/Benefit"

interface EligibleFertilityTextProps {
  eligibleExpensesDetails: Benefit["journeys"]["fertilityCare"]["eligibleExpensesDetail"]
  lineItemFilter: string
}

const QmeHeader: FC = () => {
  const { coverageConfig } = useSelector(getBenefitConfiguration)
  return (
    <>
      {coverageConfig.isMemberAndPartnerEligible && (
        <p>
          <FormattedMessage
            defaultMessage={
              "To be considered a qualified medical expense, eligible care or services must" +
              " be received by a member — or by a <link>Carrot-eligible</link> spouse or tax-dependent partner."
            }
            values={{
              link: (linkContent) => (
                <Link target="_blank" href={DbgPaths.CARROT_ELIGIBLE}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </p>
      )}
      <Spacer height={0.5} />
      <p>
        <FormattedMessage
          defaultMessage="This category includes the following when you work with a <link>Carrot-eligible provider</link>:"
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={DbgPaths.PROVIDER_ELIGIBILITY}>
                {linkContent}
              </Link>
            )
          }}
        />
      </p>
    </>
  )
}

const EligibleFertilityText: FC<EligibleFertilityTextProps> = ({ eligibleExpensesDetails, lineItemFilter }) => {
  const isCoveredWithInfertilityDiagnosis = eligibleExpensesDetails.isCoveredWithInfertilityDiagnosis
  const isElectiveCareCovered = eligibleExpensesDetails.isElectiveCareCovered
  const hasDonorAssistance = eligibleExpensesDetails.hasDonorAssistance
  const hasElectiveFertilityLineItemFilter = lineItemFilter === "ELECTIVE_FERTILITY"

  return (
    <>
      {isCoveredWithInfertilityDiagnosis &&
        !isElectiveCareCovered &&
        !hasDonorAssistance &&
        !hasElectiveFertilityLineItemFilter && (
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage={
                  "Eligible <link>fertility care</link> expenses related to an infertility diagnosis" +
                  " issued by a fertility doctor"
                }
                values={{
                  link: (linkContent) => (
                    <Link target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )}
      {(isCoveredWithInfertilityDiagnosis || isElectiveCareCovered) &&
        hasElectiveFertilityLineItemFilter &&
        !hasDonorAssistance && (
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible <link>fertility care</link> expenses"
                values={{
                  link: (linkContent) => (
                    <Link target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )}
      {((isCoveredWithInfertilityDiagnosis &&
        (isElectiveCareCovered || hasDonorAssistance) &&
        !hasElectiveFertilityLineItemFilter) ||
        ((isCoveredWithInfertilityDiagnosis || isElectiveCareCovered) &&
          hasDonorAssistance &&
          hasElectiveFertilityLineItemFilter)) && (
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>fertility care</link> expenses except:"
              values={{
                link: (linkContent) => (
                  <Link target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </p>
          <Spacer height={1} />
          {isCoveredWithInfertilityDiagnosis && (
            <UnorderedList disablePadding={true}>
              {isElectiveCareCovered && !hasElectiveFertilityLineItemFilter && (
                <UnorderedListItem>
                  <p className="lh-copy">
                    <FormattedMessage defaultMessage="Expenses that aren't related to an infertility diagnosis issued by a fertility doctor" />
                  </p>
                </UnorderedListItem>
              )}
              {hasDonorAssistance && !hasElectiveFertilityLineItemFilter && (
                <UnorderedListItem>
                  <p className="lh-copy">
                    <FormattedMessage defaultMessage="Related donor assistance expenses, with or without an infertility diagnosis" />
                  </p>
                </UnorderedListItem>
              )}
              {hasDonorAssistance && hasElectiveFertilityLineItemFilter && (
                <UnorderedListItem>
                  <p className="lh-copy">
                    <FormattedMessage defaultMessage="Related donor assistance expenses" />
                  </p>
                </UnorderedListItem>
              )}
            </UnorderedList>
          )}
        </UnorderedListItem>
      )}
    </>
  )
}

const QmeListItems: FC = () => {
  const { data: benefitInfo } = useBenefit()
  const country = useSelector(getCountryCode)
  const { data: lineItemFilter } = useCompanyComplianceConfigLineItemFilter()
  const preservation = benefitInfo.journeys.fertilityPreservation
  const fertilityCare = benefitInfo.journeys.fertilityCare
  const hasElectiveFertilityLineItemFilter = lineItemFilter === "ELECTIVE_FERTILITY"
  const menopause = benefitInfo.journeys.menopause
  const lowT = benefitInfo.journeys.lowTestosterone
  const gac = benefitInfo.journeys.genderAffirmingCare

  return (
    <>
      <EligibleFertilityText
        eligibleExpensesDetails={fertilityCare.eligibleExpensesDetail}
        lineItemFilter={lineItemFilter}
      />
      {preservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered &&
        !preservation.eligibleExpensesDetail.isElectivePreservationCovered &&
        !hasElectiveFertilityLineItemFilter && (
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage={
                  "Eligible <link>fertility preservation</link> expenses related to an" +
                  " infertility diagnosis or medical necessity"
                }
                values={{
                  link: (linkContent) => (
                    <Link target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </p>
          </UnorderedListItem>
        )}
      {preservation.eligibleExpensesDetail.isMedicallyNecessaryPreservationCovered &&
        preservation.eligibleExpensesDetail.isElectivePreservationCovered && (
          <UnorderedListItem>
            <p className="lh-copy">
              <FormattedMessage
                defaultMessage="Eligible <link>fertility preservation</link> expenses except:"
                values={{
                  link: (linkContent) => (
                    <Link target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </p>
            <Spacer height={1} />
            <UnorderedList disablePadding={true}>
              {!hasElectiveFertilityLineItemFilter && (
                <UnorderedListItem>
                  <p className="lh-copy">
                    <FormattedMessage defaultMessage="Expenses that aren't related to an infertility diagnosis or medical necessity as determined by a fertility doctor" />
                  </p>
                </UnorderedListItem>
              )}
              <UnorderedListItem>
                <p className="lh-copy">
                  <FormattedMessage defaultMessage="Storage expenses after the first year" />
                </p>
              </UnorderedListItem>
            </UnorderedList>
          </UnorderedListItem>
        )}
      {menopause.hasEligibleExpenses && !["US", "GB", "AU", "DE"].includes(country) && (
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>menopause</link> expenses"
              values={{
                link: (linkContent) => (
                  <Link target="_blank" href={DbgPaths.MENOPAUSE}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )}
      {menopause.hasEligibleExpenses && ["US", "GB", "AU", "DE"].includes(country) && (
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>menopause</link> expenses except:"
              values={{
                link: (linkContent) => (
                  <Link target="_blank" href={DbgPaths.MENOPAUSE}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </p>
          <Spacer height={1} />
          {["US", "AU"].includes(country) && (
            <UnorderedList disablePadding={true}>
              <UnorderedListItem>
                <p className="lh-copy">
                  <FormattedMessage defaultMessage="Eight Sleep and Perfectly Snug products" />
                </p>
              </UnorderedListItem>
            </UnorderedList>
          )}
          {["GB", "DE"].includes(country) && (
            <UnorderedList disablePadding={true}>
              <UnorderedListItem>
                <p className="lh-copy">
                  <FormattedMessage defaultMessage="Eight Sleep products" />
                </p>
              </UnorderedListItem>
            </UnorderedList>
          )}
        </UnorderedListItem>
      )}
      {lowT.hasEligibleExpenses && (
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>low testosterone</link> expenses"
              values={{
                link: (linkContent) => (
                  <Link target="_blank" href={DbgPaths.LOW_TESTOSTERONE}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )}
      {gac.hasEligibleExpenses && (
        <UnorderedListItem>
          <p className="lh-copy">
            <FormattedMessage
              defaultMessage="Eligible <link>gender-affirming care</link> expenses"
              values={{
                link: (linkContent) => (
                  <Link target="_blank" href={DbgPaths.GENDER_AFFIRMING_CARE}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </p>
        </UnorderedListItem>
      )}
    </>
  )
}

export function getQmeCategorySections(): CategorySections {
  return {
    header: <QmeHeader />,
    listItems: <QmeListItems />,
    includeSub: true
  }
}
