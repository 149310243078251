import { TradingPartner } from "#/components/cmd-enrollment/steps/insurance/ConnectHealthPlanStep/types"
import { InsurancePlanInformationRowData } from "./InsurancePlanInformationRow"
import moment from "moment"
import { getMemberCoverageLabelFromId } from "#/components/cmd-enrollment/steps/MemberCoverageTypeStep"
import { getPartnerCoverageTypeLabelFromId } from "#/components/cmd-enrollment/steps/PartnerCoverageTypeStep"
import { getHdhpStatusLabelFromId } from "#/components/cmd-enrollment/steps/HasHdhpStep"
import { getHsaContributionLabelFromId } from "#/components/cmd-enrollment/steps/HsaContributionStatusStep"
import { getMemberPlanSourceLabelFromId } from "#/components/cmd-enrollment/steps/MemberPlanSourceStep"
import { getPartnerPlanSourceLabelFromId } from "#/components/cmd-enrollment/steps/PartnerPlanSourceStep"
import { getSexLabelFromId } from "#/components/cmd-enrollment/steps/insurance/DobSexStep"
import {
  insuranceQuestions,
  hdhpIrsLimits,
  irsLimitsDeductibleMemberText,
  irsLimitsOutOfPocketText
} from "./insuranceQuestions"
import { useIntl, defineMessage } from "react-intl"
import { returnApplicableYear } from "#/utils/Helpers"

export const useGetInsurancePlanDetailsData = (
  userInfo: any,
  tradingPartners: TradingPartner[],
  insuranceExpired: boolean,
  memberHistory: any
): InsurancePlanInformationRowData[] => {
  // set the healthPlanInfo based on whether or not the insurance is expired
  const healthPlanInfo = insuranceExpired && memberHistory !== undefined ? memberHistory : userInfo

  const memberTradingPartnerName = tradingPartners?.find(
    (tp) => tp.tradingPartnerPk === healthPlanInfo.selectedTradingPartnerPk
  )?.description

  const partnerTradingPartnerName = tradingPartners?.find(
    (tp) => tp.tradingPartnerPk === healthPlanInfo.partnerHealthPlanSelectedTradingPartnerPk
  )?.description

  const intl = useIntl()
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  const nullCheckAndTranslate = (data) => (data ? intl.formatMessage(data) : null)

  return [
    {
      headerLabel: intl.formatMessage({ defaultMessage: "Health insurance name" }),
      memberData: memberTradingPartnerName,
      partnerData: partnerTradingPartnerName
    },
    {
      headerLabel: intl.formatMessage({ defaultMessage: "Member ID" }),
      memberData: healthPlanInfo.memberId,
      partnerData: healthPlanInfo.partnerHealthPlanMemberId
    },
    {
      headerLabel: intl.formatMessage({ defaultMessage: "Sex" }),
      memberData: nullCheckAndTranslate(getSexLabelFromId(userInfo.sex)),
      partnerData: nullCheckAndTranslate(getSexLabelFromId(userInfo.partnerSex)),
      helperText: intl.formatMessage({ defaultMessage: "(as stated on your health insurance plan)" })
    },
    {
      headerLabel: intl.formatMessage({ defaultMessage: "Date of birth" }),
      memberData: userInfo.dateOfBirth
        ? intl.formatDate(moment(userInfo.dateOfBirth).toDate(), {
            year: "numeric",
            month: "short",
            day: "2-digit"
          })
        : null,
      partnerData: userInfo.partnerDateOfBirth
        ? intl.formatDate(moment(userInfo.partnerDateOfBirth).toDate(), {
            year: "numeric",
            month: "short",
            day: "2-digit"
          })
        : null,
      helperText: intl.formatMessage({ defaultMessage: "(as stated on your health insurance plan)" })
    }
  ]
}

export const useGetPlanProviderDetailsData = (
  userInfo: any,
  insuranceExpired: boolean,
  memberHistory: any
): InsurancePlanInformationRowData[] => {
  // set the healthPlanInfo based on whether or not the insurance is expired
  const healthPlanInfo = insuranceExpired && memberHistory ? memberHistory : userInfo
  const intl = useIntl()
  const yesOrNo = (val: boolean): string =>
    val ? intl.formatMessage({ defaultMessage: "Yes" }) : intl.formatMessage({ defaultMessage: "No" })
  const applicableYear = returnApplicableYear(hdhpIrsLimits)
  const dateRangeText = defineMessage({ defaultMessage: "{START_DATE}-{END_DATE}" })
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  const nullCheckAndTranslate = (data) => (data ? intl.formatMessage(data) : null)

  return [
    {
      headerLabel: nullCheckAndTranslate(insuranceQuestions.WHERE_DO_YOU_GET_HEALTH_INSURANCE),
      memberData: nullCheckAndTranslate(getMemberPlanSourceLabelFromId(healthPlanInfo.memberPlanSourceSelectValue)),
      partnerData: nullCheckAndTranslate(getPartnerPlanSourceLabelFromId(healthPlanInfo.partnerPlanSourceSelectValue))
    },
    {
      headerLabel: nullCheckAndTranslate(insuranceQuestions.WHO_DOES_YOUR_PLAN_COVER),
      memberData: nullCheckAndTranslate(getMemberCoverageLabelFromId(healthPlanInfo.memberCoverageTypeSelectValue)),
      partnerData: nullCheckAndTranslate(
        getPartnerCoverageTypeLabelFromId(healthPlanInfo.partnerCoverageTypeSelectValue)
      )
    },
    {
      headerLabel: nullCheckAndTranslate(insuranceQuestions.DO_YOU_HAVE_AN_HDHP),
      memberData: nullCheckAndTranslate(getHdhpStatusLabelFromId(healthPlanInfo.memberHasHDHP)),
      partnerData: nullCheckAndTranslate(getHdhpStatusLabelFromId(healthPlanInfo.partnerHasHDHP)),
      dialogText: [
        intl.formatMessage(irsLimitsDeductibleMemberText, {
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          DEDUCTIBLE_INDIVIDUAL_MIN: intl.formatNumber(hdhpIrsLimits[applicableYear]["DEDUCTIBLE_INDIVIDUAL_MIN"], {
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
            maximumFractionDigits: 0
          }),
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          DEDUCTIBLE_FAMILY_MIN: intl.formatNumber(hdhpIrsLimits[applicableYear]["DEDUCTIBLE_FAMILY_MIN"], {
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
            maximumFractionDigits: 0
          })
        }),
        intl.formatMessage(irsLimitsOutOfPocketText, {
          OUT_OF_POCKET_INDIVIDUAL_MAX: intl.formatNumber(
            // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            hdhpIrsLimits[applicableYear]["OUT_OF_POCKET_INDIVIDUAL_MAX"],
            {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            }
          ),
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          OUT_OF_POCKET_FAMILY_MAX: intl.formatNumber(hdhpIrsLimits[applicableYear]["OUT_OF_POCKET_FAMILY_MAX"], {
            style: "currency",
            currency: "USD",
            currencyDisplay: "narrowSymbol",
            maximumFractionDigits: 0
          })
        }),
        intl.formatMessage({
          defaultMessage:
            "For help understanding your plan details, we recommend contacting your insurance provider or benefits manager."
        })
      ],
      dialogTitle: intl.formatMessage({
        defaultMessage: "High deductible health plan (HDHP)"
      })
    },
    {
      headerLabel: nullCheckAndTranslate(insuranceQuestions.ARE_YOU_CONTRIBUTING_TO_AN_HSA),
      memberData: nullCheckAndTranslate(getHsaContributionLabelFromId(healthPlanInfo.memberHsaContributionStatus)),
      partnerData: nullCheckAndTranslate(getHsaContributionLabelFromId(healthPlanInfo.partnerHsaContributionStatus)),
      dialogText: [
        intl.formatMessage({
          defaultMessage:
            "An HSA lets you use pre-tax income to pay for eligible medical expenses, and is only available with an HDHP."
        }),
        intl.formatMessage({
          defaultMessage:
            "For help understanding your plan details, we recommend contacting your insurance provider or benefits manager."
        })
      ],
      dialogTitle: intl.formatMessage({
        defaultMessage: "Health savings account (HSA)"
      })
    },
    {
      headerLabel: nullCheckAndTranslate(insuranceQuestions.ARE_YOU_MARRIED),
      memberData: yesOrNo(healthPlanInfo.memberHasLegalPartner),
      partnerData: null
    },
    {
      headerLabel: intl.formatMessage(insuranceQuestions.WHEN_IS_YOUR_DEDUCTIBLE_PERIOD),
      memberData: healthPlanInfo.memberCoverageStartDate
        ? intl.formatMessage(dateRangeText, {
            START_DATE: intl.formatDate(moment(healthPlanInfo.memberCoverageStartDate).toDate(), {
              year: "numeric",
              month: "short",
              day: "2-digit"
            }),
            END_DATE: intl.formatDate(moment(healthPlanInfo.memberCoverageEndDate).toDate(), {
              year: "numeric",
              month: "short",
              day: "2-digit"
            })
          })
        : null,
      partnerData: healthPlanInfo.partnerCoverageStartDate
        ? intl.formatMessage(dateRangeText, {
            START_DATE: intl.formatDate(moment(healthPlanInfo.partnerCoverageStartDate).toDate(), {
              year: "numeric",
              month: "short",
              day: "2-digit"
            }),
            END_DATE: intl.formatDate(moment(healthPlanInfo.partnerCoverageEndDate).toDate(), {
              year: "numeric",
              month: "short",
              day: "2-digit"
            })
          })
        : null
    }
  ].filter(({ memberData, partnerData }) => Boolean(memberData) || Boolean(partnerData))
}
