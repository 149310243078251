import React from "react"
import { Alert, Link } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { TtcPaths } from "#/utils/Paths"

export const invalidAddressErrorAlert = (
  <Alert severity="error">
    <FormattedMessage
      defaultMessage="We’re having trouble parsing your address. Please double check for errors and resubmit. If the problem persists, <link>contact us</link>."
      values={{
        link: (linkContent) => (
          <Link color="inherit" href={TtcPaths.TTC_CARE_NAV} target={"_blank"}>
            {linkContent}
          </Link>
        )
      }}
    />
  </Alert>
)
