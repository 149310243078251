import { Box, ExternalIcon, InternalIcon, Link, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import React, { useEffect } from "react"
import { ContentfulContentEntry, ContentfulContentRenderer } from "#/services/contentful"
import useChannelId from "./hooks/useGetChannelId"
import { getQuickLinksEntries } from "./getQuickLinksEntries"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { useIntl } from "react-intl"
import { useCurrentUser } from "#/components/context/user/UserContext"

const ENV_AGNOSTIC_CARROT_URL = "get-carrot.com"

// This overrides the link styling default for Contentful
const styleOverrides = {
  a: {
    component: ({ href, children, ...otherProps }: { href: string; children: React.ReactNode }) => {
      return (
        <Link target="_blank" href={href} endIcon={getIconType(href)} display={"inline-block"} {...otherProps}>
          {children}
        </Link>
      )
    }
  }
}

function getIconType(href: string): React.JSX.Element {
  return href.includes(ENV_AGNOSTIC_CARROT_URL) ? <InternalIcon fontSize="small" /> : <ExternalIcon fontSize="small" />
}

function areAnyValuesNull(values: Array<any>): boolean {
  return values.some((value) => value == null)
}

export function QuickLinks(): React.JSX.Element {
  const { data: channelId, isLoading } = useChannelId()
  const { company } = useCurrentUser()
  const [content, setContent] = React.useState<Array<ContentfulContentEntry>>(null)
  const [title, setTitle] = React.useState<string>(null)
  const [helperText, setHelperText] = React.useState<string>(null)
  const theme = useTheme()
  const intl = useIntl()
  const { locale } = intl

  useEffect(() => {
    // See https://github.com/facebook/react/issues/14326 for guidance on async useEffect
    let didCancel = false
    async function fetchQuickLinks() {
      try {
        const quickLinkEntry = await getQuickLinksEntries(locale, channelId, company.parentCompanyId)
        if (quickLinkEntry) {
          setContent(quickLinkEntry.fields?.links)
          setTitle(quickLinkEntry.fields?.title)
          setHelperText(quickLinkEntry.fields?.helperText)
        }
      } catch (error) {
        if (!didCancel) {
          reportErrorMessage(
            `Fetching failure for quick links component with channelId=${channelId}, parentCompanyId=${company.parentCompanyId}. Error: ${error}`
          )
        }
      }
    }
    fetchQuickLinks()
    return () => {
      didCancel = true
    }
  }, [locale, channelId, company.parentCompanyId])

  if (areAnyValuesNull([isLoading, content, title, helperText])) {
    return null
  }

  return (
    <Tile
      data-testid="health-plan-quick-links"
      sx={{
        border: 1,
        borderRadius: theme.tokens.borderRadius.md,
        borderColor: theme.palette.divider,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(theme.tokens.spacing.lg)
      }}
    >
      <Typography fontWeight="bold" color={(theme) => theme.palette.text.primary}>
        {title}
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary} paddingY={theme.spacing(theme.tokens.spacing.lg)}>
        {helperText}
      </Typography>
      <Box sx={{ columnCount: 2 }}>
        <ContentfulContentRenderer contents={content} overrides={styleOverrides} />
      </Box>
    </Tile>
  )
}
