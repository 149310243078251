import React from "react"
import { RetinaImage } from "./images/RetinaImage"
import { Box } from "@carrotfertility/carotene-core"

// @ts-expect-error TS(7031) FIXME: Binding element 'className' implicitly has an 'any... Remove this comment to see the full error message
const IlloUnderwaterDesktop = ({ className }) => (
  <RetinaImage className={className} name="illo-underwater-desktop" width={265} height={230} />
)

const IlloCircleCarrotCards = () => (
  <Box
    alt=""
    component="img"
    height={228}
    width={228}
    src={`/images/illo-carrot-cards.png`}
    srcSet={`../images/illo-carrot-cards-2x.png`}
  />
)

const IlloUnderwaterPlants = ({ height = 130, width = 212 }) => (
  <RetinaImage name="illo-underwater-plants" width={width} height={height} />
)

const IlloCarrotCards = () => <RetinaImage alt="" name="illo-carrot-cards" width={192} height={192} />

const IlloDocAndCoins = ({ className = null }) => (
  <RetinaImage name="illo-doc-and-coins" height={228} width={228} className={className} />
)

const IlloPercentCircle = ({ height = 228 }) => (
  <RetinaImage name="illo-percent-circle" height={height} width={height} />
)

const IlloBlocks = () => <RetinaImage name="illo-blocks" height={228} width={228} />

const IlloCoinsAndBills = () => <RetinaImage alt="" name="illo-coins-and-bills" height={228} width={228} />

const IlloCalendar = ({ height = 88, width = 88 }) => <RetinaImage name="illo-calendar" height={height} width={width} />

export {
  IlloCircleCarrotCards,
  IlloUnderwaterPlants,
  IlloCarrotCards,
  IlloDocAndCoins,
  IlloPercentCircle,
  IlloBlocks,
  IlloCoinsAndBills,
  IlloCalendar,
  IlloUnderwaterDesktop
}
