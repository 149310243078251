import React, { FC, useEffect } from "react"
import { BodyWithLeftSidebarLayout } from "../components/views/atoms/Layouts"
import { Redirect, Route } from "react-router-dom"
import { Paths } from "../utils/Paths"
import { TypePage, TypePageSetSkeleton } from "#/types/contentful"
import { useContentfulConditionalGate } from "../lib/contentful/components/ContentfulConditionGate"
import { ErrorPage } from "#/components/views/app/ErrorPage"
// eslint-disable-next-line no-restricted-imports
import { FlexContainer } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { DigitalBenefitsGuideNav } from "../components/digital-benefit-guide/DBGNav"
import { useQuery } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { getContentfulClient } from "#/lib/contentful/contentfulClient"
import { createErrorBoundary } from "../utils/createErrorBoundary"
import { Container } from "@carrotfertility/carotene-core"
import { ContentfulContentRenderer } from "#/services/contentful"
import { defaultMarkdownElements } from "#/services/markdown"
import { toCondition } from "#/lib/contentful/utils/conditionUtils"
import { useDoesUserSatisfyConditions } from "#/services/contentful/conditional-gate"
import { useIntl } from "react-intl"
import { useConditionDepsStatus } from "#/services/contentful/hooks/use-contentful-conditions"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"
import { getContentfulLocale } from "../lib/contentful/utils/contentfulLocaleUtils"
import { Switch } from "#/services/routing/Switch"
import useUpdateMemberActionTracking from "#/services/memberActionTracking/useUpdateMemberActionTracking"
import { MemberActions } from "#/services/memberActionTracking/memberActions"
import ContentTypes from "#/utils/ContentTypes"
import { FullScreenLoading } from "#/components/read/shared/FullScreenLoading"

const GET_PAGE_SETS_QUERY_KEY = "GET_PAGE_SETS"

const getDbgPageSets = async (locale: string) => {
  const client = getContentfulClient()
  const { items } = await client.withoutUnresolvableLinks.getEntries<TypePageSetSkeleton>({
    content_type: ContentTypes.PAGE_SET,
    include: 10,
    locale: getContentfulLocale(locale)
  })
  return items
}

const useQueryDbgPageSets = (locale: string) => {
  return useQuery([GET_PAGE_SETS_QUERY_KEY, locale], () => getDbgPageSets(locale), {
    refetchOnWindowFocus: false,
    retry: buildCarrotApiRetryPolicy(3)
  })
}

const DBGErrorPage: FC = () => (
  <PageLayout>
    <FlexContainer justifyContent="center" alignItems="center" height="calc(100% - 133px)">
      <ErrorPage />
    </FlexContainer>
  </PageLayout>
)

const BenefitGuideOuterErrorBoundary = createErrorBoundary(DBGErrorPage)
const YourBenefitGuide: FC = () => {
  return (
    <BenefitGuideOuterErrorBoundary>
      <YourBenefitGuideInnerContent />
    </BenefitGuideOuterErrorBoundary>
  )
}

type PageEntry = TypePage<"WITHOUT_UNRESOLVABLE_LINKS">

const DBGRouting = ({ topLevelPages }: { topLevelPages: PageEntry[] }): JSX.Element => {
  const { doesUserSatisfyConditions } = useDoesUserSatisfyConditions()
  const [firstPage] = topLevelPages.filter((page) => doesUserSatisfyConditions(page.fields.conditions.map(toCondition)))
  if (!firstPage) {
    throw Error("No valid benefit guide home page for member")
  }

  const homePage = `${Paths.YOUR_BENEFIT_GUIDE}/${firstPage.fields.slug}`
  return (
    <Switch>
      <Redirect exact from={Paths.YOUR_BENEFIT_GUIDE} to={homePage} />
      <Redirect
        from={`${Paths.YOUR_BENEFIT_GUIDE}/coverage/newborn-care-and-parent-support`}
        to={`${Paths.YOUR_BENEFIT_GUIDE}/coverage/parenting`}
      />
      <Redirect
        from={`${Paths.YOUR_BENEFIT_GUIDE}/support/newborn-care-and-parent-support`}
        to={`${Paths.YOUR_BENEFIT_GUIDE}/support/parenting`}
      />
      {RoutesToPages({ pages: topLevelPages, baseUrl: Paths.YOUR_BENEFIT_GUIDE })}
      <Redirect to={homePage} />
    </Switch>
  )
}

const BenefitGuideErrorBoundary = createErrorBoundary(ErrorPage)

const YourBenefitGuideInnerContent: FC = () => {
  const intl = useIntl()
  const { locale } = intl
  const { data: pageSets, isLoading, isError } = useQueryDbgPageSets(locale)

  const { data: benefit } = useBenefit()
  const { isLoading: areConditionDepsLoading, isError: isConditionDepsError } = useConditionDepsStatus()

  const { mutate: updateMemberActions } = useUpdateMemberActionTracking()

  useEffect(() => {
    updateMemberActions(MemberActions.DIGITAL_BENEFITS_GUIDE_VIEWED)
  }, [updateMemberActions])

  if (isLoading || areConditionDepsLoading) {
    return <FullScreenLoading />
  }
  if (isError || isConditionDepsError) {
    return <DBGErrorPage />
  }

  // maintaining the naming convention in here to match Contentful PageSet's name 'digital_benefits_guide'
  const digitalBenefitsGuideFields = pageSets.find((pageSet) =>
    benefit.program.isCarrotLite
      ? pageSet?.fields.key === "core_digital_benefits_guide"
      : pageSet?.fields.key === "pro_digital_benefits_guide"
  ).fields

  const topLevelPages = digitalBenefitsGuideFields?.topLevelPages ?? []

  const nav = <DigitalBenefitsGuideNav pages={topLevelPages} key="DBG_nav" />

  return (
    <PageLayout
      header={
        <PageHeader
          startGlyph={
            <PageHeaderGlyph
              src={digitalBenefitsGuideFields?.icon.fields.file.url}
              srcSet={digitalBenefitsGuideFields?.icon.fields.file.url}
            />
          }
          pageTitle={digitalBenefitsGuideFields?.title}
        />
      }
    >
      <BenefitGuideErrorBoundary>
        <BodyWithLeftSidebarLayout sideBarElements={[nav]}>
          <div className="w-100">
            <DBGRouting topLevelPages={topLevelPages} />
          </div>
        </BodyWithLeftSidebarLayout>
      </BenefitGuideErrorBoundary>
    </PageLayout>
  )
}

function BenefitGuidePage(props: { page: PageEntry }) {
  return (
    <Container maxWidth="md" disableGutters component="article">
      <defaultMarkdownElements.h1.component {...defaultMarkdownElements.h1.props} component="h2">
        {props.page.fields?.title}
      </defaultMarkdownElements.h1.component>
      <ContentfulContentRenderer contents={props.page.fields?.content} overrides={{ br: (): null => null }} />
    </Container>
  )
}

const PageOrRedirect = ({ pages }: { pages: PageEntry[] }): JSX.Element => {
  const pageConditions = pages?.map((page, i) => {
    return {
      element: <BenefitGuidePage page={page} key={i} />,
      conditions: page.fields.conditions
    }
  })
  const pageContents = useContentfulConditionalGate(pageConditions ? pageConditions : [])
  return pageContents.length === 0 ? <Redirect to={`${Paths.YOUR_BENEFIT_GUIDE}/`} /> : <>{pageContents}</>
}

const findPagesBySlug = (pages: Array<PageEntry>, slug: string): PageEntry[] =>
  pages
    .flat(Infinity)
    .filter((a) => a !== undefined)
    .filter((a) => a.fields.slug.trim() === slug.trim())

const RoutesToPages = ({ pages, baseUrl }: { pages: PageEntry[]; baseUrl: string }): React.ReactNode[] => {
  if (!pages) return []
  return pages
    ?.map(
      (page) =>
        page.fields && [
          <Route
            key={page.sys.id}
            exact
            path={`${baseUrl}/${page.fields.slug}`}
            render={() => <PageOrRedirect pages={findPagesBySlug(pages, page.fields.slug)} />}
          />,
          ...RoutesToPages({ pages: page.fields.childPages, baseUrl: `${baseUrl}/${page.fields.slug}` })
        ]
    )
    .flat()
}

export { YourBenefitGuide }
