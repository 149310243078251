import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { NiceToSeeYou } from "../../../views/register/NiceToSeeYou"
import { usePartnerRegistrationFlow } from "../PartnerRegistrationFlow"
import { useGetRegisteringPartner } from "../../hooks/usePartnerAccess"

export function WelcomeToCarrotStep(): JSX.Element {
  const { guid } = usePartnerRegistrationFlow()
  const { registeringUser } = useGetRegisteringPartner(guid)
  const { send } = useStateMachine(null)

  return (
    <NiceToSeeYou
      companyDisplayName={registeringUser.companyDisplayName}
      benefit={registeringUser.benefit}
      onComplete={() => send("")}
      employeeId={registeringUser.employeeId}
    />
  )
}
