import React from "react"
import InsuranceDetails from "#/components/insurance-details"
import { InsuranceDetailsProvider } from "#/components/insurance-details/context/useInsuranceDetails"
// import { CenteredLayout } from "#/components/views/atoms/Layouts"
import { ReimbursementStatusProvider } from "#/components/context/reimbursement/ReimbursementStatusContext"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25

import { DeductibleProvider } from "#/components/context/user/DeductibleContext"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { Redirect } from "react-router-dom"
import EmployeeOnlySpaceContainer from "#/components/employee-only-containers/EmployeeOnlySpaceContainer"
import { useIntl } from "react-intl"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"

const HealthInsuranceDetailsPage = (): JSX.Element => {
  const { shouldDisplayInsuranceDetails } = useBenefitEnrollment()

  const intl = useIntl()
  const InsuranceHeader = (): JSX.Element => (
    <PageHeader
      startGlyph={<PageHeaderGlyph src="/images/icn-insurance.png" srcSet="/images/icn-insurance-2x.png" />}
      pageTitle={intl.formatMessage({ defaultMessage: "Health insurance details" })}
    />
  )

  if (!shouldDisplayInsuranceDetails) {
    return <Redirect to="/" />
  }

  return (
    <PageLayout header={<InsuranceHeader />}>
      <EmployeeOnlySpaceContainer>
        <DeductibleProvider>
          <InsuranceDetailsProvider>
            <ReimbursementStatusProvider>
              <Container sx={{ paddingBlockEnd: "64px" }}>
                <InsuranceDetails />
              </Container>
            </ReimbursementStatusProvider>
          </InsuranceDetailsProvider>
        </DeductibleProvider>
      </EmployeeOnlySpaceContainer>
    </PageLayout>
  )
}

export default HealthInsuranceDetailsPage
