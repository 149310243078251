import { Provider, ProviderCount } from "../types/Providers"
import { Country } from "./Countries"
import { defineMessage, MessageDescriptor } from "react-intl"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { ProviderTypeSearched } from "#/pages/provider-finder/components/results/useProviderTypeSearched"

interface ChildrenProviderTypeIds {
  [providerTypeId: string]: { tag: MessageDescriptor }
}
interface parentIdDisplay {
  fullDisplayName: MessageDescriptor
  combinedDisplayName: MessageDescriptor
  tag?: MessageDescriptor
  getSecondaryText?: ({ countryCode }: { countryCode: string }) => MessageDescriptor
  filter?: (benefit: Benefit) => boolean
  childrenProviderTypeIds?: ChildrenProviderTypeIds
  providerTypeSearched?: ProviderTypeSearched
}

export const PARENT_ID_MAPPING: { [id: string]: parentIdDisplay } = {
  1: {
    fullDisplayName: defineMessage({ defaultMessage: "Fertility clinics" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Fertility clinics" }),
    tag: defineMessage({ defaultMessage: "Fertility" }),
    filter: ({ providerFinder: { showFertilityClinics } }) => showFertilityClinics,
    providerTypeSearched: ProviderTypeSearched.FertilityClinics
  },
  2: {
    fullDisplayName: defineMessage({ defaultMessage: "Reproductive urology" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Reproductive urology" }),
    tag: defineMessage({ defaultMessage: "Urology" }),
    filter: ({ providerFinder: { showReproductiveUrology } }) => showReproductiveUrology
  },
  3: {
    fullDisplayName: defineMessage({ defaultMessage: "Adoption" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Adoption agencies, legal services, and social workers" }),
    tag: defineMessage({ defaultMessage: "Adoption" }),
    getSecondaryText: () => defineMessage({ defaultMessage: "agencies, legal services, and social workers" }),
    filter: ({ providerFinder: { showAdoption } }) => showAdoption
  },
  5: {
    fullDisplayName: defineMessage({ defaultMessage: "Cryobanks/storage facilities" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Cryobanks/storage facilities" }),
    tag: defineMessage({ defaultMessage: "Cryobanks" }),
    filter: ({ providerFinder: { showCryobanks } }) => showCryobanks
  },
  6: {
    fullDisplayName: defineMessage({ defaultMessage: "Low testosterone" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Low testosterone endocrinologists and urologists" }),
    getSecondaryText: () => defineMessage({ defaultMessage: "endocrinologists and urologists" }),
    childrenProviderTypeIds: {
      35: {
        tag: defineMessage({ defaultMessage: "LOW T - ENDOCRINOLOGIST" })
      },
      36: {
        tag: defineMessage({ defaultMessage: "LOW T - UROLOGIST" })
      }
    },
    filter: ({ providerFinder: { showLowTestosterone } }) => showLowTestosterone,
    providerTypeSearched: ProviderTypeSearched.LowTestosterone
  },
  7: {
    fullDisplayName: defineMessage({ defaultMessage: "Menopause specialists" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Menopause specialists" }),
    tag: defineMessage({ defaultMessage: "Menopause" }),
    filter: ({ providerFinder: { showMenopause } }) => showMenopause,
    providerTypeSearched: ProviderTypeSearched.Menopause
  },
  34: {
    fullDisplayName: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    tag: defineMessage({ defaultMessage: "Gender-affirming hormone therapy" }),
    filter: ({ providerFinder: { showGenderAffirmingHormoneTherapy } }) => showGenderAffirmingHormoneTherapy,
    providerTypeSearched: ProviderTypeSearched.GenderAffirmingHormoneTherapy
  },
  35: {
    fullDisplayName: defineMessage({ defaultMessage: "Donor assistance" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Donor assistance" }),
    tag: defineMessage({ defaultMessage: "Donor assistance" }),
    filter: ({ providerFinder: { showDonorAssistance } }) => showDonorAssistance
  },
  36: {
    fullDisplayName: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    combinedDisplayName: defineMessage({ defaultMessage: "Gestational surrogacy agencies, legal services, and more" }),
    tag: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    getSecondaryText: ({ countryCode }) =>
      countryCode === Country.INDIA.code
        ? defineMessage({ defaultMessage: "clinics, legal services, and more" })
        : defineMessage({ defaultMessage: "agencies, legal services, and more" }),
    filter: ({ providerFinder: { showGestationalSurrogacy } }) => showGestationalSurrogacy
  }
}
export const PARENT_ID_TO_PROVIDER_COUNT_MAPPING: Record<string, keyof ProviderCount> = {
  1: "hasFertilityProviders",
  2: "hasUrologyProviders",
  3: "hasAdoptionProviders",
  5: "hasCryobanksProviders",
  6: "hasLowTProviders",
  7: "hasMenopauseProviders",
  34: "hasGenderAffirmingHormoneTherapyProviders",
  35: "hasDonorAssistedReproductionProviders",
  36: "hasGestationalCarrierProviders"
}

export function filterParentIdByBenefitConfig(benefit: Benefit): (parentId: string | number) => boolean {
  return (parentId) => {
    if (PARENT_ID_MAPPING[parentId]) {
      return !PARENT_ID_MAPPING[parentId].filter || PARENT_ID_MAPPING[parentId].filter(benefit)
    }
    return false
  }
}

export function filterProviderByBenefitConfig(benefit: Benefit): (provider: Provider) => boolean {
  return (provider) => provider.parentIds.some(filterParentIdByBenefitConfig(benefit))
}

export function getAvailableParentIdsByBenefitConfig(benefit: Benefit): string[] {
  return Object.keys(PARENT_ID_MAPPING).filter(filterParentIdByBenefitConfig(benefit))
}
