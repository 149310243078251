import {
  CarrotMatchAnswer,
  CarrotMatchInteractionPayload,
  CarrotMatchState,
  FullMatchData,
  InteractionData,
  QUESTION_OPTION_MAP,
  Questions
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export function createCarrotMatchInteractionPayload(state: CarrotMatchState): CarrotMatchInteractionPayload {
  const formattedQuestionsAndAnswers = state.questionsAndAnswers?.map((qa) => {
    const { question, answer } = qa
    const availableOptions = Object.values(QUESTION_OPTION_MAP[question])

    if (question === Questions.TREATMENT_TYPE_QUESTION) {
      return {
        question,
        answer: availableOptions.map((option) => ({
          text: option,
          selected: option === answer
        }))
      }
    } else {
      const multipleAnswers = Array.isArray(answer) ? answer : []
      return {
        question,
        answer: availableOptions.map((option) => ({
          text: option,
          selected: multipleAnswers.includes(option as CarrotMatchAnswer)
        }))
      }
    }
  })

  const formattedMatches: FullMatchData[] = state.matches.map((match) => {
    const matchedProvider = state.matchedProviders?.find((provider) => provider.providerUuid === match.providerUuid)

    return {
      ...matchedProvider,
      matchScore: match.matchScore,
      providerUuid: match.providerUuid
    }
  })

  const interactionData: InteractionData = {
    triedAgainFromInteractionId: state.triedAgainFromInteractionId,
    whoIsAnsweringTheIntake: state.whoIsAnsweringTheIntake,
    whoIsSeekingCare: state.whoIsSeekingCare,
    lastMatchViewedBeforeLeavingFlowIndex: state.lastMatchViewedBeforeLeavingFlowIndex,
    lastMatchViewedBeforeLeavingFlowProviderUuid: state.lastMatchViewedBeforeLeavingFlowProviderUuid,
    lastQuestionViewedBeforeLeavingFlow: state.lastQuestionViewedBeforeLeavingFlow,
    location: { lat: state.location.lat, long: state.location.long },
    questionsAndAnswers: formattedQuestionsAndAnswers,
    matches: formattedMatches
  }

  const payload: CarrotMatchInteractionPayload = {
    externalId: state.interactionId,
    entryPoint: state.entryPoint,
    startedAtUtc: state.startedAtUtc,
    medicalInfoId: state.medicalInfo.medicalInfoId,
    hasCompletedIntakeFlow: state.questionsAndAnswers?.at(-1)?.question === Questions.WHAT_ELSE_MATTERS_TO_XYZ_QUESTION,
    hasMatches: Boolean(state.matches.length),
    hasViewedMatches: Boolean(state.lastMatchViewedBeforeLeavingFlowProviderUuid),
    interactionDataJson: JSON.stringify(interactionData)
  }

  return payload
}
