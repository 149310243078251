/**
 * Utilities for managing multi-factor authentication remember device settings in local storage
 * This module provides functions to store, retrieve, and remove the remember device selection.
 */

type DeviceIdentifier = {
  key: string
  timestamp: string
}

const STORAGE_KEY = "rememberDevice"

const getDeviceIdentifiers = (): DeviceIdentifier[] => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY)) ?? []
  } catch {
    return []
  }
}

const saveDeviceIdentifiers = (identifiers: DeviceIdentifier[]): void => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(identifiers))
}

export const getRememberDeviceTimestamp = (email: string): string | undefined => {
  const key = btoa(email)
  const identifiers = getDeviceIdentifiers()
  return identifiers.find((identifier) => identifier.key === key)?.timestamp
}

export const setRememberDevice = (email: string, timestamp: string): void => {
  const key = btoa(email)
  const identifiers = getDeviceIdentifiers()

  const existingIndex = identifiers.findIndex((identifier) => identifier.key === key)
  if (existingIndex >= 0) {
    identifiers[existingIndex] = { key, timestamp }
  } else {
    identifiers.push({ key, timestamp })
  }

  saveDeviceIdentifiers(identifiers)
}

export const removeRememberedDevice = (email: string): void => {
  const key = btoa(email)
  const identifiers = getDeviceIdentifiers()

  const updatedIdentifiers = identifiers.filter((identifier) => identifier.key !== key)
  saveDeviceIdentifiers(updatedIdentifiers)
}
