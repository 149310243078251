import React, { useEffect } from "react"
import { Button, DialogContent, DialogTitle, ForwardIcon, Stepper } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { User } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useCurrentUser } from "#/components/context/user/UserContext"

function WhoWillBeReceivingTheCareStep({ signedInAs }: { signedInAs: User }): JSX.Element {
  const { send } = useStateMachine()
  const { updateCarrotMatchInteraction } = useCarrotMatch()
  const intl = useIntl()
  const { firstName, preferredName, partnerFirstName, partnerPreferredName } = useCurrentUser()

  const memberPreferredNameOrFirstName = preferredName || firstName
  const partnerPreferredNameOrFirstName = partnerPreferredName || partnerFirstName

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: "Who will be receiving the care?" })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const updateCarrotMatchInteractionAndProceedToNextStep: FormOnSubmitHandler<{
    whoWillBeReceivingTheCare: string
  }> = ({ whoWillBeReceivingTheCare }) => {
    switch (whoWillBeReceivingTheCare) {
      case User.MEMBER:
        updateCarrotMatchInteraction({
          whoIsSeekingCare: User.MEMBER,
          memberPreferredNameOrFirstName: memberPreferredNameOrFirstName
        })
        break
      case User.PARTNER:
        updateCarrotMatchInteraction({
          whoIsSeekingCare: User.PARTNER,
          partnerPreferredNameOrFirstName: partnerPreferredNameOrFirstName
        })
        break
    }
    send("")
  }
  return (
    <>
      <Form onSubmit={updateCarrotMatchInteractionAndProceedToNextStep}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={0} position="static" />
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Who will be receiving the care?" />
        </DialogTitle>
        <DialogContent>
          <FormRadioButtonGroup
            aria-labelledby="step-heading"
            name="whoWillBeReceivingTheCare"
            controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
            options={[
              {
                label: intl.formatMessage({ defaultMessage: "Me" }),
                value: signedInAs === User.MEMBER ? User.MEMBER : User.PARTNER
              },
              {
                label: signedInAs === User.MEMBER ? partnerPreferredNameOrFirstName : memberPreferredNameOrFirstName,
                value: signedInAs === User.MEMBER ? User.PARTNER : User.MEMBER
              }
            ]}
          />
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="outlined" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
        />
      </Form>
    </>
  )
}

export function WhoWillBeReceivingTheCareStepForMember(): JSX.Element {
  return <WhoWillBeReceivingTheCareStep signedInAs={User.MEMBER} />
}

export function WhoWillBeReceivingTheCareStepForPartner(): JSX.Element {
  return <WhoWillBeReceivingTheCareStep signedInAs={User.PARTNER} />
}
