import React from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from carotene-core or carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import styled from "styled-components"
import { Box, Progress } from "@carrotfertility/carotene-core"

export const CenteredLoadingIndicator = ({ id = "centered-loading-indicator", ...otherProps }) => (
  <Box display="flex" justifyContent="center" alignItems="center" flex={"auto"} {...otherProps}>
    <Progress id={id} data-testid={"centered-loading-indicator"} />
  </Box>
)

/**
 * @deprecated Don't use this component.
 */
export const StylelessButton = styled.button`
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
export const FadeTransition = ({ children }) => (
  <TransitionGroup>
    <CSSTransition timeout={{ exit: 200, enter: 600, appear: 600 }} classNames="fade" appear>
      {children}
    </CSSTransition>
  </TransitionGroup>
)
