// eslint-disable-next-line no-restricted-imports
import { Container, Stack } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { IlloUnderwaterPlants } from "#/components/views/atoms/Illustrations"
import React, { useEffect } from "react"
import { BenefitEnrollmentFlowModalBackButton, BenefitEnrollmentModalContinueButton } from "../shared"
import { Steps } from "../workflow/steps"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import { FlexRowCenter } from "../../views/atoms/Atoms"
import { useCurrentUser } from "../../context/user/UserContext"
import { PartnerPlanSourceOptions } from "./PartnerPlanSourceStep"
import { Link, Typography } from "@carrotfertility/carotene-core"
import { DbgPaths } from "../../../utils/Paths"
import { FormattedMessage, useIntl } from "react-intl"
import { useProgressTracker } from "../../views/modal/ProgressTracker"

const viewName = Steps.HRA_INELIGIBLE

export const HraIneligibleStep = (): JSX.Element => {
  const { setNextStep, gotoPreviousStep } = useStateMachine(viewName, null)
  const { deductibleFlowType, hraEligibility } = useBenefitEnrollment()
  const { partnerPlanSource } = useCurrentUser()

  const isForPartner =
    hraEligibility?.memberHraEligibility ??
    (partnerPlanSource === PartnerPlanSourceOptions.OTHER || partnerPlanSource === PartnerPlanSourceOptions.NOT_INSURED)

  const intl = useIntl()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  return (
    <Stack>
      <Container>
        <BenefitEnrollmentFlowModalBackButton handleGoBack={gotoPreviousStep} />
      </Container>
      <FlexRowCenter>
        <IlloUnderwaterPlants />
      </FlexRowCenter>
      <Typography id="step-heading" variant="h2" color={(theme) => theme.palette.text.primary}>
        {isForPartner
          ? intl.formatMessage({
              defaultMessage: "It looks like your partner's insurance plan is subject to some restrictions."
            })
          : intl.formatMessage({
              defaultMessage: "It looks like your insurance plan is subject to some restrictions."
            })}
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary}>
        {isForPartner
          ? intl.formatMessage({
              defaultMessage:
                "Because your partner doesn't get their health coverage through an employer, Carrot funds can't legally be used for their qualified medical expenses (QMEs). Funds can be used for other types of eligible care."
            })
          : intl.formatMessage({
              defaultMessage:
                "Because you don't get your health coverage through an employer, Carrot funds can't legally be used for your qualified medical expenses (QMEs). Funds can be used for other types of eligible care."
            })}
      </Typography>
      <Typography color={(theme) => theme.palette.text.secondary}>
        <Link color={"inherit"} href={DbgPaths.QUALIFIED_MEDICAL_EXPENSES} target="_blank">
          <FormattedMessage defaultMessage="Learn about QMEs" />
        </Link>
      </Typography>
      <BenefitEnrollmentModalContinueButton onClick={() => setNextStep(viewName, deductibleFlowType)} />
    </Stack>
  )
}
