import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Box, FormControl, Typography } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { Form, FormSelect } from "@carrotfertility/carotene-core-x"
import useGetStandardizedPhoneNumber from "./hooks/useGetStandardizedPhoneNumber"
import useGetLocalizedPhoneNumber from "./hooks/useGetLocalizedPhoneNumber"
import SmsMarketingOptIn from "../../phone-number-collection/SmsMarketingTextOptIn"
import {
  getCountryCallingCodeConsideringEdgeCases,
  usePhoneCodeSelectOptions
} from "../../phone-number-collection/usePhoneCodeSelectOptions"
import FormPhoneNumberTextInput from "../../phone-number-collection/FormPhoneNumberTextInput"
import { useTrackView } from "#/utils/heap"
import { CountryCode } from "#/utils/CountryCode"

interface PhoneNumberCollectionProps {
  phoneNumber?: string
  setPhoneNumber: (newValue: string) => void
  phoneNumberCountryCode?: string
  setPhoneNumberCountryCode: (newValue: string) => void
  allowsMarketingTexts?: boolean
  setAllowsMarketingTexts: (newValue: boolean) => void
  dbFormattedPhoneNumber?: string
  setDbFormattedPhoneNumber: (newValue: string) => void
  isUsa: boolean
  onBack: () => void
  onComplete: () => void
  employeeId: number
}

export function PhoneNumberCollection({
  phoneNumber,
  setPhoneNumber,
  phoneNumberCountryCode,
  setPhoneNumberCountryCode,
  allowsMarketingTexts,
  setAllowsMarketingTexts,
  setDbFormattedPhoneNumber,
  isUsa,
  onBack,
  onComplete,
  employeeId
}: PhoneNumberCollectionProps): JSX.Element {
  const intl = useIntl()
  const { mutateAsync: getStandardizedPhoneNumber } = useGetStandardizedPhoneNumber()
  const { mutateAsync: getLocalizedPhoneNumber } = useGetLocalizedPhoneNumber()
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false)
  const [isCountryCodeError, setIsCountryCodeError] = useState(false)
  const phoneCodeSelectOptions = usePhoneCodeSelectOptions()

  const header = intl.formatMessage({
    defaultMessage: "What is your phone number?"
  })

  useTrackView("PhoneNumberCollectionRegFlow", "Phone number collection screen")

  async function handleValidPhoneNumber(formValues: {
    phoneNumberCountryCode: any
    phoneNumber: any
    allowsMarketingTexts?: boolean
  }): Promise<boolean> {
    try {
      const phoneCountryCode = getCountryCallingCodeConsideringEdgeCases(formValues.phoneNumberCountryCode)
      const phoneNumberWithCountryCode = `${phoneCountryCode}${formValues.phoneNumber}`
      const dbFormattedPhoneNumber = await getStandardizedPhoneNumber({
        phoneNumberWithCountryCode,
        phoneNumberCountryCode: formValues.phoneNumberCountryCode
      })
      const locallyFormattedPhoneNumber = await getLocalizedPhoneNumber({
        phoneNumberWithCountryCode,
        phoneNumberCountryCode: formValues.phoneNumberCountryCode
      })
      if (dbFormattedPhoneNumber != null && locallyFormattedPhoneNumber != null) {
        setPhoneNumber(locallyFormattedPhoneNumber)
        setDbFormattedPhoneNumber(dbFormattedPhoneNumber)
        return true
      }
    } catch {
      return false
    }
  }

  function handleError(formValues: {
    phoneNumberCountryCode: any
    phoneNumber?: string
    allowsMarketingTexts?: boolean
  }): void {
    if (formValues.phoneNumberCountryCode === null || formValues.phoneNumberCountryCode === ("XX" as CountryCode)) {
      setIsCountryCodeError(true)
    } else {
      setIsCountryCodeError(false)
      setIsPhoneNumberError(true)
    }
  }

  async function handleSubmit(formValues: {
    phoneNumberCountryCode: string
    phoneNumber: string
    allowsMarketingTexts: boolean
  }): Promise<void> {
    if (await handleValidPhoneNumber(formValues)) {
      setIsPhoneNumberError(false)
      setIsCountryCodeError(false)
      setPhoneNumberCountryCode(formValues.phoneNumberCountryCode)
      setAllowsMarketingTexts(
        isUsa && formValues.phoneNumberCountryCode === "US" ? formValues.allowsMarketingTexts : false
      )
      onComplete()
    } else {
      handleError(formValues)
    }
  }

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        description={
          <Box
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
          >
            <Typography>
              <FormattedMessage defaultMessage="A current phone number helps us support you quickly when you call. We will never sell it or use it for spam calls." />
            </Typography>
          </Box>
        }
        interactiveElements={
          <Form onSubmit={handleSubmit} defaultValues={{ phoneNumber, allowsMarketingTexts, phoneNumberCountryCode }}>
            <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
              <FormControl fullWidth>
                <FormSelect
                  name={"phoneNumberCountryCode"}
                  label={intl.formatMessage({ defaultMessage: "Country Code" })}
                  options={phoneCodeSelectOptions}
                  error={isCountryCodeError}
                  helperText={
                    isCountryCodeError ? intl.formatMessage({ defaultMessage: "Please select a country code" }) : null
                  }
                />
              </FormControl>
            </Box>
            <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
              <FormPhoneNumberTextInput
                name="phoneNumber"
                countryCodeFieldName={"phoneNumberCountryCode"}
                label={intl.formatMessage({ defaultMessage: "Phone Number" })}
                error={isPhoneNumberError}
                helperText={
                  isPhoneNumberError
                    ? intl.formatMessage({ defaultMessage: "Please enter a valid phone number" })
                    : null
                }
                registerOptions={{ required: true }}
              />
            </Box>
            {isUsa ? <SmsMarketingOptIn countryCodeFieldName="phoneNumberCountryCode" /> : null}
            <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
              <RegistrationButtonFooter
                onBack={onBack}
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Box>
          </Form>
        }
        employeeId={employeeId}
      />
    </>
  )
}
