import React, { FC } from "react"
import ContentTypes, { EntryWithoutUnresolvableLinks } from "../../utils/ContentTypes"
import { Modal } from "./content-types/modal"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
import { Markdown, MarkdownProps, defaultMarkdownElements } from "#/services/markdown"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DownIcon,
  Typography,
  UnorderedList
} from "@carrotfertility/carotene-core"
import {
  LinkModalContextProvider,
  LinkToModal,
  ModalFromLink,
  ArrowLink,
  AvaArrowLink,
  CalloutBox,
  PhoneSupportNumberLink,
  PhoneSupportNumberText,
  SelectedTalkToCarrotLocation,
  DoulaAttestationLink,
  CarrotMatchLink,
  SDoHFindHelpLink,
  SDoHQuestionLink
} from "./custom-components"

import { PiiText } from "#/services/tracking"
import { useSelector } from "react-redux"
import { getCompanyDisplayName } from "#/redux/reducers/companyInfo"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import { validateContentSource } from "./util/content-validation"
import { PayingForCareCoverageDetails } from "#/components/views/reimbursements/digital-benefit-guide/PayingForCare"
import { ContentfulContentProp } from "./content-types/shared-types"
import {
  TypeBlockQuoteSkeleton,
  TypeContainerSkeleton,
  TypeContentBlockSkeleton,
  TypeContentBlockWithIconSkeleton,
  TypeTwoColumnBulletListSkeleton,
  TypeTwoColumnLayoutSkeleton,
  TypeModalSkeleton,
  TypeUnorderedListSkeleton,
  TypeAccordionSkeleton
} from "#/types/contentful"
import { Entry } from "contentful"
import { preprocess } from "../markdown/preprocessors"
import { TelemedicineForMenopauseLink } from "#/services/contentful/custom-components/telemedicine-for-mopause-link"
import { CarrotMatchContextProvider } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"

export type ContentfulContentEntry = Entry<
  | TypeAccordionSkeleton
  | TypeBlockQuoteSkeleton
  | TypeContainerSkeleton
  | TypeContentBlockSkeleton
  | TypeContentBlockWithIconSkeleton
  | TypeTwoColumnBulletListSkeleton
  | TypeTwoColumnLayoutSkeleton
  | TypeUnorderedListSkeleton
  | TypeModalSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  string
>

function isAccordian(entry: ContentfulContentEntry): entry is EntryWithoutUnresolvableLinks<TypeAccordionSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.ACCORDION
}

function isBlockQuote(entry: ContentfulContentEntry): entry is EntryWithoutUnresolvableLinks<TypeBlockQuoteSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.BLOCK_QUOTE
}

function isContainer(entry: ContentfulContentEntry): entry is EntryWithoutUnresolvableLinks<TypeContainerSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.CONTAINER
}

function isContentBlock(
  entry: ContentfulContentEntry
): entry is EntryWithoutUnresolvableLinks<TypeContentBlockSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.CONTENT_BLOCK
}

function isContentBlockWithIcon(
  entry: ContentfulContentEntry
): entry is EntryWithoutUnresolvableLinks<TypeContentBlockWithIconSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.CONTENT_BLOCK_WITH_ICON
}

function isTwoColumnBulletList(
  entry: ContentfulContentEntry
): entry is EntryWithoutUnresolvableLinks<TypeTwoColumnBulletListSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.TWO_COLUMN_BULLET_LIST
}

function isTwoColumnLayout(
  entry: ContentfulContentEntry
): entry is EntryWithoutUnresolvableLinks<TypeTwoColumnLayoutSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.TWO_COLUMN_LAYOUT
}

function isUnorderedList(
  entry: ContentfulContentEntry
): entry is EntryWithoutUnresolvableLinks<TypeUnorderedListSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.UNORDERED_LIST
}

function isModal(entry: ContentfulContentEntry): entry is EntryWithoutUnresolvableLinks<TypeModalSkeleton> {
  return entry.sys.contentType.sys.id === ContentTypes.MODAL
}

type RecursiveContentfulContentRendererProps = {
  contents: ContentfulContentEntry[]
  asList?: boolean | false
}

type ContentfulMarkdownOverrides = Omit<MarkdownProps, "children">

export interface ContentfulContentRendererProps
  extends RecursiveContentfulContentRendererProps,
    ContentfulMarkdownOverrides {}

const ContentfulMarkdownOverrideContext = React.createContext<ContentfulMarkdownOverrides>({})

export function ContentfulContentRenderer({ contents, asList, ...markdownProps }: ContentfulContentRendererProps) {
  return (
    <ContentfulMarkdownOverrideContext.Provider value={markdownProps || {}}>
      <LinkModalContextProvider>
        <CarrotMatchContextProvider>
          <RecursiveContentfulContentRenderer {...{ contents, asList }} />
        </CarrotMatchContextProvider>
      </LinkModalContextProvider>
    </ContentfulMarkdownOverrideContext.Provider>
  )
}

export function ContentfulRawMarkdownRenderer({ children, ...markdownProps }: MarkdownProps) {
  return (
    <ContentfulMarkdownOverrideContext.Provider value={markdownProps || {}}>
      <LinkModalContextProvider>
        <CarrotMatchContextProvider>
          <ContentfulMarkdown>{children}</ContentfulMarkdown>
        </CarrotMatchContextProvider>
      </LinkModalContextProvider>
    </ContentfulMarkdownOverrideContext.Provider>
  )
}

export const RecursiveContentfulContentRenderer: FC<RecursiveContentfulContentRendererProps> = ({
  contents,
  asList = false
}) => {
  return (
    <>
      {contents?.map(
        (content) =>
          content.fields && (
            <ContentfulConditionalGate conditions={content.fields.conditions} key={content.sys.id}>
              {asList ? (
                <defaultMarkdownElements.li.component {...defaultMarkdownElements.li.props}>
                  <Content content={content} />
                </defaultMarkdownElements.li.component>
              ) : (
                <Content content={content} />
              )}
            </ContentfulConditionalGate>
          )
      )}
    </>
  )
}

function ContentfulMarkdown(props: Pick<MarkdownProps, "children">) {
  const { overrides, ...otherMarkdownOverrides } = React.useContext(ContentfulMarkdownOverrideContext)

  const customMarkdownElements = {
    LinkToModal,
    ModalFromLink,
    ArrowLink,
    AvaArrowLink,
    CoverageAmountsDbg: PayingForCareCoverageDetails,
    CalloutBox,
    BodySmall: {
      ...defaultMarkdownElements.p,
      props: {
        ...defaultMarkdownElements.p?.props,
        variant: "body2",
        sx: {
          "> p": {
            fontSize: "inherit"
          }
        }
      }
    },
    CompanyDisplayName: () => {
      const companyDisplayNameInfo = useSelector(getCompanyDisplayName)
      return <PiiText>{companyDisplayNameInfo}</PiiText>
    },
    CustomCoverage: () => {
      const {
        data: {
          customizations: { memberCustomCoverageExplanationMarkdown }
        }
      } = useBenefit()

      return memberCustomCoverageExplanationMarkdown && <Markdown>{memberCustomCoverageExplanationMarkdown}</Markdown>
    },
    AdditionalEmployerBenefits: () => {
      const {
        data: {
          customizations: { additionalEmployerBenefitMarkdown }
        }
      } = useBenefit()

      return additionalEmployerBenefitMarkdown && <Markdown>{additionalEmployerBenefitMarkdown}</Markdown>
    },
    HealthPlan: () => {
      const {
        data: {
          customizations: { healthPlanMarkdown }
        }
      } = useBenefit()

      return healthPlanMarkdown && <Markdown>{healthPlanMarkdown}</Markdown>
    },
    PhoneSupportNumberLink,
    PhoneSupportNumberText,
    SelectedTalkToCarrotLocation,
    DoulaAttestationLink,
    CarrotMatchLink,
    SDoHFindHelpLink,
    SDoHQuestionLink,
    TelemedicineForMenopauseLink,
    ...overrides
  }

  const source = validateContentSource(props.children, customMarkdownElements)

  return (
    <Markdown overrides={customMarkdownElements} {...otherMarkdownOverrides} {...props}>
      {preprocess(source)}
    </Markdown>
  )
}

const Content: FC<ContentfulContentProp<ContentfulContentEntry>> = ({ content }) => {
  if (isContentBlockWithIcon(content)) {
    return (
      <Box
        display="flex"
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        justifyContent="space-between"
      >
        {content.fields.icon?.fields && (
          <Box component="img" src={content.fields.icon.fields.file?.url} height={60} width={60} alt="" />
        )}
        <Typography variant="body2">{content.fields?.body}</Typography>
      </Box>
    )
  } else if (isTwoColumnLayout(content)) {
    return (
      <Box
        display="flex"
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
        flexWrap="wrap"
        sx={(theme) => ({
          "& > *": { inlineSize: { xs: "100%", md: `calc(50% - ${theme.spacing(theme.tokens.spacing.md)})` } }
        })}
      >
        <RecursiveContentfulContentRenderer contents={content.fields?.content} />
      </Box>
    )
  } else if (isTwoColumnBulletList(content)) {
    return (
      <UnorderedList
        component="ul"
        sx={(theme) => ({
          display: "flex",
          gap: theme.spacing(theme.tokens.spacing.xs),
          paddingBlockStart: 0,
          paddingBlockEnd: (theme) => theme.spacing(theme.tokens.spacing.xl),
          flexWrap: "wrap",
          li: {
            "&:nth-child(odd)": {
              paddingInlineEnd: (theme) => theme.spacing(theme.tokens.spacing.sm)
            },
            inlineSize: { xs: "100%", md: `calc(50% - ${theme.spacing(theme.tokens.spacing.md)})` }
          }
        })}
      >
        <RecursiveContentfulContentRenderer contents={content.fields?.content} asList />
      </UnorderedList>
    )
  } else if (isBlockQuote(content)) {
    return (
      <defaultMarkdownElements.blockquote.component {...defaultMarkdownElements.blockquote.props}>
        <RecursiveContentfulContentRenderer contents={content.fields?.content} />
      </defaultMarkdownElements.blockquote.component>
    )
  } else if (isContainer(content)) {
    return <RecursiveContentfulContentRenderer contents={content.fields?.content} />
  } else if (isModal(content)) {
    return <Modal content={content} />
  } else if (isContentBlock(content)) {
    return <ContentfulMarkdown>{content.fields?.body}</ContentfulMarkdown>
  } else if (isUnorderedList(content)) {
    return (
      <defaultMarkdownElements.ul.component {...defaultMarkdownElements.ul.props}>
        <RecursiveContentfulContentRenderer contents={content.fields?.content} asList />
      </defaultMarkdownElements.ul.component>
    )
  } else if (isAccordian(content)) {
    return <ContentAccordion content={content} />
  }

  return null
}

function ContentAccordion({ content }: { content: EntryWithoutUnresolvableLinks<TypeAccordionSkeleton> }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<DownIcon fontSize="large" />}
        aria-controls={`content-accordion-details-${content.sys.id}`}
        id={`content-accordion-${content.sys.id}`}
      >
        <ContentfulMarkdown>{content.fields?.summaryClickableElement}</ContentfulMarkdown>
      </AccordionSummary>
      <AccordionDetails>
        <RecursiveContentfulContentRenderer contents={content.fields?.content} />
      </AccordionDetails>
    </Accordion>
  )
}
