import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Button, DialogContent, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"
import React from "react"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { useHandleDialogClose } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/dialogUtil"

export function CloseResultStep() {
  const { back } = useStateMachine()
  const { resetCarrotMatchInteraction, setIsCarrotMatchDialogOpen, carrotMatchInteraction } = useCarrotMatch()
  const { entryPoint } = carrotMatchInteraction

  const { handleClose } = useHandleDialogClose(resetCarrotMatchInteraction, setIsCarrotMatchDialogOpen, entryPoint)

  return (
    <>
      <DialogTitle id="close-match-heading">
        <FormattedMessage defaultMessage="Finished looking at your matches?" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage defaultMessage="Your match results will not be saved." />
        </DialogContentText>
      </DialogContent>
      <DialogActionsBar
        primaryAction={
          <Button onClick={() => back()}>
            <FormattedMessage defaultMessage="Back to matches" />
          </Button>
        }
        secondaryAction={
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
      />
    </>
  )
}
