import React from "react"
import { HR, Spacer } from "../atoms/Atoms"
import { Body } from "../atoms/Bodies"
import { ButtonPrimary } from "../atoms/buttons/ButtonPrimary"
import { Button } from "../atoms/buttons/Button"
// eslint-disable-next-line no-restricted-imports
import { H2 } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { FormattedMessage } from "react-intl"

// @ts-expect-error TS(7031) FIXME: Binding element 'returnToLink' implicitly has an '... Remove this comment to see the full error message
const UnknownErrorOccurred = ({ returnToLink }) => {
  return (
    <div className="flex-auto flex flex-column justify-center items-center vh-100">
      <div className={`br3 bg-white ba b--mint-alternate animate-all mw6-ns mw5 pv4`}>
        <div className="tc pa4">
          <H2>
            <FormattedMessage defaultMessage="An unknown error occurred." />
          </H2>
          <Spacer height={0.25} />
          <Body color="black-80">
            <FormattedMessage defaultMessage="Please refresh the page and try again." />
          </Body>
          <Spacer height={1.5} />
          <HR color="pampas" width={2} />
          <Spacer height={1.5} />
          <div className="flex flex-row justify-between">
            <div className="flex-grow-1 pr2">
              <ButtonPrimary to={returnToLink}>
                <FormattedMessage defaultMessage="Refresh the page" />
              </ButtonPrimary>
            </div>
            <div className="flex-grow-1 pl2">
              <Button to="/" outline>
                <FormattedMessage defaultMessage="Carrot Home" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { UnknownErrorOccurred }
