import React, { useState } from "react"
// eslint-disable-next-line no-restricted-imports
import { Button, Container, FlexContainer, H2, Text, Body, Modal } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { CircleImage } from "../views/atoms/images/CircleImage"
import useEmailVerification from "../EmailVerification/useEmailVerification"
import { useCurrentUser } from "../context/user/UserContext"
import { useHistory } from "react-router"
import VerificationSentModal from "./VerificationSentModal"
import { reportErrorMessage } from "#/utils/ErrorReporting"
import { FormattedMessage } from "react-intl"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { EmployeeSupportUrl } from "#/utils/EmployeeSupportLink"
import { PiiText } from "../../services/tracking"
import { Link } from "@carrotfertility/carotene-core"

export function MessagingEmailVerificationModal(): JSX.Element {
  const { personalEmail, employeeId } = useCurrentUser()
  const { sendVerificationEmail } = useEmailVerification()
  const [open, setOpen] = useState(true)
  const [verificationEmailSent, setVerificationEmailSent] = useState(false)
  const [disableSendButton, setDisableSendButton] = useState(false)
  const [error, setError] = useState(false)
  const history = useHistory()

  const onClose = (): void => {
    history.push({
      state: { showVerifyEmailModal: false }
    })
    setOpen(false)
    setError(false)
    setVerificationEmailSent(false)
  }
  const onClick = async (): Promise<void> => {
    try {
      setDisableSendButton(true)
      await sendVerificationEmail()
      setVerificationEmailSent(true)
      setDisableSendButton(false)
    } catch (error) {
      setError(true)
      reportErrorMessage(`Unable to send verification email for employee id: ${employeeId}`)
    }
  }

  return (
    <EmployeeOnlyModalContainer onClose={onClose} open={open}>
      <Modal open={open} onClose={onClose} isDismissable>
        {verificationEmailSent ? (
          <VerificationSentModal onClose={onClose} open={open} />
        ) : error ? (
          <Container paddingTopBottom={"32px"}>
            <FlexContainer stack={"32px"} paddingLeftRight={"48px"} alignItems={"center"} direction={"column"}>
              <H2 stack={"small"}>
                <FormattedMessage defaultMessage="Something's not working" />
              </H2>
              <Body size={"small"} color="black-80">
                <FormattedMessage
                  defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
                  values={{
                    link: (linkContent) => (
                      <Link href={EmployeeSupportUrl} target={"_blank"}>
                        {linkContent}
                      </Link>
                    )
                  }}
                />
              </Body>
            </FlexContainer>
            <FlexContainer justifyContent={"center"}>
              <Button onClick={onClose}>
                <FormattedMessage defaultMessage="Close" />
              </Button>
            </FlexContainer>
          </Container>
        ) : (
          <Container paddingTopBottom={"32px"}>
            <FlexContainer paddingTopBottom={"32px"} justifyContent={"center"}>
              <CircleImage size={160} iconName={"illo-envelope-at"} />
            </FlexContainer>
            <FlexContainer stack={"12px"} paddingLeftRight={"48px"} alignItems={"center"} direction={"column"}>
              <H2 stack={"4px"}>
                <FormattedMessage defaultMessage="Verify your email address" />
              </H2>
              <Body align={"center"}>
                <FormattedMessage
                  defaultMessage="Please check <bold>{personalEmail}</bold> and click the verification link to access your messages."
                  values={{
                    personalEmail: <PiiText>{personalEmail}</PiiText>,
                    bold: (boldContent) => (
                      <>
                        <br />
                        <Text weight={"bold"}>{boldContent}</Text>
                        <br />
                      </>
                    )
                  }}
                />
              </Body>
            </FlexContainer>
            <FlexContainer paddingTopBottom={"32px"} justifyContent={"center"}>
              <Button onClick={onClick} disabled={disableSendButton}>
                <FormattedMessage defaultMessage="Send verification email" />
              </Button>
            </FlexContainer>
          </Container>
        )}
      </Modal>
    </EmployeeOnlyModalContainer>
  )
}
