import React, { FC } from "react"
import Helpers from "../../../../utils/Helpers"
import { CMD_CONFIGURATION, useUserDeductible } from "../../../context/user/DeductibleContext"
import { getPiiProps } from "#/services/tracking"
import { useIntl } from "react-intl"
import { Box, Divider, InternalIcon, Link, Stack, Typography } from "@carrotfertility/carotene-core"

type CMDRowProps = {
  name: string
  value: string
}

type CMDHeaderProps = {
  startDate: string
  endDate: string
}

type CMDTableProps = {
  title: string
  startDate: string
  endDate: string
  totalAmount: number
  cappedExternalCredit: number
  cappedCarrotAmountApplied: number
  amountRemaining: number
}

const CMDHeader: FC<CMDHeaderProps> = ({ startDate, endDate }) => {
  const intl = useIntl()
  const formattedStartDate = intl.formatDate(startDate, { month: "short", day: "numeric", year: "numeric" })
  const formattedEndDate = intl.formatDate(endDate, { month: "short", day: "numeric", year: "numeric" })
  const planYear = intl.formatMessage(
    { defaultMessage: "Plan year: {startDate} - {endDate}" },
    { startDate: formattedStartDate, endDate: formattedEndDate }
  )

  return (
    <Box paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
      <Typography variant="body1" fontWeight="bold" color={(theme) => theme.palette.text.secondary}>
        {planYear}
      </Typography>
    </Box>
  )
}

const CMDRow: FC<CMDRowProps> = ({ name, value }) => {
  return (
    <Box
      paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Typography color={(theme) => theme.palette.text.secondary} variant="body1">
        {name}
      </Typography>
      <Box {...getPiiProps()}>
        <Typography color={(theme) => theme.palette.text.secondary} variant="body1">
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

const CMDTable: FC<CMDTableProps> = ({
  title,
  startDate,
  endDate,
  totalAmount,
  cappedExternalCredit,
  cappedCarrotAmountApplied,
  amountRemaining
}) => {
  const intl = useIntl()

  return (
    <Stack
      paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
      paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
    >
      <Typography
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        variant="body1"
        fontWeight="bold"
        color={(theme) => theme.palette.text.secondary}
      >
        {title}
      </Typography>
      <Box
        padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        display="flex"
        bgcolor={(theme) => theme.palette.background.default}
      >
        <Box width="100%">
          <CMDHeader startDate={startDate} endDate={endDate} />
          <CMDRow
            name={intl.formatMessage({ defaultMessage: "Annual deductible" })}
            value={intl.formatNumber(totalAmount / 100, {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })}
          />
          <CMDRow
            name={intl.formatMessage({ defaultMessage: "Credit from health plan" })}
            value={
              (cappedExternalCredit > 0 ? "-" : "") +
              intl.formatNumber(cappedExternalCredit / 100, {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 0
              })
            }
          />
          <CMDRow
            name={intl.formatMessage({ defaultMessage: "Credit from applicable expenses" })}
            value={
              (cappedCarrotAmountApplied > 0 ? "-" : "") +
              intl.formatNumber(cappedCarrotAmountApplied / 100, {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 0
              })
            }
          />
          <Box
            paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}
            paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
          >
            <Divider />
          </Box>
          <CMDRow
            name={intl.formatMessage({ defaultMessage: "Remaining deductible" })}
            value={intl.formatNumber(amountRemaining / 100, {
              style: "currency",
              currency: "USD",
              currencyDisplay: "narrowSymbol",
              maximumFractionDigits: 0
            })}
          />
        </Box>
      </Box>
    </Stack>
  )
}

function useMemberCMDTableSetup() {
  const {
    deductible: { deductibleSnapshot: memberDeductibleSnapshot }
  } = useUserDeductible()
  const startDateMember = Helpers.formatDate(memberDeductibleSnapshot.planStartDate)
  const endDateMember = Helpers.formatDate(memberDeductibleSnapshot.planEndDate)
  const externalCreditCapMember = Math.max(
    0,
    memberDeductibleSnapshot.totalAmount - memberDeductibleSnapshot.carrotAmountAppliedToDeductible
  )
  const cappedExternalCreditMember = Math.min(
    externalCreditCapMember,
    memberDeductibleSnapshot.externalAmountAppliedToDeductible
  )
  const cappedCarrotAmountAppliedMember = Math.min(
    memberDeductibleSnapshot.totalAmount,
    memberDeductibleSnapshot.carrotAmountAppliedToDeductible
  )
  return {
    memberDeductibleSnapshot,
    startDateMember,
    endDateMember,
    cappedExternalCreditMember,
    cappedCarrotAmountAppliedMember
  }
}

function usePartnerCMDTableSetup() {
  const {
    partnerDeductible: { deductibleSnapshot: partnerDeductibleSnapshot }
  } = useUserDeductible()
  const startDatePartner = Helpers.formatDate(partnerDeductibleSnapshot.planStartDate)
  const endDatePartner = Helpers.formatDate(partnerDeductibleSnapshot.planEndDate)
  const externalCreditCapPartner = Math.max(
    0,
    partnerDeductibleSnapshot.totalAmount - partnerDeductibleSnapshot.amountAppliedToDeductible
  )
  const cappedExternalCreditPartner = Math.min(
    externalCreditCapPartner,
    partnerDeductibleSnapshot.externalAmountAppliedToDeductible
  )
  const cappedCarrotAmountAppliedPartner = Math.min(
    partnerDeductibleSnapshot.totalAmount,
    partnerDeductibleSnapshot.carrotAmountAppliedToDeductible
  )
  return {
    partnerDeductibleSnapshot,
    startDatePartner,
    endDatePartner,
    cappedExternalCreditPartner,
    cappedCarrotAmountAppliedPartner
  }
}

function CMDTableMember(): JSX.Element {
  const {
    memberDeductibleSnapshot,
    startDateMember,
    endDateMember,
    cappedExternalCreditMember,
    cappedCarrotAmountAppliedMember
  } = useMemberCMDTableSetup()
  const intl = useIntl()

  return (
    <CMDTable
      title={intl.formatMessage({ defaultMessage: "Your deductible status" })}
      startDate={startDateMember}
      endDate={endDateMember}
      totalAmount={memberDeductibleSnapshot.totalAmount}
      cappedExternalCredit={cappedExternalCreditMember}
      cappedCarrotAmountApplied={cappedCarrotAmountAppliedMember}
      amountRemaining={memberDeductibleSnapshot.amountRemaining}
    />
  )
}

function CMDTablePartner(): JSX.Element {
  const {
    partnerDeductibleSnapshot,
    startDatePartner,
    endDatePartner,
    cappedExternalCreditPartner,
    cappedCarrotAmountAppliedPartner
  } = usePartnerCMDTableSetup()
  const intl = useIntl()

  return (
    <CMDTable
      title={intl.formatMessage({ defaultMessage: "Your partner's deductible status" })}
      startDate={startDatePartner}
      endDate={endDatePartner}
      totalAmount={partnerDeductibleSnapshot.totalAmount}
      cappedExternalCredit={cappedExternalCreditPartner}
      cappedCarrotAmountApplied={cappedCarrotAmountAppliedPartner}
      amountRemaining={partnerDeductibleSnapshot.amountRemaining}
    />
  )
}

function CMDTableContainer(): JSX.Element {
  const { cmdConfiguration, hasCMD } = useUserDeductible()
  const hasLegacyCMD = hasCMD && cmdConfiguration === null
  const shouldShowMemberTable =
    [
      CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE,
      CMD_CONFIGURATION.MEMBER_AND_PARTNER_COMBINED,
      CMD_CONFIGURATION.MEMBER_ONLY
    ].includes(cmdConfiguration) || hasLegacyCMD
  const shouldShowPartnerTable = [
    CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE,
    CMD_CONFIGURATION.PARTNER_ONLY
  ].includes(cmdConfiguration)
  const intl = useIntl()

  return (
    <>
      {hasCMD && (
        <Box paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Link
            href={"/your-benefit-guide/carrot-funds/deductibles"}
            target={"_blank"}
            endIcon={<InternalIcon fontSize="small" color="primary" />}
          >
            {intl.formatMessage({ defaultMessage: "Learn more about deductibles" })}
          </Link>
        </Box>
      )}
      {shouldShowMemberTable && <CMDTableMember />}
      {shouldShowPartnerTable && <CMDTablePartner />}
    </>
  )
}

export { CMDTableContainer }
