import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { carrotClient } from "#/utils/CarrotClient"
import { useQuery, UseQueryResult } from "@tanstack/react-query"

interface TelemedicineAvailability {
  isAvailable: boolean
}

const TELEMEDICINE_AVAILIBILITY_QUERY_KEY = ["telemedicineAvailability"]

export function useTelemedicineAvailibility(): UseQueryResult<TelemedicineAvailability, ResponseError> {
  return useQuery({
    queryKey: TELEMEDICINE_AVAILIBILITY_QUERY_KEY,
    queryFn: () => carrotClient.getTelemedicineAvailability(),
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })
}
