import React, { useEffect, useState } from "react"
import { useRequestCarrotCard } from "./AirwallexUserAgreement/request-carrot-card"
import { attemptRequest } from "./AirwallexUserAgreement/agreement-helpers"
import { DialogActionsBar, Form, FormButton } from "@carrotfertility/carotene-core-x"
import RequestCardWrapper from "../requestCardWrapper"
import { unexpectedServerErrorAlert } from "../requestCardWrapper/unexpected-error-alert"
import { Box, Progress } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { HttpErrors } from "#/utils/HttpErrors"
import { invalidAddressErrorAlert } from "#/pages/request-carrot-card-flow/requestCardWrapper/invalid-address-error-alert"

export function NoUserAgreementRequest() {
  const { send } = useStateMachine()
  const [isAddressError, setIsAddressError] = useState(false)
  const { mutate, isError, mutateAsync } = useRequestCarrotCard({
    onSuccess: () => send(""),
    onError: (error) => {
      if (error.name === HttpErrors.BAD_REQUEST && error.message.includes("postal_address")) {
        setIsAddressError(true)
      } else {
        setIsAddressError(false)
      }
    }
  })
  useEffect(() => mutate(), [mutate])
  const showRetry = isError

  const determineErrorToDisplay = (): JSX.Element => {
    if (isAddressError) {
      return invalidAddressErrorAlert
    }

    return (
      <>
        {unexpectedServerErrorAlert}
        <DialogActionsBar
          primaryAction={
            <FormButton type="submit">
              <FormattedMessage defaultMessage="Try again" />
            </FormButton>
          }
        />
      </>
    )
  }

  return (
    <Form onSubmit={() => attemptRequest(mutateAsync)}>
      <RequestCardWrapper hideProgressBar={isError} showBackButton={isAddressError}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: showRetry ? "flex-end" : "center" }}>
          {showRetry ? determineErrorToDisplay() : <Progress size="1rem" />}
        </Box>
      </RequestCardWrapper>
    </Form>
  )
}
