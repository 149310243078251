/* eslint formatjs/no-literal-string-in-jsx: off -- translation planned for Q1 2024. See https://carrotfertility.atlassian.net/wiki/spaces/PROD/pages/2190215916/WS+Support+E2E+Translation */

import React from "react"
// eslint-disable-next-line no-restricted-imports
import { FlexContainer, Text, H2, Image, Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { getFormattedAmount } from "../utils/currency-helper"

type PaymentStatusProps = {
  amount: number
  status: string
  currency: string
  description: string
  email: string
}

const PaymentStatus = (props: PaymentStatusProps): JSX.Element => {
  const formattedAmount = getFormattedAmount(props.amount, props.currency)
  return props.status === "succeeded" ? (
    <Container sx={{ marginBlockStart: "large" }}>
      <FlexContainer stack="huge" direction="row" justifyContent="center">
        <Image src={require(`../resources/images/check-mark.png`)} altText="check mark" width="228px" />
      </FlexContainer>
      <FlexContainer stack="small" direction="row" justifyContent="center">
        <H2>Payment successful</H2>
      </FlexContainer>
      <FlexContainer stack="small" direction="row" justifyContent="center">
        <Text align="center">
          We’ve received your {formattedAmount} payment. <br /> A confirmation email has been sent to {props.email}.
        </Text>
      </FlexContainer>
    </Container>
  ) : (
    <>
      <FlexContainer stack="tiny" direction="row" justifyContent="center">
        <Image src={require(`../resources/images/error.png`)} altText="check mark" width="300px" />
      </FlexContainer>
      <FlexContainer stack="tiny" direction="row" justifyContent="center">
        <H2>Payment Unsuccessful</H2>
      </FlexContainer>
      <FlexContainer stack="tiny" direction="row" justifyContent="center">
        <Text>
          We’re unable to process your payment because {props.description}. Your credit card will not be charged, but
          your account may show a pending transaction.
        </Text>
      </FlexContainer>
    </>
  )
}

export default PaymentStatus
