// eslint-disable-next-line no-restricted-imports
import { Container, H2, Stack } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import React from "react"
import { FormattedMessage } from "react-intl"
import { TtcPaths } from "../../../../utils/Paths"
import { Link } from "@carrotfertility/carotene-core"

export const FinancialSupportErrorMessage = (): JSX.Element => {
  return (
    <Container background={"lightGrey"} borderRadius={"8px"} padding={"48px"} width={"100%"}>
      <Stack direction={"column"} spacing={"small"}>
        <H2>
          <FormattedMessage defaultMessage="Something's not working" />
        </H2>
        <FormattedMessage
          defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
          values={{
            link: (linkContent) => (
              <Link href={TtcPaths.TTC_CARE_NAV} target="_blank">
                {linkContent}
              </Link>
            )
          }}
        />
      </Stack>
    </Container>
  )
}
