import React, { useEffect } from "react"
import Home from "#/components/views/home/Home"
import { useLocation, useHistory } from "react-router-dom"
import { EntryPoint } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import {
  useCarrotMatch,
  CarrotMatchContextProvider
} from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { CarrotMatchDialog } from "#/components/carrot-match-fertility-clinic-precision-matching/CarrotMatchDialog"
import { useCarrotMatchEnabled } from "#/components/carrot-match-fertility-clinic-precision-matching/hooks/useShowCarrotMatch"

const CarrotMatchLandingPage: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { setIsCarrotMatchDialogOpen, updateCarrotMatchInteraction } = useCarrotMatch()

  const { isLoading, isCarrotMatchEnabled } = useCarrotMatchEnabled()

  useEffect(() => {
    if (!isCarrotMatchEnabled && !isLoading) {
      history.push("/")
      return
    }

    const entryPoint = location.hash === "#carrot_plan" ? EntryPoint.CARROT_PLAN : EntryPoint.EXTERNAL

    updateCarrotMatchInteraction({ entryPoint })
    setIsCarrotMatchDialogOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [isCarrotMatchEnabled, isLoading, location.hash])

  return (
    <>
      <Home />
      {isCarrotMatchEnabled && <CarrotMatchDialog />}
    </>
  )
}

export const CarrotMatchPageWrapper: React.FC = () => {
  return (
    <CarrotMatchContextProvider>
      <CarrotMatchLandingPage />
    </CarrotMatchContextProvider>
  )
}
