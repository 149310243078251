import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Container, FlexContainer } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { RetinaImage } from "../RetinaImage"
import { FormattedMessage, useIntl } from "react-intl"
import { Link, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"

export function HomepageHelp(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <FlexContainer
      background={theme.palette.background.default}
      direction="row"
      padding={["24px 32px", null, null, "24px 66px"]}
      alignItems="center"
      borderRadius="8px"
    >
      <Container minWidth={["0", null, null, "168px"]} width={["0", null, null, "168px"]}>
        <RetinaImage name={"conversations"} height={120} width={120} />
      </Container>
      <Container>
        <Typography variant="h5" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
          <FormattedMessage defaultMessage="Can we help?" />
        </Typography>
        <Typography
          variant="body1"
          color={(theme) => theme.palette.text.secondary}
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
        >
          <FormattedMessage defaultMessage="If you have questions about finding the right provider, how to advocate for yourself, or anything else, reach out to the Care Team." />
        </Typography>
        <Container alignItems="center">
          <Link
            component={RouterLink}
            color="primary"
            to="/talk-to-carrot/sendmessage/helpfindingprovider"
            title={intl.formatMessage({ defaultMessage: "Contact your Care team for help finding a provider" })}
          >
            <FormattedMessage defaultMessage="Contact us" />
          </Link>
        </Container>
      </Container>
    </FlexContainer>
  )
}
