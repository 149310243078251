import React from "react"
import { Redirect, Route } from "react-router-dom"
// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from @carrotfertility/carotene-core or @carrotfertility/carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import { ThemeProvider } from "styled-components"
import { fleFormTheme } from "#/utils/formThemes"
import RoutingFlow from "."
import { CarrotPlansPaths, Paths } from "../../utils/Paths"
import { talkToCarrotSource } from "../talk-to-carrot/send-message/SendMessageUtil"
import { Switch } from "#/services/routing/Switch"
import { useMemberBenefitStarted } from "#/services/user/derived-selectors/member-info"
import useCarrotPlan from "./hooks/useCarrotPlan"

interface ProtectFlowProps {
  children: React.ReactElement
}

function ProtectFlow({ children }: ProtectFlowProps): JSX.Element {
  const benefitStarted = useMemberBenefitStarted()
  const carrotPlanQuery = useCarrotPlan()

  if (benefitStarted.isLoading) {
    return null
  }

  if (!benefitStarted.data) {
    return <Redirect to={Paths.HOME} />
  }

  if (carrotPlanQuery?.hasCarrotPlan) {
    return <Redirect to={`${Paths.TALK_TO_CARROT}/${talkToCarrotSource.REFRESH_CARROT_PLAN}`} />
  }

  return children
}

function RedirectToCarrotPlansNextStep(): JSX.Element {
  return <Redirect to={CarrotPlansPaths.ENROLLMENT} />
}

export function CarrotPlansRouter(): JSX.Element {
  return (
    <ThemeProvider theme={fleFormTheme}>
      <Switch>
        <Route exact path={CarrotPlansPaths.ENROLLMENT}>
          <ProtectFlow>
            <RoutingFlow />
          </ProtectFlow>
        </Route>
        <Route path={CarrotPlansPaths.REDIRECT}>
          <RedirectToCarrotPlansNextStep />
        </Route>
      </Switch>
    </ThemeProvider>
  )
}
