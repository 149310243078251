import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Box, Button, BackIcon, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Form } from "@carrotfertility/carotene-core-x"
import { useHistory } from "react-router-dom"
import { CaseRateRenewalsStatus, useGetCaseRateRenewalStatus } from "../../case-rate-renewals/useQueryCaseRateRenewals"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { useGetRegistrationJourney } from "../../hooks/useGetRegistrationJourney"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Steps } from "../../case-rate-renewals/workflow"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "#/components/carrot-plans/shared"
import {
  isJourneySupported,
  JourneyOption,
  useEnabledJourneyOptions
} from "#/components/carrot-plans/hooks/useJourneyOptions"
import useGetMemberBenefit from "#/components/carrot-plans/hooks/useGetMemberBenefit"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"
import { FormRadioButtonGroup } from "#/services/common-forms"
import ConditionalSomethingElseTextField from "../../shared/ConditionalSomethingElseTextField"
import { useLocation } from "react-router"
import { RoutingInfo } from "../../../../types/routingInfo"
import { useGetMostRecentlyCompletedRouting } from "../../hooks/useGetMostRecentlyCompletedRouting"
import { Benefit } from "#/lib/carrot-api/types/Benefit"

export const WhatKindOfJourneyHeaderText = defineMessage({
  defaultMessage: "What kind of journey are you on or most interested in?"
})

export const JustForNowLiteText = defineMessage({
  defaultMessage: "This is just for now. Your selection will not affect what resources you can access."
})

export const JustForNowProText = defineMessage({
  defaultMessage:
    "This is just for now. Your selection will not affect your Carrot funds or what resources you can access."
})

export const AccessCareText = defineMessage({
  defaultMessage:
    "Also, know that we always respect local laws and regulations. We'll help you access care domestically or across borders, as appropriate."
})

export default function WhatKindOfJourneyStep(): ReactElement {
  const { data: memberBenefit, isLoading: isMemberBenefitLoading } = useGetMemberBenefit()
  const { journeyOptions } = useEnabledJourneyOptions(memberBenefit)
  const routingData = useCheckRoutingInfoQueryCache()

  if (isMemberBenefitLoading) {
    return <CenteredLoadingIndicator />
  }

  const sendValue =
    routingData?.journey && isJourneySupported({ journey: routingData.journey, options: journeyOptions })
      ? routingData?.journey
      : null

  return (
    <WhatKindOfJourneyStepView
      sendValue={sendValue}
      journeyOptions={journeyOptions}
      memberBenefit={memberBenefit}
      routingData={routingData}
    />
  )
}

function WhatKindOfJourneyStepView({
  sendValue,
  journeyOptions,
  memberBenefit,
  routingData
}: {
  sendValue: string | null
  journeyOptions: JourneyOption[]
  memberBenefit: Benefit
  routingData: RoutingInfo
}): ReactElement {
  const { mutateAsync } = useUpdateRoutingInfo()
  const { send } = useStateMachine(sendValue)
  const { data, showAdvancedCheckInFeatures } = useGetCaseRateRenewalStatus()
  const { data: registrationJourneyResult, isLoading: isRegistrationJourneyLoading } = useGetRegistrationJourney()
  const { data: mostRecentJourney, isLoading: isMostRecentJourneyLoading } = useGetMostRecentlyCompletedRouting()
  const radioOptions = useConvertTranslatedLabel(journeyOptions)
  const history = useHistory()
  const theme = useTheme()
  const isCarrotLite = memberBenefit?.program.isCarrotLite
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(true)
  const { pathname } = useLocation()
  const isInitialRouting = !pathname.includes("case-rate-renewal") && !pathname.includes("journey-change")
  const isCaseRateRenewal = pathname.includes("case-rate-renewal")

  const header = intl.formatMessage({
    defaultMessage: "What kind of journey are you on or most interested in?"
  })

  const setRoutingJourney = useCallback(
    async (formValues: { journey: string; somethingElseJourney: string }): Promise<void> => {
      await mutateAsync(
        { ...formValues, somethingElseJourney: formValues.somethingElseJourney || null },
        {
          onSuccess: () => {
            send(formValues?.journey)
          }
        }
      )
    },
    [send, mutateAsync]
  )

  function registrationJourneyHasBeenProvided(registrationJourneyResult: {
    journey: string | null
    somethingElseJourney: string | null
  }) {
    return (
      registrationJourneyResult?.journey &&
      !(registrationJourneyResult?.journey === "SOMETHING_ELSE" && !registrationJourneyResult?.somethingElseJourney)
    )
  }

  useEffect(() => {
    if (!isRegistrationJourneyLoading && !isMostRecentJourneyLoading) {
      if (
        isInitialRouting &&
        !routingData?.journey &&
        registrationJourneyHasBeenProvided(registrationJourneyResult) &&
        isJourneySupported({ journey: registrationJourneyResult?.journey, options: journeyOptions })
      ) {
        setRoutingJourney({
          journey: registrationJourneyResult.journey,
          somethingElseJourney: registrationJourneyResult?.somethingElseJourney
        }).then(() => {
          setIsLoading(false)
        })
      } else if (
        isCaseRateRenewal &&
        !routingData?.journey &&
        mostRecentJourney?.journey &&
        isJourneySupported({ journey: mostRecentJourney?.journey, options: journeyOptions })
      ) {
        setRoutingJourney(mostRecentJourney).then(() => {
          setIsLoading(false)
        })
      } else {
        setIsLoading(false)
      }
    }
  }, [
    routingData,
    setRoutingJourney,
    journeyOptions,
    isInitialRouting,
    isCaseRateRenewal,
    mostRecentJourney,
    isRegistrationJourneyLoading,
    isMostRecentJourneyLoading,
    registrationJourneyResult
  ])

  return isLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="journey-label">{intl.formatMessage(WhatKindOfJourneyHeaderText)}</span>}
        description={
          <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
            {isCarrotLite ? (
              <Typography>{intl.formatMessage(JustForNowLiteText)}</Typography>
            ) : (
              <Typography>{intl.formatMessage(JustForNowProText)}</Typography>
            )}
            <Typography>{intl.formatMessage(AccessCareText)}</Typography>
          </Stack>
        }
        formElements={
          <Form
            defaultValues={{ journey: routingData?.journey, somethingElseJourney: routingData?.somethingElseJourney }}
            onSubmit={setRoutingJourney}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <FormRadioButtonGroup
                controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
                name="journey"
                options={radioOptions}
                aria-labelledby="journey-label"
              />
              <ConditionalSomethingElseTextField />
            </Stack>
            {data === CaseRateRenewalsStatus.GATED || showAdvancedCheckInFeatures ? (
              <Box position="absolute" marginTop={(theme) => theme.spacing(theme.tokens.spacing.xxxl)}>
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<BackIcon />}
                  onClick={() => history.push(`/?step=${Steps.UPDATE_CARROT_PLAN_DETAILS}`)}
                >
                  <FormattedMessage defaultMessage="Back" />
                </Button>
              </Box>
            ) : null}
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
