import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Steps } from "../workflow/steps"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useCurrentUser } from "../../context/user/UserContext"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor } from "react-intl"
import { returnApplicableYear } from "#/utils/Helpers"
import {
  irsLimitsDeductibleMemberText,
  irsLimitsDeductiblePartnerText,
  irsLimitsOutOfPocketText,
  hdhpIrsLimits
} from "#/components/insurance-details/insurance-coverage-section/plan-information-table/insuranceQuestions"
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DownIcon,
  InfoIcon,
  UnorderedList,
  UnorderedListItem
} from "@carrotfertility/carotene-core"

export const enum HdhpSelectionStatusOptions {
  true = "true",
  false = "false",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const hdhpStatusIdToLabelMapping = {
  [HdhpSelectionStatusOptions.true]: defineMessage({ defaultMessage: "Yes" }),
  [HdhpSelectionStatusOptions.false]: defineMessage({ defaultMessage: "No" }),
  [HdhpSelectionStatusOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getHdhpStatusLabelFromId = (id: HdhpSelectionStatusOptions): MessageDescriptor =>
  hdhpStatusIdToLabelMapping[id]

interface HasHDHPStepProps {
  ViewName: string
  FieldName: string
  updateUserInfo: (val: string) => void
  title: string
  body: string
  hasHDHPValue?: boolean
  isPartner?: boolean
}

function HasHdhpStep({
  FieldName,
  ViewName,
  updateUserInfo,
  title,
  body,
  hasHDHPValue,
  isPartner
}: HasHDHPStepProps): JSX.Element {
  const intl = useIntl()
  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])
  const formMethods = useForm()
  const { setNextStep } = useStateMachine(ViewName, hasHDHPValue)
  // @ts-expect-error TS(7006) FIXME: Parameter 'args' implicitly has an 'any' type.
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" && (await updateUserInfo(val))
    setNextStep(ViewName, val)
  })

  const { handleSubmit } = formMethods
  const applicableYear = returnApplicableYear(hdhpIrsLimits)
  const irsLimitsDeductibleText = isPartner ? irsLimitsDeductiblePartnerText : irsLimitsDeductibleMemberText

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        {title}
      </Typography>
      <Container padding="none" stack="medium">
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            padding: (theme) => theme.spacing(theme.tokens.spacing.md)
          }}
        >
          <AccordionSummary
            expandIcon={<DownIcon fontSize="medium" />}
            aria-controls={`accordion-content-hdhp`}
            id={`accordion-content-hdhp`}
          >
            <Typography variant="h5">{intl.formatMessage({ defaultMessage: `What's an HDHP?` })}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                <Typography color={(theme) => theme.palette.text.secondary}>
                  {intl.formatMessage(irsLimitsDeductibleText, {
                    DEDUCTIBLE_INDIVIDUAL_MIN: (
                      <Typography variant="body1" sx={{ all: "unset", fontWeight: "bold" }}>
                        {intl.formatNumber(
                          hdhpIrsLimits[applicableYear as keyof typeof hdhpIrsLimits]["DEDUCTIBLE_INDIVIDUAL_MIN"],
                          {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "narrowSymbol",
                            maximumFractionDigits: 0
                          }
                        )}
                      </Typography>
                    ),
                    DEDUCTIBLE_FAMILY_MIN: (
                      <Typography variant="body1" sx={{ all: "unset", fontWeight: "bold" }}>
                        {intl.formatNumber(
                          hdhpIrsLimits[applicableYear as keyof typeof hdhpIrsLimits]["DEDUCTIBLE_FAMILY_MIN"],
                          {
                            style: "currency",
                            currency: "USD",
                            currencyDisplay: "narrowSymbol",
                            maximumFractionDigits: 0
                          }
                        )}
                      </Typography>
                    )
                  })}
                </Typography>
              </UnorderedListItem>
              <UnorderedListItem>
                <Typography color={(theme) => theme.palette.text.secondary}>
                  {intl.formatMessage(irsLimitsOutOfPocketText, {
                    OUT_OF_POCKET_INDIVIDUAL_MAX: intl.formatNumber(
                      hdhpIrsLimits[applicableYear as keyof typeof hdhpIrsLimits]["OUT_OF_POCKET_INDIVIDUAL_MAX"],
                      {
                        style: "currency",
                        currency: "USD",
                        currencyDisplay: "narrowSymbol",
                        maximumFractionDigits: 0
                      }
                    ),
                    OUT_OF_POCKET_FAMILY_MAX: intl.formatNumber(
                      hdhpIrsLimits[applicableYear as keyof typeof hdhpIrsLimits]["OUT_OF_POCKET_FAMILY_MAX"],
                      {
                        style: "currency",
                        currency: "USD",
                        currencyDisplay: "narrowSymbol",
                        maximumFractionDigits: 0
                      }
                    )
                  })}
                </Typography>
              </UnorderedListItem>
            </UnorderedList>
            <Container padding="lg" sx={{ display: "flex", alignItems: "flex-start" }}>
              <InfoIcon fontSize="small" color="action" />
              <Typography
                sx={{
                  paddingInlineStart: (theme) => theme.spacing(theme.tokens.spacing.xs)
                }}
                color={(theme) => theme.palette.text.secondary}
              >
                {body}
              </Typography>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Container>
      <Container padding="none" stack="giant">
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={hasHDHPValue}
          options={[
            {
              label: intl.formatMessage(getHdhpStatusLabelFromId(HdhpSelectionStatusOptions.true)),
              value: HdhpSelectionStatusOptions.true
            },
            {
              label: intl.formatMessage(getHdhpStatusLabelFromId(HdhpSelectionStatusOptions.false)),
              value: HdhpSelectionStatusOptions.false
            },
            {
              label: intl.formatMessage(getHdhpStatusLabelFromId(HdhpSelectionStatusOptions.IM_NOT_SURE)),
              value: HdhpSelectionStatusOptions.IM_NOT_SURE
            }
          ]}
        />
      </Container>
      <Box height="6rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}
function MemberHasHdhpIndividualStep(): JSX.Element {
  const { memberHasHDHP } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()

  return (
    <HasHdhpStep
      FieldName={"memberHasHdhpValue"}
      ViewName={Steps.MEMBER_HDHP_INDIVIDUAL}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          memberHasHDHP: val
        })
      }
      hasHDHPValue={memberHasHDHP}
      title={intl.formatMessage({
        defaultMessage: "Are you currently enrolled in a high deductible health plan (HDHP)?"
      })}
      body={intl.formatMessage({
        defaultMessage: "Still not sure? Check your benefits portal or contact your insurance provider."
      })}
    />
  )
}

function MemberHasHdhpFamilyStep(): JSX.Element {
  const { memberHasHDHP } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()

  return (
    <HasHdhpStep
      FieldName={"memberHasHdhpValue"}
      ViewName={Steps.MEMBER_HDHP_FAMILY}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          memberHasHDHP: val
        })
      }
      hasHDHPValue={memberHasHDHP}
      title={intl.formatMessage({
        defaultMessage: "Are you currently enrolled in a high deductible health plan (HDHP)?"
      })}
      body={intl.formatMessage({
        defaultMessage: "Still not sure? Check your benefits portal or contact your insurance provider."
      })}
      isPartner={false}
    />
  )
}

function PartnerHasHdhpStep(): JSX.Element {
  const { partnerHasHDHP } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()

  return (
    <HasHdhpStep
      FieldName={"partnerHasHdhpValue"}
      ViewName={Steps.PARTNER_HDHP}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          partnerHasHDHP: val
        })
      }
      hasHDHPValue={partnerHasHDHP}
      title={intl.formatMessage({
        defaultMessage: "Are they currently enrolled in a high deductible health plan (HDHP)?"
      })}
      body={intl.formatMessage({
        defaultMessage:
          "Still not sure? Ask your partner to check their benefits portal or to contact their insurance provider."
      })}
      isPartner={true}
    />
  )
}
export { MemberHasHdhpIndividualStep, MemberHasHdhpFamilyStep, PartnerHasHdhpStep }
