import { BenefitHighlights } from "#/components/home/benefits-modules/BenefitHighlights"
import CarrotPlanIsOnTheWayBanner from "#/components/home/CarrotPlanOnTheWayBanner"
import { Stack, Tile, useTheme } from "@carrotfertility/carotene-core"
import React from "react"

export const Hero = ({ carrotPlanIsOnTheWay }: { carrotPlanIsOnTheWay: boolean }): JSX.Element => {
  const theme = useTheme()

  return (
    <Tile id="homepage-info-tile" color="info">
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        {carrotPlanIsOnTheWay && <CarrotPlanIsOnTheWayBanner />}
        <BenefitHighlights />
      </Stack>
    </Tile>
  )
}
