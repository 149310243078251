import { BodySmall } from "#/components/views/atoms/Bodies"
import { CircleImage } from "#/components/views/atoms/images/CircleImage"
import React from "react"
import { FormattedMessage } from "react-intl"
// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from carotene-core or carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import styled from "styled-components"

const LegalFooter = styled.div`
  display: flex;
  margin-block-start: 40px;
  margin-block-end: 48px;
  padding-block-start: 12px;
  border-block-start: 1px solid #ebebeb;
  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  display: flex;
  margin-inline: 0 16px;
  margin-block-end: 12px;
`

const TalkToCarrotLegal = () => (
  <LegalFooter>
    <ImageContainer>
      <CircleImage iconName="icn-help" backgroundColor="light-gray" />
    </ImageContainer>
    <div>
      <BodySmall color="black-60">
        <FormattedMessage defaultMessage="Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately." />
      </BodySmall>
    </div>
  </LegalFooter>
)

export { TalkToCarrotLegal }
