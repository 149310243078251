import { useQuery } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import { reportError } from "#/utils/ErrorReporting"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { BenefitConfiguration } from "#/types/benefitConfiguration"

export interface RegisteringUser {
  employeeId: number
  firstName: string
  preferredName: string
  email: string
  companyDisplayName: string
  companyId: number
  parentCompanyId: number
  hasSaml: boolean
  benefitConfiguration: BenefitConfiguration
  benefit: Benefit
  isUsa: boolean
  countryCode: string
  ssoOnly: boolean
}

const registeringUserQueryKey = "getRegisteringUser"

export function useFetchRegisteringUser(guid: string, setRegisteringUser: (user: RegisteringUser) => void) {
  return useQuery<RegisteringUser>(
    [registeringUserQueryKey, guid],
    async () => {
      await carrotClient.logout()
      return carrotClient.getRegisteringUser(guid)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3),
      onSuccess: setRegisteringUser,
      onError: (error) => reportError(error)
    }
  )
}
