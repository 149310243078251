// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from carotene-core or carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import styled from "styled-components"
import React, { FC, ReactNode } from "react"

type LabelProps = {
  color?: string
  children: ReactNode
}

/**
 * @deprecated Use `Typography` from `@carrotfertility/carotene-core` instead.
 */
const Label: FC<LabelProps> = ({ children, color = "black-60", ...props }) => (
  <StyledLabel className={`gotham ${color}`} {...props}>
    {children}
  </StyledLabel>
)

/**
 * @deprecated Use `Typography` from `@carrotfertility/carotene-core` instead.
 */
const StyledLabel = styled.span`
  letter-spacing: 0.06em;
  display: block;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 17px;
`

export { Label }
