import { EnterTelemedicineForMenopauseDialog } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import { InternalIcon, Link } from "@carrotfertility/carotene-core"
import React, { useState } from "react"

export function TelemedicineForMenopauseLink({ children }: { children: React.ReactNode }): JSX.Element {
  const [openDialogFlow, setOpenDialogFlow] = useState(false)
  return (
    <>
      <Link
        component={"button"}
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        onClick={() => setOpenDialogFlow(true)}
      >
        {children}
      </Link>
      <EnterTelemedicineForMenopauseDialog {...{ openDialogFlow, setOpenDialogFlow }} />
    </>
  )
}
