import React, { useEffect } from "react"
import {
  BackIcon,
  Button,
  dayjs,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ForwardIcon,
  Link,
  Stepper
} from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import {
  DialogActionsBar,
  Form,
  FormDatePicker,
  FormOnSubmitHandler,
  FormSelect,
  FormTextField
} from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { useGetSexOptions } from "#/services/common-forms/SexOptions"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { validatePartnerDOB } from "#/services/common-forms"
import { Sex } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { emailValidation } from "#/utils/Regexes"
import { getHeap } from "#/utils/heap"
import { useUpdatePartnerInfo } from "#/components/carrot-match-fertility-clinic-precision-matching/hooks/useUpdatePartnerInfo"

export function TellUsAboutYourPartnerStep(): JSX.Element {
  const { send, back } = useStateMachine()
  const { updateCarrotMatchInteraction, carrotMatchInteraction } = useCarrotMatch()
  const { personalEmail, email } = useCurrentUser()
  const intl = useIntl()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })
  const sexOptions = useGetSexOptions()
  const intlEmailValidation = useIntlValidator(emailValidation)
  const { mutate: updatePartnerInfo } = useUpdatePartnerInfo()

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: "Tell us about your partner" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit: FormOnSubmitHandler<{
    partnerDateOfBirth: string
    partnerEmail: string
    partnerFirstName: string
    partnerLastName: string
    partnerPreferredName: string
    partnerSex: string
  }> = (formData) => {
    const partnerAge = dayjs().diff(dayjs(formData.partnerDateOfBirth), "year")

    const { memberDemographics } = carrotMatchInteraction

    updateCarrotMatchInteraction({
      partnerPreferredNameOrFirstName: formData.partnerFirstName,
      memberDemographics: {
        ...memberDemographics,
        partnerAge: Number(partnerAge),
        partnerSex: formData.partnerSex as Sex
      }
    })

    updatePartnerInfo({
      partnerDateOfBirth: formData.partnerDateOfBirth,
      partnerEmail: formData.partnerEmail,
      partnerFirstName: formData.partnerFirstName,
      partnerLastName: formData.partnerLastName,
      partnerPreferredName: formData.partnerPreferredName,
      partnerSex: formData.partnerSex
    })

    send("")
  }

  function validatePartnerDateOfBirth(dateOfBirthValue: dayjs.Dayjs): string | boolean {
    try {
      dateOfBirthValue?.toISOString()
    } catch (e) {
      return intl.formatMessage({ defaultMessage: "Please enter a valid date" })
    }

    if (!dateOfBirthValue) return true

    const result = validatePartnerDOB(dateOfBirthValue)
    if (typeof result !== "boolean") {
      return intl.formatMessage(result)
    }
    return result
  }

  function validateEmail(partnerEmail: string) {
    if (!partnerEmail) {
      return true
    }
    if (partnerEmail && (partnerEmail === personalEmail || partnerEmail === email)) {
      getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
      return intl.formatMessage({ defaultMessage: "That email address is already associated with your account." })
    }

    return intlEmailValidation(partnerEmail)
  }
  return (
    <>
      <Form onSubmit={onSubmit}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={0} position="static" />
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Tell us about your partner" />
        </DialogTitle>
        <DialogContentText paddingBlockEnd={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
          <FormattedMessage
            defaultMessage="Providing details about your partner or spouse helps us better support both of you. You can update this
          information in your <link>Account settings</link> anytime."
            values={{
              link: (linkContent) => (
                <Link href="/account#partnerInformationSettings" target="_blank" color="inherit">
                  {linkContent}
                </Link>
              )
            }}
          />
        </DialogContentText>
        <DialogContent>
          <FormTextField
            name="partnerFirstName"
            label={intl.formatMessage({ defaultMessage: "Partner's first name" })}
            inputProps={{ "data-testid": "carrot-match-partnerFirstName" }}
            registerOptions={{ required: requiredText }}
          />
          <FormTextField
            name="partnerLastName"
            label={intl.formatMessage({ defaultMessage: "Partner's last name" })}
            inputProps={{ "data-testid": "carrot-match-partnerLastName" }}
            registerOptions={{ required: requiredText }}
          />
          <FormTextField
            name="partnerEmail"
            type="email"
            label={intl.formatMessage({ defaultMessage: "Partner's email address" })}
            inputProps={{ "data-testid": "about-you-partnerEmail" }}
            registerOptions={{ required: requiredText, validate: validateEmail }}
          />
          <FormDatePicker
            label={intl.formatMessage({ defaultMessage: "Partner's date of birth" })}
            name={"partnerDateOfBirth"}
            controllerProps={{
              rules: {
                required: requiredText,
                validate: validatePartnerDateOfBirth
              }
            }}
          />
          <FormSelect
            name="partnerSex"
            label={intl.formatMessage({ defaultMessage: "Partner's sex" })}
            options={sexOptions}
            controllerProps={{ rules: { required: requiredText } }}
          />
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="outlined" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
          tertiaryAction={
            <Button onClick={() => back()} variant="text" color="secondary" startIcon={<BackIcon />}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
