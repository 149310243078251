import React, { useEffect } from "react"
import { BackIcon, Button, DialogContent, DialogTitle, ForwardIcon, Stepper } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import {
  User,
  NONE_OF_THESE,
  ClinicSpecialization,
  Questions
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { FormCheckboxButtonGroup } from "#/services/common-forms/FormCheckboxButtonGroup"
import { useHandleCheckboxGroupNoneOfThese } from "#/components/carrot-match-fertility-clinic-precision-matching/hooks/useHandleCheckboxGroupNoneOfThese"

type ClinicSpecializationCheckboxOption = { label: string; value: ClinicSpecialization | typeof NONE_OF_THESE }
function ClinicSpecializationCheckboxGroup({ name }: { name: string }): JSX.Element {
  const intl = useIntl()
  const handleCheckboxGroup = useHandleCheckboxGroupNoneOfThese(name)

  const options: ClinicSpecializationCheckboxOption[] = [
    {
      label: intl.formatMessage({
        defaultMessage: "Minimum Stimulation Cycle"
      }),
      value: ClinicSpecialization.MINIMUM_STIMULATION_CYCLE
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Natural Cycle"
      }),
      value: ClinicSpecialization.NATURAL_CYCLE
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Male Factor Infertility"
      }),
      value: ClinicSpecialization.MALE_FACTOR_INFERTILITY
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Donor Assisted Reproduction"
      }),
      value: ClinicSpecialization.DONOR_ASSISTED_REPRODUCTION
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Unexplained Infertility"
      }),
      value: ClinicSpecialization.UNEXPLAINED_INFERTILITY
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Uterine Factor"
      }),
      value: ClinicSpecialization.UTERINE_FACTOR
    },
    {
      label: intl.formatMessage({
        defaultMessage: "High BMI"
      }),
      value: ClinicSpecialization.HIGH_BMI
    },
    {
      label: intl.formatMessage({
        defaultMessage: "PCOS"
      }),
      value: ClinicSpecialization.PCOS
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Endometriosis"
      }),
      value: ClinicSpecialization.ENDOMETRIOSIS
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Diminished Ovarian Reserve"
      }),
      value: ClinicSpecialization.DIMINISHED_OVARIAN_RESERVE
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Tubal Factor"
      }),
      value: ClinicSpecialization.TUBAL_FACTOR
    },
    {
      label: intl.formatMessage({ defaultMessage: "None of these" }),
      value: NONE_OF_THESE
    }
  ]

  return <FormCheckboxButtonGroup name={name} options={options} onChange={handleCheckboxGroup} />
}

export function ClinicSpecializationStep() {
  const { send, back } = useStateMachine()
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const {
    memberPreferredNameOrFirstName,
    partnerPreferredNameOrFirstName,
    whoIsAnsweringTheIntake,
    whoIsSeekingCare,
    questionsAndAnswers
  } = carrotMatchInteraction
  const intl = useIntl()

  useEffect(() => {
    updateCarrotMatchInteraction({
      lastQuestionViewedBeforeLeavingFlow: Questions.CLINIC_SPECIALIZATION_QUESTION
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const getClinicSpecializationDialogTitle = () => {
    if (whoIsAnsweringTheIntake === whoIsSeekingCare) {
      return intl.formatMessage({
        defaultMessage: "Are you interested in clinics that have experience in the following?"
      })
    } else if (whoIsSeekingCare === User.PARTNER) {
      return intl.formatMessage(
        {
          defaultMessage:
            "Is {partnerPreferredNameOrFirstName} interested in clinics that have experience in the following?"
        },
        { partnerPreferredNameOrFirstName }
      )
    } else {
      return intl.formatMessage(
        {
          defaultMessage:
            "Is {memberPreferredNameOrFirstName} interested in clinics that have experience in the following?"
        },
        { memberPreferredNameOrFirstName }
      )
    }
  }

  const handleSubmitAndProceedToNextStep: FormOnSubmitHandler<{
    clinicSpecialization: ClinicSpecialization[]
  }> = ({ clinicSpecialization }: { clinicSpecialization: ClinicSpecialization[] }) => {
    updateCarrotMatchInteraction({
      questionsAndAnswers: [
        ...questionsAndAnswers.filter((qa) => qa.question !== Questions.CLINIC_SPECIALIZATION_QUESTION),
        {
          question: Questions.CLINIC_SPECIALIZATION_QUESTION,
          answer: clinicSpecialization
        }
      ]
    })
    send("")
  }

  const handleSkipAndProceedToNextStep = () => {
    updateCarrotMatchInteraction({
      questionsAndAnswers: [
        ...questionsAndAnswers.filter((qa) => qa.question !== Questions.CLINIC_SPECIALIZATION_QUESTION),
        {
          question: Questions.CLINIC_SPECIALIZATION_QUESTION,
          answer: []
        }
      ]
    })
    send("")
  }

  return (
    <>
      <Form onSubmit={handleSubmitAndProceedToNextStep}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={1} position="static" />
        <DialogTitle id="step-heading">{getClinicSpecializationDialogTitle()}</DialogTitle>
        <DialogContent>
          <ClinicSpecializationCheckboxGroup name="clinicSpecialization" />
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="outlined" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
          secondaryAction={
            <Button variant="text" color="secondary" type="submit" onClick={handleSkipAndProceedToNextStep}>
              <FormattedMessage defaultMessage="Skip" />
            </Button>
          }
          tertiaryAction={
            <Button onClick={() => back()} variant="text" color="secondary" startIcon={<BackIcon />}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
