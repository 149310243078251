import React, { useEffect } from "react"
import { IlloUnderwaterPlants } from "../atoms/Illustrations"
import { Link as RouterLink } from "react-router-dom"
import { getHeap } from "#/utils/heap"
import { FormattedMessage } from "react-intl"
import { PageLayout } from "#/features/global-ui/page-layout"
import { Box, Button, Tile, Typography } from "@carrotfertility/carotene-core"

const PageNotFound = () => {
  useEffect(() => {
    getHeap().track("PageNotFound", { EventName: "User saw page not found screen" })
  }, [])

  return (
    <PageLayout header={null}>
      <Box display="flex" justifyContent="center">
        <Tile
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBlockStart: "8rem"
          }}
        >
          <IlloUnderwaterPlants />
          <Typography
            sx={(theme) => ({
              marginBlockStart: theme.spacing(theme.tokens.spacing.md),
              marginBlockEnd: theme.spacing(theme.tokens.spacing.xxs)
            })}
            variant="h2"
          >
            <FormattedMessage defaultMessage="Page not found!" />
          </Typography>
          <Typography
            sx={(theme) => ({
              marginBlockStart: theme.spacing(theme.tokens.spacing.xxs),
              marginBlockEnd: theme.spacing(theme.tokens.spacing.md)
            })}
          >
            <FormattedMessage defaultMessage="Sorry we couldn't find what you were looking for" />
          </Typography>
          <Button component={RouterLink} to="/">
            <FormattedMessage defaultMessage="Return home" />
          </Button>
        </Tile>
      </Box>
    </PageLayout>
  )
}

export { PageNotFound }
