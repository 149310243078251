import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import React, { useEffect, useState } from "react"
import { FormDatePicker, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { Button, Stack, Typography, dayjs, useTheme } from "@carrotfertility/carotene-core"
import { UpdatePhoneNumberModal } from "./phoneNumberUpdate/UpdatePhoneNumberModal"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { MemberGenderIdentitySettings, MemberSexSettings } from "./MemberPartnerPersonalSettings"
import { FormattedMessage, useIntl } from "react-intl"
import { validateMemberDOB } from "#/services/common-forms"

function DateOfBirth(): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  const intl = useIntl()

  function validate(dateOfBirth: dayjs.Dayjs): boolean | string {
    try {
      dateOfBirth?.toISOString()
    } catch (e) {
      return intl.formatMessage({ defaultMessage: "Please enter a valid date" })
    }

    const result = validateMemberDOB(dateOfBirth)
    if (typeof result !== "boolean") {
      return intl.formatMessage(result)
    }
    return result
  }

  return (
    <FormDatePicker
      readOnly={isPartnerAccessingAccount}
      controllerProps={{
        rules: {
          required: intl.formatMessage({ defaultMessage: "Required" }),
          validate: validate
        }
      }}
      name="dateOfBirth"
      label={intl.formatMessage({ defaultMessage: "Date of birth" })}
    />
  )
}

function PhoneNumber(): JSX.Element {
  const [openDialog, setOpenDialog] = useState(false)
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()
  const { phoneNumber, refreshUserInfo } = useCurrentUser()
  const { setValue } = useFormContext()
  const intl = useIntl()

  useEffect(() => {
    setValue("phoneNumber", phoneNumber)
  }, [phoneNumber, setValue])

  return (
    <>
      <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
        <FormTextField
          label={intl.formatMessage({ defaultMessage: "Phone number" })}
          name="phoneNumber"
          InputProps={{ readOnly: true }}
        />

        {!isPartnerAccessingAccount && (
          <div>
            <Button variant="outlined" color="secondary" onClick={() => setOpenDialog(true)}>
              <FormattedMessage defaultMessage="Update phone number" />
            </Button>
          </div>
        )}
      </Stack>
      <UpdatePhoneNumberModal
        open={openDialog}
        onClose={() => {
          refreshUserInfo()
          setOpenDialog(false)
        }}
      />
    </>
  )
}

export function PersonalInformationSettings(): JSX.Element {
  const theme = useTheme()
  const { isPartnerAccessingAccount } = useUserRole()
  const intl = useIntl()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="Personal information" />
      </Typography>
      <FormTextField
        InputProps={{ readOnly: true }}
        name="firstName"
        label={intl.formatMessage({ defaultMessage: "first name" })}
      />
      <FormTextField
        InputProps={{ readOnly: true }}
        name="lastName"
        label={intl.formatMessage({ defaultMessage: "last name" })}
      />
      <FormTextField
        disabled={isPartnerAccessingAccount}
        label={intl.formatMessage({ defaultMessage: "Preferred name" })}
        name="preferredName"
        inputProps={{ maxLength: 200 }}
      />
      <DateOfBirth />
      <MemberGenderIdentitySettings
        shouldBeDisabled={isPartnerAccessingAccount}
        names={{ genderIdentity: "genderIdentity", genderIdentityOther: "genderIdentityOther" }}
      />
      <MemberSexSettings name="sex" shouldBeDisabled={isPartnerAccessingAccount} />
      <PhoneNumber />
    </Stack>
  )
}
