import React from "react"
import useInsuranceDetails from "../context/useInsuranceDetails"
import InsurancePlanInformationTable from "../insurance-coverage-section/plan-information-table"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { FormattedMessage } from "react-intl"
import { Typography, Stack, Link, Box, InternalIcon } from "@carrotfertility/carotene-core"
import { COMPANY_DEDUCTIBLE_STATUS } from "#/components/context/user/DeductibleContext"
import { InsurancePlanInformationRowData } from "../insurance-coverage-section/plan-information-table/InsurancePlanInformationRow"
import { useHistory } from "react-router-dom"
import Locations from "#/components/views/home/navigation"

const EMDInsuranceConnectionMessage = (): JSX.Element => {
  return (
    <Typography>
      <FormattedMessage
        defaultMessage="You've connected your health insurance to your Carrot account.  Any <link>qualified medical expenses</link> you 
        submit to Carrot will also be applied to your insurance deductible. This process usually takes 1-2 weeks."
        values={{
          link: (linkContent) => (
            <Link target="_blank" href={"/your-benefit-guide/carrot-funds/qualified-medical-expenses"} color="inherit">
              {linkContent}
            </Link>
          )
        }}
      />
    </Typography>
  )
}

const CMDInsuranceConnectionMessage = (): JSX.Element => {
  return (
    <Typography>
      <FormattedMessage
        defaultMessage="You've connected your health insurance to your Carrot account.  Any expenses applied to your insurance deductible 
        will also be credited to your <link>deductible</link> managed by Carrot."
        values={{
          link: (linkContent) => (
            <Link target="_blank" href={"/your-benefit-guide/carrot-funds/deductibles"} color="inherit">
              {linkContent}
            </Link>
          )
        }}
      />
    </Typography>
  )
}

const InsuranceNotConnectedForCMDMessage = (): JSX.Element => {
  const history = useHistory()
  return (
    <>
      <Typography>
        <FormattedMessage
          defaultMessage="You haven't connected your health insurance to your Carrot account.  This optional step may help you meet your <link>deductible</link> faster."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={"/your-benefit-guide/carrot-funds/deductibles"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
      <Box paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <Typography>
          <FormattedMessage
            defaultMessage="Any expenses applied to your insurance deductible will also be credited to your deductible managed by Carrot. <link>Connect your insurance</link>"
            values={{
              link: (linkContent) => (
                <Box paddingTop={(theme) => theme.spacing(theme.tokens.spacing.md)}>
                  <Link
                    color="inherit"
                    endIcon={<InternalIcon fontSize="small" color="primary" />}
                    component="button"
                    onClick={() => history.push(Locations.Home.InsuranceEditEnrollmentActive)}
                  >
                    {linkContent}
                  </Link>
                </Box>
              )
            }}
          />
        </Typography>
      </Box>
    </>
  )
}

interface InsuranceConnectionMessageProps {
  userHasConnectedInsurance: boolean
  companyDeductibleStatus: string
}

const InsuranceConnectionMessage = ({
  userHasConnectedInsurance,
  companyDeductibleStatus
}: InsuranceConnectionMessageProps): JSX.Element => {
  if (!userHasConnectedInsurance && companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE) {
    return <InsuranceNotConnectedForCMDMessage />
  }

  const MessageComponentMap = {
    [COMPANY_DEDUCTIBLE_STATUS.EXTERNAL]: <EMDInsuranceConnectionMessage />,
    [COMPANY_DEDUCTIBLE_STATUS.CARROT_MANAGED_DEDUCTIBLE]: <CMDInsuranceConnectionMessage />
  }

  return MessageComponentMap[companyDeductibleStatus] || <CMDInsuranceConnectionMessage />
}

const checkUserHasConnectedInsurance = (insuranceProviderDetailsData: InsurancePlanInformationRowData[]): boolean => {
  let userHasConnectedInsurance = false

  const healthInsuranceNameMember = insuranceProviderDetailsData[0].memberData
  const healthInsuranceNamePartner = insuranceProviderDetailsData[0].partnerData
  const memberIdMember = insuranceProviderDetailsData[1].memberData
  const memberIdPartner = insuranceProviderDetailsData[1].partnerData

  if ((healthInsuranceNameMember && memberIdMember) || (healthInsuranceNamePartner && memberIdPartner))
    userHasConnectedInsurance = true

  return userHasConnectedInsurance
}

export const InsuranceConnectionSection = (): JSX.Element | null => {
  const userInfo = useCurrentUser()
  const { companyDeductibleStatus, insuranceProviderDetailsData, insuranceExpired, memberHistory } =
    useInsuranceDetails()

  const userHasPartner =
    insuranceExpired && memberHistory ? memberHistory.memberHasLegalPartner : userInfo.memberHasLegalPartner

  const userHasConnectedInsurance = checkUserHasConnectedInsurance(insuranceProviderDetailsData)

  if (companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.NO_DEDUCTIBLE) return null

  const showTitle = (insuranceExpired && userHasConnectedInsurance) || !insuranceExpired
  const showInsuranceMessage = !insuranceExpired
  const showInsuranceTable = userHasConnectedInsurance

  return (
    <>
      {showTitle && (
        <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
          <Typography variant="h2">
            <FormattedMessage defaultMessage="Insurance connection" />
          </Typography>
        </Stack>
      )}

      {showInsuranceMessage && (
        <InsuranceConnectionMessage
          userHasConnectedInsurance={userHasConnectedInsurance}
          companyDeductibleStatus={companyDeductibleStatus}
        />
      )}

      {showInsuranceTable && (
        <InsurancePlanInformationTable
          hasPartner={userHasPartner}
          planData={insuranceProviderDetailsData}
          insuranceExpired={insuranceExpired}
        />
      )}
    </>
  )
}
