import React, { useEffect } from "react"
import { RegisterError } from "#/components/views/register/RegisterError"
import { NiceToSeeYou } from "#/components/views/register/NiceToSeeYou"
import { SetPassword } from "#/components/views/register/SetPassword"
import { AcknowledgeTerms } from "#/components/views/register/AcknowledgeTerms"
import { HealthConsent } from "#/components/views/register/HealthConsent"
import { EmailOptIn, getIsDefaultEmailOptIn } from "./EmailOptIn"
import { PersonalEmail } from "./PersonalEmail"
import { PreferredName } from "./PreferredName"
import { PersonalEmailVerification } from "./PersonalEmailVerification"
import { RegisteringLoadingIndicator } from "./RegistrationLoadingIndicator"
import { WhatKindOfJourney } from "./WhatKindOfJourney"
import { PhoneNumberCollection } from "./PhoneNumberCollection"
import { RegistrationComplete } from "./RegistrationComplete"
import { PregnancyMedicalRecordsCollection } from "./PregnancyMedicalRecordsCollection"
import { WhereAreYouInJourney } from "#/components/views/register/WhereAreYouInJourney"
import NumberOfChildren from "#/components/views/register/NumberofChildren"
import { STEPS, useRegistration } from "#/components/views/register/RegistrationContext"
import { useRegistrationNavigation } from "./hooks/useRegistrationNavigation"
import { RegisteringUser, useFetchRegisteringUser } from "#/components/views/register/useFetchRegisteringUser"
import { useHistory } from "react-router-dom"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { LaunchDarklyUser } from "#/types/launchDarklyUser"
import { useEnabledJourneyOptions } from "#/components/carrot-plans/hooks/useJourneyOptions"

export interface RegistrationJourney {
  journeyType: string
  somethingElseJourney: string
  journeyStage: string
  numberOfChildren: string
  skipped: boolean
}

const RegisterWizard = (): JSX.Element => {
  const history = useHistory()
  const ldClient = useLDClient()

  const {
    guid,
    step,
    setStep,
    personalEmail,
    preferredName,
    setPreferredName,
    phoneNumber,
    setPhoneNumber,
    phoneNumberCountryCode,
    setPhoneNumberCountryCode,
    allowsMarketingTexts,
    setAllowsMarketingTexts,
    setDbFormattedPhoneNumber,
    healthConsent,
    setHealthConsent,
    emailOptIn,
    setEmailOptIn,
    readPolicy,
    setReadPolicy,
    readTerms,
    setReadTerms,
    journey,
    journeyStage,
    somethingElseJourney,
    isSubmitting,
    numberOfChildren,
    otherJourneyStage
  } = useRegistration()

  const setRegisteringUser = (registeringUser: RegisteringUser): void => {
    setPreferredName(registeringUser?.preferredName)
    setEmailOptIn(getIsDefaultEmailOptIn(registeringUser?.countryCode))
    setPhoneNumberCountryCode(registeringUser?.countryCode)
    setStep(STEPS.INTRO)
  }

  const registeringUserQuery = useFetchRegisteringUser(guid, setRegisteringUser)
  const registeringUser = registeringUserQuery?.data

  useEffect(() => {
    if (registeringUser) {
      if (!registeringUser.email) {
        history.push("/signup")
      }
    }
  }, [registeringUser, history])

  useEffect(() => {
    async function loadLDFlagsForRegisteringUser(
      memberEmail: string,
      memberCompanyId: number,
      memberParentCompanyId?: number,
      companyCountryCode?: string
    ): Promise<void> {
      if (!ldClient || !memberEmail || !memberCompanyId) return

      await ldClient.waitUntilReady()

      const ldUser: LaunchDarklyUser = {
        key: memberEmail,
        email: memberEmail,
        custom: {
          companyId: memberCompanyId.toString(),
          countryCode: companyCountryCode,
          isPartner: false,
          isEnrolled: false,
          isEngaged: false
        }
      }

      if (memberParentCompanyId && memberParentCompanyId !== 0) {
        ldUser.custom["parentCompanyId"] = memberParentCompanyId?.toString()
      }

      await ldClient.identify(ldUser)
    }

    const { email, companyId, parentCompanyId, countryCode } = registeringUser || {}

    loadLDFlagsForRegisteringUser(email, companyId, parentCompanyId, countryCode).catch((error) => reportError(error))
  }, [ldClient, registeringUser])

  const {
    onCompleteIntro,
    onBackSetPassword,
    onCompleteSetPassword,
    onBackPersonalEmail,
    onCompletePersonalEmail,
    onBackEmailOptIn,
    onCompleteEmailOptIn,
    onBackPreferredName,
    onCompletePreferredName,
    onBackPhoneNumberCollection,
    onCompletePhoneNumberCollection,
    onBackAcknowledgeTerms,
    onCompleteAcknowledgeTerms,
    onBackHealthConsent,
    onCompleteHealthConsent,
    onBackJourney,
    onCompleteJourney,
    onBackNumberOfChildren,
    onCompleteNumberOfChildren,
    onBackJourneyStage,
    onCompleteJourneyStage,
    onCompleteVerifyPersonalEmail,
    onCompletePregnancyMedicalRecordsCollection
  } = useRegistrationNavigation(registeringUser)

  const { journeyOptions } = useEnabledJourneyOptions(registeringUser?.benefit)

  switch (step) {
    case STEPS.INTRO:
      return (
        <NiceToSeeYou
          companyDisplayName={registeringUser?.companyDisplayName}
          onComplete={onCompleteIntro}
          benefit={registeringUser?.benefit}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.SET_PASSWORD:
      return (
        <SetPassword
          email={registeringUser?.email}
          onBack={onBackSetPassword}
          onComplete={onCompleteSetPassword}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.PERSONAL_EMAIL:
      return (
        <PersonalEmail
          personalEmail={personalEmail || ""}
          email={registeringUser?.email}
          onBack={onBackPersonalEmail}
          onComplete={onCompletePersonalEmail}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.PREFERRED_NAME:
      return (
        <PreferredName
          {...{ setPreferredName, preferredName }}
          onBack={onBackPreferredName}
          onComplete={onCompletePreferredName}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.PHONE_NUMBER_COLLECTION:
      return (
        <PhoneNumberCollection
          {...{
            setPhoneNumber,
            phoneNumber,
            setPhoneNumberCountryCode,
            phoneNumberCountryCode,
            setAllowsMarketingTexts,
            setDbFormattedPhoneNumber,
            allowsMarketingTexts
          }}
          isUsa={registeringUser?.isUsa}
          onBack={onBackPhoneNumberCollection}
          onComplete={onCompletePhoneNumberCollection}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.ACKNOWLEDGE_TERMS:
      return (
        <AcknowledgeTerms
          {...{ readPolicy, setReadPolicy, readTerms, setReadTerms }}
          firstName={registeringUser?.firstName}
          preferredName={preferredName}
          onBack={onBackAcknowledgeTerms}
          onComplete={onCompleteAcknowledgeTerms}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.HEALTH_CONSENT:
      return (
        <HealthConsent
          isSubmitting={isSubmitting}
          healthConsent={healthConsent}
          setHealthConsent={setHealthConsent}
          benefitConfiguration={registeringUser?.benefitConfiguration}
          onBack={onBackHealthConsent}
          onComplete={onCompleteHealthConsent}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.EMAIL_OPT_IN:
      return (
        <EmailOptIn
          isSubmitting={isSubmitting}
          emailOptIn={emailOptIn}
          setEmailOptIn={setEmailOptIn}
          onBack={onBackEmailOptIn}
          onComplete={onCompleteEmailOptIn}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.PERSONAL_EMAIL_VERIFICATION:
      return (
        <PersonalEmailVerification
          personalEmail={personalEmail}
          onComplete={onCompleteVerifyPersonalEmail}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.WHAT_KIND_OF_JOURNEY:
      return (
        <WhatKindOfJourney
          journey={journey}
          somethingElseJourney={somethingElseJourney}
          journeyOptions={journeyOptions}
          isCarrotLite={registeringUser?.benefit?.program.isCarrotLite}
          onBack={onBackJourney}
          onComplete={onCompleteJourney}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.NUMBER_OF_CHILDREN:
      return (
        <NumberOfChildren
          numberOfChildren={numberOfChildren}
          onBack={onBackNumberOfChildren}
          onComplete={onCompleteNumberOfChildren}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.WHERE_ARE_YOU_IN_JOURNEY:
      return (
        <WhereAreYouInJourney
          memberBenefit={registeringUser?.benefit}
          journey={journey}
          numberOfChildren={numberOfChildren}
          journeyStage={journeyStage}
          otherJourneyStage={otherJourneyStage}
          onBack={() => onBackJourneyStage(journey)}
          onComplete={onCompleteJourneyStage}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.PREGNANCY_MEDICAL_RECORDS_COLLECTION:
      return (
        <PregnancyMedicalRecordsCollection
          onComplete={onCompletePregnancyMedicalRecordsCollection}
          employeeId={registeringUser?.employeeId}
        />
      )
    case STEPS.REGISTRATION_COMPLETED:
      return <RegistrationComplete employeeId={registeringUser?.employeeId} />
    case STEPS.ERROR:
      return <RegisterError employeeId={registeringUser?.employeeId} />
    default:
      return <RegisteringLoadingIndicator />
  }
}

export default RegisterWizard
