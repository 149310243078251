import React, { FC } from "react"
import { Box, Stack, Typography, useTheme, LinkProps, Link, InternalIcon } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { getSupportCenterLink, goToHelpCenter } from "#/lib/carrot-api/HelpCenterClient"

type ZendeskArrowLinkProps = {
  supportCenterLink?: string
} & LinkProps

const ZendeskArrowLink: FC<ZendeskArrowLinkProps> = ({ supportCenterLink, ...props }: ZendeskArrowLinkProps) => {
  let zdLinkFragment = supportCenterLink
  // Contentful zendesk links will be provided via the "to" and they will be full URLs to zendesk, so parse out the fragment
  // e.g. https://get-carrot.zendesk.com/hc/sections/4410088379931-Carrot-Plans
  if (props.href) {
    const path = props.href?.split("/")
    zdLinkFragment = path[path.length - 2] + "/" + path[path.length - 1]
  }

  return (
    <Link
      href={getSupportCenterLink(zdLinkFragment)}
      onClick={(event) => goToHelpCenter(event, zdLinkFragment)}
      target={"_blank"}
      endIcon={<InternalIcon fontSize="small" color="primary" />}
    >
      {props.children}
    </Link>
  )
}

export function HelpCenterModule(): JSX.Element {
  const theme = useTheme()
  return (
    <Box
      id="home-sidebar-help-center-module"
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      bgcolor={(theme) => theme.palette.background.default}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
    >
      <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Typography variant="h4" component="h2">
          <FormattedMessage defaultMessage="Get answers to frequently asked questions" />
        </Typography>
        <ZendeskArrowLink>
          <FormattedMessage defaultMessage="Visit Help Center" />
        </ZendeskArrowLink>
      </Stack>
    </Box>
  )
}
