import React, { useEffect } from "react"
import { CircleImage } from "../CircleImage"
import { Link } from "react-router-dom"
import { NoResultMessage } from "./NoResultMessage"
import { providerFinderRootPath } from "../../utils/providerFinderSearchUrl"
import { getHeap } from "../../utils/heap"
import { defineMessage, FormattedMessage } from "react-intl"
import { Box, Button, Progress, Typography } from "@carrotfertility/carotene-core"
import { useEnhancedEmptyState } from "#/pages/provider-finder/hooks/useEnhancedEmptyState"
import { ProviderEligibilityMessage } from "#/pages/provider-finder/components/results/ProviderEligibilityMessage"

export function NoResultDisplay({ isCountryCodeNotMatching }: { isCountryCodeNotMatching: boolean }): JSX.Element {
  useEffect(
    function trackProviderSearchNoResultsMessageRendered() {
      getHeap().track("Provider Search No Results Message Rendered", {
        "Location Is Outside Country": isCountryCodeNotMatching ? "true" : "false"
      })
    },
    [isCountryCodeNotMatching]
  )

  const enhancedEmptyState = useEnhancedEmptyState()
  const headerMessage = enhancedEmptyState.isEnhancedEmptyState
    ? defineMessage({ defaultMessage: "No results in Provider finder, but..." })
    : defineMessage({ defaultMessage: "No results found" })

  const footerMessage = enhancedEmptyState.isEnhancedEmptyState
    ? defineMessage({ defaultMessage: "Search again" })
    : defineMessage({ defaultMessage: "Reset location" })

  return enhancedEmptyState.isLoadingContent ? (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Progress />
    </Box>
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      padding="80px 0 0 0"
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
    >
      <CircleImage iconName="icn-find" backgroundColor="lightBlue" size={84} alt="" />
      <Box textAlign="center">
        <Typography variant="h4" color="secondary">
          <FormattedMessage {...headerMessage} />
        </Typography>
        {enhancedEmptyState.isEnhancedEmptyState ? (
          <ProviderEligibilityMessage
            displayName={enhancedEmptyState.emptyStateProviderTypeDisplayName}
            dialogContent={enhancedEmptyState.emptyStateContent}
          />
        ) : (
          <NoResultMessage isCountryCodeNotMatching={isCountryCodeNotMatching} />
        )}
      </Box>
      <Link to={providerFinderRootPath}>
        <Button variant="outlined" color="secondary" size="medium">
          <FormattedMessage {...footerMessage} />
        </Button>
      </Link>
    </Box>
  )
}
