import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Box, Progress } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import {
  EntryPoint,
  GetCarrotMatchMatchesResponse
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import useProvidersByProviderUuids from "#/pages/provider-finder/hooks/useProvidersByProviderUuids"
import { Provider } from "#/pages/provider-finder/types/Providers"

export function ShowOneLastStepDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()

  const topProvidersByMatchScore = carrotMatchInteraction.matches
    ?.sort((a: GetCarrotMatchMatchesResponse, b: GetCarrotMatchMatchesResponse) => b.matchScore - a.matchScore)
    .slice(0, 3)
  const providerUuids = topProvidersByMatchScore.map((match) => match.providerUuid)

  const { data: providersData, isLoading: isProvidersDataLoading } = useProvidersByProviderUuids({
    providerUuids: providerUuids ?? [],
    lat: carrotMatchInteraction.location?.lat,
    long: carrotMatchInteraction.location?.long
  })

  useEffect(() => {
    if (!isProvidersDataLoading) {
      const sortedProviderDetails = topProvidersByMatchScore.map((match) => {
        const provider = providersData?.find((provider: Provider) => provider.providerUuid === match.providerUuid)
        return {
          ...match,
          ...provider
        }
      })
      updateCarrotMatchInteraction({ matchedProviders: sortedProviderDetails })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [isProvidersDataLoading, carrotMatchInteraction.matches, providersData])

  useEffect(() => {
    if (!isProvidersDataLoading) {
      if (carrotMatchInteraction.entryPoint === EntryPoint.PROVIDER_FINDER) {
        send("NO")
      } else {
        send("YES")
      }
    }
  }, [send, carrotMatchInteraction.entryPoint, isProvidersDataLoading])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Progress />
    </Box>
  )
}
