import React, { useEffect, useState } from "react"
import {
  BackIcon,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  ForwardIcon,
  Stepper,
  useTheme
} from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { User } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { LocationPicker } from "#/pages/provider-finder/components/location-picker/LocationPicker"

export function WhereIsXyzReceivingCareStep(): JSX.Element {
  const { send, back } = useStateMachine()
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const { memberPreferredNameOrFirstName, partnerPreferredNameOrFirstName, whoIsAnsweringTheIntake, whoIsSeekingCare } =
    carrotMatchInteraction
  const intl = useIntl()
  const [locationPickerValue, setLocationPickerValue] = useState("")
  const theme = useTheme()

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: "Where is XYZ receiving care?" })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const getSeekingCareDialogTitle = () => {
    if (whoIsAnsweringTheIntake === whoIsSeekingCare) {
      return intl.formatMessage({ defaultMessage: "Where are you seeking care?" })
    } else if (whoIsSeekingCare === User.PARTNER) {
      return intl.formatMessage(
        { defaultMessage: "Where is {partnerPreferredNameOrFirstName} seeking care?" },
        { partnerPreferredNameOrFirstName }
      )
    } else {
      return intl.formatMessage(
        { defaultMessage: "Where is {memberPreferredNameOrFirstName} seeking care?" },
        { memberPreferredNameOrFirstName }
      )
    }
  }

  return (
    <>
      <Form
        onSubmit={() => {
          updateCarrotMatchInteraction({
            location: { ...carrotMatchInteraction.location, locationString: locationPickerValue }
          })
          send("")
        }}
      >
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={0} position="static" />
        <DialogTitle id="step-heading">{getSeekingCareDialogTitle()}</DialogTitle>
        <DialogContent>
          <Box sx={{ marginBlockStart: theme.tokens.spacing.xs }}>
            <LocationPicker label={""} setLocationPickerValue={setLocationPickerValue} />
          </Box>
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<ForwardIcon />}
              type="submit"
              disabled={!locationPickerValue}
            >
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
          tertiaryAction={
            <Button onClick={() => back()} variant="text" color="secondary" startIcon={<BackIcon />}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
