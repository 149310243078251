import { FormSelectOption } from "@carrotfertility/carotene-core-x"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { getBenefitConfiguration } from "#/redux/reducers/benefitConfiguration"
import { BenefitConfiguration } from "#/types/benefitConfiguration"

export function useGetSexOptions(): FormSelectOption[] {
  const benefitConfiguration = useSelector(getBenefitConfiguration)
  const intl = useIntl()
  return (
    [
      { label: intl.formatMessage({ defaultMessage: "Male" }), value: "MALE" },
      { label: intl.formatMessage({ defaultMessage: "Female" }), value: "FEMALE" },
      {
        label: intl.formatMessage({ defaultMessage: "Intersex" }),
        value: "INTERSEX",
        filterFunc: (c: BenefitConfiguration) => c?.featureConfig?.isLgtbqEnabled
      }
    ]
      // Ensure that the options do not have a filterFunction or that the filterFunction returns true
      .filter((c) => !c.filterFunc || c.filterFunc(benefitConfiguration))
      // Remove any filterFunctions from each option before returning the options array
      // This is to prevent console errors
      .map((optionObj) => {
        const { filterFunc: _, ...rest } = optionObj
        return rest
      })
  )
}
