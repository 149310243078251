import React from "react"
import { InternalIcon, Link } from "@carrotfertility/carotene-core"
import { useCarrotMatchEnabled } from "#/components/carrot-match-fertility-clinic-precision-matching/hooks/useShowCarrotMatch"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { CarrotMatchDialog } from "#/components/carrot-match-fertility-clinic-precision-matching/CarrotMatchDialog"
import { EntryPoint } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export const CarrotMatchLink = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { isLoading, isCarrotMatchEnabled } = useCarrotMatchEnabled()
  const { setIsCarrotMatchDialogOpen, updateCarrotMatchInteraction } = useCarrotMatch()
  const shouldShowCarrotMatchContent = !isLoading && isCarrotMatchEnabled
  const entryPoint = EntryPoint.DBG

  return (
    shouldShowCarrotMatchContent && (
      <>
        <Link
          textAlign="start"
          component="button"
          endIcon={<InternalIcon color="primary" fontSize="small" />}
          onClick={() => {
            updateCarrotMatchInteraction({ entryPoint })
            setIsCarrotMatchDialogOpen(true)
          }}
        >
          {children}
        </Link>
        <CarrotMatchDialog />
      </>
    )
  )
}
