import React from "react"
import { Form } from "@carrotfertility/carotene-core-x"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { RegistrationFlowTitle } from "#/components/views/register/RegistrationFlowTitle"
import { RegistrationFlowLayout } from "#/components/views/register/RegistrationLayouts"
import { RegistrationButtonFooter, ContinueButton } from "#/components/views/register/RegistrationButtons"
import { useConvertTranslatedLabel } from "#/services/common-forms/useConvertTranslatedLabel"

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "I have one child" }), value: "ONE_CHILD" },
  { label: defineMessage({ defaultMessage: "I have multiple children" }), value: "MULTIPLE_CHILDREN" }
]
export default function NumberOfChildren({
  numberOfChildren,
  onComplete,
  onBack,
  employeeId
}: {
  numberOfChildren: string | null
  onComplete: (formValues: { numberOfChildren: string }) => void
  onBack: () => void
  employeeId: number
}): JSX.Element {
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)

  const header = intl.formatMessage({
    defaultMessage: "You selected parenting as your journey. How many children do you have?"
  })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={<span id="numberOfChildren-label">{header}</span>}
        description={null}
        interactiveElements={
          <>
            <Form defaultValues={{ numberOfChildren }} onSubmit={onComplete}>
              <FormRadioButtonGroup
                name="numberOfChildren"
                aria-labelledby="numberOfChildren-label"
                options={radioOptions}
                controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              />
              <RegistrationButtonFooter
                onBack={onBack}
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Form>
          </>
        }
        employeeId={employeeId}
      />
    </>
  )
}
