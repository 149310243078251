import React, { useEffect, useState } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Box, dayjs, Progress } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import {
  ClinicSpecialization,
  FertilityTreatment,
  GetCarrotMatchMatchesRequest,
  GetCarrotMatchMatchesResponse,
  Sex,
  SpecialServices,
  Questions,
  User,
  WhatMatters,
  YesNo
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useGetLocationDetails } from "#/pages/provider-finder/hooks/useGetLocationDetails"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import ResponseError from "#/pages/provider-finder/types/ResponseError"
import { useProviderFinderClient } from "#/pages/provider-finder/hooks/useProviderFinderClient"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { MedicalInfo } from "#/types/medicalInfo"
import { carrotClient } from "#/utils/CarrotClient"

const CARROT_MATCH_QUERY_KEY = "GetCarrotMatchMatches"

function useCarrotMatchResult(
  payload: GetCarrotMatchMatchesRequest
): UseQueryResult<GetCarrotMatchMatchesResponse[], ResponseError> {
  const client = useProviderFinderClient()

  return useQuery<GetCarrotMatchMatchesResponse[], ResponseError>(
    [CARROT_MATCH_QUERY_KEY, payload],
    async () => {
      if (!payload) return []
      const response = await client.getCarrotMatchMatches(payload)
      return response ?? null
    },
    {
      enabled: !!payload,
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}

const MEDICAL_INFO_QUERY_KEY = "GetMedicalInfoForCarrotMatch"

function useMedicalInfoForCarrotMatch(user: User): UseQueryResult<MedicalInfo, ResponseError> {
  return useQuery<MedicalInfo, ResponseError>(
    [MEDICAL_INFO_QUERY_KEY, user],
    () => {
      if (user === User.MEMBER) {
        return carrotClient.getMemberMedicalInfoForCarrotMatch()
      } else {
        return carrotClient.getPartnerMedicalInfoForCarrotMatch()
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}

export function HasResultsDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const { data: medicalInfo, isLoading: isMedicalInfoLoading } = useMedicalInfoForCarrotMatch(
    carrotMatchInteraction.whoIsSeekingCare
  )
  const { dateOfBirth: memberDateOfBirth, sex: memberSex, partnerDateOfBirth, partnerSex } = useCurrentUser()

  const [payload, setPayload] = useState<GetCarrotMatchMatchesRequest | null>(null)
  const [isPayloadReady, setIsPayloadReady] = useState(false)

  const { data: locationDetailsData, isLoading: isLocationDetailsLoading } = useGetLocationDetails(
    carrotMatchInteraction.location.locationString
  )
  const latitude = locationDetailsData?.latitude
  const longitude = locationDetailsData?.longitude

  // Step 1.1: Save memberDemographics to carrotMatchInteraction
  useEffect(() => {
    updateCarrotMatchInteraction({
      memberDemographics: {
        memberAge: dayjs().diff(dayjs(memberDateOfBirth), "year"),
        memberSex: memberSex as Sex,
        partnerAge: dayjs().diff(dayjs(partnerDateOfBirth), "year"),
        partnerSex: partnerSex as Sex
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [memberDateOfBirth, memberSex, partnerDateOfBirth, partnerSex])

  // Step 1.2: Fetch and save location details to carrotMatchInteraction
  useEffect(() => {
    if (latitude && longitude) {
      updateCarrotMatchInteraction({
        location: {
          ...carrotMatchInteraction.location,
          lat: latitude,
          long: longitude
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [latitude, longitude])

  // Step 1.3: Fetch and save medical info to carrotMatchInteraction
  useEffect(() => {
    if (medicalInfo) {
      updateCarrotMatchInteraction({
        medicalInfo: {
          medicalInfoId: medicalInfo?.medicalInfoId,
          femaleMedicalHistory: medicalInfo?.femaleMedicalHistory,
          femaleConditions: medicalInfo?.femaleConditions,
          maleMedicalHistory: medicalInfo?.maleMedicalHistory,
          maleConditions: medicalInfo?.maleConditions,
          previouslyPregnant: medicalInfo?.pregnantPreviously as YesNo,
          receivedInfertilityTreatment: medicalInfo?.pregnancyPastFertilityTreatment as YesNo,
          weight: medicalInfo?.weight,
          height: medicalInfo?.height
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [medicalInfo])

  // Step 2: Set payload using data from carrotMatchInteraction
  useEffect(() => {
    const { location, whoIsSeekingCare, memberDemographics, questionsAndAnswers, medicalInfo } = carrotMatchInteraction

    if (
      (memberDemographics.memberAge || memberDemographics.partnerAge) &&
      location.lat &&
      location.long &&
      !isMedicalInfoLoading
    ) {
      setPayload({
        location: { lat: location.lat, long: location.long },
        seekingCare: whoIsSeekingCare,
        memberDemographics: memberDemographics,
        treatmentType: questionsAndAnswers.find(({ question }) => question === Questions.TREATMENT_TYPE_QUESTION)
          ?.answer as FertilityTreatment,
        whatMatters: questionsAndAnswers.find(({ question }) => question === Questions.WHAT_MATTERS_TO_XYZ_QUESTION)
          ?.answer as WhatMatters[],
        specialServices: questionsAndAnswers.find(({ question }) => question === Questions.SPECIAL_SERVICES_QUESTION)
          ?.answer as SpecialServices[],
        clinicSpecialization: questionsAndAnswers.find(
          ({ question }) => question === Questions.CLINIC_SPECIALIZATION_QUESTION
        )?.answer as ClinicSpecialization[],
        femaleMedicalHistory: medicalInfo?.femaleMedicalHistory ?? [],
        femaleConditions: medicalInfo?.femaleConditions ?? [],
        maleMedicalHistory: medicalInfo?.maleMedicalHistory ?? [],
        maleConditions: medicalInfo?.maleConditions ?? [],
        PREGNANT_PREVIOUSLY: medicalInfo?.previouslyPregnant as YesNo,
        RECEIVED_INFERTILITY_TREATMENT: medicalInfo?.receivedInfertilityTreatment as YesNo,
        WEIGHT: medicalInfo?.weight,
        HEIGHT: medicalInfo?.height
      })
      setIsPayloadReady(true)
    }
  }, [carrotMatchInteraction, isMedicalInfoLoading, latitude, longitude])

  const {
    data: carrotMatchResult,
    isLoading: isCarrotMatchResultLoading,
    isError: isCarrotMatchResultError
  } = useCarrotMatchResult(isPayloadReady ? payload : null)

  // Step 3: Determine if a valid result is returned and proceed to next step in workflow
  useEffect(() => {
    if (isCarrotMatchResultError) {
      send("NO")
    }

    if (!isLocationDetailsLoading && !isMedicalInfoLoading && !isCarrotMatchResultLoading && carrotMatchResult) {
      if (Array.isArray(carrotMatchResult) && carrotMatchResult.length) {
        updateCarrotMatchInteraction({ matches: carrotMatchResult })
        send("YES")
      } else {
        send("NO")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [
    carrotMatchResult,
    isCarrotMatchResultError,
    isCarrotMatchResultLoading,
    isLocationDetailsLoading,
    isMedicalInfoLoading
  ])

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Progress />
    </Box>
  )
}
