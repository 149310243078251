import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { carrotClient } from "../../../utils/CarrotClient"
import buildCarrotApiRetryPolicy from "../../../utils/CarrotApiRetryPolicy"
import ResponseError from "../../../types/responseError"

const REGISTRATION_JOURNEY_QUERY_KEY = "GetRegistrationJourney"

export type RegistrationJourneyResult = {
  journey: string | null
  somethingElseJourney: string | null
  journeyStage: string | null
  otherJourneyStage: string | null
  numberOfChildren: string | null
}

export function useGetRegistrationJourney(): UseQueryResult<RegistrationJourneyResult, ResponseError> {
  return useQuery([REGISTRATION_JOURNEY_QUERY_KEY], () => carrotClient.getRegistrationJourney(), {
    retry: buildCarrotApiRetryPolicy(3),
    staleTime: Infinity
  })
}
