export enum CountryCode {
  Albania = "AL",
  Algeria = "DZ",
  American_Samoa = "AS",
  Andorra = "AD",
  Angola = "AO",
  Anguilla = "AI",
  Antigua_and_Barbuda = "AG",
  Argentina = "AR",
  Armenia = "AM",
  Aruba = "AW",
  Australia = "AU",
  Austria = "AT",
  Azerbaijan = "AZ",
  Bahamas = "BS",
  Bahrain = "BH",
  Bangladesh = "BD",
  Barbados = "BB",
  Belgium = "BE",
  Belize = "BZ",
  Benin = "BJ",
  Bermuda = "BM",
  Bhutan = "BT",
  Bolivia = "BO",
  Bosnia_and_Herzegovina = "BA",
  Botswana = "BW",
  Brazil = "BR",
  British_Indian_Ocean_Territory = "IO",
  British_Virgin_Islands = "VG",
  Brunei = "BN",
  Bulgaria = "BG",
  Cambodia = "KH",
  Cameroon = "CM",
  Canada = "CA",
  Cape_Verde = "CV",
  Caribbean_Netherlands = "BQ",
  Cayman_Islands = "KY",
  Chile = "CL",
  China = "CN",
  Colombia = "CO",
  Costa_Rica = "CR",
  Cote_dIvoire = "CI",
  Croatia = "HR",
  Curacao = "CW",
  Cyprus = "CY",
  Czechia = "CZ",
  Denmark = "DK",
  Djibouti = "DJ",
  Dominica = "DM",
  Dominican_Republic = "DO",
  Ecuador = "EC",
  Egypt = "EG",
  El_Salvador = "SV",
  Equatorial_Guinea = "GQ",
  Eritrea = "ER",
  Estonia = "EE",
  Eswatini = "SZ",
  Ethiopia = "ET",
  Falkland_Islands = "FK",
  Faroe_Islands = "FO",
  Fiji = "FJ",
  Finland = "FI",
  France = "FR",
  French_Guiana = "GF",
  Gabon = "GA",
  Gambia = "GM",
  Georgia = "GE",
  Germany = "DE",
  Greece = "GR",
  Greenland = "GL",
  Grenada = "GD",
  Guatemala = "GT",
  Guernsey = "GG",
  Guinea = "GN",
  Guinea_Bissau = "GW",
  Guyana = "GY",
  Haiti = "HT",
  Honduras = "HN",
  Hong_Kong = "HK",
  Hungary = "HU",
  Iceland = "IS",
  India = "IN",
  Indonesia = "ID",
  Ireland = "IE",
  Isle_Of_Man = "IM",
  Israel = "IL",
  Italy = "IT",
  Jamaica = "JM",
  Japan = "JP",
  Jordan = "JO",
  Kazakhstan = "KZ",
  Kenya = "KE",
  Kosovo = "XK",
  Kuwait = "KW",
  Kyrgyzstan = "KG",
  Laos = "LA",
  Latvia = "LV",
  Lesotho = "LS",
  Liberia = "LR",
  Liechtenstein = "LI",
  Lithuania = "LT",
  Luxembourg = "LU",
  Macao = "MO",
  Madagascar = "MG",
  Malaysia = "MY",
  Maldives = "MV",
  Malta = "MT",
  Marshall_Islands = "MH",
  Martinique = "MQ",
  Mauritania = "MR",
  Mauritius = "MU",
  Mexico = "MX",
  Micronesia = "FM",
  Moldova = "MD",
  Monaco = "MC",
  Mongolia = "MN",
  Montenegro = "ME",
  Morocco = "MA",
  Mozambique = "MZ",
  Namibia = "NA",
  Nauru = "NR",
  Nepal = "NP",
  Netherlands = "NL",
  New_Caledonia = "NC",
  New_Zealand = "NZ",
  Nigeria = "NG",
  North_Macedonia = "MK",
  Northern_Mariana_Islands = "MP",
  Norway = "NO",
  Oman = "OM",
  Pakistan = "PK",
  Palau = "PW",
  Panama = "PA",
  Papua_New_Guinea = "PG",
  Paraguay = "PY",
  Peru = "PE",
  Philippines = "PH",
  Poland = "PL",
  Portugal = "PT",
  Puerto_Rico = "PR",
  Qatar = "QA",
  Reunion = "RE",
  Romania = "RO",
  Rwanda = "RW",
  San_Marino = "SM",
  Sao_Tome_and_Principe = "ST",
  Saudi_Arabia = "SA",
  Senegal = "SN",
  Serbia = "RS",
  Seychelles = "SC",
  Singapore = "SG",
  Sint_Maarten = "SX",
  Slovakia = "SK",
  Slovenia = "SI",
  Solomon_Islands = "SB",
  South_Africa = "ZA",
  South_Korea = "KR",
  Spain = "ES",
  Sri_Lanka = "LK",
  St_Helena = "SH",
  St_Kitts_and_Nevis = "KN",
  St_Lucia = "LC",
  St_Martin = "MF",
  St_Vincent_and_Grenadines = "VC",
  Suriname = "SR",
  Sweden = "SE",
  Switzerland = "CH",
  Taiwan = "TW",
  Tajikistan = "TJ",
  Tanzania = "TZ",
  Thailand = "TH",
  Timor_Leste = "TL",
  Togo = "TG",
  Tonga = "TO",
  Trinidad_and_Tobago = "TT",
  Tunisia = "TN",
  Turkey = "TR",
  Turkmenistan = "TM",
  Turks_and_Caicos_Islands = "TC",
  US_Virgin_Islands = "VI",
  Uganda = "UG",
  Ukraine = "UA",
  United_Arab_Emirates = "AE",
  United_Kingdom = "GB",
  United_States = "US",
  Uruguay = "UY",
  Uzbekistan = "UZ",
  Vanuatu = "VU",
  Vatican_City = "VA",
  Vietnam = "VN",
  Western_Sahara = "EH",
  Zambia = "ZM",
  Zimbabwe = "ZW"
}

export const CountryCodes: ReadonlyArray<CountryCode> = Object.values(CountryCode)
