export const MockProviderFinderClient = {
  getHasProviderTypeInCountry: () => {
    const mockProviderTypeResponse = {
      hasFertilityProviders: true,
      hasUrologyProviders: true,
      hasAdoptionProviders: true,
      hasCryobanksProviders: true,
      hasLowTProviders: true,
      hasMenopauseProviders: true,
      hasGenderAffirmingHormoneTherapyProviders: true,
      hasDonorAssistedReproductionProviders: true,
      hasGestationalCarrierProviders: true,
      hasProviders: true
    }
    return mockProviderTypeResponse
  },
  getHasCustomNetworks: () => {
    const mockCustomNetworksResponse = {
      hasCustomNetworks: false,
      isInFertilityNetwork: false,
      isInMenopauseNetwork: false,
      isInLowTNetwork: false
    }
    return mockCustomNetworksResponse
  }
}
