import { Provider } from "#/pages/provider-finder/types/Providers"

export type CarrotMatchAnswer =
  | FertilityTreatment
  | WhatMatters
  | SpecialServices
  | ClinicSpecialization
  | WhatElseMatters
  | typeof NONE_OF_THESE

export interface CarrotMatchQuestion {
  question: string
  answer: CarrotMatchAnswer | CarrotMatchAnswer[]
}

export enum EntryPoint {
  PROVIDER_FINDER = "PROVIDER_FINDER",
  CARROT_PLAN = "CARROT_PLAN",
  DBG = "DBG",
  EXTERNAL = "EXTERNAL"
}

export enum User {
  MEMBER = "MEMBER",
  PARTNER = "PARTNER"
}

export enum Sex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  INTERSEX = "INTERSEX"
}

export enum YesNo {
  YES = "YES",
  NO = "NO"
}

export const NONE_OF_THESE = "NONE_OF_THESE" as const

export enum Questions {
  NONE_OF_THESE = "NONE_OF_THESE",
  TREATMENT_TYPE_QUESTION = "What treatment type is XYZ looking for?",
  WHAT_MATTERS_TO_XYZ_QUESTION = "What matters to XYZ?",
  SPECIAL_SERVICES_QUESTION = "What special services is XYZ looking for?",
  CLINIC_SPECIALIZATION_QUESTION = "XYZ interested in clinics that have experience in the following?",
  WHAT_ELSE_MATTERS_TO_XYZ_QUESTION = "What else matters to XYZ?"
}

export enum FertilityTreatment {
  FERTILITY_PRESERVATION = "FERTILITY_PRESERVATION",
  IUI = "IUI",
  FULL_IVF = "FULL_IVF",
  FET = "FET",
  PGT_GENETIC_TESTING = "PGT_GENETIC_TESTING",
  EMBRYO_STORAGE = "EMBRYO_STORAGE",
  DONOR_ASSISTED_REPRODUCTION = "DONOR_ASSISTED_REPRODUCTION",
  GESTATIONAL_SURROGACY = "GESTATIONAL_SURROGACY"
}

export enum WhatMatters {
  CLINICAL_SUCCESS_RATES = "CLINICAL_SUCCESS_RATES",
  DISTANCE = "DISTANCE",
  COST_DISCOUNTS = "COST_DISCOUNTS",
  SPECIAL_SERVICES_AT_THE_CLINIC = "SPECIAL_SERVICES_AT_THE_CLINIC"
}

export enum SpecialServices {
  REMOTE_MONITORING = "REMOTE_MONITORING",
  ONSITE_GENETIC_COUNSELING = "ONSITE_GENETIC_COUNSELING",
  MENTAL_HEALTH_SERVICES = "MENTAL_HEALTH_SERVICES",
  ONSITE_DIETITIAN = "ONSITE_DIETITIAN",
  ACUPUNCTURE = "ACUPUNCTURE"
}

export enum ClinicSpecialization {
  MINIMUM_STIMULATION_CYCLE = "MINIMUM_STIMULATION_CYCLE",
  NATURAL_CYCLE = "NATURAL_CYCLE",
  MALE_FACTOR_INFERTILITY = "MALE_FACTOR_INFERTILITY",
  DONOR_ASSISTED_REPRODUCTION = "DONOR_ASSISTED_REPRODUCTION",
  UNEXPLAINED_INFERTILITY = "UNEXPLAINED_INFERTILITY",
  UTERINE_FACTOR = "UTERINE_FACTOR",
  HIGH_BMI = "HIGH_BMI",
  PCOS = "PCOS",
  ENDOMETRIOSIS = "ENDOMETRIOSIS",
  DIMINISHED_OVARIAN_RESERVE = "DIMINISHED_OVARIAN_RESERVE",
  TUBAL_FACTOR = "TUBAL_FACTOR"
}

export enum WhatElseMatters {
  LGBTQIA_SUPPORT = "LGBTQIA_SUPPORT",
  SINGLE_INTENDED_PARENT_SUPPORT = "SINGLE_INTENDED_PARENT_SUPPORT",
  BIPOC_PROVIDER = "BIPOC_PROVIDER",
  FAITH_INCLUSIVE_CARE = "FAITH_INCLUSIVE_CARE",
  PROVIDER_YEARS_OF_EXPERIENCE = "PROVIDER_YEARS_OF_EXPERIENCE",
  PROVIDER_EDUCATION = "PROVIDER_EDUCATION",
  PATIENT_RATINGS_AND_REVIEWS = "PATIENT_RATINGS_AND_REVIEWS",
  APPOITNMENT_AVAILABILITY_AND_TIMING = "APPOITNMENT_AVAILABILITY_AND_TIMING"
}

export const QUESTION_OPTION_MAP: Record<string, Record<string, string>> = {
  [Questions.TREATMENT_TYPE_QUESTION]: FertilityTreatment,
  [Questions.WHAT_MATTERS_TO_XYZ_QUESTION]: WhatMatters,
  [Questions.SPECIAL_SERVICES_QUESTION]: SpecialServices,
  [Questions.CLINIC_SPECIALIZATION_QUESTION]: ClinicSpecialization,
  [Questions.WHAT_ELSE_MATTERS_TO_XYZ_QUESTION]: WhatElseMatters
} as const

export interface CarrotMatchState {
  interactionId: string
  triedAgainFromInteractionId: string
  startedAtUtc: string
  entryPoint: EntryPoint
  location: {
    lat: number
    long: number
    locationString: string
  }
  whoIsAnsweringTheIntake: User
  whoIsSeekingCare: User
  memberPreferredNameOrFirstName: string
  partnerPreferredNameOrFirstName: string
  memberDemographics: {
    memberAge: number
    memberSex: Sex
    partnerAge: number
    partnerSex: Sex
  }
  medicalInfo: {
    medicalInfoId: number
    femaleMedicalHistory: string[]
    femaleConditions: string[]
    maleMedicalHistory: string[]
    maleConditions: string[]
    previouslyPregnant: YesNo
    receivedInfertilityTreatment: YesNo
    weight: number
    height: number
  }
  questionsAndAnswers: CarrotMatchQuestion[]
  lastQuestionViewedBeforeLeavingFlow: string
  matches: {
    providerUuid: number
    matchScore: number
  }[]
  matchedProviders: Provider[]
  lastMatchViewedBeforeLeavingFlowProviderUuid: number
  lastMatchViewedBeforeLeavingFlowIndex: number
}

// Get CarrotMatch matches
export interface GetCarrotMatchMatchesRequest {
  location: {
    lat: number
    long: number
  }
  seekingCare: User
  memberDemographics: {
    memberAge: number
    memberSex: Sex
    partnerAge: number
    partnerSex: Sex
  }
  treatmentType: FertilityTreatment
  whatMatters: WhatMatters[]
  specialServices: SpecialServices[]
  clinicSpecialization: ClinicSpecialization[]
  femaleMedicalHistory: string[]
  femaleConditions: string[]
  maleMedicalHistory: string[]
  maleConditions: string[]
  PREGNANT_PREVIOUSLY: YesNo
  RECEIVED_INFERTILITY_TREATMENT: YesNo
  WEIGHT: number
  HEIGHT: number
}

export interface GetCarrotMatchMatchesResponse {
  providerUuid: number
  matchScore: number
}

// Save CarrotMatchInteraction to DB
export interface FullMatchData extends Omit<Provider, "providerUuid"> {
  matchScore: number
  providerUuid: number
}

export interface InteractionData {
  triedAgainFromInteractionId: string
  location: {
    lat: number
    long: number
  }
  whoIsAnsweringTheIntake: User
  whoIsSeekingCare: User
  lastQuestionViewedBeforeLeavingFlow: string
  lastMatchViewedBeforeLeavingFlowProviderUuid: number
  lastMatchViewedBeforeLeavingFlowIndex: number
  questionsAndAnswers: {
    question: string
    answer: {
      text: string
      selected: boolean
    }[]
  }[]
  matches: FullMatchData[]
}

export interface CarrotMatchInteractionPayload {
  externalId: string
  entryPoint: EntryPoint
  startedAtUtc?: string
  medicalInfoId: number
  hasCompletedIntakeFlow: boolean
  hasMatches: boolean
  hasViewedMatches: boolean
  interactionDataJson: string
}
