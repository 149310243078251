import React, { FC, useState } from "react"
import { ContentfulConditionalGate } from "../../lib/contentful/components/ContentfulConditionGate"
// eslint-disable-next-line no-restricted-imports
import { Container, Drawer, FlexContainer, Icon, IconButton, useBreakpointComponent } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Paths } from "../../utils/Paths"
import { PageNav } from "./DBGNavLinks"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, NavigationIcon, Tile } from "@carrotfertility/carotene-core"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { TypePageSkeleton } from "#/types/contentful"

type DigitalBenefitsGuideNavFields = {
  pages: EntryWithoutUnresolvableLinks<TypePageSkeleton>[]
}

const DigitalBenefitsGuideNavLinks: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  return (
    <>
      {pages?.map(
        (page) =>
          page.fields && (
            <ContentfulConditionalGate conditions={page.fields.conditions} key={page.sys.id}>
              <PageNav page={page} baseSlug={`${Paths.YOUR_BENEFIT_GUIDE}`} top={pages[0] === page} />
            </ContentfulConditionalGate>
          )
      )}
    </>
  )
}

const DigitalBenefitsGuideDesktopNav: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  return (
    <Tile
      component="nav"
      sx={(theme) => ({
        backgroundColor: theme.palette.success.light
        // In the future, we should use the provided theme colors
      })}
    >
      <DigitalBenefitsGuideNavLinks pages={pages} />
    </Tile>
  )
}

const MobileNavButton = ({
  displayNavDrawer,
  setDisplayNavDrawer
}: {
  displayNavDrawer: boolean
  setDisplayNavDrawer: (inputValue: boolean) => void
}): JSX.Element => {
  return (
    <Button
      variant="outlined"
      onClick={() => setDisplayNavDrawer(!displayNavDrawer)}
      startIcon={<NavigationIcon />}
      color="secondary"
    >
      <FlexContainer gap="12px" justifyContent="center" alignItems="center">
        <FormattedMessage defaultMessage="Benefit guide menu" />
      </FlexContainer>
    </Button>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const DrawerCloseButton = (props) => {
  const intl = useIntl()
  const ariaLabel = intl.formatMessage({
    defaultMessage: "Close drawer",
    description:
      "Drawer refers to a website navigation drawer (sidebar menu) on this page that opens when 'Benefit guide menu' is clicked. The drawer only appears on smaller screen sizes (mobile and some tablets)."
  })
  return (
    <FlexContainer paddingLeftRight="16px" sx={{ paddingBlockStart: "16px" }} justifyContent="flex-end">
      <IconButton aria-label={ariaLabel} icon={<Icon size="small" color="black80" name="x" />} {...props} />
    </FlexContainer>
  )
}

const DigitalBenefitsGuideMobileNavDrawer: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  const [displayNavDrawer, setDisplayNavDrawer] = useState(false)

  return (
    <>
      <FlexContainer
        justifyContent="flex-end"
        direction="row"
        alignItems="center"
        gap="small"
        sx={{ marginBlockStart: ["-8px", "-8px", "-16px", "0"], marginBlockEnd: "24px" }}
      >
        <MobileNavButton setDisplayNavDrawer={setDisplayNavDrawer} displayNavDrawer={displayNavDrawer} />
      </FlexContainer>
      <Drawer
        background="mintAlternate"
        placement="right"
        width={["256px", "320px", "320px", "0"]}
        open={displayNavDrawer}
        onClose={() => setDisplayNavDrawer(false)}
        closeButton={DrawerCloseButton}
      >
        <Container sx={{ padding: "12px 24px 12px 24px" }}>
          <DigitalBenefitsGuideNavLinks pages={pages} />
        </Container>
      </Drawer>
    </>
  )
}

export const DigitalBenefitsGuideNav: FC<DigitalBenefitsGuideNavFields> = ({ pages }) => {
  const element = useBreakpointComponent([
    <DigitalBenefitsGuideMobileNavDrawer pages={pages} key={"small"} />,
    <DigitalBenefitsGuideMobileNavDrawer pages={pages} key={"medium"} />,
    <DigitalBenefitsGuideMobileNavDrawer pages={pages} key={"large"} />,
    <DigitalBenefitsGuideDesktopNav pages={pages} key={"x-large"} />
  ])
  return element || <></>
}
