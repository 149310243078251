import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Steps } from "../workflow/steps"
import { useCurrentUser } from "../../context/user/UserContext"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, MessageDescriptor, FormattedMessage, useIntl } from "react-intl"
import { Box, Typography } from "@carrotfertility/carotene-core"
import { useProgressTracker } from "../../views/modal/ProgressTracker"

const FieldName = "partnerCoverageTypeSelectValue"
const ViewName = Steps.PARTNER_COVERAGE_TYPE

export const enum PartnerCoverageTypeOptions {
  PARTNER_ONLY = "PARTNER_ONLY",
  MEMBER_AND_PARTNER = "MEMBER_AND_PARTNER",
  MEMBER_PARTNER_DEPENDENTS = "MEMBER_PARTNER_DEPENDENTS",
  PARTNER_DEPENDENTS_NOT_MEMBER = "PARTNER_DEPENDENTS_NOT_MEMBER",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const partnerCoverageTypeIdToLabel = {
  [PartnerCoverageTypeOptions.PARTNER_ONLY]: defineMessage({ defaultMessage: "Just them" }),
  [PartnerCoverageTypeOptions.MEMBER_AND_PARTNER]: defineMessage({ defaultMessage: "Them and me" }),
  [PartnerCoverageTypeOptions.MEMBER_PARTNER_DEPENDENTS]: defineMessage({
    defaultMessage: "Them and me and dependents"
  }),
  [PartnerCoverageTypeOptions.PARTNER_DEPENDENTS_NOT_MEMBER]: defineMessage({
    defaultMessage: "Them and dependents but not me"
  }),
  [PartnerCoverageTypeOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getPartnerCoverageTypeLabelFromId = (id: PartnerCoverageTypeOptions): MessageDescriptor =>
  partnerCoverageTypeIdToLabel[id]

function PartnerCoverageTypeStep(): JSX.Element {
  const { partnerCoverageTypeSelectValue } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const { setNextStep } = useStateMachine(ViewName, partnerCoverageTypeSelectValue)

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const formMethods = useForm()
  // @ts-expect-error TS(7006) FIXME: Parameter 'args' implicitly has an 'any' type.
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" &&
      (await updateBenefitEnrollmentAnswers({
        partnerCoverageTypeSelectValue: val
      }))
    setNextStep(ViewName, val)
  })

  const { handleSubmit } = formMethods
  const intl = useIntl()

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xl}
      >
        <FormattedMessage defaultMessage="Does their health plan cover just them or does it cover other people as well?" />
      </Typography>
      <Container padding="none" stack="giant">
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={partnerCoverageTypeSelectValue}
          options={[
            {
              label: intl.formatMessage(getPartnerCoverageTypeLabelFromId(PartnerCoverageTypeOptions.PARTNER_ONLY)),
              value: PartnerCoverageTypeOptions.PARTNER_ONLY
            },
            {
              label: intl.formatMessage(
                getPartnerCoverageTypeLabelFromId(PartnerCoverageTypeOptions.MEMBER_AND_PARTNER)
              ),
              value: PartnerCoverageTypeOptions.MEMBER_AND_PARTNER
            },
            {
              label: intl.formatMessage(
                getPartnerCoverageTypeLabelFromId(PartnerCoverageTypeOptions.MEMBER_PARTNER_DEPENDENTS)
              ),
              value: PartnerCoverageTypeOptions.MEMBER_PARTNER_DEPENDENTS
            },
            {
              label: intl.formatMessage(
                getPartnerCoverageTypeLabelFromId(PartnerCoverageTypeOptions.PARTNER_DEPENDENTS_NOT_MEMBER)
              ),
              value: PartnerCoverageTypeOptions.PARTNER_DEPENDENTS_NOT_MEMBER
            },
            {
              label: intl.formatMessage(getPartnerCoverageTypeLabelFromId(PartnerCoverageTypeOptions.IM_NOT_SURE)),
              value: PartnerCoverageTypeOptions.IM_NOT_SURE
            }
          ]}
        />
      </Container>
      <Box height="20rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

export { PartnerCoverageTypeStep }
