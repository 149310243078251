import React from "react"
import useInsuranceDetails from "../context/useInsuranceDetails"
import { CMDTableContainer } from "#/components/views/reimbursements/educationmodal/CMDTable"
import { FormattedMessage } from "react-intl"
import { Typography, Stack, Link, Box } from "@carrotfertility/carotene-core"
import {
  COMPANY_DEDUCTIBLE_STATUS,
  CMD_CONFIGURATION,
  useUserDeductible
} from "#/components/context/user/DeductibleContext"

interface DeductibleTitleProps {
  cmdConfiguration: string
}

const DeductibleTitle = ({ cmdConfiguration }: DeductibleTitleProps): JSX.Element => {
  return cmdConfiguration === CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE ? (
    <Typography variant="h2">
      <FormattedMessage defaultMessage="Deductibles" />
    </Typography>
  ) : (
    <Typography variant="h2">
      <FormattedMessage defaultMessage="Deductible" />
    </Typography>
  )
}

const NoDeductibleMessage = (): JSX.Element => {
  return (
    <>
      <Typography>
        <FormattedMessage
          defaultMessage="Based on the information you provided, no deductibles apply to your <link>Carrot funds.</link> This means you can use your funds for eligible expenses right away."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={"/your-benefit-guide/carrot-funds"} color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
      <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Typography>
          <FormattedMessage defaultMessage="You'll need to update your insurance information each year when it expires." />
        </Typography>
      </Box>
    </>
  )
}

const MemberAndPartnerCombinedDeductibleMessage = (): JSX.Element => {
  return (
    <>
      <Typography>
        <FormattedMessage
          defaultMessage="You and your partner need to meet an annual deductible before you can use Carrot funds for <link>qualified medical expenses.</link>"
          values={{
            link: (linkContent) => (
              <Link
                target="_blank"
                href={"/your-benefit-guide/carrot-funds/qualified-medical-expenses"}
                color="inherit"
              >
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    </>
  )
}

const MemberAndPartnerSeparateDeductibleMessage = (): JSX.Element => {
  return (
    <>
      <Typography>
        <FormattedMessage
          defaultMessage="You and your partner need to meet annual deductibles before you can use Carrot funds for <link>qualified medical expenses.</link>"
          values={{
            link: (linkContent) => (
              <Link
                target="_blank"
                href={"/your-benefit-guide/carrot-funds/qualified-medical-expenses"}
                color="inherit"
              >
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    </>
  )
}

const MemberOnlyDeductibleMessage = (): JSX.Element => {
  return (
    <>
      <Typography>
        <FormattedMessage
          defaultMessage="You need to meet an annual deductible before you can use Carrot funds for <link>qualified medical expenses.</link>"
          values={{
            link: (linkContent) => (
              <Link
                target="_blank"
                href={"/your-benefit-guide/carrot-funds/qualified-medical-expenses"}
                color="inherit"
              >
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    </>
  )
}

const PartnerOnlyDeductibleMessage = (): JSX.Element => {
  return (
    <>
      <Typography>
        <FormattedMessage
          defaultMessage="Your partner needs to meet an annual deductible before they can use Carrot funds for <link>qualified medical expenses.</link>"
          values={{
            link: (linkContent) => (
              <Link
                target="_blank"
                href={"/your-benefit-guide/carrot-funds/qualified-medical-expenses"}
                color="inherit"
              >
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
    </>
  )
}

interface DeductibleInfoMessageProps {
  cmdConfiguration: string
  companyDeductibleStatus: string
}

const DeductibleInfoMessage = ({
  cmdConfiguration,
  companyDeductibleStatus
}: DeductibleInfoMessageProps): JSX.Element => {
  if (companyDeductibleStatus === COMPANY_DEDUCTIBLE_STATUS.NO_DEDUCTIBLE) {
    return <NoDeductibleMessage />
  }

  const DeductibleMessageMap = {
    [CMD_CONFIGURATION.MEMBER_AND_PARTNER_COMBINED]: <MemberAndPartnerCombinedDeductibleMessage />,
    [CMD_CONFIGURATION.MEMBER_AND_PARTNER_SEPARATE]: <MemberAndPartnerSeparateDeductibleMessage />,
    [CMD_CONFIGURATION.MEMBER_ONLY]: <MemberOnlyDeductibleMessage />,
    [CMD_CONFIGURATION.PARTNER_ONLY]: <PartnerOnlyDeductibleMessage />
  }

  return DeductibleMessageMap[cmdConfiguration] || <NoDeductibleMessage />
}

const DeductibleTables = (): JSX.Element => {
  return (
    <>
      <CMDTableContainer />
    </>
  )
}

export const DeductiblesSection = (): JSX.Element => {
  const { companyDeductibleStatus, insuranceExpired } = useInsuranceDetails()
  const { cmdConfiguration } = useUserDeductible()

  return (
    companyDeductibleStatus !== COMPANY_DEDUCTIBLE_STATUS.EXTERNAL &&
    !insuranceExpired && (
      <>
        <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xxl)}>
          <DeductibleTitle cmdConfiguration={cmdConfiguration} />
        </Stack>
        <DeductibleInfoMessage cmdConfiguration={cmdConfiguration} companyDeductibleStatus={companyDeductibleStatus} />
        {companyDeductibleStatus !== COMPANY_DEDUCTIBLE_STATUS.NO_DEDUCTIBLE && <DeductibleTables />}
      </>
    )
  )
}
