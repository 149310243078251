import { useHistory } from "react-router-dom"
import { EntryPoint } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export function useHandleDialogClose(
  resetInteraction: () => void,
  setIsDialogOpen: (isOpen: boolean) => void,
  entryPoint: EntryPoint
) {
  const history = useHistory()

  const handleClose = () => {
    setIsDialogOpen(false)
    resetInteraction()

    if (entryPoint === EntryPoint.EXTERNAL || entryPoint === EntryPoint.CARROT_PLAN) {
      history.push("/")
    }
  }

  return { handleClose }
}
