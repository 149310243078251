import React from "react"
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ForwardIcon,
  Link
} from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form } from "@carrotfertility/carotene-core-x"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"

export function OneLastThingStep(): JSX.Element {
  const { send } = useStateMachine()
  const { data: benefit } = useBenefit()

  const getOneLastThingContent = () => {
    if (
      benefit.journeys.fertilityCare.hasEligibleExpenses ||
      benefit.journeys.fertilityPreservation.hasEligibleExpenses ||
      benefit.journeys.gestationalCarrier.hasEligibleExpenses
    ) {
      return (
        <FormattedMessage
          defaultMessage="You can use Carrot funds for <link>eligible care and services</link> received from the following providers. If you plan to use your health insurance, check your insurance portal to make sure these providers are in network."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href="/your-benefit-guide/coverage" fontSize="inherit" color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      )
    } else {
      return (
        <FormattedMessage defaultMessage="The following providers have been approved by Carrot's team based on clinical outcomes and other data. If you plan to use your health insurance, check your insurance portal to make sure these providers are in network." />
      )
    }
  }

  return (
    <>
      <Form
        onSubmit={() => {
          send("")
        }}
      >
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="One last thing..." />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{getOneLastThingContent()}</DialogContentText>
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="contained" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
