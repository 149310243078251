import React, { useState } from "react"
import { useRequestCarrotCard } from "../request-carrot-card"
import { Form, FormCheckbox } from "@carrotfertility/carotene-core-x"
import { AirwallexUserAgreementTemplate, attemptRequest, IssuingRegion } from "../agreement-helpers"
import { FormattedMessage } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { HttpErrors } from "#/utils/HttpErrors"

export function AirwallexAustraliaUserAgreement() {
  const { send } = useStateMachine()
  const [isAddressError, setIsAddressError] = useState(false)
  const requestCardMutation = useRequestCarrotCard({
    onSuccess: () => send(""),
    onError: (error) => {
      if (error.name === HttpErrors.BAD_REQUEST && error.message.includes("postal_address")) {
        setIsAddressError(true)
      } else {
        setIsAddressError(false)
      }
    }
  })

  return (
    <Form
      onSubmit={() => attemptRequest(requestCardMutation.mutateAsync)}
      defaultValues={{ airwallexUserAgreement: false }}
    >
      <AirwallexUserAgreementTemplate
        data-testid={`issuing-region-${IssuingRegion.Australia}`}
        disableContinue={["airwallexUserAgreement"]}
        userAgreementChecks={[
          <FormCheckbox
            name={"airwallexUserAgreement"}
            key="airwallex-user-agreement"
            label={
              <FormattedMessage
                values={{
                  spendManagementTermsLink: (linkContent) => (
                    <Link
                      href="https://www.airwallex.com/au/terms/cards-and-spend-management-terms"
                      target="_blank"
                      color="inherit"
                    >
                      {linkContent}
                    </Link>
                  )
                }}
                defaultMessage="I consent to the <spendManagementTermsLink>cards and spend management terms</spendManagementTermsLink>."
              />
            }
            required
          />
        ]}
        isError={requestCardMutation.isError}
        isAddressError={isAddressError}
      />
    </Form>
  )
}
