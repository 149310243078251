import React, { FC, useEffect } from "react"
// eslint-disable-next-line no-restricted-imports
import { FlexContainer, Stack } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Typography } from "@carrotfertility/carotene-core"
import { Steps } from "../workflow/steps"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { IlloCalendar } from "../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../shared"
import { useBenefitEnrollment } from "../../context/enrollment/BenefitEnrollmentContext"
import { FormattedMessage } from "react-intl"

const ViewName = Steps.RE_ENROLL_INTRO_INFORMATIONAL

const ReEnrollIntroInsuranceFlow: FC = () => {
  const { needsBenefitEnrollment } = useBenefitEnrollment()
  const { setNextStep } = useStateMachine(ViewName, null)
  const { hideProgress } = useProgressTracker()

  useEffect(() => {
    hideProgress()
  }, [hideProgress])

  return (
    <>
      <Stack spacing="medium">
        <FlexContainer justifyContent="center" padding="none">
          <IlloCalendar height={228} width={228} />
        </FlexContainer>
        <Typography
          id="step-heading"
          variant="h2"
          color={(theme) => theme.palette.text.primary}
          paddingBottom={(theme) => theme.tokens.spacing.xs}
        >
          <FormattedMessage defaultMessage="To access your Carrot funds, update your health insurance information" />
        </Typography>
      </Stack>
      <Stack spacing="medium">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="Your insurance plan information needs to be updated every year to maintain access to your Carrot funds." />
        </Typography>
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="You may want to have your insurance card nearby. If you have a partner, we'll need information on their insurance as well." />
        </Typography>
        <BenefitEnrollmentModalContinueButton
          onClick={() => setNextStep(ViewName, needsBenefitEnrollment ? "" : "SKIP_THIS_FLOW")}
        />
      </Stack>
    </>
  )
}

export { ReEnrollIntroInsuranceFlow }
