import { useLocaleContext } from "#/components/context/user/LocaleContext"
import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { useLocation, useParams } from "react-router-dom"
import { SupportedLocale, Locale } from "#/types/Locale"

export enum STEPS {
  LOADING,
  INTRO,
  SET_PASSWORD,
  PERSONAL_EMAIL,
  PREFERRED_NAME,
  ACKNOWLEDGE_TERMS,
  HEALTH_CONSENT,
  EMAIL_OPT_IN,
  ERROR,
  PERSONAL_EMAIL_VERIFICATION,
  WHAT_KIND_OF_JOURNEY,
  NUMBER_OF_CHILDREN,
  WHERE_ARE_YOU_IN_JOURNEY,
  PHONE_NUMBER_COLLECTION,
  REGISTRATION_COMPLETED,
  PREGNANCY_MEDICAL_RECORDS_COLLECTION
}

export interface RegistrationJourney {
  journeyType: string
  somethingElseJourney: string
  journeyStage: string
  otherJourneyStage: string
  numberOfChildren: string
  skipped: boolean
}

interface RegistrationContextType {
  guid: string
  step: STEPS
  setStep: (step: STEPS) => void
  isSubmitting: boolean
  setIsSubmitting: (isSubmitting: boolean) => void
  personalEmail: string
  setPersonalEmail: (personalEmail: string) => void
  preferredName: string
  setPreferredName: (preferredName: string) => void
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  phoneNumberCountryCode: string
  setPhoneNumberCountryCode: (phoneNumberCountryCode: string) => void
  dbFormattedPhoneNumber: string
  setDbFormattedPhoneNumber: (dbFormattedPhoneNumber: string) => void
  allowsMarketingTexts: boolean
  setAllowsMarketingTexts: (allowsMarketingTexts: boolean) => void
  readPolicy: boolean
  setReadPolicy: (readPolicy: boolean) => void
  readTerms: boolean
  setReadTerms: (readTerms: boolean) => void
  healthConsent: boolean
  setHealthConsent: (healthConsent: boolean) => void
  emailOptIn: boolean
  setEmailOptIn: (emailOptIn: boolean) => void
  journey: string | null
  setJourney: (journey: string) => void
  journeyStage: string | null
  setJourneyStage: (journeyStage: string) => void
  otherJourneyStage: string | null
  setOtherJourneyStage: (otherJourneyStage: string) => void
  somethingElseJourney: string | null
  setSomethingElseJourney: (somethingElseJourney: string) => void
  numberOfChildren: string | null
  setNumberOfChildren: (numberOfChildren: string) => void
  setSkippedJourney: (skippedJourney: boolean) => void
  setPassword: (password: string) => void
  password: string
  skippedJourney: boolean
}

const RegistrationContext = React.createContext<RegistrationContextType>(null)

export const useRegistration = () => React.useContext<RegistrationContextType>(RegistrationContext)

export const RegistrationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [step, setStep] = useState(STEPS.LOADING)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [personalEmail, setPersonalEmail] = useState(null)
  const [preferredName, setPreferredName] = useState(null)
  const [password, setPassword] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] = useState("US")
  const [dbFormattedPhoneNumber, setDbFormattedPhoneNumber] = useState(null)
  const [allowsMarketingTexts, setAllowsMarketingTexts] = useState(false)
  const [readPolicy, setReadPolicy] = useState(false)
  const [readTerms, setReadTerms] = useState(false)
  const [healthConsent, setHealthConsent] = useState(false)
  const [emailOptIn, setEmailOptIn] = useState(false)
  const [journey, setJourney] = useState(null)
  const [journeyStage, setJourneyStage] = useState(null)
  const [otherJourneyStage, setOtherJourneyStage] = useState(null)
  const [somethingElseJourney, setSomethingElseJourney] = useState(null)
  const [numberOfChildren, setNumberOfChildren] = useState(null)
  const [skippedJourney, setSkippedJourney] = useState(null)

  const { setLocale } = useLocaleContext()
  const location = useLocation()

  const { guid } = useParams<{ guid: string }>()

  const langFromParams = React.useMemo(() => {
    return queryString.parse(location.search).lang
  }, [location.search])

  useEffect(() => {
    if (typeof langFromParams === "string" && SupportedLocale.isSupportedLocale(langFromParams)) {
      setLocale(Locale.getLocaleFromString(langFromParams))
    }
  }, [setLocale, langFromParams])

  return (
    <RegistrationContext.Provider
      value={{
        guid,
        step,
        setStep,
        isSubmitting,
        setIsSubmitting,
        personalEmail,
        setPersonalEmail,
        preferredName,
        setPreferredName,
        phoneNumber,
        setPhoneNumber,
        phoneNumberCountryCode,
        setPhoneNumberCountryCode,
        dbFormattedPhoneNumber,
        setDbFormattedPhoneNumber,
        allowsMarketingTexts,
        setAllowsMarketingTexts,
        readPolicy,
        setReadPolicy,
        readTerms,
        setReadTerms,
        healthConsent,
        setHealthConsent,
        emailOptIn,
        setEmailOptIn,
        journey,
        setJourney,
        journeyStage,
        setJourneyStage,
        otherJourneyStage,
        setOtherJourneyStage,
        somethingElseJourney,
        setSomethingElseJourney,
        numberOfChildren,
        setNumberOfChildren,
        setSkippedJourney,
        setPassword,
        password,
        skippedJourney
      }}
    >
      {children}
    </RegistrationContext.Provider>
  )
}
