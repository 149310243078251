import { useMutation, UseMutationOptions } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import ResponseError from "#/types/responseError"

export function useRequestCarrotCard(options: UseMutationOptions<unknown, ResponseError, void, unknown> = {}) {
  return useMutation({
    mutationFn: () => carrotClient.requestCard(),
    ...options
  })
}
