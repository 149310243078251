import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  DialogContentText,
  InternalIcon,
  LaptopIcon,
  Link,
  ListItemText,
  Progress,
  Stack,
  Typography,
  UnorderedList,
  UnorderedListItem,
  useTheme
} from "@carrotfertility/carotene-core"
import { useHistory } from "react-router"
import usePartnerAccess from "#/components/partner-access/hooks/usePartnerAccess"

import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader } from "#/features/global-ui/page-header"
import { FormattedMessage, useIntl } from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import { Title } from "#/services/page-title/Title"
import { EnterTelemedicineForMenopauseDialog } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import { useTelemedicineAvailibility } from "#/components/telemedicine/hooks/useTelemedicineAvailability"
import { ConfirmationDialog } from "@carrotfertility/carotene-core-x"
import { useMutation } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"

export function TelemedicineForMenopauseLandingPage(): JSX.Element {
  const history = useHistory()
  const [openDialogFlow, setOpenDialogFlow] = useState(false)
  const intl = useIntl()
  const theme = useTheme()
  const { showPartnerAccess } = usePartnerAccess()
  const { data: telemedicineAvailability, isLoading: telemedicineAvailabilityIsLoading } = useTelemedicineAvailibility()
  const [openInterestDialog, setOpenInterestDialog] = useState(false)
  const { mutate: createTelemedicineInterest, isLoading: creatTelemedicineInterestIsLoading } = useMutation(
    () => carrotClient.createTelemedicineInterest(),
    {
      retry: false
    }
  )

  useEffect(() => {
    if (!telemedicineAvailability?.isAvailable && !telemedicineAvailabilityIsLoading) {
      history.push("/providerfinder")
    }
  }, [history, telemedicineAvailability?.isAvailable, telemedicineAvailabilityIsLoading])

  return telemedicineAvailabilityIsLoading || !telemedicineAvailability?.isAvailable ? (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ blockSize: "100vb", inlineSize: "100vi" }}>
      <Progress />
    </Box>
  ) : (
    <PageLayout
      variant="sidebar"
      sidebar={<></>}
      header={
        <PageHeader
          startGlyph={<LaptopIcon fontSize="large" />}
          pageTitle={<FormattedMessage defaultMessage="Carrot virtual menopause clinic" />}
        />
      }
    >
      <Title title={intl.formatMessage({ defaultMessage: "Carrot virtual menopause clinic" })} />
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Typography>
          <FormattedMessage
            defaultMessage="Carrot has partnered with Wheel to provide convenient, on-demand menopause telemedicine services for members who are between the ages of 45 and 65 {partnerText}"
            values={{
              partnerText: showPartnerAccess ? intl.formatMessage({ defaultMessage: " and their partners." }) : null
            }}
          />
        </Typography>
        <Box>
          <Link
            component="button"
            endIcon={<InternalIcon fontSize="small" color="primary" />}
            onClick={() => setOpenDialogFlow(true)}
          >
            <FormattedMessage defaultMessage="Schedule a virtual visit with Carrot’s menopause clinic" />
          </Link>
        </Box>
        <Box
          sx={{ alignSelf: "flex-start" }}
          component="img"
          src="/images/woman-at-table-wheel-logo.svg"
          alt="woman-at-table-wheel"
          maxHeight="180px"
        />
        <Box>
          <GetSupportUnorderedList />
          <WhoCanUseVirtualMenopauseClinic {...{ showPartnerAccess }} />
          <Typography
            sx={(theme) => ({
              marginBlockStart: theme.spacing(theme.tokens.spacing.xxs),
              marginBlockEnd: theme.spacing(theme.tokens.spacing.lg)
            })}
          >
            <FormattedMessage
              defaultMessage="Outside this age range but interested in using Wheel? <link>Let us know</link>"
              values={{
                link: (linkContent) => (
                  <Link
                    onClick={() => {
                      setOpenInterestDialog(true)
                      createTelemedicineInterest(null)
                    }}
                    component="button"
                  >
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Typography>
          <ThanksForYourInterestDialog
            open={openInterestDialog}
            isLoading={creatTelemedicineInterestIsLoading}
            setOpen={setOpenInterestDialog}
          />
          <PayingForCareUnorderedList />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
          <Button
            component={RouterLink}
            to="/your-benefit-guide/additional-at-home-care/virtual-menopause-clinic"
            variant="outlined"
            color="secondary"
          >
            <FormattedMessage defaultMessage="Learn more in your benefit guide" />
          </Button>
          <Button onClick={() => setOpenDialogFlow(true)}>
            <FormattedMessage defaultMessage="Schedule a visit" />
          </Button>
        </Box>
      </Stack>
      <EnterTelemedicineForMenopauseDialog {...{ openDialogFlow, setOpenDialogFlow }} />
    </PageLayout>
  )
}

function GetSupportUnorderedList(): JSX.Element {
  const intl = useIntl()
  return (
    <>
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Get support with:" />
      </Typography>
      <UnorderedList>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Telehealth appointments"
            })}
          />
        </UnorderedListItem>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Laboratory testing"
            })}
          />
        </UnorderedListItem>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Hormone therapy (HT) prescriptions when appropriate"
            })}
          />
        </UnorderedListItem>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Non-hormonal treatment options"
            })}
          />
        </UnorderedListItem>
      </UnorderedList>
    </>
  )
}

function WhoCanUseVirtualMenopauseClinic({ showPartnerAccess }: { showPartnerAccess: boolean }): JSX.Element {
  const intl = useIntl()
  return (
    <>
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Who can use Carrot’s virtual menopause clinic" />
      </Typography>
      <UnorderedList>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Carrot members between the ages of 45 and 65"
            })}
          />
        </UnorderedListItem>
        {showPartnerAccess ? (
          <UnorderedListItem>
            <ListItemText
              primary={intl.formatMessage(
                {
                  defaultMessage:
                    "Carrot members' partners between the ages of 45 and 65 who have been  <link>invited to join the member’s account</link>"
                },
                {
                  link: (linkContent) => (
                    <Link color="inherit" component={RouterLink} to="/account#partnerAccessFooterContainer">
                      {linkContent}
                    </Link>
                  )
                }
              )}
            />
          </UnorderedListItem>
        ) : null}
      </UnorderedList>
    </>
  )
}

function PayingForCareUnorderedList(): JSX.Element {
  const intl = useIntl()
  const { data: benefitData, isLoading: isBenefitLoading } = useBenefit()
  return (
    <>
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Paying for care" />
      </Typography>
      <UnorderedList>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage:
                "Wheel providers may be in network with your insurance. You will be able to verify coverage before scheduling your fist virtual visit."
            })}
          />
        </UnorderedListItem>
        {!isBenefitLoading && benefitData.journeys.menopause.hasEligibleExpenses ? (
          <UnorderedListItem>
            <ListItemText
              primary={intl.formatMessage(
                {
                  defaultMessage:
                    "You may be able to use your Carrot funds for <link>eligible menopause expenses</link> not covered by insurance. Check your benefit guide for details on menopause coverage. "
                },
                {
                  link: (linkContent) => (
                    <Link color="inherit" component={RouterLink} to="/your-benefit-guide/coverage/menopause">
                      {linkContent}
                    </Link>
                  )
                }
              )}
            />
          </UnorderedListItem>
        ) : null}
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage:
                "You can pay for your care out of pocket with a credit card or your HSA/FSA funds. Visits cost $135 for Initial and $90 for follow-ups."
            })}
          />
        </UnorderedListItem>
      </UnorderedList>
    </>
  )
}

function ThanksForYourInterestDialog({
  open,
  isLoading,
  setOpen
}: {
  open: boolean
  isLoading: boolean
  setOpen: (arg: boolean) => void
}): JSX.Element {
  const intl = useIntl()
  return (
    <ConfirmationDialog
      onClose={() => setOpen(false)}
      title={intl.formatMessage({ defaultMessage: "Thanks for your interest" })}
      open={open}
      primaryAction={
        <Button component={RouterLink} to="/" variant="outlined" color="secondary">
          <FormattedMessage defaultMessage="Go to Carrot home" />
        </Button>
      }
    >
      <DialogContentText>
        {isLoading ? (
          <Progress />
        ) : (
          <FormattedMessage defaultMessage="We’ve noted your interest in Carrot’s virtual menopause clinic and will be in touch with any updates." />
        )}
      </DialogContentText>
    </ConfirmationDialog>
  )
}
