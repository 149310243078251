import React, { Dispatch, SetStateAction, useEffect } from "react"
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import { v4 as uuid } from "uuid"
import {
  CarrotMatchInteractionPayload,
  CarrotMatchState,
  EntryPoint
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { createCarrotMatchInteractionPayload } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/createCarrotInteractionPayload"

function useSaveCarrotMatchInteraction(): UseMutationResult<unknown, Error, CarrotMatchInteractionPayload> {
  return useMutation<unknown, Error, CarrotMatchInteractionPayload>(
    (carrotMatchInteractionPayload: CarrotMatchInteractionPayload) =>
      carrotClient.saveCarrotMatchInteraction(carrotMatchInteractionPayload),
    {
      retry: buildCarrotApiRetryPolicy(1)
    }
  )
}

const initialState: CarrotMatchState = {
  interactionId: uuid(),
  triedAgainFromInteractionId: "",
  startedAtUtc: null,
  entryPoint: EntryPoint.PROVIDER_FINDER,
  location: { lat: null, long: null, locationString: "" },
  whoIsAnsweringTheIntake: null,
  whoIsSeekingCare: null,
  memberPreferredNameOrFirstName: "",
  partnerPreferredNameOrFirstName: "",
  memberDemographics: {
    memberAge: null,
    memberSex: null,
    partnerAge: null,
    partnerSex: null
  },
  medicalInfo: {
    medicalInfoId: null,
    femaleMedicalHistory: [],
    femaleConditions: [],
    maleMedicalHistory: [],
    maleConditions: [],
    previouslyPregnant: null,
    receivedInfertilityTreatment: null,
    weight: null,
    height: null
  },
  questionsAndAnswers: [],
  lastQuestionViewedBeforeLeavingFlow: "",
  matches: [],
  matchedProviders: [],
  lastMatchViewedBeforeLeavingFlowProviderUuid: null,
  lastMatchViewedBeforeLeavingFlowIndex: null
}

interface ICarrotMatchContext {
  carrotMatchInteraction: CarrotMatchState
  updateCarrotMatchInteraction: (newState: Partial<CarrotMatchState>) => void
  resetCarrotMatchInteraction: () => void
  retryCarrotMatchSearchWithCareReceiverData: () => void
  isCarrotMatchDialogOpen: boolean
  setIsCarrotMatchDialogOpen: Dispatch<SetStateAction<boolean>>
}

const CarrotMatchContext = React.createContext<ICarrotMatchContext>(null)

export const useCarrotMatch = () => React.useContext<ICarrotMatchContext>(CarrotMatchContext)

export const CarrotMatchContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [carrotMatchInteraction, setCarrotMatchInteraction] = React.useState<CarrotMatchState>(initialState)
  React.useState<CarrotMatchInteractionPayload>(null)
  const [isCarrotMatchDialogOpen, setIsCarrotMatchDialogOpen] = React.useState(false)
  const { mutateAsync } = useSaveCarrotMatchInteraction()

  useEffect(() => {
    if (!carrotMatchInteraction.startedAtUtc) {
      return
    }

    const debounceTimer = setTimeout(() => {
      const carrotMatchInteractionPayload = createCarrotMatchInteractionPayload(carrotMatchInteraction)
      mutateAsync(carrotMatchInteractionPayload)
    }, 500)

    return () => clearTimeout(debounceTimer)
  }, [carrotMatchInteraction, mutateAsync])

  const updateCarrotMatchInteraction = (newState: Partial<CarrotMatchState>) => {
    setCarrotMatchInteraction((prevState) => ({ ...prevState, ...newState }))
  }

  const resetCarrotMatchInteraction = () => setCarrotMatchInteraction({ ...initialState, interactionId: uuid() })

  const retryCarrotMatchSearchWithCareReceiverData = () => {
    setCarrotMatchInteraction((prevState) => ({
      interactionId: uuid(),
      startedAtUtc: new Date().toISOString(),
      triedAgainFromInteractionId: prevState.interactionId,
      entryPoint: prevState.entryPoint,
      location: prevState.location,
      whoIsAnsweringTheIntake: prevState.whoIsAnsweringTheIntake,
      whoIsSeekingCare: prevState.whoIsSeekingCare,
      memberPreferredNameOrFirstName: prevState.memberPreferredNameOrFirstName,
      partnerPreferredNameOrFirstName: prevState.partnerPreferredNameOrFirstName,
      memberDemographics: prevState.memberDemographics,
      medicalInfo: prevState.medicalInfo,
      questionsAndAnswers: [],
      lastQuestionViewedBeforeLeavingFlow: "",
      matches: [],
      matchedProviders: [],
      lastMatchViewedBeforeLeavingFlowProviderUuid: null,
      lastMatchViewedBeforeLeavingFlowIndex: null
    }))
  }

  return (
    <CarrotMatchContext.Provider
      value={{
        carrotMatchInteraction,
        updateCarrotMatchInteraction,
        resetCarrotMatchInteraction,
        retryCarrotMatchSearchWithCareReceiverData,
        isCarrotMatchDialogOpen,
        setIsCarrotMatchDialogOpen
      }}
    >
      {children}
    </CarrotMatchContext.Provider>
  )
}
