import { useTheme, Stack } from "@carrotfertility/carotene-core"
import { QuickLinks } from "../../home/quick-links/QuickLinks"
import React from "react"
import { CarrotPlansRefreshModule } from "#/components/home/CarrotPlansRefreshModule"
import CarrotPlanHero from "#/components/home/CarrotPlansHero"
import QualtricsIntercept from "#/components/views/home/QualtricsIntercept"
import { useGetCarrotPlanRequestStatus } from "#/components/carrot-plans/hooks/useCarrotPlanRequestStatus"
import useCarrotPlan from "#/components/carrot-plans/hooks/useCarrotPlan"
import useCarrotPlanEmployeeSettings from "#/components/carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { Hero } from "#/components/home/Hero"

export const HomeMain = (): JSX.Element => {
  const theme = useTheme()
  const carrotPlanQuery = useCarrotPlan()
  const carrotPlanEmployeeSettingsQuery = useCarrotPlanEmployeeSettings()
  const cpRequestStatusQuery = useGetCarrotPlanRequestStatus()

  const shouldShowBenefitHighlightsHero =
    !carrotPlanQuery.hasCarrotPlan ||
    cpRequestStatusQuery.data?.carrotPlanIsOnTheWay ||
    carrotPlanEmployeeSettingsQuery.isDoneForNow

  const shouldShowCarrotPlanHero =
    !shouldShowBenefitHighlightsHero &&
    carrotPlanQuery.data &&
    !carrotPlanQuery.data?.journeyCompleteTimestampUtc &&
    !carrotPlanEmployeeSettingsQuery.appIsUnlockedAndPendingCarrotPlan

  const areAnyLoading =
    carrotPlanQuery.isLoading || carrotPlanEmployeeSettingsQuery.isLoading || cpRequestStatusQuery.isLoading

  if (areAnyLoading) {
    return null
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <CarrotPlansRefreshModule />
      {shouldShowBenefitHighlightsHero && (
        <Hero carrotPlanIsOnTheWay={cpRequestStatusQuery.data?.carrotPlanIsOnTheWay} />
      )}
      <Stack spacing={theme.spacing(theme.tokens.spacing.xxxl)}>
        {shouldShowCarrotPlanHero && <CarrotPlanHero plan={carrotPlanQuery.data} />}
        <QualtricsIntercept />
      </Stack>
      <QuickLinks />
    </Stack>
  )
}
