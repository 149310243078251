import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Image } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Typography, useTheme, Link, Box } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"

const headerText = defineMessage({ defaultMessage: "Something’s not working" })

export function ErrorPage(): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  return (
    <Box
      title={intl.formatMessage(headerText)}
      gap={theme.tokens.spacing.sm}
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      sx={{ marginBlockStart: theme.tokens.spacing.xxxl }}
    >
      <Image
        src={require(`./resources/images/error-sign.png`)}
        altText=""
        width="300px"
        sx={{ marginBlockEnd: theme.tokens.spacing.xl }}
      />
      <Typography variant="h2" sx={{ color: theme.palette.text.secondary, margin: "sm" }}>
        {intl.formatMessage(headerText)}
      </Typography>
      <Typography variant="body1" sx={{ color: theme.palette.text.secondary, textAlign: "center" }}>
        <FormattedMessage
          defaultMessage="Try reloading this page. If the problem persists, {br} feel free to <TextLink>contact us </TextLink>."
          values={{
            br: <br />,
            TextLink: (chunks) => (
              <Link
                component={ReactRouterLink}
                to="/send-a-message"
                color="inherit"
                title={intl.formatMessage({
                  defaultMessage: "Contact us about finding a provider"
                })}
              >
                {chunks}
              </Link>
            )
          }}
        />
      </Typography>
    </Box>
  )
}
