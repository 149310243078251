import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import React, { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  FormTextField,
  Form,
  FormCheckbox,
  FormButton,
  FormAlert,
  useFormContext
} from "@carrotfertility/carotene-core-x"
import { Box, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import { EmployeeSupportUrl } from "#/utils/EmployeeSupportLink"
import { CarrotErrorCodes, getErrorMessageFromCode } from "#/utils/CarrotErrors"
import { MultiFactorAuthContext } from "#/components/containers/login/MultiFactorAuthContainer"

function ServerErrorShim({ serverError }: { serverError: string }): null {
  const { setError, clearErrors } = useFormContext()
  React.useEffect(() => {
    if (serverError === null) {
      clearErrors()
    } else {
      setError("code", { type: "custom", message: serverError })
    }
  }, [clearErrors, serverError, setError])
  return null
}

export const MultiFactorAuth = (): JSX.Element => {
  const intl = useIntl()
  const theme = useTheme()
  const { newCodeSent, showConnectionError, onSendNewCode, onSubmit, serverError } = useContext(MultiFactorAuthContext)

  const MultiFactorAuthAlert = (): JSX.Element | null => {
    if (newCodeSent) {
      return <FormAlert severity="info">{intl.formatMessage({ defaultMessage: "New code sent" })}</FormAlert>
    } else if (showConnectionError) {
      return (
        <FormAlert severity="error">
          {intl.formatMessage(getErrorMessageFromCode(CarrotErrorCodes.CONNECTION_PROBLEM))}
        </FormAlert>
      )
    }
    return null
  }

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Check your inbox" })}>
      <AppAccessPageCard>
        <Form onSubmit={onSubmit} reValidateMode="onSubmit" defaultValues={{ code: "", rememberDevice: false }}>
          <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
            <ServerErrorShim serverError={serverError} />
            <MultiFactorAuthAlert />
            <Typography variant="h1" textAlign="center">
              <FormattedMessage defaultMessage="Check your inbox" />
            </Typography>
            <Typography
              variant="body1"
              marginBlockEnd={(theme) => theme.spacing(theme.tokens.spacing.xs)}
              sx={{
                maxInlineSize: {
                  md: "100%",
                  sm: "345px"
                }
              }}
            >
              <FormattedMessage
                defaultMessage="To finish signing in, enter the code we sent to the email address associated with your account. If you experience any issues, please <link>contact us</link>."
                values={{
                  link: (linkContent) => (
                    <Link color="inherit" href={EmployeeSupportUrl} target="_blank">
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
            <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
              <FormTextField
                registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
                label={intl.formatMessage({
                  defaultMessage: "Code",
                  description: "Code refers to the authentication code sent to the user's email"
                })}
                name="code"
                sx={{ paddingBlockEnd: (theme) => theme.spacing(theme.tokens.spacing.sm) }}
              />
              <FormCheckbox
                label={intl.formatMessage({ defaultMessage: "Remember this device" })}
                name="rememberDevice"
              />
              <Box display="flex" justifyContent="center" paddingBlockEnd={theme.spacing(theme.tokens.spacing.lg)}>
                <FormButton type="submit">
                  <FormattedMessage defaultMessage="Sign in" />
                </FormButton>
              </Box>
            </Stack>
          </Stack>
        </Form>
        <Stack justifyContent="center" alignItems="center" spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Link component="button" onClick={onSendNewCode}>
            <FormattedMessage
              defaultMessage="Send new code"
              description="This button is used to send a new authentication code to the user's email"
            />
          </Link>
          <UnauthPreferredLocale />
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
