import React from "react"
import { Box, InternalIcon, LaptopIcon, Link, Tile, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import { useTelemedicineAvailibility } from "#/components/telemedicine/hooks/useTelemedicineAvailability"
import {
  ProviderTypeSearched,
  useProviderTypeSearched
} from "#/pages/provider-finder/components/results/useProviderTypeSearched"

export function TelemedicineForMenopausePfBanner(): JSX.Element {
  const { data: telemedicineAvailability } = useTelemedicineAvailibility()
  const providerTypeSearched = useProviderTypeSearched()
  return providerTypeSearched === ProviderTypeSearched.Menopause && telemedicineAvailability?.isAvailable ? (
    <Tile color="primary">
      <Box
        sx={(theme) => ({
          display: "flex",
          direction: "row",
          marginBlockEnd: theme.spacing(theme.tokens.spacing.xs)
        })}
      >
        <LaptopIcon />
        <Typography sx={(theme) => ({ marginInlineStart: theme.spacing(theme.tokens.spacing.xxs) })} variant="h5">
          <FormattedMessage defaultMessage="Get care at Carrot’s virtual menopause clinic" />
        </Typography>
      </Box>
      <Typography sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.md) })}>
        <FormattedMessage defaultMessage="Are you between the ages of 45 and 65? Get virtual menopause care, including hormone therapy (HT) prescriptions when appropriate, from the comfort of your home." />
      </Typography>
      <Link
        component={RouterLink}
        to="/providerfinder/telemedicine-for-menopause"
        endIcon={<InternalIcon color="primary" fontSize="small" />}
      >
        <FormattedMessage defaultMessage="Learn more about virtual menopause care" />
      </Link>
    </Tile>
  ) : null
}
