import React, { ReactElement, useEffect, useState } from "react"

import TwoColumnRightPrimary from "#/components/views/atoms/layouts/TwoColumnRightPrimary"
import { getTalkToCarrotDeleteAccountGuidance } from "../../lib/contentful/contentfulClient"
import { GuidanceElement } from "../../lib/contentful/components/GuidanceElement"
import { talkToCarrotSource } from "../../components/talk-to-carrot/send-message/SendMessageUtil"
import { SendMessage } from "../../components/talk-to-carrot/send-message/SendMessage"
import { TalkToCarrotLegal } from "../../components/talk-to-carrot/TalkToCarrotLegal"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { NonStaleDoneUsingCarrotModal } from "../../components/carrot-plans/case-rate-renewals/NonStaleDoneUsingCarrotModal"
import { AccountDeletedModal } from "#/components/account-deletion/AccountDeletedModal"
import { disableBiometrics, isMobileNativeAndBiometricsCapable } from "#/lib/carrot-api/MobileNativeClient"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"
import { useIntl } from "react-intl"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageBreadcrumbHeader } from "#/features/global-ui/page-header"
import { Breadcrumbs, Breadcrumb } from "@carrotfertility/carotene-core"

export const DeleteAccount = ({ breadcrumbs }: any) => {
  const { handleSignOut } = useAuthentication()
  const [guidance, setGuidance] = useState<ReactElement<typeof GuidanceElement>>(null)
  const [openDoneUsingCarrotModal, setOpenDoneUsingCarrotModal] = React.useState(false)
  const intl = useIntl()
  const { locale } = intl
  const breadcrumbTitle = intl.formatMessage({
    defaultMessage: "Delete your account"
  })
  const sendMessageDefault = intl.formatMessage({ defaultMessage: "Please delete my account." })

  useEffect(() => {
    const loadGuidance = async () => {
      const guidance = await getTalkToCarrotDeleteAccountGuidance(locale)
      setGuidance(<GuidanceElement guidance={guidance} />)
    }
    if (!guidance) {
      loadGuidance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  async function handleOnExit(): Promise<void> {
    if (await isMobileNativeAndBiometricsCapable()) {
      disableBiometrics()
    }
    handleSignOut()
  }

  return (
    <PageLayout
      footer={<TalkToCarrotLegal />}
      header={
        <PageBreadcrumbHeader
          breadcrumbs={
            <Breadcrumbs>
              {[
                ...breadcrumbs,
                <Breadcrumb key="help-breadcrumb" currentPage>
                  {breadcrumbTitle}
                </Breadcrumb>
              ]}
            </Breadcrumbs>
          }
        />
      }
    >
      <TwoColumnRightPrimary
        secondaryContent={<Container stack={"medium"}>{guidance}</Container>}
        primaryContent={
          <SendMessage
            defaultValues={{
              message: sendMessageDefault
            }}
            onExit={handleOnExit}
            successModal={AccountDeletedModal}
            source={talkToCarrotSource.DELETE_ACCOUNT}
          />
        }
      />
      <NonStaleDoneUsingCarrotModal {...{ openDoneUsingCarrotModal, setOpenDoneUsingCarrotModal }} />
    </PageLayout>
  )
}
