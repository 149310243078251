import React from "react"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogTitle, DialogContent, DialogContentText, Button, Link } from "@carrotfertility/carotene-core"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { EntryPoint } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useHandleDialogClose } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/dialogUtil"
import { Paths } from "#/utils/Paths"

export function NoResultsStep() {
  const { send } = useStateMachine()
  const {
    carrotMatchInteraction,
    retryCarrotMatchSearchWithCareReceiverData,
    resetCarrotMatchInteraction,
    setIsCarrotMatchDialogOpen
  } = useCarrotMatch()

  const { entryPoint } = carrotMatchInteraction

  const { handleClose } = useHandleDialogClose(resetCarrotMatchInteraction, setIsCarrotMatchDialogOpen, entryPoint)
  const handleTryAgain = () => {
    retryCarrotMatchSearchWithCareReceiverData()
    send("")
  }
  const dialogContent =
    carrotMatchInteraction.entryPoint === EntryPoint.PROVIDER_FINDER ? (
      <FormattedMessage defaultMessage="We couldn’t find clinics in your area that match your preferences. Try adjusting your search." />
    ) : (
      <FormattedMessage
        defaultMessage="We couldn’t find clinics in your area that match your preferences. Try adjusting your matching criteria or search for providers in <link>Provider finder</link>."
        values={{
          link: (linkContent) => (
            <Link target="_blank" href={Paths.PROVIDER_FINDER} color="inherit">
              {linkContent}
            </Link>
          )
        }}
      />
    )

  return (
    <>
      <DialogTitle id="no-match-heading">
        <FormattedMessage defaultMessage="No matches found" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActionsBar
        primaryAction={
          <Button onClick={handleTryAgain}>
            <FormattedMessage defaultMessage="Try again" />
          </Button>
        }
        secondaryAction={
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
      />
    </>
  )
}
