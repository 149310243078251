import { Box, Button, Divider, InternalIcon, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import React, { ReactElement } from "react"
import { MessageDescriptor, useIntl } from "react-intl"
import { ValueProposition } from "./hooks/useBenefitHighlights"

type BenefitHighlightHeroProps = {
  buttonText: MessageDescriptor
  messageText: MessageDescriptor
  handleClick: () => void
  carrotPlanIsOnTheWay: boolean
  filteredValueProps: ValueProposition[]
}

interface BenefitHighlightsProps {
  useCompactSpacing?: boolean
  filteredValueProps: ValueProposition[]
}

interface ValuePropDisplayProps {
  valueProp: ValueProposition
  stackSpacing: string
  key: any
}

function ValuePropDisplay({ valueProp, stackSpacing }: ValuePropDisplayProps): ReactElement {
  const theme = useTheme()
  const intl = useIntl()
  return (
    <Stack direction="row" alignItems={"center"} spacing={stackSpacing}>
      <Box
        component="img"
        src={`/images/${valueProp.iconId}.png`}
        srcSet={`/images/${valueProp.iconId}-2x.png`}
        alt=""
        height={36}
        width={36}
        margin={theme.spacing(theme.tokens.spacing.sm)}
      />
      <Link
        href={valueProp.link}
        color={theme.palette.text.primary}
        endIcon={<InternalIcon fontSize="small" color="secondary" />}
      >
        {intl.formatMessage(valueProp.valuePropMessage)}
      </Link>
    </Stack>
  )
}

export function BenefitHighlightsInner({
  useCompactSpacing,
  filteredValueProps
}: BenefitHighlightsProps): ReactElement {
  const theme = useTheme()

  return (
    <Stack spacing={useCompactSpacing ? theme.spacing(theme.tokens.spacing.xs) : null}>
      {filteredValueProps.map((valueProp: ValueProposition) => (
        <ValuePropDisplay
          valueProp={valueProp}
          stackSpacing={theme.spacing(useCompactSpacing ? theme.tokens.spacing.none : theme.tokens.spacing.lg)}
          key={valueProp.valuePropId}
        />
      ))}
    </Stack>
  )
}

export function BenefitHighlightsContent({
  buttonText,
  messageText,
  handleClick,
  carrotPlanIsOnTheWay,
  filteredValueProps
}: BenefitHighlightHeroProps): JSX.Element {
  const intl = useIntl()
  return (
    <>
      <BenefitHighlightsInner useCompactSpacing={false} filteredValueProps={filteredValueProps} />
      {!carrotPlanIsOnTheWay ? (
        <>
          <Divider />
          <Typography margin={0} variant="body1" color={(theme) => theme.palette.text.secondary}>
            {intl.formatMessage(messageText)}
          </Typography>
          <Stack alignItems={"start"}>
            <Button onClick={handleClick} color="secondary" variant="contained" fullWidth={false}>
              {intl.formatMessage(buttonText)}
            </Button>
          </Stack>
        </>
      ) : null}
    </>
  )
}
