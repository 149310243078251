import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from carotene-core or carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import styled from "styled-components"
import { Paths } from "#/utils/Paths"
import { FormattedMessage } from "react-intl"
import { Tile } from "#/lib/contentful/components/TileElement"
import { mapContentToTileDisplay } from "./utils"
import { Breadcrumb } from "@carrotfertility/carotene-core"
import { TypeTileDisplaySkeleton } from "#/types/contentful"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"

type GetPathParams = { baseUrl: string }

export const getHelpPath = ({ baseUrl }: GetPathParams) => `${baseUrl}/i-need-help`
export const getProviderEligibilityPath = ({ baseUrl }: GetPathParams) => `${baseUrl}/provider-eligibility`
export const getRefreshCarrotPlanPath = ({ baseUrl }: GetPathParams) => `${baseUrl}/refresh-carrot-plan`
export const getGetYourCarrotPlanPath = ({ baseUrl }: GetPathParams) => `${baseUrl}/get-your-carrot-plan`
export const getAccountDeletionPath = ({ baseUrl }: GetPathParams) => `${baseUrl}/delete-account`

export const RootBreadcrumb = () => (
  <Breadcrumb component={ReactRouterLink} to={Paths.TALK_TO_CARROT}>
    <FormattedMessage defaultMessage="Talk to Carrot" />
  </Breadcrumb>
)

export const SmallHeaderOverride = styled.div`
  margin-block-start: 32px;
`

interface TileElementProps {
  tileDisplay: EntryWithoutUnresolvableLinks<TypeTileDisplaySkeleton>
  onClick: () => void
  showArrow?: boolean
  isDisabled?: boolean
}
export const createTileElement = ({
  tileDisplay,
  onClick,
  showArrow = true,
  isDisabled = false
}: TileElementProps): JSX.Element => {
  const { id: entryId, title, description, secondaryDescription, iconUrl } = mapContentToTileDisplay(tileDisplay)

  return (
    <Tile
      key={entryId}
      title={title}
      description={description}
      entryId={entryId}
      secondaryDescription={secondaryDescription}
      iconUrl={iconUrl}
      disabled={isDisabled}
      showArrow={showArrow}
      onClick={onClick}
    />
  )
}
