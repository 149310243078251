import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Progress } from "@carrotfertility/carotene-core"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { User } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export function IsPartnerAccountSignedInDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { isPartnerAccessingAccount } = useUserRole()
  const { updateCarrotMatchInteraction } = useCarrotMatch()

  useEffect(() => {
    if (isPartnerAccessingAccount) {
      updateCarrotMatchInteraction({ whoIsAnsweringTheIntake: User.PARTNER })
      send("YES")
    } else {
      updateCarrotMatchInteraction({ whoIsAnsweringTheIntake: User.MEMBER })
      send("NO")
    }
  }, [updateCarrotMatchInteraction, send, isPartnerAccessingAccount])

  return <Progress />
}
