import { ProviderFinderClient } from "../lib/ProviderFinderClient"
import Settings from "../lib/Settings"
import { useMemo } from "react"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"

export const useProviderFinderClient = (): ProviderFinderClient => {
  const { handleSessionExpiration } = useAuthentication()

  if (!handleSessionExpiration) {
    throw new Error("Missing handleSessionExpiration")
  }

  return useMemo(() => {
    return new ProviderFinderClient(Settings.BASE_URL, handleSessionExpiration)
  }, [handleSessionExpiration])
}
