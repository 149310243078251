import { useTheme, Stack } from "@carrotfertility/carotene-core"
import useGetMemberBenefit from "#/components/carrot-plans/hooks/useGetMemberBenefit"
import { useGetRegistrationJourney } from "#/components/carrot-plans/hooks/useGetRegistrationJourney"
import { useOptionsForJourneyType } from "#/components/carrot-plans/hooks/useJourneyStageOptions"
import { RoutingFlowTitle, StepLayout, ButtonFooterLayout, ContinueButton } from "#/components/carrot-plans/shared"
import { ChangeJourneyButton } from "#/components/carrot-plans/shared/ChangeJourneyButtton"
import { OtherJourneyStageTextField } from "#/components/carrot-plans/shared/OtherJourneyStageTextField"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import {
  getStateMachineValue,
  useGetHeadingCopy
} from "#/components/carrot-plans/steps/journey/WhereAreYouInJourneyStep"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { CenteredLoadingIndicator } from "#/components/views/molecules/Molecules"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { useConvertTranslatedLabel } from "#/services/common-forms/useConvertTranslatedLabel"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Form } from "@carrotfertility/carotene-core-x"

/**
 * This step is for members who have stated that they are no longer in the same journey phase as when they registered.
 * It has to be distinct from the WhereAreYouInJourneyStep in order to avoid an infinite loop in the workflow steps.
 */
export default function WhereAreYouInYourJourneyNowStep(): JSX.Element {
  const { data: memberBenefit, isLoading: isMemberBenefitLoading } = useGetMemberBenefit()
  const intl = useIntl()
  const theme = useTheme()
  const routingData = useCheckRoutingInfoQueryCache()
  const registrationJourneyResult = useGetRegistrationJourney()
  const registrationJourneyStage = registrationJourneyResult?.data?.journeyStage
  const defaultValue = routingData?.journeyStage
  const { send } = useStateMachine(getStateMachineValue(defaultValue))
  const { mutateAsync } = useUpdateRoutingInfo()
  const { stageOptions } = useOptionsForJourneyType(routingData?.journey)

  const stageOptionsWithoutRegJourneyStage = stageOptions.filter((stage) => stage.value !== registrationJourneyStage)

  const radioOptions = useConvertTranslatedLabel(stageOptionsWithoutRegJourneyStage).map(({ label, value }) => {
    return {
      label,
      value
    }
  })

  const { header, supportingCopy } = useGetHeadingCopy({
    memberBenefit,
    journey: routingData?.journey,
    numberOfChildren: routingData?.numberOfChildren,
    journeyStageCheckInPostRegistration: true
  })

  async function onClickContinue(formValues: { journeyStage: string; otherJourneyStage: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send(getStateMachineValue(formValues.journeyStage)) })
  }

  const title = intl.formatMessage({ defaultMessage: "Where are you in this journey now?" })

  if (isMemberBenefitLoading) {
    return <CenteredLoadingIndicator />
  }

  return (
    <>
      <RoutingFlowTitle title={title} />
      <StepLayout
        header={header}
        description={supportingCopy}
        formElements={
          <>
            <ChangeJourneyButton />
            <Form
              defaultValues={{ journeyStage: defaultValue, otherJourneyStage: routingData?.otherJourneyStage }}
              onSubmit={onClickContinue}
            >
              <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <FormRadioButtonGroup
                  name="journeyStage"
                  options={radioOptions}
                  controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
                  aria-labelledby="journeyStage-label"
                />
                <OtherJourneyStageTextField />
              </Stack>
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Form>
          </>
        }
      />
    </>
  )
}
