import React from "react"
import { PartnerRegistrationComplete } from "../../../views/register/RegistrationComplete"
import { usePartnerRegistrationFlow } from "../PartnerRegistrationFlow"
import { useGetRegisteringPartner } from "../../hooks/usePartnerAccess"

export function RegistrationCompleteStep(): JSX.Element {
  const { guid } = usePartnerRegistrationFlow()
  const { registeringUser } = useGetRegisteringPartner(guid)
  return (
    <PartnerRegistrationComplete
      isInitialCarrotPlanRoutingCompleted={registeringUser.isInitialCarrotPlanRoutingCompleted}
      employeeId={registeringUser.employeeId}
    />
  )
}
