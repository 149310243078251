import { Typography } from "@carrotfertility/carotene-core"
import { FormSelectOption } from "@carrotfertility/carotene-core-x"
import React from "react"
import { FormattedMessage } from "react-intl"

/**
 * Returns a form select option that represents a "None" choice, using "nullOut" as the value
 * to indicate that the field should be set to null in the database.
 */
export function NoneNullOutOption(): FormSelectOption {
  return {
    label: <ItalicNoneLabel />,
    value: "nullOut"
  }
}

function ItalicNoneLabel(): JSX.Element {
  return (
    <Typography sx={{ fontStyle: "italic" }}>
      <FormattedMessage defaultMessage="None" />
    </Typography>
  )
}
