import React from "react"

/**
 * @deprecated Don't use this component.
 */
// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const NotSmall = ({ children }) => <div className="dn db-ns">{children}</div>

/**
 * @deprecated Don't use this component.
 */
// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Small = ({ children }) => <div className="db dn-ns">{children}</div>

export { NotSmall, Small }
