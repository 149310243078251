import React, { useEffect, useState } from "react"
import {
  BackIcon,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  ForwardIcon,
  Link,
  LocationIcon,
  SelectedIcon,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { useGetLocalizedDistanceStringWithUnits } from "#/pages/provider-finder/hooks/useGetLocalizedDistanceStringWithUnits"
import { useCurrentUser } from "#/components/context/user/UserContext"
import carrotPartnerIndicator from "#/pages/provider-finder/resources/images/carrot-partner-indicator.svg"
import { getMatchAttributesOrder } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/matchAttributesOrder"
import {
  CarrotMatchAnswer,
  FertilityTreatment,
  Questions,
  WhatMatters
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"

const DirectionsLink = ({ address, searchLocation }: { address: string; searchLocation: string }): JSX.Element => {
  const getGoogleDirections = (address: string): string => {
    // The structure of the url is `/maps/dir/<starting point>/<destination>`
    const googleBaseUrl = "https://www.google.com/maps/dir"
    const encodedSearchLocation = encodeURIComponent(searchLocation)
    const encodedAddress = encodeURIComponent(address)
    return `${googleBaseUrl}/${encodedSearchLocation}/${encodedAddress}`
  }
  return (
    <Link data-id="carrot-match-directions-link" href={getGoogleDirections(address)} target="_blank" color="inherit">
      <FormattedMessage
        defaultMessage="Directions"
        description="Clicking on the directions link will open up Google Maps to get the directions to this provider's location"
      />
    </Link>
  )
}
export function ResultStep(): JSX.Element {
  const { send } = useStateMachine()
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const { location, questionsAndAnswers, lastMatchViewedBeforeLeavingFlowIndex, matchedProviders } =
    carrotMatchInteraction
  const intl = useIntl()
  const theme = useTheme()
  const { company } = useCurrentUser()
  const { countryCode: companyCountryCode } = company

  const [currentProviderIndex, setCurrentProviderIndex] = useState(lastMatchViewedBeforeLeavingFlowIndex ?? 0)
  const [matchAttributes, setMatchAttributes] = useState<any[]>([])

  const treatmentTypeSelected = questionsAndAnswers.find(
    ({ question }) => question === Questions.TREATMENT_TYPE_QUESTION
  )?.answer as FertilityTreatment

  const specializationSelected = questionsAndAnswers.find(
    ({ question }) => question === Questions.CLINIC_SPECIALIZATION_QUESTION
  )?.answer as CarrotMatchAnswer[]

  const specialServiceSelected = questionsAndAnswers.find(
    ({ question }) => question === Questions.SPECIAL_SERVICES_QUESTION
  )?.answer as CarrotMatchAnswer[]

  const selectedCostSensitivity = questionsAndAnswers.some(
    ({ question, answer }) =>
      question === Questions.WHAT_MATTERS_TO_XYZ_QUESTION &&
      Array.isArray(answer) &&
      answer.includes(WhatMatters.COST_DISCOUNTS)
  )

  const currentProvider = matchedProviders?.[currentProviderIndex] ?? null
  const isCarrotPartner = currentProvider?.carrotPartner

  const localizedProviderDistance = useGetLocalizedDistanceStringWithUnits({
    distanceInKm: currentProvider?.distanceInKm,
    countryCode: companyCountryCode,
    numberOfFractionDigits: 1
  })

  useEffect(() => {
    updateCarrotMatchInteraction({
      lastMatchViewedBeforeLeavingFlowProviderUuid: currentProvider?.providerUuid,
      lastMatchViewedBeforeLeavingFlowIndex: currentProviderIndex
    })
    const updatedAttributes = getMatchAttributesOrder({
      specializationSelected,
      treatmentTypeSelected,
      specialServiceSelected,
      isCarrotPartner,
      selectedCostSensitivity
    })

    setMatchAttributes(updatedAttributes)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [
    specializationSelected,
    treatmentTypeSelected,
    specialServiceSelected,
    isCarrotPartner,
    selectedCostSensitivity,
    currentProvider,
    currentProviderIndex
  ])

  const getSeekingCareDialogTitle = () => {
    const titles = ["Best match", "Great match", "Good match"]
    return matchedProviders?.length > 0 ? titles[currentProviderIndex] : titles[0]
  }
  const isNextMatchAvailable = matchedProviders?.length > 1 && currentProviderIndex < matchedProviders?.length - 1

  const handleNextMatch = () => {
    if (currentProviderIndex < matchedProviders?.length - 1) {
      setCurrentProviderIndex(currentProviderIndex + 1)
    } else {
      send("")
    }
  }

  const handlePreviousMatch = () => {
    if (currentProviderIndex > 0) {
      setCurrentProviderIndex(currentProviderIndex - 1)
    }
  }

  const requestAppointmentButton = (
    <Button
      variant="contained"
      size="small"
      color="secondary"
      component="a"
      fullWidth={false}
      sx={{ inlineSize: "fit-content" }}
      href={`/providerfinder/requestappointment/${encodeURIComponent(currentProvider?.providerName)}`}
      title={intl.formatMessage({ defaultMessage: "Contact our team to book an appointment" })}
      target="_blank"
    >
      <FormattedMessage
        defaultMessage="Request appointment"
        description="Clicking on the Request appointment button will redirect you to directions on how to book an appointment at a provider"
      />
    </Button>
  )

  const address = (
    <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)} alignItems="start">
      <LocationIcon fontSize="inherit" role="presentation" color="inherit" />
      <Typography variant="body2" color="inherit">
        <FormattedMessage
          defaultMessage="{distance} • {address}"
          values={{
            distance: localizedProviderDistance,
            address: currentProvider?.address
          }}
        />
      </Typography>
    </Box>
  )

  const providerDetails = (
    <Box
      display="flex"
      flexDirection="row"
      gap={(theme) => `${theme.spacing(theme.tokens.spacing.xs)} ${theme.spacing(theme.tokens.spacing.lg)}`}
      sx={{ whiteSpace: "nowrap", flexWrap: "wrap" }}
    >
      {currentProvider?.phone && (
        <Link
          data-id="carrot-match-provider-phone-link"
          dir="ltr"
          href={`tel:${currentProvider?.phone}`}
          color="inherit"
        >
          {currentProvider?.phone}
        </Link>
      )}
      {currentProvider?.website && (
        <Link
          data-id="carrot-match-provider-website-link"
          href={currentProvider?.website}
          target="_blank"
          rel="noreferrer noopener"
          color="inherit"
        >
          <FormattedMessage defaultMessage="Visit website" />
        </Link>
      )}
      {currentProvider?.address && (
        <DirectionsLink address={currentProvider?.address} searchLocation={`${location.lat},${location.long}`} />
      )}
    </Box>
  )
  const buttonMessage = isNextMatchAvailable ? (
    <FormattedMessage defaultMessage="See next match" />
  ) : (
    <FormattedMessage defaultMessage="Close" />
  )

  return (
    <>
      <Box>
        <CarrotMatchDialogCloseButton />
        <DialogTitle id="match-heading">{getSeekingCareDialogTitle()}</DialogTitle>
        <DialogContent>
          <Box sx={{ marginBlockStart: theme.tokens.spacing.xs }}>
            {matchedProviders?.length > 0 && (
              <Box
                key={currentProviderIndex}
                display="flex"
                flexDirection="column"
                gap={(theme) => theme.tokens.spacing.sm}
              >
                <Typography variant="h3" color="inherit">
                  {currentProvider?.providerName}
                </Typography>
                <Box display="flex" flexDirection="column" gap={(theme) => theme.tokens.spacing.xxs}>
                  {address}
                  {currentProvider?.carrotPartner && (
                    <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}>
                      <img
                        src={carrotPartnerIndicator}
                        alt={intl.formatMessage({ defaultMessage: "Carrot Partner indicator" })}
                      />
                      <Typography>
                        <FormattedMessage defaultMessage="Carrot partner" />
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="row" gap={(theme) => theme.tokens.spacing.xs} flexWrap="wrap">
                  {matchAttributes.map((attribute) => (
                    <Box
                      key={attribute.key}
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      gap={(theme) => theme.tokens.spacing.xxxs}
                      color={(theme) => theme.palette.text.tertiary}
                    >
                      <SelectedIcon fontSize="small" color="inherit" />
                      <Typography variant="body1" color={(theme) => theme.palette.text.tertiary}>
                        {intl.formatMessage(attribute.description)}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                {providerDetails}
                <Link
                  href={`https://carrot.research.net/r/9G3NGSP?clinic=${currentProvider?.providerUuid}`}
                  target="_blank"
                  data-carrot-match-review-link-provider-id={currentProvider?.providerUuid}
                >
                  <FormattedMessage defaultMessage="Give feedback on this match" />
                </Link>
                {isCarrotPartner && requestAppointmentButton}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button
              variant="outlined"
              color="secondary"
              endIcon={isNextMatchAvailable && <ForwardIcon />}
              type="button"
              onClick={handleNextMatch}
            >
              {buttonMessage}
            </Button>
          }
          tertiaryAction={
            currentProviderIndex > 0 && (
              <Button onClick={handlePreviousMatch} variant="text" color="secondary" startIcon={<BackIcon />}>
                <FormattedMessage defaultMessage="See previous match" />
              </Button>
            )
          }
        />
      </Box>
    </>
  )
}
