import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { carrotClient } from "#/utils/CarrotClient"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const TELEMEDICINE_PROFILE_QUERY_KEY = ["telemedicineProfile"]

export function useTelemedicinePatientProfile() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => carrotClient.createOrUpdateTelemedicineProfile(),
    retry: buildCarrotApiRetryPolicy(1),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: TELEMEDICINE_PROFILE_QUERY_KEY })
    }
  })
}
