import { DbgPaths } from "../../../../../utils/Paths"
import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import { Link, UnorderedListItem } from "@carrotfertility/carotene-core"
import { CategorySections } from "../PayingForCareModal"
import { reimbursementCategoryNameMessageKeys } from "../../reimbursementCategoryNameMessageKeys"

const MenopauseLowTestosteroneHeader = (): JSX.Element => {
  return (
    <p>
      <FormattedMessage defaultMessage="This category includes eligible expenses for the following journeys:" />
    </p>
  )
}

interface MLTListItemsProps {
  category: string
}

const MenopauseLowTestosteroneListItems: FC<MLTListItemsProps> = ({ category }): JSX.Element => {
  return (
    <>
      <UnorderedListItem>
        <FormattedMessage
          defaultMessage="<link>Menopause</link>"
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={DbgPaths.MENOPAUSE}>
                {linkContent}
              </Link>
            )
          }}
        />
      </UnorderedListItem>
      <UnorderedListItem>
        <FormattedMessage
          defaultMessage="<link>Low testosterone</link>"
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={DbgPaths.LOW_TESTOSTERONE}>
                {linkContent}
              </Link>
            )
          }}
        />
      </UnorderedListItem>
      {category === reimbursementCategoryNameMessageKeys.PREGNANCY_M_LT && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Pregnancy and postpartum</link>"
            values={{
              link: (linkContent) => (
                <Link target="_blank" href={DbgPaths.PREGNANCY_COVERAGE}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </UnorderedListItem>
      )}
      {category === reimbursementCategoryNameMessageKeys.M_LT_GAC && (
        <UnorderedListItem>
          <FormattedMessage
            defaultMessage="<link>Gender-affirming care</link>"
            values={{
              link: (linkContent) => (
                <Link target="_blank" href={DbgPaths.GENDER_AFFIRMING_CARE}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </UnorderedListItem>
      )}
    </>
  )
}

export function getMenopauseLowTestosteroneSections(category: string): CategorySections {
  return {
    header: <MenopauseLowTestosteroneHeader />,
    listItems: <MenopauseLowTestosteroneListItems category={category} />
  }
}
