// eslint-disable-next-line no-restricted-imports
import { Button, Container, H2, Modal, Stack, Text } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { MessageReceivedModalBaseProps } from "#/components/talk-to-carrot/send-message/SendMessage"
import React from "react"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { FormattedMessage } from "react-intl"

type AccountDeletedModalProps = MessageReceivedModalBaseProps

export function AccountDeletedModal(props: AccountDeletedModalProps): JSX.Element {
  return (
    <EmployeeOnlyModalContainer onClose={props.onExit}>
      <Modal open width="calc(100% - 32px)" onClose={props.onExit}>
        <Container sx={{ textAlign: "center" }} paddingTopBottom="huge" paddingLeftRight="large">
          <Stack stack="huge" padding="16px 0 0 0">
            <H2>
              <FormattedMessage defaultMessage="Request sent" />
            </H2>
            <Text>
              <FormattedMessage defaultMessage="We'll provide an update within 30 business days." />
            </Text>
          </Stack>
          <Button data-testid="account-deletion-sign-out" onClick={props.onExit}>
            <FormattedMessage defaultMessage="Sign out" />
          </Button>
        </Container>
      </Modal>
    </EmployeeOnlyModalContainer>
  )
}
