import React from "react"
import { useSelector } from "react-redux"
import { ScrollToTop } from "../ScrollToTop"
import { BenefitEnrollmentFlowContainer } from "#/components/cmd-enrollment"
import { useHistory, useLocation } from "react-router-dom"
import Locations from "./navigation"
import EmailVerifiedModal from "../../EmailVerification/EmailVerifiedModal"
import { PageHeader } from "#/features/global-ui/page-header/page-header"
import { FormattedMessage } from "react-intl"
import { PiiText } from "#/services/tracking"
import { getMemberFullName } from "#/redux/derivedSelectors"
import { getPreferredName } from "#/redux/reducers/userInfo"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { PageLayout } from "#/features/global-ui/page-layout"
import { JourneyChange } from "../../carrot-plans/journey-change/JourneyChange"
import { HomeMain } from "#/components/views/home/HomeMain"
import { HomeSidebar } from "#/components/views/home/HomeSidebar"

type LocationState = {
  state: {
    [key: string]: unknown
  }
}

const Home = (): JSX.Element => {
  const theme = useTheme()
  const location: LocationState = useLocation()
  const state = location.state || {}
  const history = useHistory()
  const memberFullName = useSelector(getMemberFullName)
  const preferredName = useSelector(getPreferredName)
  const displayName = preferredName ? preferredName : memberFullName

  const onEnrollmentExit = (): void => {
    history.push(Locations.Home.Index)
  }

  return (
    // See https://trello.com/c/qQYnzDnt This was added because on mobile, home would load scrolled down a little.
    <ScrollToTop>
      <PageLayout
        variant="sidebar"
        sidebar={<HomeSidebar />}
        header={
          <Stack marginBottom={theme.spacing(theme.tokens.spacing.xl)} spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <PageHeader
              pageTitle={
                <FormattedMessage
                  defaultMessage="Hello, {displayName}"
                  values={{
                    displayName: <PiiText>{displayName}</PiiText>
                  }}
                />
              }
            />
            <JourneyChange />
          </Stack>
        }
      >
        <HomeMain />
      </PageLayout>
      {state.showBenefitEnrollmentModal && (
        <BenefitEnrollmentFlowContainer enrollmentType={state.enrollmentType} onExit={onEnrollmentExit} />
      )}
      <EmailVerifiedModal open={Boolean(state.showPersonalEmailVerifiedModal)} />
    </ScrollToTop>
  )
}

export default Home
