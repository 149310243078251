import React, { useCallback, useEffect, useState } from "react"
import { Autocomplete, Box, Progress, LocationIcon, TextField, Typography } from "@carrotfertility/carotene-core"
import { useIntl } from "react-intl"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import { getPiiProps } from "#/services/tracking"
import Settings from "#/utils/CarrotConfig"

export const LocationPicker = ({
  label,
  helperText,
  isDisabled,
  isRequired,
  hasError,
  typeaheadLocationSearch,
  onChange,
  setLocationPickerValue
}: {
  label: string
  helperText?: string
  isDisabled?: boolean
  isRequired?: boolean
  hasError?: boolean
  typeaheadLocationSearch?: string
  onChange?: (event: React.SyntheticEvent<Element, Event>, option: any) => void
  setLocationPickerValue: (input: string) => void
}): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const debounceRef = React.useRef(null)
  const [userValue, setUserValue] = useState(null)
  const [previousTypeaheadLocationSearch, setPreviousTypeaheadLocationSearch] = useState("")
  const intl = useIntl()
  const { placePredictions, isPlacePredictionsLoading, getPlacePredictions, placesAutocompleteService } =
    usePlacesService({
      options: {
        types: ["geocode"],
        input: null
      },
      apiKey: Settings.GOOGLE_PLACES_API,
      language: intl.locale // ❗ The language will not update if the user changes their locale after the Google Places API loads. See https://carrotfertility.atlassian.net/l/cp/90h5F13n#Doesn%E2%80%99t-update-when-locale-is-changed
    })
  const placeholderText = intl.formatMessage({ defaultMessage: "Address, city, state, zip" })

  const handleAutocompleteValueChange = useCallback(
    (location: google.maps.places.AutocompletePrediction): void => {
      setUserValue(location)
      setLocationPickerValue(location?.description || "")
      if (location) {
        setOpen(false)
      }
    },
    [setLocationPickerValue]
  )

  useEffect(() => {
    async function setLocationFromTypeaheadSelection(): Promise<void> {
      const response = await placesAutocompleteService.getPlacePredictions({ input: typeaheadLocationSearch })
      if ((response?.predictions?.length ?? 0) > 0) {
        const value = response.predictions[0]
        handleAutocompleteValueChange(value)
        setPreviousTypeaheadLocationSearch(typeaheadLocationSearch)
      }
    }

    if (typeaheadLocationSearch && typeaheadLocationSearch !== previousTypeaheadLocationSearch) {
      setLocationFromTypeaheadSelection()
    }
  }, [
    placesAutocompleteService,
    previousTypeaheadLocationSearch,
    handleAutocompleteValueChange,
    typeaheadLocationSearch
  ])

  return (
    <Autocomplete
      id="provider-location-autocomplete"
      autoComplete
      clearOnEscape
      disabled={isDisabled}
      filterOptions={(x) => x}
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      isOptionEqualToValue={(option, value) => option.description === value.description}
      loading={isPlacePredictionsLoading}
      loadingText={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
          sx={{ grow: 1 }}
        >
          <Progress />
        </Box>
      }
      noOptionsText={null}
      options={isPlacePredictionsLoading ? [] : placePredictions}
      popupIcon={null}
      value={userValue}
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(event, newUserValue) => {
        handleAutocompleteValueChange(newUserValue)
        if (onChange) {
          onChange(event, newUserValue)
        }
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current)
        }
        if (reason === "reset") {
          setOpen(false)
          return
        }
        debounceRef.current = setTimeout(() => {
          if (newInputValue) {
            getPlacePredictions({ input: newInputValue })
            setOpen(true)
          }
        }, 500)
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          required={isRequired}
          helperText={helperText}
          placeholder={placeholderText}
          error={hasError}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <LocationIcon
                fontSize="medium"
                sx={(theme) => ({
                  color: theme.palette.text.disabled,
                  marginInlineStart: theme.spacing(theme.tokens.spacing.xxs),
                  marginInlineEnd: theme.spacing(theme.tokens.spacing.xxs)
                })}
              />
            )
          }}
        />
      )}
      renderOption={(props, placePrediction) => {
        const { description, place_id: placeId, matched_substrings: matchedSubstrings } = placePrediction
        const matchedSubstringDetails = matchedSubstrings[0]
        const { length, offset } = matchedSubstringDetails || { length: 0, offset: 0 }
        const beforeMatchedSubstring = matchedSubstringDetails ? description.slice(0, offset) : description
        const matchedSubstring = matchedSubstringDetails ? description.slice(offset, offset + length) : ""
        const afterMatchedSubstring = matchedSubstringDetails ? description.slice(offset + length) : ""

        return (
          <Box
            component="li"
            key={`${description}-${placeId}`}
            sx={{ "::before": { display: "none" } }} // main.scss puts styles we don't want here.
          >
            <Box {...getPiiProps()} {...props} component="span">
              <Typography variant="body1" color={(theme) => theme.palette.text.primary} component="span">
                {beforeMatchedSubstring}
                <Typography variant="inherit" fontWeight="bold" component="span">
                  {matchedSubstring}
                </Typography>
                {afterMatchedSubstring}
              </Typography>
            </Box>
          </Box>
        )
      }}
    />
  )
}
