import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Progress } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { EntryPoint } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { useLocation } from "react-router-dom"

export function IsUserEnteringFromProviderFinderDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const location = useLocation()

  useEffect(() => {
    if (carrotMatchInteraction?.entryPoint === EntryPoint.PROVIDER_FINDER) {
      const params = new URLSearchParams(location.search)
      const searchLocation = params.get("search")
      const decodedSearchLocation = searchLocation ? decodeURIComponent(searchLocation) : ""
      updateCarrotMatchInteraction({
        location: { ...carrotMatchInteraction.location, locationString: decodedSearchLocation }
      })
      send("YES")
    } else {
      send("NO")
    }
  }, [updateCarrotMatchInteraction, send, carrotMatchInteraction, location])

  return <Progress />
}
