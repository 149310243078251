import { FormattedMessage, useIntl } from "react-intl"

import React, { ReactElement } from "react"
import { Card, Button } from "@carrotfertility/carotene-core"
import { ProgramType } from "#/lib/carrot-api/types/Benefit"
import { DbgPaths } from "#/utils/Paths"
import { useBenefitHighlights } from "./hooks/useBenefitHighlights"
import { BenefitHighlightsInner } from "#/components/home/benefits-modules/BenefitHighlightInnerContent"

export function BenefitHighlightsCard(): ReactElement {
  const intl = useIntl()
  const { isBenefitLoading, programTypeAllowsBenefitHighlight, filteredValueProps, programType } =
    useBenefitHighlights()

  if (isBenefitLoading || !programTypeAllowsBenefitHighlight) {
    return null
  }

  return (
    <Card
      id="benefit-highlights-sidebar"
      sx={{
        backgroundColor: (theme) => theme.palette.background.hover
      }}
      title={intl.formatMessage({
        defaultMessage: "Benefit highlights"
      })}
      primaryAction={
        programType === ProgramType.PRO && (
          <Button variant="outlined" color="secondary" href={DbgPaths.CARROT_ELIGIBLE} component="a" fullWidth>
            <FormattedMessage defaultMessage="Learn to use Carrot funds" />
          </Button>
        )
      }
    >
      <BenefitHighlightsInner useCompactSpacing filteredValueProps={filteredValueProps} />
    </Card>
  )
}
