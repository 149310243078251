import { ProviderFinder } from "#/pages/provider-finder"
import React from "react"
import { useSelector } from "react-redux"
import { ProviderFinderErrorReportingProvider } from "#/pages/provider-finder/context/ErrorReportingContext"
import { UserContextProvider } from "#/pages/provider-finder/context/UserContext"
// should decouple the following imports in the long run
import { useAuthentication } from "../../../context/authentication/AuthenticationProvider"
import { getCompanyId, getCountryCode } from "#/redux/reducers/companyInfo"
import { CompanyContextProvider } from "#/pages/provider-finder/context/CompanyContextProvider"
import { CarrotMatchContextProvider } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"

export const ProviderFinderAdapter = (): JSX.Element => {
  const companyCountryCode = useSelector(getCountryCode)
  const companyId = useSelector(getCompanyId)

  const { isLoggedIn, handleSessionExpiration } = useAuthentication()

  return (
    <ProviderFinderErrorReportingProvider>
      <UserContextProvider
        companyCountryCode={companyCountryCode}
        isLoggedIn={isLoggedIn}
        handleSessionExpiration={handleSessionExpiration}
      >
        <CompanyContextProvider companyId={companyId}>
          <CarrotMatchContextProvider>
            <ProviderFinder />
          </CarrotMatchContextProvider>
        </CompanyContextProvider>
      </UserContextProvider>
    </ProviderFinderErrorReportingProvider>
  )
}
