import React, { FC } from "react"
import { CarrotCardModuleSharedProps } from "#/components/views/molecules/CarrotCardModuleContainer"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import { getCompanyDisplayName } from "#/redux/reducers/companyInfo"
import CardStates from "#/utils/CardStates"
import { CarrotCardModuleTemplate } from "./carrot-card-module-template"
import useGetReimbursementDetails from "#/services/reimbursements/hooks/useGetReimbursementDetails"
import { PiiText } from "#/services/tracking"

const CarrotCardRestrictionPeriodModule: FC<CarrotCardModuleSharedProps> = () => {
  const reimbursementDetails = useGetReimbursementDetails()
  const intl = useIntl()
  const companyDisplayName = useSelector(getCompanyDisplayName)

  if (!reimbursementDetails.data || reimbursementDetails.isLoading || reimbursementDetails.isError) {
    return null
  }

  return (
    <CarrotCardModuleTemplate
      cardState={CardStates.RESTRICTION_PERIOD}
      body={
        <FormattedMessage
          defaultMessage="Due to {companyName}'s policies, your Carrot Card is locked from {formattedLockDate} to {formattedUnlockDate}."
          values={{
            companyName: <PiiText>{companyDisplayName}</PiiText>,
            formattedUnlockDate: intl.formatDate(reimbursementDetails.data.restrictionPeriodUnlockDate, {
              month: "long",
              day: "numeric"
            }),
            formattedLockDate: intl.formatDate(reimbursementDetails.data.restrictionPeriodLockDate, {
              month: "long",
              day: "numeric"
            })
          }}
        />
      }
    />
  )
}

export { CarrotCardRestrictionPeriodModule }
