import React, { useEffect, useState } from "react"
import {
  Alert,
  BackIcon,
  Button,
  DialogContent,
  DialogTitle,
  ForwardIcon,
  Stepper
} from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import {
  FertilityTreatment,
  NONE_OF_THESE,
  Questions,
  User
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"

type FertilityTypeRadioOption = { label: string; value: FertilityTreatment | typeof NONE_OF_THESE }

export function WhatTreatmentTypeIsXYZLookingForStep() {
  const { send, back } = useStateMachine()
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const {
    memberPreferredNameOrFirstName,
    partnerPreferredNameOrFirstName,
    whoIsAnsweringTheIntake,
    whoIsSeekingCare,
    questionsAndAnswers
  } = carrotMatchInteraction
  const intl = useIntl()
  const [selectedOption, setSelectedOption] = useState(null)
  const { data: benefitData, isLoading: isBenefitLoading } = useBenefit()

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: Questions.TREATMENT_TYPE_QUESTION })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const getFertilityTypeRadioOptions = (): FertilityTypeRadioOption[] => {
    const fertilityTypes: FertilityTypeRadioOption[] = [
      {
        label: intl.formatMessage({
          defaultMessage: "Fertility preservation (e.g., freezing eggs, sperm or embryos)"
        }),
        value: FertilityTreatment.FERTILITY_PRESERVATION
      },
      { label: intl.formatMessage({ defaultMessage: "IUI" }), value: FertilityTreatment.IUI },
      {
        label: intl.formatMessage({ defaultMessage: "Full IVF cycle (retrieval and transfer)" }),
        value: FertilityTreatment.FULL_IVF
      },
      {
        label: intl.formatMessage({ defaultMessage: "FET cycle with existing embryos" }),
        value: FertilityTreatment.FET
      },
      {
        label: intl.formatMessage({ defaultMessage: "PGT/genetic testing" }),
        value: FertilityTreatment.PGT_GENETIC_TESTING
      },
      {
        label: intl.formatMessage({ defaultMessage: "Embryo storage" }),
        value: FertilityTreatment.EMBRYO_STORAGE
      },
      {
        label: intl.formatMessage({ defaultMessage: "Donor assisted reproduction" }),
        value: FertilityTreatment.DONOR_ASSISTED_REPRODUCTION
      }
    ]
    if (!isBenefitLoading && benefitData.journeys.gestationalCarrier.isAppSupportEnabled) {
      fertilityTypes.push({
        label: intl.formatMessage({ defaultMessage: "Gestational surrogacy" }),
        value: FertilityTreatment.GESTATIONAL_SURROGACY
      })
    }
    return [
      ...fertilityTypes,
      {
        label: intl.formatMessage({ defaultMessage: "None of these" }),
        value: NONE_OF_THESE
      }
    ]
  }

  const getTreatmentTypeDialogTitle = () => {
    if (whoIsAnsweringTheIntake === whoIsSeekingCare) {
      return intl.formatMessage({ defaultMessage: "What treatment type are you looking for?" })
    } else if (whoIsSeekingCare === User.PARTNER) {
      return intl.formatMessage(
        { defaultMessage: "What treatment type is {partnerPreferredNameOrFirstName} looking for?" },
        { partnerPreferredNameOrFirstName }
      )
    } else {
      return intl.formatMessage(
        { defaultMessage: "What treatment type is {memberPreferredNameOrFirstName} looking for?" },
        { memberPreferredNameOrFirstName }
      )
    }
  }

  const updateFertilityTreatmentTypeAndProceedToNextStep: FormOnSubmitHandler<{
    whatTreatmentTypeIsXYZLookingFor: FertilityTreatment
  }> = ({ whatTreatmentTypeIsXYZLookingFor }: { whatTreatmentTypeIsXYZLookingFor: FertilityTreatment }) => {
    updateCarrotMatchInteraction({
      questionsAndAnswers: [
        ...questionsAndAnswers.filter((qa) => qa.question !== Questions.TREATMENT_TYPE_QUESTION),
        {
          question: Questions.TREATMENT_TYPE_QUESTION,
          answer: whatTreatmentTypeIsXYZLookingFor
        }
      ]
    })
    send("")
  }

  return (
    <>
      <Form onSubmit={updateFertilityTreatmentTypeAndProceedToNextStep}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={1} position="static" />
        <DialogTitle id="step-heading">{getTreatmentTypeDialogTitle()}</DialogTitle>
        <DialogContent>
          <FormRadioButtonGroup
            aria-labelledby="step-heading"
            name="whatTreatmentTypeIsXYZLookingFor"
            controllerProps={{
              rules: { required: intl.formatMessage({ defaultMessage: "Required" }) }
            }}
            options={getFertilityTypeRadioOptions()}
            onChange={(e) => {
              setSelectedOption((e.target as HTMLInputElement).value)
            }}
          />
          {selectedOption === NONE_OF_THESE && (
            <Alert severity="warning">
              <FormattedMessage defaultMessage="We may not be able to recommend the best clinic for your particular treatment type, but will do our best to recommend a high-quality provider who matches your preferences." />
            </Alert>
          )}
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="outlined" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
          tertiaryAction={
            <Button onClick={() => back()} variant="text" color="secondary" startIcon={<BackIcon />}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
