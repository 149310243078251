import React from "react"
import { Box, Typography } from "@carrotfertility/carotene-core"
// eslint-disable-next-line no-restricted-imports
import { SimpleGridProps } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25

type ReimbursementDescriptionListProps = React.PropsWithChildren<Pick<SimpleGridProps, "columns">>

interface ReimbursementDescriptionGroupProps {
  title: React.ReactNode
  value: React.ReactNode
}

export function ReimbursementDescriptionList({ children }: ReimbursementDescriptionListProps) {
  return (
    <Box display="flex" flexDirection="row">
      {children}
    </Box>
  )
}

export function ReimbursementDescriptionGroup({ title, value }: ReimbursementDescriptionGroupProps) {
  return (
    <Box paddingRight={(theme) => theme.tokens.spacing.lg}>
      <Box display="flex" flexDirection="column">
        <Typography
          variant="overline"
          fontWeight="normal"
          fontStyle="normal"
          color={(theme) => theme.palette.text.secondary}
        >
          {title}
        </Typography>
        <Typography variant="body1" fontWeight="bold" color={(theme) => theme.palette.text.secondary}>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
