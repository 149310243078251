import React from "react"
import { Alert, Link } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import {
  ProviderTypeSearched,
  useProviderTypeSearched
} from "#/pages/provider-finder/components/results/useProviderTypeSearched"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"

export function CheckYourBenefitAlert({ isCustomNetworks }: { isCustomNetworks: boolean }): JSX.Element {
  const providerTypeSearched = useProviderTypeSearched()
  const benefit = useBenefit()
  const journeys = benefit?.data?.journeys
  const shouldShowCheckYourBenefitAlert = benefit?.data?.program.type === "PRO" && !isCustomNetworks

  if (providerTypeSearched === ProviderTypeSearched.FertilityClinics) {
    if (
      (!journeys?.fertilityCare?.hasEligibleExpenses &&
        !journeys?.fertilityPreservation?.hasEligibleExpenses &&
        !journeys?.gestationalCarrier?.hasEligibleExpenses) ||
      benefit?.data?.program?.type === "CORE"
    ) {
      return (
        <Alert severity="info">
          <FormattedMessage defaultMessage="The following providers have been approved by Carrot's team based on clinical outcomes and other data. If you plan to use your health insurance, check your insurance portal to make sure these providers are in network." />
        </Alert>
      )
    }

    if (
      journeys?.fertilityCare?.hasEligibleExpenses ||
      journeys?.fertilityPreservation?.hasEligibleExpenses ||
      journeys?.gestationalCarrier?.hasEligibleExpenses
    ) {
      return (
        <Alert severity="info">
          <FormattedMessage
            defaultMessage="You can use Carrot funds for <link>eligible care and services</link> received from the following providers. If you plan to use your health insurance, check your insurance portal to make sure these providers are in network."
            values={{
              link: (linkContent) => (
                <Link target="_blank" href="/your-benefit-guide/coverage" fontSize="inherit" color="inherit">
                  {linkContent}
                </Link>
              )
            }}
          />
        </Alert>
      )
    }
  }

  if (shouldShowCheckYourBenefitAlert) {
    return (
      <Alert severity="info">
        <FormattedMessage
          defaultMessage="Always check your <link>benefit guide</link> to ensure the care and services you are seeking are covered."
          values={{
            link: (linkContent) => (
              <Link target="_blank" href="/your-benefit-guide/coverage" fontSize="inherit" color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Alert>
    )
  }
  return null
}
