import { Box, Stack, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import React from "react"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { SkipButton, ContinueButton, RegistrationButtonFooter } from "./RegistrationButtons"
import { useTrackView } from "#/utils/heap"
import { Form } from "@carrotfertility/carotene-core-x"
import { useConvertTranslatedLabel } from "#/services/common-forms/useConvertTranslatedLabel"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { OtherJourneyStageTextField } from "#/components/carrot-plans/shared/OtherJourneyStageTextField"
import { useGetHeadingCopy } from "#/components/carrot-plans/steps/journey/WhereAreYouInJourneyStep"
import { useOptionsForJourneyType } from "#/components/carrot-plans/hooks/useJourneyStageOptions"
interface WhereAreYouInJourneyProps {
  onBack: () => void
  onComplete: ({
    selectedJourneyStage,
    otherJourneyStage
  }: {
    selectedJourneyStage: string | null
    otherJourneyStage: string | null
  }) => void
  employeeId: number
  journey: string
  journeyStage: string | null
  otherJourneyStage: string | null
  memberBenefit: Benefit
  numberOfChildren: string
}

export function WhereAreYouInJourney({
  onBack,
  onComplete,
  employeeId,
  journey,
  journeyStage,
  otherJourneyStage,
  memberBenefit,
  numberOfChildren
}: WhereAreYouInJourneyProps): JSX.Element {
  useTrackView("JourneyStageSelectionRegFlow", "Registration journey stage selection screen")
  const theme = useTheme()
  const intl = useIntl()
  const { stageOptions } = useOptionsForJourneyType(journey)
  const radioOptions = useConvertTranslatedLabel(stageOptions)

  const onSubmit = (formValues: { journeyStage?: string; otherJourneyStage?: string }) => {
    onComplete({
      selectedJourneyStage: formValues?.journeyStage,
      otherJourneyStage: formValues?.otherJourneyStage
    })
  }

  const { header, supportingCopy } = useGetHeadingCopy({ memberBenefit, journey, numberOfChildren })

  return (
    <>
      <RegistrationFlowTitle
        title={intl.formatMessage({
          defaultMessage: "Where are you in this journey?"
        })}
      />
      <RegistrationFlowLayout
        header={header}
        description={supportingCopy}
        interactiveElements={
          <Form onSubmit={(formValues) => onSubmit(formValues)} defaultValues={{ journeyStage, otherJourneyStage }}>
            <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
              <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <FormRadioButtonGroup name="journeyStage" options={radioOptions} aria-labelledby="journeyStage-label" />
                <OtherJourneyStageTextField />
              </Stack>
              <RegistrationButtonFooter
                onBack={onBack}
                skipButton={
                  <SkipButton onClick={() => onComplete({ selectedJourneyStage: null, otherJourneyStage: null })}>
                    <FormattedMessage defaultMessage="Skip for now" />
                  </SkipButton>
                }
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Box>
          </Form>
        }
        employeeId={employeeId}
      />
    </>
  )
}
