import { PARENT_ID_MAPPING } from "#/pages/provider-finder/utils/parentIdMapping"
import { ProviderFinderParam } from "#/pages/provider-finder/utils/providerFinderSearchUrl"
import { useHistory } from "react-router"

export enum ProviderTypeSearched {
  Menopause = "MENOPAUSE_SPECIALISTS",
  FertilityClinics = "FERTILITY_CLINICS",
  GenderAffirmingHormoneTherapy = "GENDER_AFFIRMING_HORMONE_THERAPY",
  LowTestosterone = "LOW_TESTOSTERONE"
}
export function useProviderTypeSearched(): ProviderTypeSearched {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  return PARENT_ID_MAPPING[params.get(ProviderFinderParam.ProviderType)]?.providerTypeSearched
}
