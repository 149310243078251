import React, { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Card, Button, Typography } from "@carrotfertility/carotene-core"

type BenefitEnrollmentModuleProps = {
  onClickEnroll: () => void
}

const BenefitEnrollmentModule: FC<BenefitEnrollmentModuleProps> = ({ onClickEnroll }) => {
  const { formatMessage } = useIntl()
  return (
    <Card
      sx={(theme) => ({
        // In the future, we should use the provided colors
        backgroundColor: theme.palette.success.light
      })}
      title={formatMessage({ defaultMessage: "To access your funds, tell us about your insurance" })}
      primaryAction={
        <Button variant="contained" color="secondary" onClick={onClickEnroll}>
          <FormattedMessage defaultMessage="Share plan info" />
        </Button>
      }
      id="home-sidebar-benefit-enrollment-module"
    >
      <Typography>
        <FormattedMessage defaultMessage="Your insurance coverage affects how Carrot funds are taxed and whether a deductible applies." />
      </Typography>
    </Card>
  )
}

export { BenefitEnrollmentModule }
