import React, { useState } from "react"
// eslint-disable-next-line no-restricted-imports
import { useBreakpointComponent } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import useCreateThread from "./hooks/useCreateThread"
import { InAppMessagingThread } from "./inAppMessagingTypes"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import useGetThreads from "./hooks/useGetThreads"
import { reportErrorMessage } from "../../utils/ErrorReporting"
import { useCurrentUser } from "../context/user/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { Box, Typography, Button, Stack, useTheme, Alert, Link } from "@carrotfertility/carotene-core"
import { FormTextFieldWithCharacterCount } from "#/services/common-forms/FormTextFieldWithCharacterCount"
import { FormCancelButton, FormSendNewMessageButton } from "./InAppMessagingFormButtons"
import { EmployeeSupportUrl } from "../../utils/EmployeeSupportLink"

type MessageButtonContainerProps = {
  onClick: () => void
  disabled: boolean
}

type InAppMessagingCreateNewThreadFormProps = {
  newestThread: InAppMessagingThread
  scrollThreadsListToTop: () => void
}

export function NewMessageButtonContainer({ onClick, disabled }: MessageButtonContainerProps): JSX.Element {
  return (
    <Box
      borderRadius="0 0 8px 8px"
      height="172.05px"
      sx={{
        position: "relative",
        textAlign: "center",
        insetBlockEnd: "172.05px",
        background: (theme) =>
          `linear-gradient(180deg, rgba(250, 249, 247, 0) 0%, ${theme.palette.background.paper} 58.43%)`,
        pointerEvents: "none"
      }}
    >
      <Box display="flex" width="100%" justifyContent="center" padding="108px 0 0px 0" height="inherit">
        <Box width="100%" sx={{ pointerEvents: "all", marginBlockStart: "-60px" }} padding="60px 0px 24px 0">
          <Button style={{ pointerEvents: "auto" }} disabled={disabled} onClick={onClick}>
            <FormattedMessage defaultMessage="New message" />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export function InAppMessagingCreateNewThreadForm({
  newestThread,
  scrollThreadsListToTop
}: InAppMessagingCreateNewThreadFormProps): JSX.Element {
  const { mutateAsync } = useCreateThread()
  const { refetch: refetchThreads } = useGetThreads()
  const { setSelectedThread, setShowNewThreadForm } = useInAppMessaging()
  const [isError, setError] = useState(false)
  const { employeeId } = useCurrentUser()
  const intl = useIntl()
  const theme = useTheme()

  const newThreadHeader = useBreakpointComponent([
    false,
    false,
    false,
    <Box key="large">
      <Typography variant="h3" component="h2" color={theme.palette.text.primary}>
        <FormattedMessage defaultMessage="Send a new message" />
      </Typography>
    </Box>
  ])

  async function onSubmit(values: { subject: string; message: string }): Promise<void> {
    if (values?.subject && values?.message) {
      try {
        const data = await mutateAsync(values)
        await refetchThreads()
        setSelectedThread(data)
        setShowNewThreadForm(false)
        scrollThreadsListToTop()
      } catch (error) {
        reportErrorMessage(`Unable to create new thread for employee id: ${employeeId}. Error: ${error}`)
        setError(true)
      }
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      <Stack
        direction="column"
        justifyContent="center"
        spacing={theme.tokens.spacing.xl}
        paddingLeft={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
        paddingRight={(theme) => theme.spacing(theme.tokens.spacing.md)}
      >
        {newThreadHeader}
        <FormTextField
          name="subject"
          aria-label={intl.formatMessage({ defaultMessage: "subject" })}
          label={intl.formatMessage({ defaultMessage: "subject" })}
          inputProps={{ maxLength: 150 }}
          required
        />
        <FormTextFieldWithCharacterCount
          name="message"
          multiline
          rows={11}
          placeholder={intl.formatMessage({
            defaultMessage: "Write a message. We'll respond within one business day."
          })}
          maxLength={1600}
          required
          label={intl.formatMessage({ defaultMessage: "message" })}
          aria-label={intl.formatMessage({ defaultMessage: "message" })}
        />
        {isError && (
          <Alert severity="error">
            <Typography color={(theme) => theme.palette.error.main}>
              <FormattedMessage
                defaultMessage="Your message failed to send. Please try again or <link>contact us</link>."
                values={{
                  link: (linkContent) => (
                    <Link color="inherit" href={EmployeeSupportUrl} target={"_blank"}>
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
          </Alert>
        )}
        <Stack
          direction="row"
          spacing={theme.tokens.spacing.sm}
          justifyContent="flex-end"
          marginBlockEnd={theme.spacing(theme.tokens.spacing.lg)}
        >
          <FormCancelButton
            onClick={() => {
              setShowNewThreadForm(false)
              setSelectedThread(newestThread)
            }}
          />
          <FormSendNewMessageButton />
        </Stack>
      </Stack>
    </Form>
  )
}
