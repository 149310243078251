import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Select, SelectProps } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export interface FormSingleSelectProps extends SelectProps {
  name?: string
  getSelectedValue?: any
  optionComponent?: any
  rules?: any
}

/**
 * @deprecated Use `FormSelect` from `@carrotfertility/carotene-core` instead.
 */
export const FormSingleSelect = React.forwardRef(
  ({ rules, name, onChange, optionComponent, ...props }: FormSingleSelectProps, ref) => {
    const {
      formState: { errors },
      ...formMethods
    } = useFormContext()
    const intl = useIntl()
    return (
      <Controller
        rules={rules}
        {...formMethods}
        name={name}
        render={({ field }) => (
          <Select
            ref={ref}
            isSearchable={false}
            placeholder={intl.formatMessage({ defaultMessage: "Select an option" })}
            isError={Boolean(errors[name])}
            errorMessage={errors[name]?.message.toString()}
            {...{ ...props, ...field }}
            onChange={(args) => field.onChange(onChange ? onChange(args) : args)}
            optionComponent={optionComponent}
          />
        )}
      />
    )
  }
)
FormSingleSelect.displayName = "FormSingleSelect"
