import React from "react"
import {
  Box,
  Checkbox,
  Link,
  Typography,
  ClickAwayListener,
  Tooltip,
  checkboxClasses,
  ForwardIcon,
  InternalIcon,
  Tile
} from "@carrotfertility/carotene-core"
import { CarrotPlan, CarrotPlanStep } from "../../types/carrotPlanTypes"
import useCarrotPlanMenuItems, { CarrotPlanStepMenuItem } from "../carrot-plans/hooks/useCarrotPlanMenuItems"
import { reportErrorMessage } from "../../utils/ErrorReporting"
import useUpdatePlanStep from "../carrot-plans/hooks/useUpdatePlanStep"
import { FormattedMessage, useIntl } from "react-intl"
import moment from "moment"
import { Paths } from "#/utils/Paths"
import { Link as RouterLink } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getCarrotPlanQueryKey } from "#/components/carrot-plans/hooks/useCarrotPlan"
import { ErrorPage } from "#/components/views/app/ErrorPage"

type CarrotPlanHeroProps = {
  plan: CarrotPlan
}

type PlanStepProps = {
  carrotPlanStepMenuItem: CarrotPlanStepMenuItem
}

interface StepContentProps extends Omit<PlanStepProps, "employeeId">, React.HTMLAttributes<HTMLSpanElement> {
  stepCompleted: boolean
}

type StepCheckmarkProps = {
  carrotPlanStepMenuItem: CarrotPlanStepMenuItem
  stepCompleted: boolean
  handleStepCompleted: (stepId: number) => void
}

export default function CarrotPlanHero({ plan }: CarrotPlanHeroProps): JSX.Element {
  const intl = useIntl()
  const { locale } = intl

  const allStepsCompleted = plan?.steps.every((step) => step.completedAtUtc)
  // We only want to show this when we aren't also rendering the Carrot Plans Refresh Module above the hero
  const shouldShowHasAnythingChangedTile = !(allStepsCompleted || plan.isStale)

  const {
    data: carrotPlanStepMenuItems,
    isLoading: menuItemsLoading,
    error: menuItemsError
  } = useCarrotPlanMenuItems(plan.steps, locale)

  if (menuItemsError) {
    reportErrorMessage(
      `Unable to retrieve Carrot Plan menu items from Contentful for employee ${plan.employeeId}, error: ${menuItemsError}`
    )
    return <ErrorPage />
  }
  const createdAtUtcDate: Date = moment(plan.createdAtUtc).toDate()

  return (
    <>
      {!menuItemsLoading ? (
        <Box
          id="carrot-plans-hero"
          width="100%"
          display="flex"
          component="article"
          gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
          flexDirection="column"
          borderRadius={(theme) => theme.tokens.borderRadius.md}
          bgcolor={(theme) => theme.palette.info.light}
          padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginBottom={(theme) => theme.tokens.spacing.xs}
          >
            <Box display="flex" gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)} alignItems="center">
              <Typography variant="h3" component="h2" color="inherit">
                <FormattedMessage defaultMessage="Your Carrot Plan" />
              </Typography>
            </Box>
            <Typography variant="body2" color="inherit" data-testid="carrot-plans-hero">
              <FormattedMessage
                defaultMessage="Updated {formattedDate}"
                values={{
                  formattedDate: intl.formatDate(createdAtUtcDate, {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })
                }}
              />
            </Typography>
          </Box>
          <Box
            component="ul"
            display="flex"
            gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
            flexDirection="column"
            sx={{ "li::before": { display: "none" } }}
          >
            {carrotPlanStepMenuItems.map((stepMenuItem) => (
              <Box
                padding={(theme) => theme.spacing(theme.tokens.spacing.md)}
                bgcolor={(theme) => theme.palette.background.paper}
                component="li"
                display="flex"
                gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
                flexDirection="column"
                key={stepMenuItem?.carrotPlanStepId}
                id={`cp-menuitem-${stepMenuItem?.menuItemId}`}
                borderRadius={(theme) => theme.tokens.borderRadius.sm}
              >
                <PlanStep carrotPlanStepMenuItem={stepMenuItem} />
              </Box>
            ))}
          </Box>
          {shouldShowHasAnythingChangedTile && <HasAnythingChangedTile />}
        </Box>
      ) : null}
    </>
  )
}

export function getStepMenuItemDisplayText(carrotPlanStepMenuItem: CarrotPlanStepMenuItem): JSX.Element | string {
  const displayText = carrotPlanStepMenuItem?.displayText

  if (carrotPlanStepMenuItem?.readLearnArticleText) {
    const readLearnArticleText = carrotPlanStepMenuItem?.readLearnArticleText
    if (readLearnArticleText) {
      return displayText?.replace(/\[(.+?)]/g, readLearnArticleText)
    }
  } else if (carrotPlanStepMenuItem?.entryId && !carrotPlanStepMenuItem?.readLearnArticleText) {
    return (
      <FormattedMessage defaultMessage="Check out our Carrot Academy resources for more information about your journey" />
    )
  }
  return displayText
}

function StepContent({ carrotPlanStepMenuItem: step, stepCompleted, ...otherProps }: StepContentProps): JSX.Element {
  const showLink = React.useMemo(
    () => Boolean(step?.url) && !(step?.isLocked && stepCompleted),
    [step?.isLocked, step?.url, stepCompleted]
  )

  if (showLink) {
    return (
      <Link
        color="inherit"
        underline="hover"
        sx={{
          display: "flex",
          alignItems: "center",
          "&:hover": {
            textDecorationThickness: "2px",
            textDecorationLine: "underline",
            textDecorationColor: "inherit"
          }
        }}
        href={step?.url}
        id={`cp-item-${step?.carrotPlanStepId}-label`}
      >
        {getStepMenuItemDisplayText(step)}
        <ForwardIcon />
      </Link>
    )
  }
  return (
    <Typography id={`cp-item-${step?.carrotPlanStepId}-label`} color="inherit" {...otherProps}>
      {step?.displayText}
    </Typography>
  )
}

function StepCheckmark({
  carrotPlanStepMenuItem: step,
  stepCompleted,
  handleStepCompleted
}: StepCheckmarkProps): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const handleTooltipClose = () => {
    setOpen(false)
  }
  const handleTooltipOpen = () => {
    setOpen(!open)
  }
  return step?.isLocked ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onClose={handleTooltipClose}
        open={open}
        title={
          stepCompleted ? (
            <FormattedMessage defaultMessage="We checked this one for you" />
          ) : (
            <FormattedMessage defaultMessage="We'll check this one for you when it's done" />
          )
        }
      >
        <Checkbox
          sx={{
            color: "inherit",
            [`&.${checkboxClasses.checked}`]: {
              color: "inherit"
            },
            "&:hover": { color: "inherit" }
          }}
          readOnly
          checked={stepCompleted}
          onClick={handleTooltipOpen}
          inputProps={{ "aria-labelledby": `cp-item-${step?.carrotPlanStepId}-label` }}
        />
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <Checkbox
      sx={{
        color: "inherit",
        [`&.${checkboxClasses.checked}`]: {
          color: "inherit"
        },
        "&:hover": { color: "inherit" }
      }}
      checked={stepCompleted}
      onChange={() => handleStepCompleted(step?.carrotPlanStepId)}
      inputProps={{ "aria-labelledby": `cp-item-${step?.carrotPlanStepId}-label` }}
    />
  )
}

function PlanStep({ carrotPlanStepMenuItem: step }: PlanStepProps): JSX.Element {
  const updatePlanStep = useUpdatePlanStep()

  // We use useQuery to subscribe to React Query's cache updates,
  // ensuring the UI automatically reflects optimistic updates
  // and server responses. This pattern maintains consistency
  // between the cache and component state.
  const carrotPlanQuery = useQuery<CarrotPlan>({ queryKey: getCarrotPlanQueryKey() })
  const currentStep =
    carrotPlanQuery.data?.steps.find((s: CarrotPlanStep) => s.carrotPlanStepId === step.carrotPlanStepId) ?? step
  const stepCompleted = currentStep?.completedAtUtc

  const handleStepCompleted = React.useCallback(() => {
    updatePlanStep.mutate({ stepId: step.carrotPlanStepId, completed: !stepCompleted })
  }, [updatePlanStep, stepCompleted, step.carrotPlanStepId])

  return (
    <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(theme.tokens.spacing.md)} alignItems="center">
      <StepCheckmark
        carrotPlanStepMenuItem={step}
        stepCompleted={!!stepCompleted}
        handleStepCompleted={handleStepCompleted}
      />
      <StepContent carrotPlanStepMenuItem={step} stepCompleted={!!step?.completedAtUtc} />
    </Box>
  )
}

function HasAnythingChangedTile(): JSX.Element {
  return (
    <Tile
      component={Box}
      display="flex"
      padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      border={(theme) => `2px solid ${theme.palette.background.paper}`}
      borderRadius={(theme) => theme.tokens.borderRadius.sm}
      color="info"
    >
      <Link
        to={Paths.REFRESH_CARROT_PLAN}
        endIcon={<InternalIcon fontSize="small" color="secondary" />}
        component={RouterLink}
      >
        <FormattedMessage defaultMessage="Has anything changed? Let us know so we can add new, personalized recommendations to your Carrot Plan" />
      </Link>
    </Tile>
  )
}
