/* eslint formatjs/no-literal-string-in-jsx: off -- translation planned for Q1 2024. See https://carrotfertility.atlassian.net/wiki/spaces/PROD/pages/2190215916/WS+Support+E2E+Translation */

import React, { ReactNode } from "react"

// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"

type InboundMemberPaymentProps = {
  children: ReactNode
}

export function InboundMemberPaymentsLayout({ children }: InboundMemberPaymentProps): JSX.Element {
  const InboundMemberPaymentsHeader = (): JSX.Element => {
    return (
      <PageHeader
        pageTitle={`Make a payment`}
        startGlyph={<PageHeaderGlyph src="/images/icn-carrot-cards.png" srcSet="/images/icn-carrot-cards-2x.png" />}
      />
    )
  }
  return (
    <Container sx={{ position: "relative" }}>
      <PageLayout header={<InboundMemberPaymentsHeader />}>{children}</PageLayout>
    </Container>
  )
}
