import React from "react"
import { FormControlLabel, Checkbox, checkboxClasses } from "@carrotfertility/carotene-core"
import { FormCheckboxGroup, FormCheckBoxGroupProps } from "@carrotfertility/carotene-core-x"

export function FormCheckboxButtonGroup({ name, options, ...otherProps }: FormCheckBoxGroupProps): JSX.Element {
  return (
    <FormCheckboxGroup
      {...{ name, options }}
      {...otherProps}
      renderItem={({ formControlLabelProps, checkboxProps }) => (
        <FormControlLabel
          key={checkboxProps.value as string}
          {...formControlLabelProps}
          sx={(theme) => ({
            padding: theme.spacing(theme.tokens.spacing.sm),
            borderRadius: theme.tokens.borderRadius.md,
            borderStyle: "solid",
            borderWidth: "2px",
            inlineSize: "fit-content",
            alignItems: "center",
            borderColor: checkboxProps.checked ? theme.palette.secondary.main : "transparent",
            [`:not(.${checkboxClasses.disabled}):hover`]: {
              bgcolor: theme.palette.secondary.light
            },
            [`&.${checkboxClasses.disabled}`]: {
              borderColor: checkboxProps.checked ? theme.palette.action.disabled : "transparent"
            }
          })}
          control={<Checkbox {...checkboxProps} />}
        />
      )}
    />
  )
}
