import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Steps } from "../workflow/steps"
import { useProgressTracker } from "../../views/modal/ProgressTracker"
import { useStateMachine } from "../../context/stateMachine/StateMachineContext"
import useSubmitWithErrorCatch from "../hooks/useSubmitWithErrorCatch"
import {
  BenefitEnrollmentFlowModalBackButton,
  BenefitEnrollmentModalContinueButton,
  BenefitEnrollmentModalFormSelect
} from "../shared"
import { useCurrentUser } from "../../context/user/UserContext"
import { useUserDeductible } from "../../context/user/DeductibleContext"
import { defineMessage, useIntl, MessageDescriptor } from "react-intl"
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  UnorderedList,
  DownIcon,
  UnorderedListItem,
  InfoIcon
} from "@carrotfertility/carotene-core"

interface HsaContributionStatusStepProps {
  ViewName: string
  FieldName: string
  hsaContributionStatusValue: string
  contributionStatus?: string
  updateUserInfo: (val: string) => void
  title: string
  bodyAccordionHeading: string
  bodyHsaContributionExplanation: string
  bodyHsaFsaExplanation: string
  bodyBottom: string
}

export const enum HsaContributionOptions {
  ACTIVELY_CONTRIBUTING = "ACTIVELY_CONTRIBUTING",
  EXPECT_TO_CONTRIBUTE = "EXPECT_TO_CONTRIBUTE",
  NOT_CONTRIBUTING = "NOT_CONTRIBUTING",
  IM_NOT_SURE = "IM_NOT_SURE"
}

const hsaIdToLabelMapping = {
  [HsaContributionOptions.ACTIVELY_CONTRIBUTING]: defineMessage({ defaultMessage: "Actively contributing" }),
  [HsaContributionOptions.EXPECT_TO_CONTRIBUTE]: defineMessage({
    defaultMessage: "Expecting to contribute this benefit year"
  }),
  [HsaContributionOptions.NOT_CONTRIBUTING]: defineMessage({
    defaultMessage: "Not contributing or planning to contribute this benefit year"
  }),
  [HsaContributionOptions.IM_NOT_SURE]: defineMessage({ defaultMessage: "I'm not sure" })
}

export const getHsaContributionLabelFromId = (id: HsaContributionOptions): MessageDescriptor => hsaIdToLabelMapping[id]

function HsaContributionStatusStep({
  ViewName,
  FieldName,
  hsaContributionStatusValue,
  contributionStatus,
  updateUserInfo,
  title,
  bodyAccordionHeading,
  bodyHsaContributionExplanation,
  bodyHsaFsaExplanation,
  bodyBottom
}: HsaContributionStatusStepProps): JSX.Element {
  const intl = useIntl()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  const formMethods = useForm()
  function getHsaContributionStatusValue(val: string, answer: string) {
    return val !== "IM_NOT_SURE" && val !== null ? answer : val
  }
  const { setNextStep } = useStateMachine(
    ViewName,
    getHsaContributionStatusValue(contributionStatus, hsaContributionStatusValue)
  )
  // @ts-expect-error TS(7006) FIXME: Parameter 'args' implicitly has an 'any' type.
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const val = args[FieldName].value
    val !== "IM_NOT_SURE" && (await updateUserInfo(val))
    setNextStep(ViewName, getHsaContributionStatusValue(val, hsaContributionStatusValue))
  })

  const { handleSubmit } = formMethods

  return (
    <FormProvider {...formMethods}>
      <Container padding="none" stack="huge">
        <BenefitEnrollmentFlowModalBackButton />
      </Container>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        {title}
      </Typography>
      <Container padding="none" stack="medium">
        <Accordion
          sx={{
            backgroundColor: (theme) => theme.palette.background.default,
            padding: (theme) => theme.spacing(theme.tokens.spacing.md)
          }}
        >
          <AccordionSummary
            expandIcon={<DownIcon fontSize="medium" />}
            aria-controls={`accordion-content-hsa`}
            id={`accordion-content-hsa`}
          >
            <Typography variant="h5">{bodyAccordionHeading}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnorderedList>
              <UnorderedListItem>
                <Typography color={(theme) => theme.palette.text.secondary}>
                  {bodyHsaContributionExplanation}
                </Typography>
              </UnorderedListItem>
              <UnorderedListItem>
                <Typography color={(theme) => theme.palette.text.secondary}>{bodyHsaFsaExplanation}</Typography>
              </UnorderedListItem>
            </UnorderedList>
            <Container padding="lg" sx={{ display: "flex", alignItems: "flex-start" }}>
              <InfoIcon fontSize="small" />
              <Typography
                sx={{
                  paddingInlineStart: (theme) => theme.spacing(theme.tokens.spacing.xs)
                }}
                color={(theme) => theme.palette.text.secondary}
              >
                {bodyBottom}
              </Typography>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Container>
      <Container padding="none" stack="giant">
        <BenefitEnrollmentModalFormSelect
          name={FieldName}
          aria-labelledby="step-heading"
          defaultValue={contributionStatus}
          options={[
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.ACTIVELY_CONTRIBUTING)),
              value: HsaContributionOptions.ACTIVELY_CONTRIBUTING
            },
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.EXPECT_TO_CONTRIBUTE)),
              value: HsaContributionOptions.EXPECT_TO_CONTRIBUTE
            },
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.NOT_CONTRIBUTING)),
              value: HsaContributionOptions.NOT_CONTRIBUTING
            },
            {
              label: intl.formatMessage(getHsaContributionLabelFromId(HsaContributionOptions.IM_NOT_SURE)),
              value: HsaContributionOptions.IM_NOT_SURE
            }
          ]}
        />
      </Container>
      <Box height="10rem" />
      <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
    </FormProvider>
  )
}

function MemberHsaContributionStatusStepBase(step: Steps): JSX.Element {
  const { memberHsaContributionStatus } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()
  return (
    <HsaContributionStatusStep
      FieldName={"memberHsaContributionStatusValue"}
      ViewName={step}
      hsaContributionStatusValue={"MEMBER_ANSWER"}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          memberHsaContributionStatus: val
        })
      }
      title={intl.formatMessage({
        defaultMessage:
          "Are you or your employer contributing to a health savings account (HSA) associated with your HDHP?"
      })}
      bodyAccordionHeading={intl.formatMessage({ defaultMessage: "Where can I check my HSA contributions?" })}
      bodyHsaContributionExplanation={intl.formatMessage({
        defaultMessage:
          "If you’re contributing, your paychecks should have deductions labeled “HSA.” Any employer contributions should also appear on your paychecks."
      })}
      bodyHsaFsaExplanation={intl.formatMessage({
        defaultMessage:
          "An HSA is different from a flexible spending account (FSA) or health reimbursement arrangement (HRA)."
      })}
      bodyBottom={intl.formatMessage({
        defaultMessage: "Still not sure? Contact your employer’s payroll or benefits team."
      })}
      contributionStatus={memberHsaContributionStatus}
    />
  )
}

function MemberIndividualHsaContributionStatusStep(): JSX.Element {
  return MemberHsaContributionStatusStepBase(Steps.MEMBER_INDIVIDUAL_HSA_CONTRIBUTION)
}

function MemberFamilyHsaContributionStatusStep(): JSX.Element {
  return MemberHsaContributionStatusStepBase(Steps.MEMBER_FAMILY_HSA_CONTRIBUTION)
}

function PartnerHsaContributionStatusStep(): JSX.Element {
  const { partnerHsaContributionStatus } = useCurrentUser()
  const { updateBenefitEnrollmentAnswers } = useUserDeductible()
  const intl = useIntl()
  return (
    <HsaContributionStatusStep
      FieldName={"partnerHsaContributionStatusValue"}
      ViewName={Steps.PARTNER_HSA_CONTRIBUTION}
      hsaContributionStatusValue={"PARTNER_ANSWER"}
      updateUserInfo={(val) =>
        updateBenefitEnrollmentAnswers({
          partnerHsaContributionStatus: val
        })
      }
      title={intl.formatMessage({
        defaultMessage:
          "Are they or their employer contributing to a health savings account (HSA) associated with their HDHP?"
      })}
      bodyAccordionHeading={intl.formatMessage({ defaultMessage: "Where can they check their HSA contributions?" })}
      bodyHsaContributionExplanation={intl.formatMessage({
        defaultMessage:
          "If they’re contributing, their paychecks should have deductions labeled “HSA.” Any employer contributions should also appear on their paychecks."
      })}
      bodyHsaFsaExplanation={intl.formatMessage({
        defaultMessage:
          "An HSA is different from a flexible spending account (FSA) or health reimbursement arrangement (HRA)."
      })}
      bodyBottom={intl.formatMessage({
        defaultMessage: "Still not sure? Ask your partner to contact their employer’s payroll or benefits team."
      })}
      contributionStatus={partnerHsaContributionStatus}
    />
  )
}

export {
  MemberIndividualHsaContributionStatusStep,
  MemberFamilyHsaContributionStatusStep,
  PartnerHsaContributionStatusStep
}
