import React, { useCallback, useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { ChangeJourneyButton } from "#/components/carrot-plans/shared/ChangeJourneyButtton"
import { useGetRegistrationJourney } from "#/components/carrot-plans/hooks/useGetRegistrationJourney"
import { useLocation } from "react-router-dom"

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "I have one child" }), value: "ONE_CHILD" },
  { label: defineMessage({ defaultMessage: "I have multiple children" }), value: "MULTIPLE_CHILDREN" }
]
export default function NumberOfChildrenStep(): JSX.Element {
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.numberOfChildren
  const { send } = useStateMachine(defaultValue ? "" : null)
  const registrationJourneyResult = useGetRegistrationJourney()
  const { pathname } = useLocation()
  const isInitialRouting = !pathname.includes("case-rate-renewal") && !pathname.includes("journey-change")

  async function onClickContinue(formValues: { numberOfChildren: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }

  const header = intl.formatMessage({
    defaultMessage: "You selected parenting as your journey. How many children do you have?"
  })

  const setRoutingNumberOfChildren = useCallback(
    async (formValues: { numberOfChildren: string }): Promise<void> => {
      await mutateAsync(formValues, {
        onSuccess: () => {
          send("")
        }
      })
    },
    [send, mutateAsync]
  )

  useEffect(() => {
    if (!registrationJourneyResult?.isLoading) {
      if (isInitialRouting && !routingData?.numberOfChildren && registrationJourneyResult?.data?.numberOfChildren) {
        setRoutingNumberOfChildren({
          numberOfChildren: registrationJourneyResult.data.numberOfChildren
        })
        send("")
      }
    }
  }, [routingData, setRoutingNumberOfChildren, isInitialRouting, registrationJourneyResult, send])

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="numberOfChildren-label">{header}</span>}
        formElements={
          <>
            <ChangeJourneyButton />
            <Form defaultValues={{ numberOfChildren: defaultValue }} onSubmit={onClickContinue}>
              <FormRadioButtonGroup
                name="numberOfChildren"
                aria-labelledby="numberOfChildren-label"
                options={radioOptions}
                controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              />
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Form>
          </>
        }
      />
    </>
  )
}
