import { FormEvent } from "react"
import { useFormContext } from "react-hook-form"
import { NONE_OF_THESE } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export function useHandleCheckboxGroupNoneOfThese(inputName: string): (_: FormEvent<HTMLFieldSetElement>) => void {
  const { watch, setValue } = useFormContext()

  return (_: FormEvent<HTMLFieldSetElement>) => {
    const currentValue: string[] = watch(inputName)
    if (!currentValue) return

    const lastSelected = currentValue.at(-1)

    if (lastSelected === NONE_OF_THESE) {
      setValue(inputName, [NONE_OF_THESE])
    } else {
      setValue(
        inputName,
        currentValue.filter((value) => value !== NONE_OF_THESE)
      )
    }
  }
}
