// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import React from "react"
import { UnderwaterPlants, UnderwaterPlantsRight, UnderwaterSeaweed, Person } from "./BackgroundImages"
import { useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

export const BlueBottomBackground = (): JSX.Element => {
  const theme = useTheme()
  const showDesktopBackground = useMediaQuery(theme.breakpoints.up("lg"))
  const mobileBackground = (
    <Container background="patternsBlue" width="100%" height="302px" sx={{ position: "relative" }}>
      <UnderwaterPlants sx={{ position: "absolute", insetBlockStart: "-35px", insetInlineStart: "20px" }} />
      <UnderwaterSeaweed sx={{ position: "absolute", insetInlineStart: "120px" }} />
    </Container>
  )
  const desktopBackground = (
    <>
      <Container
        background="patternsBlue"
        width="100%"
        height="calc(100% - 600px)"
        sx={{ position: "absolute", insetBlockStart: "600px" }}
      >
        <UnderwaterPlants
          sx={{ position: "absolute", insetBlockStart: "-35px", insetInlineStart: "calc(50% - 670px)" }}
        />
        <UnderwaterSeaweed
          sx={{ position: "absolute", insetBlockStart: "35px", insetInlineStart: "calc(50% - 580px)" }}
        />
        <UnderwaterPlantsRight
          sx={{ position: "absolute", insetBlockStart: "-50px", insetInlineEnd: "calc(50% - 720px)" }}
        />
      </Container>
      <Person sx={{ position: "absolute", insetBlockStart: "415px", insetInlineEnd: "calc(50% - 710px)" }} />
    </>
  )
  return showDesktopBackground ? desktopBackground : mobileBackground
}
