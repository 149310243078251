import React from "react"

/**
 * @deprecated Use `Form` from `@carrotfertility/carotene-core` instead.
 */
// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const Form = ({ children, onSubmit, ...props }) => {
  if (!onSubmit) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    onSubmit = (event) => {
      event.preventDefault()
      return false
    }
  }
  return (
    <form onSubmit={onSubmit} {...props}>
      {children}
      <input type="submit" className="invisible" />
    </form>
  )
}

export { Form }
