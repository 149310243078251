import React, { useEffect } from "react"
import {
  BackIcon,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stepper
} from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import {
  NONE_OF_THESE,
  User,
  Questions,
  WhatElseMatters
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { FormCheckboxButtonGroup } from "#/services/common-forms/FormCheckboxButtonGroup"
import { useHandleCheckboxGroupNoneOfThese } from "#/components/carrot-match-fertility-clinic-precision-matching/hooks/useHandleCheckboxGroupNoneOfThese"

type WhatElseMattersCheckboxOption = { label: string; value: WhatElseMatters | typeof NONE_OF_THESE }
function WhatMattersCheckboxGroup({ name }: { name: string }): JSX.Element {
  const intl = useIntl()
  const handleCheckboxGroup = useHandleCheckboxGroupNoneOfThese(name)

  const options: WhatElseMattersCheckboxOption[] = [
    {
      label: intl.formatMessage({
        defaultMessage: "LGBTQIA+ support"
      }),
      value: WhatElseMatters.LGBTQIA_SUPPORT
    },
    {
      label: intl.formatMessage({ defaultMessage: "Single intended parent support" }),
      value: WhatElseMatters.SINGLE_INTENDED_PARENT_SUPPORT
    },
    {
      label: intl.formatMessage({ defaultMessage: "BIPOC (Black, Indigenous, and people of color) provider" }),
      value: WhatElseMatters.BIPOC_PROVIDER
    },
    {
      label: intl.formatMessage({ defaultMessage: "Faith-inclusive care" }),
      value: WhatElseMatters.FAITH_INCLUSIVE_CARE
    },
    {
      label: intl.formatMessage({ defaultMessage: "Provider’s years of experience" }),
      value: WhatElseMatters.PROVIDER_YEARS_OF_EXPERIENCE
    },
    {
      label: intl.formatMessage({ defaultMessage: "Provider’s education" }),
      value: WhatElseMatters.PROVIDER_EDUCATION
    },
    {
      label: intl.formatMessage({ defaultMessage: "Patient ratings and reviews" }),
      value: WhatElseMatters.PATIENT_RATINGS_AND_REVIEWS
    },
    {
      label: intl.formatMessage({ defaultMessage: "Appointment availability and timing" }),
      value: WhatElseMatters.APPOITNMENT_AVAILABILITY_AND_TIMING
    },
    {
      label: intl.formatMessage({ defaultMessage: "None of these" }),
      value: NONE_OF_THESE
    }
  ]

  return <FormCheckboxButtonGroup name={name} options={options} onChange={handleCheckboxGroup} />
}

export function WhatElseMattersToXYZStep() {
  const { send, back } = useStateMachine()
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const {
    memberPreferredNameOrFirstName,
    partnerPreferredNameOrFirstName,
    whoIsAnsweringTheIntake,
    whoIsSeekingCare,
    questionsAndAnswers
  } = carrotMatchInteraction
  const intl = useIntl()

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: Questions.WHAT_ELSE_MATTERS_TO_XYZ_QUESTION })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const getWhatElseMattersDialogTitle = () => {
    if (whoIsAnsweringTheIntake === whoIsSeekingCare) {
      return intl.formatMessage({ defaultMessage: "What else matters to you?" })
    } else if (whoIsSeekingCare === User.PARTNER) {
      return intl.formatMessage(
        { defaultMessage: "What else matters to {partnerPreferredNameOrFirstName}?" },
        { partnerPreferredNameOrFirstName }
      )
    } else {
      return intl.formatMessage(
        { defaultMessage: "What else matters to {memberPreferredNameOrFirstName}?" },
        { memberPreferredNameOrFirstName }
      )
    }
  }

  const handleSubmitAndProceedToNextStep: FormOnSubmitHandler<{
    whatElseMatters: WhatElseMatters[]
  }> = ({ whatElseMatters }: { whatElseMatters: WhatElseMatters[] }) => {
    updateCarrotMatchInteraction({
      questionsAndAnswers: [
        ...questionsAndAnswers,
        {
          question: Questions.WHAT_ELSE_MATTERS_TO_XYZ_QUESTION,
          answer: whatElseMatters || []
        }
      ]
    })
    send("")
  }

  return (
    <>
      <Form onSubmit={handleSubmitAndProceedToNextStep}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={2} position="static" />
        <DialogTitle id="step-heading">{getWhatElseMattersDialogTitle()}</DialogTitle>
        <DialogContentText paddingBlockEnd={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
          <FormattedMessage defaultMessage="These may not be available in all locations or situations, but we're committed to helping you find the best provider for your journey." />
        </DialogContentText>
        <DialogContent>
          <WhatMattersCheckboxGroup name="whatElseMatters" />
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="contained" color="primary" type="submit">
              <FormattedMessage defaultMessage="Search" />
            </Button>
          }
          tertiaryAction={
            <Button onClick={() => back()} variant="text" color="secondary" startIcon={<BackIcon />}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
