import React from "react"
import { useHistory } from "react-router-dom"
import { CircleImage } from "#/components/views/atoms/images/CircleImage"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Card, Typography } from "@carrotfertility/carotene-core"

const NeedsMemberUploadsModule = ({ hasMultipleExpensesNeedingUploads }: NeedsMemberUploadsModuleProps) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  return (
    <Card
      primaryAction={
        <Button variant="contained" color="secondary" onClick={() => history.push("/messages")}>
          {hasMultipleExpensesNeedingUploads ? (
            <FormattedMessage defaultMessage="View messages" />
          ) : (
            <FormattedMessage defaultMessage="View message" />
          )}
        </Button>
      }
      sx={(theme) => ({
        backgroundColor: theme.palette.success.light
      })}
      image={<CircleImage iconName="icn-documents" backgroundColor="white" />}
      title={formatMessage({ defaultMessage: "Expense update" })}
      id="home-sidebar-needs-member-upload-module"
    >
      <Typography>
        {hasMultipleExpensesNeedingUploads ? (
          <FormattedMessage defaultMessage="We need additional documents to process your expenses." />
        ) : (
          <FormattedMessage defaultMessage="We need additional documents to process your expense." />
        )}
      </Typography>
    </Card>
  )
}

type NeedsMemberUploadsModuleProps = {
  hasMultipleExpensesNeedingUploads: boolean
}

export { NeedsMemberUploadsModule }
