import {
  ProviderTypeSearched,
  useProviderTypeSearched
} from "#/pages/provider-finder/components/results/useProviderTypeSearched"
import { useContentfulEntry } from "#/lib/contentful/hooks/useContentfulEntry"
import { TypeContainerSkeleton } from "#/types/contentful"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"
import { useDoesUserSatisfyConditions } from "#/services/contentful/conditional-gate"
import { toCondition } from "#/lib/contentful/utils/conditionUtils"

export type EnhancedEmptyStateProviderType =
  | ProviderTypeSearched.Menopause
  | ProviderTypeSearched.GenderAffirmingHormoneTherapy
  | ProviderTypeSearched.LowTestosterone

const emptyStateDataByProviderType = {
  [ProviderTypeSearched.Menopause]: {
    entryId: "5rDP9nftKqh8OwgW5Zy47M",
    displayName: defineMessage({ defaultMessage: "Menopause specialists" })
  },
  [ProviderTypeSearched.GenderAffirmingHormoneTherapy]: {
    entryId: "18lVZXb70wFLZJqHXlpgLg",
    displayName: defineMessage({ defaultMessage: "Gender-affirming hormone therapy providers" })
  },
  [ProviderTypeSearched.LowTestosterone]: {
    entryId: "65PMk16AxSXnuaQYGppcIL",
    displayName: defineMessage({ defaultMessage: "Endocrinologists and urologists" })
  }
}

export function useEnhancedEmptyState(): {
  providerType: EnhancedEmptyStateProviderType
  isEnhancedEmptyState: boolean
  emptyStateProviderTypeDisplayName: MessageDescriptor
  emptyStateContent: EntryWithoutUnresolvableLinks<TypeContainerSkeleton>
  isLoadingContent: boolean
} {
  const intl = useIntl()
  const providerTypeSearched = useProviderTypeSearched()
  const doesProviderTypeSupportEnhancedEmptyState =
    Object.keys(emptyStateDataByProviderType).includes(providerTypeSearched)
  const providerType = doesProviderTypeSupportEnhancedEmptyState
    ? (providerTypeSearched as EnhancedEmptyStateProviderType)
    : null

  const { data, isLoading, isError } = useContentfulEntry<TypeContainerSkeleton>(
    doesProviderTypeSupportEnhancedEmptyState ? emptyStateDataByProviderType[providerType].entryId : "",
    intl.locale
  )

  const { doesUserSatisfyConditions, isLoading: isDoesUserSatisfyConditionsLoading } = useDoesUserSatisfyConditions()
  const conditions = data?.fields?.conditions?.map(toCondition)
  const doesMeetEnhancedEmptyStateConditions = doesUserSatisfyConditions(conditions)
  const isEnhancedEmptyState =
    !isError && doesProviderTypeSupportEnhancedEmptyState && doesMeetEnhancedEmptyStateConditions

  return {
    providerType,
    isEnhancedEmptyState,
    emptyStateProviderTypeDisplayName: isEnhancedEmptyState
      ? emptyStateDataByProviderType[providerType].displayName
      : null,
    emptyStateContent: data,
    isLoadingContent: isLoading || isDoesUserSatisfyConditionsLoading
  }
}
