import React, { useCallback, useContext, useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import {
  BackIcon,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Link,
  ListItemText,
  Progress,
  Typography,
  UnorderedList,
  UnorderedListItem
} from "@carrotfertility/carotene-core"
import { DialogActionsBar, DialogCloseButton } from "@carrotfertility/carotene-core-x"
import Settings from "#/utils/CarrotConfig"
import { FormattedMessage, useIntl } from "react-intl"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import { useTelemedicinePatientProfile } from "#/components/telemedicine/hooks/useTelemedicinePatientProfile"
import { useMutation } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"

export function YouAreLeavingCarrotStep(): JSX.Element {
  const { back } = useStateMachine(null)
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const intl = useIntl()
  const { mutateAsync: createTelemedicineProfile, isLoading: isCreatingTelemedicineProfile } =
    useTelemedicinePatientProfile()
  const { mutateAsync: trackTelemedicineLogin } = useMutation(() => carrotClient.trackTelemedicineLogin(), {
    retry: false
  })

  const createProfile = useCallback(() => {
    createTelemedicineProfile(null)
  }, [createTelemedicineProfile])

  useEffect(() => {
    createProfile()
  }, [createProfile])

  const onContinueToWheelClick = async () => {
    window.open(Settings.WHEEL_TELEMEDICINE_FOR_MENOPAUSE_URL, "_blank")
    await trackTelemedicineLogin()
  }

  return (
    <>
      <DialogCloseButton onClick={() => setOpenDialogFlow(false)} />
      <DialogTitle>
        <FormattedMessage defaultMessage="You are leaving Carrot" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage defaultMessage="You are about to leave Carrot and proceed to a third-party website operated by Wheel Health." />
        </Typography>
        <Box>
          <Typography>
            <FormattedMessage defaultMessage="Please note:" />
          </Typography>
          <UnorderedList>
            <UnorderedListItem color="secondary">
              <ListItemText
                primary={intl.formatMessage({
                  defaultMessage:
                    "Carrot is not responsible for the accuracy or legality of the content found on Wheel Health’s website nor for Wheel Health’s security or privacy practices."
                })}
              />
            </UnorderedListItem>
            <UnorderedListItem>
              <ListItemText
                primary={intl.formatMessage({
                  defaultMessage:
                    "Wheel Health will be solely responsible for the collection, use, and disclosure of your personal data."
                })}
              />
            </UnorderedListItem>
            <UnorderedListItem>
              <ListItemText
                primary={intl.formatMessage(
                  {
                    defaultMessage:
                      "You can learn more about Wheel Health’s collection, use, and disclosure of personal data in their <pnLink>Privacy Notice</pnLink> and <tsLink>Terms of Service</tsLink>."
                  },
                  {
                    pnLink: (linkContent) => (
                      <Link target="_blank" color="inherit" href="https://www.wheel.com/privacy-policy">
                        {linkContent}
                      </Link>
                    ),
                    tsLink: (linkContent) => (
                      <Link target="_blank" color="inherit" href="https://www.wheel.com/wheel-health-inc-terms-of-use">
                        {linkContent}
                      </Link>
                    )
                  }
                )}
              />
            </UnorderedListItem>
          </UnorderedList>
        </Box>
        <Typography>
          <FormattedMessage defaultMessage="By continuing, you acknowledge and accept that Carrot is not liable or responsible for any actions or inactions taken by Wheel Health." />
        </Typography>
      </DialogContent>
      <DialogActionsBar
        primaryAction={
          <Button
            onClick={onContinueToWheelClick}
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isCreatingTelemedicineProfile}
          >
            {isCreatingTelemedicineProfile ? (
              <Progress size="1.25rem" color="inherit" />
            ) : (
              <FormattedMessage defaultMessage="Continue to Wheel" />
            )}
          </Button>
        }
        tertiaryAction={
          <Button color="secondary" onClick={back} variant="text" startIcon={<BackIcon />}>
            <FormattedMessage defaultMessage="Back" />
          </Button>
        }
      />
    </>
  )
}
