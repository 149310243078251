import useCarrotPlan from "#/components/carrot-plans/hooks/useCarrotPlan"
import useCarrotPlanEmployeeSettings from "#/components/carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { useGetCarrotPlanRequestStatus } from "#/components/carrot-plans/hooks/useCarrotPlanRequestStatus"
import { BenefitEnrollmentFlowContainer } from "#/components/cmd-enrollment"
import { useBenefitEnrollment } from "#/components/context/enrollment/BenefitEnrollmentContext"
import { useCurrentUser } from "#/components/context/user/UserContext"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import BenefitsModule from "#/components/home/benefits-modules"
import { BenefitHighlightsCard } from "#/components/home/benefits-modules/BenefitsHighlightsCard"
import CarrotRxHomeSidebarModule from "#/components/home/CarrotRxHomeSidebarModule"
import { NeedsMemberUploadsModule } from "#/components/home/NeedsMemberUploadsModule"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import AvailableFundsModule from "#/components/views/molecules/AvailableFundsModule"
import { BenefitEnrollmentModule } from "#/components/views/molecules/BenefitEnrollmentModule"
import { HelpCenterModule } from "#/components/views/molecules/HelpCenterModule"
import { CenteredLoadingIndicator } from "#/components/views/molecules/Molecules"
import { ProgramType } from "#/lib/carrot-api/types/Benefit"
import { getReimbursementsList } from "#/redux/reducers/reimbursements"
import { getSubsidies } from "#/redux/reducers/subsidies"
import { ExpenseState } from "#/utils/ExpenseState"
import { useToggle } from "#/utils/Hooks"
import { Box } from "@carrotfertility/carotene-core"
import React, { useMemo } from "react"
import { useSelector } from "react-redux"

const SidebarModules = (): JSX.Element => {
  const { status: benefitEnrollmentStatus, availabilityStatus } = useBenefitEnrollment()
  const { data: benefit, isLoading: isUseBenefitLoading } = useBenefit()
  const { needsBenefitEnrollment } = benefitEnrollmentStatus
  const [showBenefitEnrollmentModal, toggleBenefitEnrollmentModal] = useToggle(false)
  const { data: memberHasACarrotPlan, isLoading: isCarrotPlanLoading } = useCarrotPlan()
  const { isInAppLockdown } = useCurrentUser()
  const subsidies = useSelector(getSubsidies)
  // @ts-expect-error TS(7006) FIXME: Parameter 'plan' implicitly has an 'any' type.
  const hasUnlimitedBenefit = subsidies?.every((plan) => plan.hasUnlimitedBenefit)
  const expensesNeedingMemberUploads = useSelector((state) => {
    const reimbursementsList = getReimbursementsList(state)
    return reimbursementsList?.filter(
      (expense: { expenseState: string }) => expense.expenseState === ExpenseState.NEEDS_MEMBER_UPLOADS
    )
  })
  const { isPartnerAccessingAccount } = useUserRole()
  const cpesQuery = useCarrotPlanEmployeeSettings()
  const cpRequestStatusQuery = useGetCarrotPlanRequestStatus()

  const isPro = benefit?.program?.type === ProgramType.PRO
  const isCoreOrEngage = [ProgramType.CORE, ProgramType.WTW_ENGAGE].includes(benefit?.program?.type)

  const sideModulesToRender = useMemo(() => {
    const modules = []
    const hideAvailableFundsModule: boolean = expensesNeedingMemberUploads?.length > 0

    if (isCarrotPlanLoading || isUseBenefitLoading || cpRequestStatusQuery.isLoading) {
      return [<CenteredLoadingIndicator key={"centered-loading-indicator"} id={"loading-indicator-test"} />]
    }

    if (isPro && !isPartnerAccessingAccount && expensesNeedingMemberUploads?.length) {
      modules.push(
        <NeedsMemberUploadsModule hasMultipleExpensesNeedingUploads={expensesNeedingMemberUploads.length > 1} />
      )
    }

    if (isPro && !hasUnlimitedBenefit) {
      modules.push(
        <BenefitsModule
          appLockedDown={isInAppLockdown}
          availabilityStatus={availabilityStatus}
          hideAvailableFundsModule={hideAvailableFundsModule}
        />
      )
    } else {
      if (isPro) {
        if (isInAppLockdown && !hasUnlimitedBenefit) {
          if (!hideAvailableFundsModule) {
            modules.push(<AvailableFundsModule />)
          }
        } else {
          if (needsBenefitEnrollment) {
            modules.push(<BenefitEnrollmentModule onClickEnroll={toggleBenefitEnrollmentModal} />)
          } else if (!hasUnlimitedBenefit && !hideAvailableFundsModule) {
            modules.push(<AvailableFundsModule />)
          }
        }
      }
    }

    if (memberHasACarrotPlan && !(cpRequestStatusQuery.data?.carrotPlanIsOnTheWay || cpesQuery.isDoneForNow)) {
      modules.push(<BenefitHighlightsCard />)
    }

    if (isCoreOrEngage) {
      modules.push(<HelpCenterModule />)
    }

    if (benefit?.carrotRx.isCarrotRxActive) {
      const fertilityCareOrPreservationEnabled =
        benefit?.journeys.fertilityPreservation.isAppSupportEnabled ||
        benefit?.journeys.fertilityCare.isAppSupportEnabled
      modules.push(
        <CarrotRxHomeSidebarModule fertilityCareOrPreservationEnabled={fertilityCareOrPreservationEnabled} />
      )
    }
    return modules
  }, [
    isCarrotPlanLoading,
    memberHasACarrotPlan,
    isPro,
    isCoreOrEngage,
    hasUnlimitedBenefit,
    cpesQuery,
    isInAppLockdown,
    availabilityStatus,
    needsBenefitEnrollment,
    toggleBenefitEnrollmentModal,
    expensesNeedingMemberUploads,
    benefit,
    isUseBenefitLoading,
    isPartnerAccessingAccount,
    cpRequestStatusQuery
  ])

  return (
    <>
      {sideModulesToRender.map((module, idx) => (
        <React.Fragment key={idx}>{module}</React.Fragment>
      ))}
      {showBenefitEnrollmentModal && <BenefitEnrollmentFlowContainer onExit={toggleBenefitEnrollmentModal} />}
    </>
  )
}

export const HomeSidebar = (): JSX.Element => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection="row"
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      sx={(theme) => ({
        [theme.breakpoints.down("lg")]: {
          "& > *": {
            flex: 1,
            blockSize: "auto",
            minInlineSize: "20rem",
            inlineSize: "calc(50% - 1.5rem)"
          }
        }
      })}
    >
      <SidebarModules />
    </Box>
  )
}
