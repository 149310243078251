import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { useGetRegistrationJourney } from "#/components/carrot-plans/hooks/useGetRegistrationJourney"

export default function JourneyStageDecisionStep(): JSX.Element {
  const { send } = useStateMachine()
  const { journeyStageScreenInReg } = useFlags()

  const routingData = useCheckRoutingInfoQueryCache()
  const registrationJourney = useGetRegistrationJourney()?.data

  const hasExistingRoutingJourneyStage = Boolean(routingData?.journeyStage)
  const hasRegistrationJourneyStage = Boolean(registrationJourney?.journeyStage)
  const journeyChanged = registrationJourney?.journey !== routingData?.journey

  const shouldShowJourneyStageCheckInScreen =
    hasRegistrationJourneyStage && journeyStageScreenInReg && !hasExistingRoutingJourneyStage && !journeyChanged

  useEffect(() => {
    send(shouldShowJourneyStageCheckInScreen ? "YES" : "NO")
  }, [shouldShowJourneyStageCheckInScreen, send])

  return <></>
}
