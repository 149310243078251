import {
  CarrotMatchAnswer,
  ClinicSpecialization,
  FertilityTreatment,
  NONE_OF_THESE,
  SpecialServices
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { defineMessages, MessageDescriptor } from "react-intl"

interface MatchAttribute {
  key: string
  rank: number
  description: MessageDescriptor
}

interface GetMatchAttributesOrderParams {
  treatmentTypeSelected: FertilityTreatment | typeof NONE_OF_THESE
  specializationSelected: CarrotMatchAnswer[]
  specialServiceSelected: CarrotMatchAnswer[]
  isCarrotPartner: boolean
  selectedCostSensitivity: boolean
}

const fertilityTreatmentMapping = defineMessages({
  [FertilityTreatment.FERTILITY_PRESERVATION]: {
    defaultMessage: "Top success rates for fertility preservation (e.g., freezing eggs, sperm, or embryos)"
  },
  [FertilityTreatment.IUI]: { defaultMessage: "Top success rates for IUI" },
  [FertilityTreatment.FULL_IVF]: { defaultMessage: "Top success rates for full IVF cycle (retrieval and transfer)" },
  [FertilityTreatment.FET]: { defaultMessage: "Top success rates for FET cycle with existing embryos" },
  [FertilityTreatment.PGT_GENETIC_TESTING]: { defaultMessage: "Top success rates for PGT/Genetic Testing" },
  [FertilityTreatment.EMBRYO_STORAGE]: { defaultMessage: "Top success rates for embryo storage" },
  [FertilityTreatment.DONOR_ASSISTED_REPRODUCTION]: {
    defaultMessage: "Top success rates for donor-assisted reproduction"
  },
  [FertilityTreatment.GESTATIONAL_SURROGACY]: { defaultMessage: "Top success rates for gestational surrogacy" }
})

const specializationMapping = defineMessages({
  [ClinicSpecialization.MINIMUM_STIMULATION_CYCLE]: { defaultMessage: "Has experience with minimum stimulation cycle" },
  [ClinicSpecialization.NATURAL_CYCLE]: { defaultMessage: "Has experience with natural cycle" },
  [ClinicSpecialization.MALE_FACTOR_INFERTILITY]: { defaultMessage: "Has experience with male factor infertility" },
  [ClinicSpecialization.DONOR_ASSISTED_REPRODUCTION]: {
    defaultMessage: "Has experience with donor assisted reproduction"
  },
  [ClinicSpecialization.UNEXPLAINED_INFERTILITY]: { defaultMessage: "Has experience with unexplained infertility" },
  [ClinicSpecialization.UTERINE_FACTOR]: { defaultMessage: "Has experience with uterine factor" },
  [ClinicSpecialization.HIGH_BMI]: { defaultMessage: "Has experience with high BMI" },
  [ClinicSpecialization.PCOS]: { defaultMessage: "Has experience with PCOS" },
  [ClinicSpecialization.ENDOMETRIOSIS]: { defaultMessage: "Has experience with endometriosis" },
  [ClinicSpecialization.DIMINISHED_OVARIAN_RESERVE]: {
    defaultMessage: "Has experience with diminished ovarian reserve"
  },
  [ClinicSpecialization.TUBAL_FACTOR]: { defaultMessage: "Has experience with tubal factor" }
})

const specialServicesMapping = defineMessages({
  [SpecialServices.REMOTE_MONITORING]: { defaultMessage: "Offers remote monitoring" },
  [SpecialServices.ONSITE_GENETIC_COUNSELING]: { defaultMessage: "Offers onsite genetic counseling" },
  [SpecialServices.MENTAL_HEALTH_SERVICES]: { defaultMessage: "Offers mental health services" },
  [SpecialServices.ONSITE_DIETITIAN]: { defaultMessage: "Offers onsite dietitian" },
  [SpecialServices.ACUPUNCTURE]: { defaultMessage: "Offers acupuncture" }
})

const DESCRIPTIONS = defineMessages({
  DISTANCE: { defaultMessage: "Within driving distance" },
  COST_SENSITIVITY: { defaultMessage: "Offers discounts for Carrot members" },
  MEMBER_DEMOGRAPHICS: { defaultMessage: "Good outcomes for people like you" },
  EMBRYO_STORAGE: { defaultMessage: "Offers embryo storage" },
  NONE_OF_THESE: { defaultMessage: "Top success rates" }
})

const SUCCESS_RATE_KEY = "SUCCESS_RATE_FOR_SIMILAR_PATIENTS"
const DISTANCE_KEY = "DISTANCE_TO_CLINIC"
const COST_SENSITIVITY_KEY = "COST_SENSITIVITY"
const MEMBER_DEMOGRAPHICS_KEY = "MEMBER_DEMOGRAPHICS"
const CLINIC_SPECIALIZATION_KEY = "CLINIC_SPECIALIZATION"
const SPECIAL_SERVICES_KEY = "SPECIAL_SERVICES"

const MAX_ATTRIBUTES_LENGTH = 5

const addAttribute = (attributes: MatchAttribute[], key: string, rank: number, description: MessageDescriptor) => {
  attributes.push({ key, rank, description })
}

const getTreatmentDescription = (treatmentType: FertilityTreatment | typeof NONE_OF_THESE): MessageDescriptor => {
  if (treatmentType === FertilityTreatment.EMBRYO_STORAGE) return DESCRIPTIONS.EMBRYO_STORAGE
  if (treatmentType === NONE_OF_THESE) return DESCRIPTIONS.NONE_OF_THESE
  return fertilityTreatmentMapping[treatmentType]
}

const addOptionalAttributes = (
  attributes: MatchAttribute[],
  items: CarrotMatchAnswer[],
  key: string,
  mapping: Record<string, MessageDescriptor>
) => {
  items.forEach((item) => {
    if (attributes.length < MAX_ATTRIBUTES_LENGTH) {
      addAttribute(attributes, `${key}_${item}`, attributes.length + 1, mapping[item])
    }
  })
}

export const getMatchAttributesOrder = ({
  specializationSelected,
  treatmentTypeSelected,
  specialServiceSelected,
  isCarrotPartner,
  selectedCostSensitivity
}: GetMatchAttributesOrderParams): MatchAttribute[] => {
  const attributes: MatchAttribute[] = []

  addAttribute(attributes, SUCCESS_RATE_KEY, 1, getTreatmentDescription(treatmentTypeSelected))
  addAttribute(attributes, DISTANCE_KEY, 2, DESCRIPTIONS.DISTANCE)

  if (isCarrotPartner && selectedCostSensitivity) {
    addAttribute(attributes, COST_SENSITIVITY_KEY, 3, DESCRIPTIONS.COST_SENSITIVITY)
  }

  addAttribute(attributes, MEMBER_DEMOGRAPHICS_KEY, 4, DESCRIPTIONS.MEMBER_DEMOGRAPHICS)

  if (
    specializationSelected &&
    specializationSelected.length &&
    !specializationSelected.includes(NONE_OF_THESE) &&
    attributes.length < MAX_ATTRIBUTES_LENGTH
  ) {
    addOptionalAttributes(attributes, specializationSelected, CLINIC_SPECIALIZATION_KEY, specializationMapping)
  }

  if (
    specialServiceSelected &&
    specialServiceSelected.length &&
    !specialServiceSelected.includes(NONE_OF_THESE) &&
    attributes.length < MAX_ATTRIBUTES_LENGTH
  ) {
    addOptionalAttributes(attributes, specialServiceSelected, SPECIAL_SERVICES_KEY, specialServicesMapping)
  }

  return attributes.sort((a, b) => a.rank - b.rank)
}
