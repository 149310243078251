import React, { useEffect } from "react"
import { BackIcon, Button, DialogContent, DialogTitle, ForwardIcon, Stepper } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { DialogActionsBar, Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { CarrotMatchDialogCloseButton } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CarrotMatchDialogCloseButton"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import {
  NONE_OF_THESE,
  Questions,
  User,
  WhatMatters
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"
import { FormCheckboxButtonGroup } from "#/services/common-forms/FormCheckboxButtonGroup"
import { useHandleCheckboxGroupNoneOfThese } from "#/components/carrot-match-fertility-clinic-precision-matching/hooks/useHandleCheckboxGroupNoneOfThese"

type WhatMattersCheckboxOption = { label: string; value: WhatMatters | typeof NONE_OF_THESE }
function WhatMattersCheckboxGroup({ name }: { name: string }): JSX.Element {
  const intl = useIntl()
  const handleCheckboxGroup = useHandleCheckboxGroupNoneOfThese(name)

  const options: WhatMattersCheckboxOption[] = [
    {
      label: intl.formatMessage({
        defaultMessage: "Clinical success rates"
      }),
      value: WhatMatters.CLINICAL_SUCCESS_RATES
    },
    {
      label: intl.formatMessage({ defaultMessage: "Distance" }),
      value: WhatMatters.DISTANCE
    },
    {
      label: intl.formatMessage({ defaultMessage: "Cost and discounts" }),
      value: WhatMatters.COST_DISCOUNTS
    },
    {
      label: intl.formatMessage({ defaultMessage: "Special services at the clinic" }),
      value: WhatMatters.SPECIAL_SERVICES_AT_THE_CLINIC
    },
    {
      label: intl.formatMessage({ defaultMessage: "None of these" }),
      value: NONE_OF_THESE
    }
  ]

  return <FormCheckboxButtonGroup name={name} options={options} onChange={handleCheckboxGroup} />
}

export function WhatMattersToXYZStep() {
  const { send, back } = useStateMachine()
  const { carrotMatchInteraction, updateCarrotMatchInteraction } = useCarrotMatch()
  const {
    memberPreferredNameOrFirstName,
    partnerPreferredNameOrFirstName,
    whoIsAnsweringTheIntake,
    whoIsSeekingCare,
    questionsAndAnswers
  } = carrotMatchInteraction
  const intl = useIntl()

  useEffect(() => {
    updateCarrotMatchInteraction({ lastQuestionViewedBeforeLeavingFlow: Questions.WHAT_MATTERS_TO_XYZ_QUESTION })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const getWhatMattersDialogTitle = () => {
    if (whoIsAnsweringTheIntake === whoIsSeekingCare) {
      return intl.formatMessage({ defaultMessage: "What matters to you?" })
    } else if (whoIsSeekingCare === User.PARTNER) {
      return intl.formatMessage(
        { defaultMessage: "What matters to {partnerPreferredNameOrFirstName}?" },
        { partnerPreferredNameOrFirstName }
      )
    } else {
      return intl.formatMessage(
        { defaultMessage: "What matters to {memberPreferredNameOrFirstName}?" },
        { memberPreferredNameOrFirstName }
      )
    }
  }

  const handleSubmitAndProceedToNextStep: FormOnSubmitHandler<{
    whatMatters: WhatMatters[]
  }> = ({ whatMatters }: { whatMatters: WhatMatters[] }) => {
    updateCarrotMatchInteraction({
      questionsAndAnswers: [
        ...questionsAndAnswers.filter((qa) => qa.question !== "What matters to XYZ?"),
        {
          question: "What matters to XYZ?",
          answer: whatMatters
        }
      ]
    })
    send("")
  }

  const handleSkipAndProceedToNextStep = () => {
    updateCarrotMatchInteraction({
      questionsAndAnswers: [
        ...questionsAndAnswers.filter((qa) => qa.question !== "What matters to XYZ?"),
        {
          question: "What matters to XYZ?",
          answer: []
        }
      ]
    })
    send("")
  }

  return (
    <>
      <Form onSubmit={handleSubmitAndProceedToNextStep}>
        <CarrotMatchDialogCloseButton />
        <Stepper variant="progress" steps={4} activeStep={1} position="static" />
        <DialogTitle id="step-heading">{getWhatMattersDialogTitle()}</DialogTitle>
        <DialogContent>
          <WhatMattersCheckboxGroup name="whatMatters" />
        </DialogContent>
        <DialogActionsBar
          primaryAction={
            <Button variant="outlined" color="secondary" endIcon={<ForwardIcon />} type="submit">
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          }
          secondaryAction={
            <Button variant="text" color="secondary" type="submit" onClick={handleSkipAndProceedToNextStep}>
              <FormattedMessage defaultMessage="Skip" />
            </Button>
          }
          tertiaryAction={
            <Button onClick={() => back()} variant="text" color="secondary" startIcon={<BackIcon />}>
              <FormattedMessage defaultMessage="Back" />
            </Button>
          }
        />
      </Form>
    </>
  )
}
