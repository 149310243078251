import React from "react"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { RegistrationButtonFooter } from "./RegistrationButtons"
import { Button, Typography } from "@carrotfertility/carotene-core"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { CarrotPlansPaths, Paths } from "#/utils/Paths"

interface PartnerRegistrationCompleteProps {
  isInitialCarrotPlanRoutingCompleted: boolean
  employeeId: number
}

function continueToRouting(): void {
  window.location.href = CarrotPlansPaths.ENROLLMENT
}

function goToHomePage(): void {
  window.location.href = Paths.HOME
}

const headerText = defineMessage({ defaultMessage: "Thanks." })
const descriptionText = defineMessage({
  defaultMessage:
    "You can now explore Carrot or tell us a little more about yourself to get a free, personalized Carrot Plan and unlock your full benefit."
})

export function RegistrationComplete({ employeeId }: { employeeId: number }): JSX.Element {
  const intl = useIntl()

  return (
    <>
      <RegistrationFlowTitle title={intl.formatMessage(headerText)} />
      <RegistrationFlowLayout
        header={intl.formatMessage(headerText)}
        imageName="illo-underwater-dashboard"
        description={<Typography>{intl.formatMessage(descriptionText)}</Typography>}
        showLocaleSelect={false}
        interactiveElements={
          <RegistrationButtonFooter
            exploreBenefitButton={
              <Button onClick={goToHomePage} color="secondary" variant="outlined">
                <FormattedMessage defaultMessage="Explore your benefit" />
              </Button>
            }
            getCarrotPlanButton={
              <Button onClick={continueToRouting} color="primary" variant="contained">
                <FormattedMessage defaultMessage="Get your Carrot Plan" />
              </Button>
            }
          />
        }
        employeeId={employeeId}
      />
    </>
  )
}

export function PartnerRegistrationComplete({
  isInitialCarrotPlanRoutingCompleted,
  employeeId
}: PartnerRegistrationCompleteProps): JSX.Element {
  const intl = useIntl()

  return (
    <>
      <RegistrationFlowTitle title={intl.formatMessage(headerText)} />
      <RegistrationFlowLayout
        header={intl.formatMessage(headerText)}
        imageName="illo-underwater-dashboard"
        description={<Typography>{intl.formatMessage(descriptionText)}</Typography>}
        showLocaleSelect={false}
        interactiveElements={
          isInitialCarrotPlanRoutingCompleted ? (
            <RegistrationButtonFooter
              getCarrotPlanButton={
                <Button onClick={goToHomePage} color="primary" variant="contained">
                  <FormattedMessage defaultMessage="Explore your benefit" />
                </Button>
              }
            />
          ) : (
            <RegistrationButtonFooter
              exploreBenefitButton={
                <Button onClick={goToHomePage} color="secondary" variant="outlined">
                  <FormattedMessage defaultMessage="Explore your benefit" />
                </Button>
              }
              getCarrotPlanButton={
                <Button onClick={continueToRouting} color="primary" variant="contained">
                  <FormattedMessage defaultMessage="Get your Carrot Plan" />
                </Button>
              }
            />
          )
        }
        employeeId={employeeId}
      />
    </>
  )
}
