import { EntrySkeletonType } from "contentful"
import { getContentfulClient } from "#/lib/contentful/contentfulClient"
import { getContentfulLocale } from "#/lib/contentful/utils/contentfulLocaleUtils"
import contentTypes from "../../../utils/ContentTypes"

export async function getQuickLinksEntries(
  locale: string,
  channelId: number,
  parentCompanyId: number = null
): Promise<EntrySkeletonType> {
  if (parentCompanyId == null && channelId == null) {
    return null
  }

  const contentfulClient = getContentfulClient()
  const contentfulLocale = getContentfulLocale(locale)

  if (parentCompanyId != null) {
    const { items } = await contentfulClient.getEntries({
      content_type: contentTypes.QUICK_LINKS,
      include: 10,
      locale: contentfulLocale,
      "fields.parentSegmentId": parentCompanyId.toString()
    })

    if (items.length) return items[0] as unknown as EntrySkeletonType
  }

  if (channelId == null) {
    return null
  }

  const { items } = await contentfulClient.getEntries({
    content_type: contentTypes.QUICK_LINKS,
    include: 10,
    locale: contentfulLocale,
    "fields.channelId": channelId.toString()
  })
  return items[0] as unknown as EntrySkeletonType
}
