import { defineMessage, MessageDescriptor } from "react-intl"

export const journeyStages = [
  { value: "EXPLORING", label: defineMessage({ defaultMessage: "Exploring, trying to learn more" }) },
  { value: "CHOOSING_AGENCY_ATTORNEY", label: defineMessage({ defaultMessage: "Choosing an agency and/or attorney" }) },
  {
    value: "WORKING_WITH_AGENCY_ATTORNEY",
    label: defineMessage({ defaultMessage: "Currently working with an agency and/or attorney" })
  },
  {
    value: "CHOOSING_AGENCY_CLINIC_ATTORNEY",
    label: defineMessage({ defaultMessage: "Choosing a clinic, agency/consultancy, and/or attorney" })
  },
  {
    value: "WORKING_WITH_AGENCY_CLINIC_ATTORNEY",
    label: defineMessage({ defaultMessage: "Currently working with a clinic, agency/consultancy, and/or attorney" })
  },
  { value: "FINAL_STAGES", label: defineMessage({ defaultMessage: "In final stages of the process" }) },
  { value: "GESTATIONAL_PREGNANT", label: defineMessage({ defaultMessage: "The gestational carrier is pregnant" }) },
  { value: "OTHER", label: defineMessage({ defaultMessage: "Other" }) },
  {
    value: "PRESERVATION_READY",
    label: defineMessage({ defaultMessage: "Would like to receive preservation care soon" })
  },
  { value: "PRESERVATION_STARTED", label: defineMessage({ defaultMessage: "Currently receiving preservation care" }) },
  {
    value: "PRESERVATION_RECENTLY_RECEIVED",
    label: defineMessage({ defaultMessage: "Recently received preservation care" })
  },
  {
    value: "MIGHT_WANT_IUI_IVF",
    label: defineMessage({ defaultMessage: "Might want IUI or IVF but curious about other options" })
  },
  { value: "GETTING_READY_START_IUI", label: defineMessage({ defaultMessage: "Getting ready to start IUI" }) },
  { value: "GETTING_READY_START_IVF", label: defineMessage({ defaultMessage: "Getting ready to start IVF" }) },
  { value: "ALREADY_STARTED_IUI_CYCLE", label: defineMessage({ defaultMessage: "Already started IUI cycle" }) },
  { value: "ALREADY_STARTED_IVF_CYCLE", label: defineMessage({ defaultMessage: "Already started IVF cycle" }) },
  { value: "RECENTLY_COMPLETED_IUI_CYCLE", label: defineMessage({ defaultMessage: "Recently completed IUI cycle" }) },
  { value: "RECENTLY_COMPLETED_IVF_CYCLE", label: defineMessage({ defaultMessage: "Recently completed IVF cycle" }) },
  { value: "HAVE_NOT_STARTED", label: defineMessage({ defaultMessage: "Haven't started yet" }) },
  {
    value: "TRY_PREGNANT_LESS_THAN_SIX_MONTHS",
    label: defineMessage({ defaultMessage: "Been trying for less than 6 months" })
  },
  {
    value: "TRY_PREGNANT_SIX_TO_TWELVE_MONTHS",
    label: defineMessage({ defaultMessage: "Been trying for 6 to 12 months" })
  },
  { value: "TRY_PREGNANT_OVER_ONE_YEAR", label: defineMessage({ defaultMessage: "Been trying for over 1 year" }) },
  { value: "PREGNANT_FIRST", label: defineMessage({ defaultMessage: "First trimester (fewer than 14 weeks)" }) },
  { value: "PREGNANT_SECOND", label: defineMessage({ defaultMessage: "Second trimester (14-28 weeks)" }) },
  { value: "PREGNANT_THIRD", label: defineMessage({ defaultMessage: "Third trimester (more than 28 weeks)" }) },
  { value: "POSTPARTUM", label: defineMessage({ defaultMessage: "Postpartum" }) },
  { value: "NOT_SURE_PARENT", label: defineMessage({ defaultMessage: "Not sure whether I want to be a parent" }) },
  {
    value: "LEARNING_FERTILITY",
    label: defineMessage({ defaultMessage: "Want to start learning about my fertility health" })
  },
  {
    value: "EXPLORING_NO_PATH",
    label: defineMessage({ defaultMessage: "Interested in parenthood eventually but haven't chosen a path yet" })
  },
  {
    value: "EXPLORING_MULTIPLE_PATHS",
    label: defineMessage({ defaultMessage: "Started down multiple paths to parenthood" })
  },
  {
    value: "EXPLORING_NEW_OPTIONS",
    label: defineMessage({ defaultMessage: "Previous paths haven't worked out, looking for new options" })
  },
  {
    value: "START_OF_PERI_MENOPAUSE",
    label: defineMessage({ defaultMessage: "Anticipating the start of (peri)menopause" })
  },
  {
    value: "MENOPAUSE_SYMPTOMS_WITH_MENSTRUAL_CYCLES",
    label: defineMessage({
      defaultMessage: "Currently experiencing menopause symptoms but still having menstrual cycles"
    })
  },
  { value: "NO_MENSTRUAL_CYCLES", label: defineMessage({ defaultMessage: "No longer having menstrual cycles" }) },
  {
    value: "EXPERIENCING_LOW_T_SYMPTOMS",
    label: defineMessage({ defaultMessage: "Experiencing low-T-related symptoms" })
  },
  { value: "DIAGNOSED_LOW_T", label: defineMessage({ defaultMessage: "Diagnosed with low T" }) },
  { value: "HORMONE_THERAPY_INTEREST", label: defineMessage({ defaultMessage: "Interested in hormone therapy" }) },
  {
    value: "NON_HORMONAL_SUPPORT_INTEREST",
    label: defineMessage({ defaultMessage: "Interested in non-hormonal support" })
  },
  { value: "ON_HORMONES", label: defineMessage({ defaultMessage: "Currently on hormones" }) },
  { value: "GOING_OFF_HORMONES", label: defineMessage({ defaultMessage: "Stopping/want to stop hormones" }) },
  { value: "OTHER_WITH_USER_INPUT", label: defineMessage({ defaultMessage: "Other" }) },
  { value: "CHILD_LESS_THAN_YEAR", label: defineMessage({ defaultMessage: "Parenting an infant (0–12 months)" }) },
  { value: "CHILD_ONE_TO_TWO_YEARS", label: defineMessage({ defaultMessage: "Parenting a child (1–2 years)" }) },
  { value: "CHILD_THREE_TO_FIVE_YEARS", label: defineMessage({ defaultMessage: "Parenting a child (3–5 years)" }) },
  { value: "CHILD_SIX_TO_EIGHT_YEARS", label: defineMessage({ defaultMessage: "Parenting a child (6–8 years)" }) },
  { value: "CHILD_NINE_TO_TWELVE_YEARS", label: defineMessage({ defaultMessage: "Parenting a child (9–12 years)" }) }
]

export const journeyTypeStageOptions: Record<string, string[]> = {
  ADOPTION: ["EXPLORING", "CHOOSING_AGENCY_ATTORNEY", "WORKING_WITH_AGENCY_ATTORNEY", "FINAL_STAGES"],
  GESTATIONAL: [
    "EXPLORING",
    "CHOOSING_AGENCY_CLINIC_ATTORNEY",
    "WORKING_WITH_AGENCY_CLINIC_ATTORNEY",
    "GESTATIONAL_PREGNANT",
    "OTHER"
  ],
  PRESERVATION: ["EXPLORING", "PRESERVATION_READY", "PRESERVATION_STARTED", "PRESERVATION_RECENTLY_RECEIVED"],
  ASSISTED_REPRODUCTION: [
    "EXPLORING",
    "MIGHT_WANT_IUI_IVF",
    "GETTING_READY_START_IUI",
    "GETTING_READY_START_IVF",
    "ALREADY_STARTED_IUI_CYCLE",
    "ALREADY_STARTED_IVF_CYCLE",
    "RECENTLY_COMPLETED_IUI_CYCLE",
    "RECENTLY_COMPLETED_IVF_CYCLE"
  ],
  TRY_PREGNANT: [
    "HAVE_NOT_STARTED",
    "TRY_PREGNANT_LESS_THAN_SIX_MONTHS",
    "TRY_PREGNANT_SIX_TO_TWELVE_MONTHS",
    "TRY_PREGNANT_OVER_ONE_YEAR"
  ],
  PREGNANT: ["PREGNANT_FIRST", "PREGNANT_SECOND", "PREGNANT_THIRD", "POSTPARTUM"],
  EXPLORING: [
    "NOT_SURE_PARENT",
    "LEARNING_FERTILITY",
    "EXPLORING_NO_PATH",
    "EXPLORING_MULTIPLE_PATHS",
    "EXPLORING_NEW_OPTIONS"
  ],
  MENOPAUSE: [
    "EXPLORING",
    "START_OF_PERI_MENOPAUSE",
    "MENOPAUSE_SYMPTOMS_WITH_MENSTRUAL_CYCLES",
    "NO_MENSTRUAL_CYCLES"
  ],
  LOW_TESTOSTERONE: ["EXPLORING", "EXPERIENCING_LOW_T_SYMPTOMS", "DIAGNOSED_LOW_T"],
  GENDER_AFFIRMING_CARE: [
    "EXPLORING",
    "HORMONE_THERAPY_INTEREST",
    "NON_HORMONAL_SUPPORT_INTEREST",
    "ON_HORMONES",
    "GOING_OFF_HORMONES",
    "OTHER_WITH_USER_INPUT"
  ],
  PARENTING: [
    "CHILD_LESS_THAN_YEAR",
    "CHILD_ONE_TO_TWO_YEARS",
    "CHILD_THREE_TO_FIVE_YEARS",
    "CHILD_SIX_TO_EIGHT_YEARS",
    "CHILD_NINE_TO_TWELVE_YEARS"
  ]
}

export function getLabelForJourneyStage(journeyStage: string): MessageDescriptor {
  const journeyStageOption = journeyStages.find((stage) => stage.value === journeyStage)
  return journeyStageOption?.label
}

export function useOptionsForJourneyType(memberJourney: string): {
  stageOptions: { label: { defaultMessage: string }; value: string }[]
} {
  const journeyStageOptions = journeyTypeStageOptions?.[memberJourney] || []
  const stageOptions = journeyStages.filter((stage) => journeyStageOptions.includes(stage.value))

  return {
    stageOptions
  }
}
