import { FormTextFieldWithCharacterCount } from "#/components/carrot-plans/shared"
import { useWatch } from "@carrotfertility/carotene-core-x"
import React from "react"
import { FormattedMessage, useIntl } from "react-intl"

export function OtherJourneyStageTextField() {
  const intl = useIntl()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })
  const selectedValue = useWatch({ name: "journeyStage" })
  if (selectedValue !== "OTHER_WITH_USER_INPUT") {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="otherJourneyStage"
      helperText={<FormattedMessage defaultMessage="Additional details about your journey" />}
      registerOptions={{ required: requiredText }}
    />
  )
}
