import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import {
  useTelemedicineConsents,
  useTelemedicineConsentsUpdate
} from "#/components/telemedicine/hooks/useTelemedicineConsents"
import { BackIcon, Button, DialogContent, DialogTitle, ForwardIcon, Typography } from "@carrotfertility/carotene-core"
import { DialogActionsBar, DialogCloseButton, Form, FormButton, FormCheckbox } from "@carrotfertility/carotene-core-x"
import React, { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"

export function LegalConsentForTelemedicineStep(): JSX.Element {
  const { send, back } = useStateMachine(null)
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const { data: telemedicineConsentTime } = useTelemedicineConsents()
  const { mutateAsync } = useTelemedicineConsentsUpdate()
  const intl = useIntl()
  return (
    <Form
      onSubmit={() => mutateAsync(null, { onSuccess: () => send("") })}
      defaultValues={{ telemedicineConsent: Boolean(telemedicineConsentTime?.telemedicineConsentTimeUtc) }}
    >
      <DialogCloseButton onClick={() => setOpenDialogFlow(false)} />
      <DialogTitle>
        <FormattedMessage defaultMessage="Consent for telemedicine services" />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage defaultMessage="I understand that if I have given anyone my personal credentials to app.get-carrot.com, then that person may be able to view my sensitive health information within the Wheel Health platform. This information may include, but is not limited to, chat history with care providers, medications and prescription information, diagnosis, medical history, saved credit card, HSA, FSA, or other insurance information." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="I acknowledge and understand the potential risks associated with sharing my sensitive health information with anyone I have previously given my app.get-carrot.com credentials to. I accept these risks and consent to proceed." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="I acknowledge that I may be responsible for out-of-pocket costs not covered by my health insurance." />
        </Typography>
        <FormCheckbox
          name="telemedicineConsent"
          label={intl.formatMessage({ defaultMessage: "Yes, I give my consent" })}
          controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
        />
      </DialogContent>
      <DialogActionsBar
        primaryAction={
          <FormButton type="submit" color="secondary" variant="outlined" endIcon={<ForwardIcon />}>
            <FormattedMessage defaultMessage="Continue" />
          </FormButton>
        }
        tertiaryAction={
          <Button color="secondary" onClick={back} variant="text" startIcon={<BackIcon />}>
            <FormattedMessage defaultMessage="Back" />
          </Button>
        }
      />
    </Form>
  )
}
