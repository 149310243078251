import React from "react"
import { Box, Button, LaptopIcon, Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader } from "#/features/global-ui/page-header"
import { FormattedMessage, useIntl } from "react-intl"
import { Title } from "#/services/page-title/Title"
import { TtcPaths } from "#/utils/Paths"
import { Link as RouterLink } from "react-router-dom"

export function TelemedicineUnauthorizedPage(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <PageLayout
      variant="default"
      header={
        <PageHeader
          startGlyph={<LaptopIcon fontSize="large" />}
          pageTitle={<FormattedMessage defaultMessage="Carrot virtual menopause clinic" />}
        />
      }
    >
      <Title title={intl.formatMessage({ defaultMessage: "Carrot virtual menopause clinic" })} />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Stack
          spacing={theme.spacing(theme.tokens.spacing.md)}
          sx={{
            maxInlineSize: "25rem",
            paddingBlockStart: theme.spacing(theme.tokens.spacing.xl),
            alignItems: "center"
          }}
        >
          <Typography variant="h4">
            <FormattedMessage defaultMessage="We're sorry, but you are not eligible to use Carrot's virtual menopause clinic." />
          </Typography>
          <Typography>
            <FormattedMessage
              defaultMessage="Questions? <link>Contact us</link>"
              values={{
                link: (linkContent) => (
                  <Link color="inherit" component={RouterLink} to={TtcPaths.TTC_CARE_NAV}>
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Typography>
          <Button variant="outlined" color="secondary" component={RouterLink} to="/">
            <FormattedMessage defaultMessage="Go to Carrot home" />
          </Button>
        </Stack>
      </Box>
    </PageLayout>
  )
}
