import React, { FC } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Button, Card } from "@carrotfertility/carotene-core"

type ConnectInsuranceInfoModuleProps = {
  onClickEnroll: () => void
}

const ConnectInsuranceInfoModule: FC<ConnectInsuranceInfoModuleProps> = ({ onClickEnroll }) => {
  const { formatMessage } = useIntl()
  return (
    <Card
      sx={{
        // In the future, we should use the provided theme colors
        backgroundColor: "#eee"
      }}
      title={formatMessage({
        defaultMessage: "Connect to your health insurance plan and you may get more money back"
      })}
      primaryAction={
        <Button variant="contained" color="secondary" onClick={onClickEnroll}>
          <FormattedMessage defaultMessage="Add insurance info" />
        </Button>
      }
    />
  )
}

export { ConnectInsuranceInfoModule }
