import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import React, { useEffect } from "react"
// eslint-disable-next-line no-restricted-imports
import { Container, FlexContainer } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Typography } from "@carrotfertility/carotene-core"
import { Spacer } from "../../../views/atoms/Atoms"
import { IlloUnderwaterPlants } from "../../../views/atoms/Illustrations"
import { BenefitEnrollmentModalContinueButton } from "../../shared"
import { FormattedMessage } from "react-intl"
import { useProgressTracker } from "../../../views/modal/ProgressTracker"

export function SkipPartnerConnectInsurance(): JSX.Element {
  const { setNextStep } = useStateMachine(Steps.SKIP_PARTNER_CONNECT_INSURANCE, null)

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  function onClickContinue(): void {
    setNextStep(Steps.SKIP_PARTNER_CONNECT_INSURANCE, "")
  }

  return (
    <div>
      <Spacer height={3} />
      <FlexContainer justifyContent="center" padding="none" stack="large">
        <IlloUnderwaterPlants />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="You've skipped connecting to their insurance provider" />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="We'll take it from here and will let you know if we have any more questions about their insurance." />
        </Typography>
      </Container>
      <BenefitEnrollmentModalContinueButton onClick={onClickContinue} />
    </div>
  )
}
