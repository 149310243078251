import React from "react"
import { TypeContainerSkeleton } from "#/types/contentful"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"
import { Box, Dialog, DialogContent, DialogContentText, Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { ContentfulContentRenderer } from "#/services/contentful/content-renderer"
import { DialogCloseButton } from "@carrotfertility/carotene-core-x"

export type EnhancedEmptyStateProps = {
  displayName: MessageDescriptor
  dialogContent: EntryWithoutUnresolvableLinks<TypeContainerSkeleton>
  useMaxWidth?: boolean
}

export function ProviderEligibilityMessage({
  displayName,
  dialogContent,
  useMaxWidth = false
}: EnhancedEmptyStateProps): JSX.Element {
  const intl = useIntl()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const closeDialog = () => setIsDialogOpen(false)
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      width={useMaxWidth ? "100%" : "363px"}
    >
      <Typography variant="body1" color="secondary">
        <FormattedMessage
          defaultMessage="{providerDisplayName} do not have to appear in Provider finder to be eligible. <link>Learn more about provider eligibility</link>."
          values={{
            providerDisplayName: intl.formatMessage(displayName),
            link: (linkContent) => (
              <Link component="button" onClick={() => setIsDialogOpen(true)} fontSize="inherit" color="inherit">
                {linkContent}
              </Link>
            )
          }}
        />
      </Typography>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogCloseButton onClick={closeDialog} />
        <Typography variant="h2" sx={(theme) => ({ marginBlockEnd: theme.tokens.spacing.xs })}>
          <FormattedMessage defaultMessage="Provider eligibility" />
        </Typography>
        <DialogContent>
          <DialogContentText>
            <ContentfulContentRenderer contents={dialogContent.fields?.content} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
