// eslint-disable-next-line no-restricted-imports
import { FlexContainer, Image } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import React from "react"

export const TalkToCarrotMultilingualImage = (): JSX.Element => {
  return (
    <FlexContainer
      width="100%"
      maxHeight="336px"
      background="lightBlue"
      borderRadius="8px"
      justifyContent="center"
      alignItems="center"
      paddingTopBottom="10%"
    >
      <Image
        src={require(`./resources/illo-benefit-chat-language-selection.png`)}
        srcSet={require(`./resources/illo-benefit-chat-language-selection-2x.png`)}
        altText=""
        width="52%"
        height="74%"
        sx={{ maxInlineSize: "312px", maxBlockSize: "250px" }}
      />
    </FlexContainer>
  )
}
