// eslint-disable-next-line no-restricted-imports
import { Container, ContainerProps, Image } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import React from "react"

export const UnderwaterSeaweed = (props: ContainerProps): JSX.Element => {
  return (
    <Container {...props}>
      <Image
        src={require(`../resources/images/underwater-seaweed.png`)}
        srcSet={require(`../resources/images/underwater-seaweed-2x.png`)}
        altText=""
        width={"128px"}
        height={"72px"}
        sx={{
          transform: "scaleX(-1)"
        }}
      />
    </Container>
  )
}
export const UnderwaterPlants = (props: ContainerProps): JSX.Element => {
  return (
    <Container {...props}>
      <Image
        src={require(`../resources/images/underwater-plants.png`)}
        srcSet={require(`../resources/images/underwater-plants-2x.png`)}
        altText=""
        width={"156px"}
        height={"96px"}
        sx={{
          transform: "scaleX(-1)"
        }}
      />
    </Container>
  )
}
export const UnderwaterPlantsRight = (props: ContainerProps): JSX.Element => {
  return (
    <Container {...props}>
      <Image
        src={require(`../resources/images/underwater-plants-right.png`)}
        srcSet={require(`../resources/images/underwater-plants-right-2x.png`)}
        altText=""
        width={"188px"}
        height={"96px"}
        sx={{
          transform: "scaleX(-1)"
        }}
      />
    </Container>
  )
}

export const Person = (props: ContainerProps): JSX.Element => {
  return (
    <Container {...props}>
      <Image
        src={require(`../resources/images/person_right.png`)}
        altText=""
        width={"225px"}
        height={"480px"}
        sx={{
          "[dir='rtl'] &": {
            transform: "scaleX(-1)"
          }
        }}
      />
    </Container>
  )
}
