import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export const journeysWhereNextStepNotAnythingElse = [
  "ADOPTION",
  "GESTATIONAL",
  "MENOPAUSE",
  "LOW_TESTOSTERONE",
  "GENDER_AFFIRMING_CARE",
  "PRESERVATION"
]

export default function MostHelpFulPathwayFindingProviderDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()

  const isNextStepAnythingElse = (journeyValue: string): boolean =>
    !journeysWhereNextStepNotAnythingElse.includes(journeyValue)

  const nextStepValue = isNextStepAnythingElse(routingData?.journey) ? "YES" : "NO"

  const { send } = useStateMachine()
  useEffect(() => {
    send(nextStepValue)
  })
  return <></>
}
