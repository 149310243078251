import React, { FC, ReactNode } from "react"
import { Spacer } from "../Atoms"
// eslint-disable-next-line no-restricted-imports
import { Container, FlexContainer, Stack } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { useMediaQuery, useTheme } from "@carrotfertility/carotene-core"

type TwoColumnRightPrimaryProps = {
  secondaryContent: ReactNode
  primaryContent: ReactNode
  bottomContent?: ReactNode
}

const TwoColumnRightPrimary: FC<TwoColumnRightPrimaryProps> = ({
  secondaryContent,
  primaryContent,
  bottomContent = null
}) => {
  const theme = useTheme()
  const showDesktopContent = useMediaQuery(theme.breakpoints.up("lg"))

  const desktopContent = (
    <>
      <Container width="calc(100% / 3)" maxWidth="320px" minWidth="232px">
        {secondaryContent}
      </Container>
      <Spacer width={3} />
      <Container width="calc(100% / 1.5)" maxWidth="600px" minWidth="384px">
        {primaryContent}
      </Container>
    </>
  )

  const mobileContent = (
    <Container width="100%">
      <Stack spacing={["large", null, "huge"]}>
        {secondaryContent}
        {primaryContent}
      </Stack>
    </Container>
  )

  const responsiveContent = showDesktopContent ? desktopContent : mobileContent

  return (
    <>
      <FlexContainer>{responsiveContent}</FlexContainer>
      {bottomContent && (
        <>
          <Spacer height={3} expand />
          {bottomContent}
        </>
      )}
    </>
  )
}

export default TwoColumnRightPrimary
