import { HasPartnerDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/HasPartnerDecisionStep"
import { WillYouBeReceivingTheCareStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WillYouBeReceivingTheCareStep"
import { CloseWithoutAMatchStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CloseWithoutAMatchStep"

import { IsPartnerAccountSignedInDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/IsPartnerAccountSignedInDecisionStep"
import {
  WhoWillBeReceivingTheCareStepForMember,
  WhoWillBeReceivingTheCareStepForPartner
} from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WhoWillBeReceivingTheCareStep"
import { IsUserEnteringFromProviderFinderDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/IsUserEnteringFromProviderFinderDecisionStep"
import { WhereIsXyzReceivingCareStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WhereIsXyzSeekingCareStep"
import { TellUsAboutYourPartnerStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/TellUsAboutYourPartnerStep"
import { WhatTreatmentTypeIsXYZLookingForStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WhatTreatmentTypeIsXYZLookingForStep"
import { WhatMattersToXYZStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WhatMattersToXYZStep"
import { WasSpecialServicesSelectedDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WasSpecialServicesSelectedDecisionStep"
import { WhatSpecialServicesXYZLookingForStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WhatSpecialServicesXYZLookingForStep"
import { ClinicSpecializationStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/ClinicSpecializationStep"
import { WhatElseMattersToXYZStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WhatElseMattersToXyzStep"
import { ResultStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/ResultStep"
import { HasResultsDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/HasResultsDecisionStep"
import { OneLastThingStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/OneLastThingStep"
import { NoResultsStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/NoResultsStep"
import { CloseResultStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CloseResultStep"
import { ShowOneLastStepDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/ShowOneLastStepDecisionStep"

export enum Steps {
  CLOSE_WITHOUT_A_MATCH = "Close without a match?",
  HAS_PARTNER_DECISION = "Has Partner",
  WILL_MEMBER_BE_RECEIVING_CARE = "Will member be receiving care?",
  TELL_US_ABOUT_YOUR_PARTNER = "Tell us about your partner",
  IS_PARTNER_ACCOUNT_SIGNED_IN_DECISION = "Is partner account signed in",
  WHO_WILL_BE_RECEIVING_THE_CARE_FOR_MEMBER = "Who will be receiving the care for member",
  WHO_WILL_BE_RECEIVING_THE_CARE_FOR_PARTNER = "Who will be receiving the care for partner",
  IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION = "Is user entering from Provider Finder",
  WHERE_IS_XYZ_RECEIVING_CARE = "Where is XYZ Receiving Care?",
  WHAT_TREATMENT_TYPE_IS_XYZ_LOOKING_FOR = "What treatment type is XYZ looking for?",
  WHAT_MATTERS_TO_XYZ = "What matters to XYZ?",
  WAS_SPECIAL_SERVICES_SELECTED_DECISION = "Was special services selected",
  WHAT_SPECIAL_SERVICES_XYZ_LOOKING_FOR = "What special services is XYZ looking for?",
  CLINIC_SPECIALIZATION = "XYZ interested in clinics that have experience in the following?",
  WHAT_ELSE_MATTERS_TO_XYZ = "What else matters to XYZ?",
  RESULT_STEP = "Result step",
  HAS_RESULTS_DECISION = "Has results decision",
  SHOW_ONE_LAST_THING_DECISION = "Show One last thing decision",
  ONE_LAST_THING = "One last thing",
  CLOSE_RESULT_STEP = "Close result step",
  NO_RESULTS_STEP = "No results step"
}

export enum Workflows {
  CARROT_MATCH = "CarrotMatch"
}

// prettier-ignore
export const carrotMatchSteps = [
  { name: Steps.CLOSE_WITHOUT_A_MATCH, component: CloseWithoutAMatchStep },
  { name: Steps.HAS_PARTNER_DECISION, component: HasPartnerDecisionStep, decisionStep: true },
  { name: Steps.TELL_US_ABOUT_YOUR_PARTNER, component: TellUsAboutYourPartnerStep },
  { name: Steps.WILL_MEMBER_BE_RECEIVING_CARE,  component: WillYouBeReceivingTheCareStep },
  { name: Steps.IS_PARTNER_ACCOUNT_SIGNED_IN_DECISION, component: IsPartnerAccountSignedInDecisionStep, decisionStep: true },
  { name: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_MEMBER, component: WhoWillBeReceivingTheCareStepForMember },
  { name: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_PARTNER, component: WhoWillBeReceivingTheCareStepForPartner },
  { name: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION, component: IsUserEnteringFromProviderFinderDecisionStep, decisionStep: true},
  { name: Steps.WHERE_IS_XYZ_RECEIVING_CARE, component: WhereIsXyzReceivingCareStep},
  { name: Steps.WHAT_TREATMENT_TYPE_IS_XYZ_LOOKING_FOR, component: WhatTreatmentTypeIsXYZLookingForStep},
  { name: Steps.WHAT_MATTERS_TO_XYZ, component: WhatMattersToXYZStep},
  { name: Steps.WAS_SPECIAL_SERVICES_SELECTED_DECISION, component: WasSpecialServicesSelectedDecisionStep, decisionStep: true },
  { name: Steps.WHAT_SPECIAL_SERVICES_XYZ_LOOKING_FOR, component: WhatSpecialServicesXYZLookingForStep},
  { name: Steps.CLINIC_SPECIALIZATION, component: ClinicSpecializationStep},
  { name: Steps.WHAT_ELSE_MATTERS_TO_XYZ, component: WhatElseMattersToXYZStep},
  { name: Steps.RESULT_STEP, component: ResultStep},
  { name: Steps.HAS_RESULTS_DECISION, component: HasResultsDecisionStep},
  { name: Steps.SHOW_ONE_LAST_THING_DECISION, component: ShowOneLastStepDecisionStep},
  { name: Steps.ONE_LAST_THING, component: OneLastThingStep},
  { name: Steps.CLOSE_RESULT_STEP, component: CloseResultStep},
  { name: Steps.NO_RESULTS_STEP, component: NoResultsStep}
]

// prettier-ignore
const carrotMatchWorkflow = [
  { at: Steps.HAS_PARTNER_DECISION,                                         given: "YES",             goto: Steps.IS_PARTNER_ACCOUNT_SIGNED_IN_DECISION },
  { at: Steps.HAS_PARTNER_DECISION,                                         given: "NO",              goto: Steps.WILL_MEMBER_BE_RECEIVING_CARE },
  { at: Steps.WILL_MEMBER_BE_RECEIVING_CARE,                                given: "YES",             goto: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION },
  { at: Steps.WILL_MEMBER_BE_RECEIVING_CARE,                                given: "NO",              goto: Steps.TELL_US_ABOUT_YOUR_PARTNER },
  { at: Steps.WILL_MEMBER_BE_RECEIVING_CARE,                                given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH },
  { at: Steps.TELL_US_ABOUT_YOUR_PARTNER,                                   given: "",                goto: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION },
  { at: Steps.TELL_US_ABOUT_YOUR_PARTNER,                                   given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH },   
  { at: Steps.IS_PARTNER_ACCOUNT_SIGNED_IN_DECISION,                        given: "YES",             goto: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_PARTNER},
  { at: Steps.IS_PARTNER_ACCOUNT_SIGNED_IN_DECISION,                        given: "NO",              goto: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_MEMBER},
  { at: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_MEMBER,                    given: "",                goto: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION},
  { at: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_MEMBER,                    given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH },
  { at: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_PARTNER,                   given: "",                goto: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION},
  { at: Steps.WHO_WILL_BE_RECEIVING_THE_CARE_FOR_PARTNER,                   given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH },
  { at: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION,               given: "YES",             goto: Steps.WHAT_TREATMENT_TYPE_IS_XYZ_LOOKING_FOR},
  { at: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION,               given: "NO",              goto: Steps.WHERE_IS_XYZ_RECEIVING_CARE},
  { at: Steps.WHERE_IS_XYZ_RECEIVING_CARE,                                  given: "",                goto: Steps.WHAT_TREATMENT_TYPE_IS_XYZ_LOOKING_FOR},
  { at: Steps.WHERE_IS_XYZ_RECEIVING_CARE,                                  given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH},
  { at: Steps.WHAT_TREATMENT_TYPE_IS_XYZ_LOOKING_FOR,                       given: "",                goto: Steps.WHAT_MATTERS_TO_XYZ},
  { at: Steps.WHAT_TREATMENT_TYPE_IS_XYZ_LOOKING_FOR,                       given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH},
  { at: Steps.WHAT_MATTERS_TO_XYZ,                                          given: "",                goto: Steps.WAS_SPECIAL_SERVICES_SELECTED_DECISION},
  { at: Steps.WHAT_MATTERS_TO_XYZ,                                          given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH},
  { at: Steps.WAS_SPECIAL_SERVICES_SELECTED_DECISION,                       given: "YES",             goto: Steps.WHAT_SPECIAL_SERVICES_XYZ_LOOKING_FOR},
  { at: Steps.WAS_SPECIAL_SERVICES_SELECTED_DECISION,                       given: "NO",              goto: Steps.CLINIC_SPECIALIZATION},
  { at: Steps.WHAT_SPECIAL_SERVICES_XYZ_LOOKING_FOR,                        given: "",                goto: Steps.CLINIC_SPECIALIZATION},
  { at: Steps.WHAT_SPECIAL_SERVICES_XYZ_LOOKING_FOR,                        given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH},
  { at: Steps.CLINIC_SPECIALIZATION,                                        given: "",                goto: Steps.WHAT_ELSE_MATTERS_TO_XYZ},
  { at: Steps.CLINIC_SPECIALIZATION,                                        given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH},
  { at: Steps.WHAT_ELSE_MATTERS_TO_XYZ,                                     given: "",                goto: Steps.HAS_RESULTS_DECISION},
  { at: Steps.WHAT_ELSE_MATTERS_TO_XYZ,                                     given: "CLOSE",           goto: Steps.CLOSE_WITHOUT_A_MATCH},
  { at: Steps.HAS_RESULTS_DECISION,                                         given: "YES",             goto: Steps.SHOW_ONE_LAST_THING_DECISION},
  { at: Steps.HAS_RESULTS_DECISION,                                         given: "NO",              goto: Steps.NO_RESULTS_STEP},
  { at: Steps.SHOW_ONE_LAST_THING_DECISION,                                 given: "YES",             goto: Steps.ONE_LAST_THING},
  { at: Steps.SHOW_ONE_LAST_THING_DECISION,                                 given: "NO",              goto: Steps.RESULT_STEP},
  { at: Steps.NO_RESULTS_STEP,                                              given: "",                goto: Steps.IS_USER_ENTERING_FROM_PROVIDER_FINDER_DECISION},
  { at: Steps.ONE_LAST_THING,                                               given: "",                goto: Steps.RESULT_STEP},
  { at: Steps.RESULT_STEP,                                                  given: "",                goto: Steps.CLOSE_RESULT_STEP},
  { at: Steps.RESULT_STEP,                                                  given: "CLOSE",           goto: Steps.CLOSE_RESULT_STEP},
]

export const carrotMatchWorkflows = [{ name: Workflows.CARROT_MATCH, workflow: carrotMatchWorkflow }]
