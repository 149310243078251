import React from "react"
// eslint-disable-next-line no-restricted-imports
import { Container, FlexContainer, Stack, Text } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { CloseIcon, IconButton, useTheme } from "@carrotfertility/carotene-core"
import { MultiPathSVG } from "#/components/views/SVG"
import AttachmentStates from "#/utils/AttachmentStates"
import { AttachmentType } from "#/components/get-reimbursed/steps/UploadFile"
import { FormattedMessage, useIntl } from "react-intl"
import Settings from "../../../../utils/CarrotConfig"

interface AttachmentHelperTextProps {
  attachmentState: AttachmentStates
  handleRetryClick: () => void
  fileName: string
}

interface UploadFileProps {
  attachment: AttachmentType
  onRetry: (attachmentGuid: string) => void
  onDelete: (attachmentGuid: string) => void
}

const isAttachmentError = (attachment: AttachmentType): boolean =>
  [
    AttachmentStates.OVERSIZED,
    AttachmentStates.FAILED,
    AttachmentStates.INVALIDTYPE,
    AttachmentStates.VIRUSDETECTED
  ].includes(attachment.state)

const FailedStateLabel = ({ handleRetryClick }: Pick<AttachmentHelperTextProps, "handleRetryClick">) => (
  <Stack direction="row" spacing="tiny">
    <FormattedMessage defaultMessage="upload failed" />
    <a style={{ color: "inherit" }} href="#/" onClick={handleRetryClick}>
      <FormattedMessage defaultMessage="RETRY?" />
    </a>
  </Stack>
)

const UploadingLabel = () => (
  <>
    <MultiPathSVG className="mr2" iconName="icn-uploading-animated" />
    <FormattedMessage defaultMessage="uploading" />
  </>
)

function AttachmentHelperText(props: AttachmentHelperTextProps) {
  const fileType = /\.\w+$/.exec(props.fileName)
  const maxFileSize = Settings.MAX_FILE_SIZE / 1000000
  switch (props?.attachmentState) {
    case AttachmentStates.UPLOADING:
      return <UploadingLabel />
    case AttachmentStates.COMPLETED:
      return <FormattedMessage defaultMessage="upload complete" />
    case AttachmentStates.FAILED:
      return <FailedStateLabel handleRetryClick={props?.handleRetryClick} />
    case AttachmentStates.OVERSIZED:
      return (
        <FormattedMessage defaultMessage="FILE TOO LARGE. MAX FILE SIZE IS {maxFileSize} MB" values={{ maxFileSize }} />
      )
    case AttachmentStates.INVALIDTYPE:
      return (
        <FormattedMessage defaultMessage="Invalid file type {fileType} files are not supported" values={{ fileType }} />
      )
    case AttachmentStates.VIRUSDETECTED:
      return <FormattedMessage defaultMessage="upload failed - corrupt file" />
    default:
      return <></>
  }
}

const UploadFile = ({ attachment, onRetry, onDelete }: UploadFileProps): JSX.Element => {
  const theme = useTheme()
  const { file } = attachment
  const attachmentError = isAttachmentError(attachment)
  const intl = useIntl()

  const handleDeleteClick = React.useCallback(() => {
    onDelete(attachment.guid)
  }, [attachment.guid, onDelete])

  const handleRetryClick = React.useCallback(() => {
    onRetry(attachment.guid)
  }, [attachment.guid, onRetry])

  return (
    <Container>
      <FlexContainer alignItems="center" justifyContent="space-between">
        <Text
          as="span"
          title={file?.name}
          weight="semibold"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {file?.name}
        </Text>
        <div className="flex-none pl3 pointer black-40">
          <IconButton
            size="small"
            color="secondary"
            aria-label={intl.formatMessage({ defaultMessage: "Remove upload" })}
            onClick={handleDeleteClick}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </FlexContainer>
      <Text style={{ color: attachmentError ? theme.palette.error.main : theme.palette.text.disabled }} size="small">
        <AttachmentHelperText
          attachmentState={attachment?.state}
          handleRetryClick={handleRetryClick}
          fileName={file?.name}
        />
      </Text>
    </Container>
  )
}

export { UploadFile }
