import { MultiFactorAuth } from "#/components/multi-factor-auth"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import { CarrotErrorCodes, getErrorMessageFromCode } from "#/utils/CarrotErrors"
import React from "react"
import { useDispatch } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { useAuthentication } from "#/components/context/authentication/AuthenticationProvider"
import { signIn } from "#/redux/actions/loginActions"
import { useIntl } from "react-intl"
import { carrotClient } from "#/utils/CarrotClient"
import { HttpErrors } from "#/utils/HttpErrors"
import Helpers from "#/utils/Helpers"
import queryString from "query-string"
import { dayjs } from "@carrotfertility/carotene-core"
import { setRememberDevice } from "#/utils/rememberDeviceStorage"

export type MultiFactorAuthPayload = { code: string; rememberDevice: boolean }

type MultiFactorAuthState = {
  onSubmit: (data: MultiFactorAuthPayload) => void
  newCodeSent: boolean
  serverError: string | null
  showConnectionError: boolean
  onSendNewCode: () => void
}

export const MultiFactorAuthContext = React.createContext<MultiFactorAuthState>({
  onSubmit: () => Promise.resolve(),
  newCodeSent: false,
  serverError: null,
  showConnectionError: false,
  onSendNewCode: () => Promise.resolve()
})

function useLoginMutation(): UseMutationResult<void, Error, MultiFactorAuthPayload> {
  return useMutation(
    (payload: MultiFactorAuthPayload): Promise<void> => carrotClient.multiFactorAuthValidateCode(payload)
  )
}

function useNewCodeMutation(): UseMutationResult<void, any> {
  return useMutation(() => carrotClient.sendNewMultiFactorAuthCode())
}

const MultiFactorAuthContainer = () => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { resetExplicitLogout } = useAuthentication()
  const login = useLoginMutation()
  const sendNewCode = useNewCodeMutation()
  const [newCodeSent, setNewCodeSent] = React.useState<boolean>(false)
  const [serverError, setServerError] = React.useState<string>(null)
  const [showConnectionError, setShowConnectionError] = React.useState<boolean>(false)
  const { email } = queryString.parse(location.search)

  const onLoginSuccess = (rememberDevice: boolean) => {
    if (rememberDevice) {
      const currentTimestampUtc = dayjs().toISOString()
      setRememberDevice(email?.toString(), currentTimestampUtc)
    }

    dispatch(signIn())
    resetExplicitLogout()
    history.push("/")
  }

  const redirectIfNotValidUser = async (error: any) => {
    if ([HttpErrors.UNAUTHORIZED, HttpErrors.FORBIDDEN].includes(error.name)) {
      await carrotClient.logout()
      Helpers.browserRedirect("/")
    }
  }

  const onLoginError = async (error: any) => {
    await redirectIfNotValidUser(error)

    if (error.response) {
      const { code } = await error.response.json()
      if ([CarrotErrorCodes.INCORRECT_CODE, CarrotErrorCodes.EXPIRED_CODE].includes(code)) {
        const errorMessage = getErrorMessageFromCode(code)
        setServerError(intl.formatMessage(errorMessage))
      } else {
        setShowConnectionError(true)
      }
    } else {
      // default error message to catch rate limiting errors. These errors show as a Fetch Error and not a 429 status code
      setShowConnectionError(true)
    }
  }

  const onSubmit = async (data: MultiFactorAuthPayload) => {
    setServerError(null)
    setShowConnectionError(false)
    setNewCodeSent(false)
    login.mutate(
      { code: data.code, rememberDevice: data.rememberDevice },
      {
        onSuccess: () => onLoginSuccess(data.rememberDevice),
        onError: (error) => {
          onLoginError(error)
        }
      }
    )
  }

  const onSendNewCode = () => {
    setServerError(null)
    setShowConnectionError(false)
    setNewCodeSent(false)
    sendNewCode.mutate(
      {},
      {
        onSuccess: () => setNewCodeSent(true),
        onError: async (error) => {
          await redirectIfNotValidUser(error)
          setShowConnectionError(true)
        }
      }
    )
  }

  return (
    <MultiFactorAuthContext.Provider
      value={{
        onSubmit,
        newCodeSent,
        serverError,
        showConnectionError,
        onSendNewCode
      }}
    >
      <MultiFactorAuth />
    </MultiFactorAuthContext.Provider>
  )
}

export default MultiFactorAuthContainer
