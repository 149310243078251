export enum CarrotCardProvider {
  STRIPE = "STRIPE",
  AIRWALLEX = "AIRWALLEX"
}

export type ReimbursementDetails = {
  insuranceSync: InsuranceSync
  companyDeductibleStatus: string
  cmdConfiguration: string
  deductible: Deductible
  partnerDeductible: Deductible
  hasAca: boolean
  isMemberHraEligible?: boolean
  isPartnerHraEligible?: boolean
  carrotCardState: string
  knownTradingPartnerOutages: string[]
  companySupportsOOPM: boolean
  carrotCardProvider: CarrotCardProvider
  carrotCardCardId: string
  scaSetupCompletedDateTime: Date
  restrictionPeriodLockDate: Date
  restrictionPeriodUnlockDate: Date
}

export type InsuranceSync = {
  memberInformation: string
  tradingPartners: {
    availableTradingPartners: TradingPartner[]
  }
}

export type TradingPartner = {
  id: string
  description: string
  tradingPartnerPk: number
}

export type Deductible = {
  displayDeductibleBreakdown: boolean
  deductibleSnapshot: DeductibleSnapshot
  sourceName: string
}

export type DeductibleSnapshot = {
  totalAmount: number
  amountAppliedToDeductible: number
  carrotAmountAppliedToDeductible: number
  externalAmountAppliedToDeductible: number
  amountRemaining: number
  planStartDate: string
  planEndDate: string
}
