// eslint-disable-next-line no-restricted-imports
import { Icon, shadows, colors } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import React from "react"
// eslint-disable-next-line no-restricted-imports -- FIXME: Usages of styled-components should be replaced with components from carotene-core or carotene-core-x. Please do not import styled-components after eslint rule added on 3/21/2025.
import styled from "styled-components"

const StyledButton = styled.button`
  background: white;
  border: 2px solid transparent;
  border-radius: 2px;
  display: block;
  inline-size: 40px;
  block-size: 40px;
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  background-origin: border-box;
  box-shadow: ${shadows.light};
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
  margin: 24px 10px;
`

const StyledIcon = styled(Icon)`
  ${StyledButton}:hover & {
    fill: ${colors.base.black80};
  }
`

export function RecenterButton({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <StyledButton onClick={onClick} id="provider-finder-recenter-map-button">
      <StyledIcon name="recenter" size="24px" color="black60" />
    </StyledButton>
  )
}
