import React from "react"
// eslint-disable-next-line no-restricted-imports
import { FlexContainer } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { useReadLearnLinks } from "./shared/ReadLearnLinks"
import { EntryWithoutUnresolvableLinks, isVideo, ReadLearnArticleEntry } from "../../utils/ContentTypes"
import { contentTypeMapping } from "./shared/ContentTypeConstants"
import { useIntl } from "react-intl"
import { Box, Link, Typography, useTheme } from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"
import { TypeCategorySkeleton } from "#/types/contentful"

type ArticleThumbnailProps = {
  contentType: string
  article: ReadLearnArticleEntry
  category: EntryWithoutUnresolvableLinks<TypeCategorySkeleton>
}

function ThumbnailImage({ article }: { article: ReadLearnArticleEntry }): JSX.Element {
  const thumbnailUrl = article.fields?.thumbnail?.fields?.file?.url
  const theme = useTheme()
  return isVideo(article) ? (
    <Box display="flex" alignItems={"center"} justifyContent="center">
      <img
        src={thumbnailUrl}
        height="85px"
        width="85px"
        style={{
          borderRadius: theme.borderRadius(theme.tokens.borderRadius.md),
          position: "relative",
          maxWidth: "85px"
        }}
        alt=""
      />
      <img
        height="40px"
        width="40px"
        src="/images/icon-video-play-button.svg"
        style={{
          position: "absolute"
        }}
        alt=""
      />
    </Box>
  ) : (
    <img
      src={thumbnailUrl}
      height="85px"
      width="85px"
      style={{ borderRadius: theme.borderRadius(theme.tokens.borderRadius.md), position: "relative", maxWidth: "85px" }}
      alt=""
    />
  )
}

export default function ArticleThumbnail({ contentType, article, category }: ArticleThumbnailProps): JSX.Element {
  const { articleLink } = useReadLearnLinks({ category, article })
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Link to={articleLink} color="inherit" component={ReactRouterLink} sx={{ textDecoration: "none" }}>
      <FlexContainer gap={"16px"}>
        <ThumbnailImage {...{ article }} />
        <FlexContainer direction={"column"}>
          <Typography variant="overline" color={theme.palette.text.tertiary}>
            {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {intl.formatMessage(contentTypeMapping[contentType]?.name)}
          </Typography>
          <Typography variant="h5">{article?.fields?.title}</Typography>
        </FlexContainer>
      </FlexContainer>
    </Link>
  )
}
