import { useMutation } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import { dayjs } from "@carrotfertility/carotene-core"
import { getHeap } from "#/utils/heap"
import { Paths } from "#/utils/Paths"
import { RegistrationJourney } from "../RegistrationContext"
import { useTrackExperimentEvent } from "../hooks/useExperiments"

interface CreateUserParams {
  guid: string
  password: string
  emailOptIn: boolean
  agreeToTerms: boolean
  agreeToPolicy: boolean
  agreeToHealthData: boolean
  personalEmail: string
  preferredName: string
  registrationJourney: RegistrationJourney
  phoneNumber: string
  phoneNumberCountryCode: string
  allowsMarketingTexts: boolean
  // eslint-disable-next-line no-restricted-syntax -- We include the user's preferredLocale in the request to create their account. Carrot-app's LocaleService will then determine if they can use the selected language once authenticated.
  preferredLocale: string
}

export const useCreateUser = () => {
  const { trackExperimentEvent } = useTrackExperimentEvent()

  return useMutation({
    mutationFn: async (params: CreateUserParams) => {
      await carrotClient.createAccount({
        ...params,
        localTime: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS")
      })

      getHeap().track("MemberRegistrationCompleted", {
        EventName: "MemberRegistrationCompleted"
      })
    },
    onSuccess: async () => {
      await trackExperimentEvent("registration-complete")
    },
    onError: () => {
      window.location.href = Paths.HOME
    }
  })
}
