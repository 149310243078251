import React, { useEffect } from "react"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"
import { useRoutingFlow } from "../../shared/RoutingFlowContainer"
import { waitForMinimumDelay } from "../../../../utils/WaitForMinimumDelay"
import useCarrotPlanEmployeeSettings, {
  setMemberRequestedEmailForInitialCarrotPlanChat
} from "../../hooks/useCarrotPlanEmployeeSettings"
import {
  CaseRateRenewalsStatus,
  useGetCaseRateRenewalStatus,
  useRequestNewCaseRateRenewalCarrotPlan
} from "../../case-rate-renewals/useQueryCaseRateRenewals"
import { useRequestJourneyChangeCarrotPlan } from "../../shared/useQueryRoutingInfo"
import { useHistory } from "react-router-dom"

export default function ProcessInitialCarrotPlanChatRequestedStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { setShowProgressTracker } = useRoutingFlow()
  const { data, showAdvancedCheckInFeatures } = useGetCaseRateRenewalStatus()
  const { mutate: requestNewCaseRateRenewalCarrotPlan } = useRequestNewCaseRateRenewalCarrotPlan()
  const { mutate: requestJourneyChangeCarrotPlan } = useRequestJourneyChangeCarrotPlan()
  const { isDoneForNow } = useCarrotPlanEmployeeSettings()
  const history = useHistory()
  const isJourneyChangeFlow = window.location.href.includes("/carrot-plans/journey-change/routing")

  useEffect(() => {
    setShowProgressTracker(false)
    if (data === CaseRateRenewalsStatus.GATED || isDoneForNow || showAdvancedCheckInFeatures) {
      history.push("/carrot-plans/case-rate-renewal/routing/?refreshRequest=true")
      requestNewCaseRateRenewalCarrotPlan(null, { onSuccess: () => send("") })
    } else if (isJourneyChangeFlow) {
      history.push("/carrot-plans/journey-change/routing")
      requestJourneyChangeCarrotPlan(null, { onSuccess: () => send("") })
    } else {
      waitForMinimumDelay(setMemberRequestedEmailForInitialCarrotPlanChat(), 1000).then(() => send(""))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return (
    <>
      <Container padding="large" stack="giant">
        <CenteredLoadingIndicator data-testid="carrot-plan-loader" />
      </Container>
    </>
  )
}
