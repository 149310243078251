import { useQuery, UseQueryResult } from "@tanstack/react-query"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import ResponseError from "../types/ResponseError"
import { useProviderFinderClient } from "./useProviderFinderClient"
import { Provider } from "#/pages/provider-finder/types/Providers"
import { ProviderFinderParam } from "#/pages/provider-finder/utils/providerFinderSearchUrl"

const GET_PROVIDER_BY_PROVIDER_UUIDS_QUERY_KEY = "GetProvidersByProviderUuids"

export default function useProvidersByProviderUuids({
  providerUuids,
  lat,
  long
}: {
  providerUuids: number[]
  lat: number
  long: number
}): UseQueryResult<Provider[], ResponseError> {
  const client = useProviderFinderClient()

  const params = new URLSearchParams()
  params.append(ProviderFinderParam.Latitude, lat.toString())
  params.append(ProviderFinderParam.Longitude, long.toString())

  const providerUuidsString = encodeURIComponent(providerUuids.join(","))

  return useQuery<Provider[], ResponseError>(
    [GET_PROVIDER_BY_PROVIDER_UUIDS_QUERY_KEY, providerUuids, lat, long],
    async () => {
      if (!providerUuids || !lat || !long) return []
      const response = await client.getProvidersByProviderUuids(providerUuidsString, params)
      return response || undefined
    },
    {
      refetchOnWindowFocus: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
