import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Progress } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"
import { WhatMatters } from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

export function WasSpecialServicesSelectedDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { carrotMatchInteraction } = useCarrotMatch()
  const answerToWhatMatters = carrotMatchInteraction.questionsAndAnswers.find(
    // TODO: replace the question string with a constant
    (value) => value.question === "What matters to XYZ?"
  ).answer
  const wasSpecialServicesSelected = answerToWhatMatters.includes(WhatMatters.SPECIAL_SERVICES_AT_THE_CLINIC)

  useEffect(() => {
    if (wasSpecialServicesSelected) {
      send("YES")
    } else {
      send("NO")
    }
  }, [wasSpecialServicesSelected, send])

  return <Progress />
}
