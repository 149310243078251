import React from "react"
import { NotSmall, Small } from "./Responsive"
// eslint-disable-next-line no-restricted-imports
import { H1, H2 } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25

/**
 * @deprecated Use `Typography` from `@carrotfertility/carotene-core` instead.
 */
// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const SwitchH1ToH2 = ({ children }) => (
  <>
    <NotSmall>
      <H1>{children}</H1>
    </NotSmall>
    <Small>
      <H2>{children}</H2>
    </Small>
  </>
)

export { SwitchH1ToH2 }
