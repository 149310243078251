import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { Progress } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"

export function HasPartnerDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { partnerFirstName, partnerPreferredName } = useCurrentUser()
  const { updateCarrotMatchInteraction } = useCarrotMatch()

  useEffect(() => {
    updateCarrotMatchInteraction({ startedAtUtc: new Date().toISOString() })
    if (partnerFirstName || partnerPreferredName) {
      send("YES")
    } else {
      send("NO")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [send, partnerFirstName, partnerPreferredName])

  return <Progress />
}
