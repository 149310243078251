import { ButtonFooterLayout, ContinueButton, RoutingFlowTitle, StepLayout } from "#/components/carrot-plans/shared"
import { useUpdateRoutingInfo } from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import React, { useCallback } from "react"
import { useIntl, FormattedMessage } from "react-intl"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { FormRadioButtonGroup } from "#/services/common-forms/form-radio-button-group"
import { ChangeJourneyButton } from "#/components/carrot-plans/shared/ChangeJourneyButtton"
import { getLabelForJourneyStage } from "#/components/carrot-plans/hooks/useJourneyStageOptions"
import { useGetRegistrationJourney } from "#/components/carrot-plans/hooks/useGetRegistrationJourney"
import { getStateMachineValue } from "#/components/carrot-plans/steps/journey/WhereAreYouInJourneyStep"

function lowercaseFirstLetter(string: string, locale: string) {
  if (typeof string !== "string" || string.length === 0) {
    return string
  }
  return string.charAt(0).toLocaleLowerCase(locale) + string.slice(1)
}

/**
 * This step is for members who added a journey stage during registration
 */
export default function AreYouStillInThisPhaseStep() {
  const intl = useIntl()
  const theme = useTheme()
  const registrationJourneyResult = useGetRegistrationJourney()
  const registrationJourneyStage = registrationJourneyResult?.data?.journeyStage
  const registrationOtherJourneyStage = registrationJourneyResult?.data?.otherJourneyStage
  const { send } = useStateMachine()
  const updateRoutingInfo = useUpdateRoutingInfo()

  const yesNoOptions = [
    { label: intl.formatMessage({ defaultMessage: "Yes" }), value: "YES" },
    { label: intl.formatMessage({ defaultMessage: "No, I've moved on" }), value: "NO" }
  ]

  const setRoutingJourneyStage = useCallback(async (): Promise<void> => {
    await updateRoutingInfo.mutateAsync(
      { journeyStage: registrationJourneyStage, otherJourneyStage: registrationOtherJourneyStage },
      {
        onSuccess: () => {
          send(getStateMachineValue(registrationJourneyStage))
        }
      }
    )
  }, [send, updateRoutingInfo, registrationJourneyStage, registrationOtherJourneyStage])

  async function onClickContinue(formValues: { journeyStageCheckIn: "YES" | "NO" }): Promise<void> {
    if (formValues.journeyStageCheckIn === "YES") {
      await setRoutingJourneyStage()
    } else {
      send("NO")
    }
  }

  const formattedJourneyStage = registrationJourneyStage
    ? intl.formatMessage(getLabelForJourneyStage(registrationJourneyStage))
    : ""

  const title = intl.formatMessage(
    {
      defaultMessage: "Are you still in the “{journeyStage}” phase of your journey?"
    },
    {
      journeyStage: lowercaseFirstLetter(formattedJourneyStage, intl.locale)
    }
  )

  return (
    <>
      <RoutingFlowTitle title={title} />
      <StepLayout
        header={<span id="areYouStillInThisPhase-label">{title}</span>}
        description={null}
        formElements={
          <>
            <ChangeJourneyButton />
            <Form onSubmit={onClickContinue}>
              <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <FormRadioButtonGroup
                  name="journeyStageCheckIn"
                  options={yesNoOptions}
                  controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
                  aria-labelledby="areYouStillInThisPhase-label"
                />
              </Stack>
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Form>
          </>
        }
      />
    </>
  )
}
