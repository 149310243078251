import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import { getAccountDeletionPath } from "#/components/talk-to-carrot/util/talkToCarrotMolecules"
import { Paths } from "#/utils/Paths"
import { Box, Button, Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { usePrivacyNoticeUrl } from "#/utils/hooks/legal-notice-urls"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader } from "#/features/global-ui/page-header"

export function AccountDeletionPage(): JSX.Element {
  const privacyNoticeUrl = usePrivacyNoticeUrl()
  return (
    <PageLayout header={<PageHeader pageTitle={<FormattedMessage defaultMessage="About account deletion" />} />}>
      <Box display="flex" flexDirection="column" gap={(theme) => theme.spacing(theme.tokens.spacing.md)}>
        <Typography>
          <FormattedMessage defaultMessage="Once you send a request, we'll reach out within 30 business days to let you know that your deletion is complete or to provide an update if it might take longer to fulfill." />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="While your account will be closed, certain data may be retained to support legitimate business interests and/or legal obligations." />
        </Typography>
        <Typography>
          <FormattedMessage
            defaultMessage="For more information about how we use and protect your personal information, please visit our <link>Privacy Notice</link>."
            values={{
              link: (linkContent) => (
                <Link target="_blank" href={privacyNoticeUrl}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
        justifyContent="flex-end"
        marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}
      >
        <Button variant="outlined" color="secondary" component={ReactRouterLink} to="/account">
          <FormattedMessage defaultMessage="Back" />
        </Button>
        <Button
          data-testid="continue-to-account-deletion"
          component={ReactRouterLink}
          to={getAccountDeletionPath({ baseUrl: Paths.TALK_TO_CARROT })}
        >
          <FormattedMessage defaultMessage="Continue to request deletion" />
        </Button>
      </Box>
    </PageLayout>
  )
}
