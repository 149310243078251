import React from "react"
import { Box, Button, BackIcon } from "@carrotfertility/carotene-core"
import { Steps } from "../steps/journey/step-library"
import { FormattedMessage } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"

export function ChangeJourneyButton() {
  const { backToStep } = useStateMachine()
  return (
    <Box
      justifyContent={"flex"}
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.sm)}
      marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
    >
      <Button
        onClick={() => backToStep(Steps.WHAT_KIND_OF_JOURNEY)}
        variant="text"
        color="secondary"
        startIcon={<BackIcon />}
        sx={{
          paddingInlineStart: (theme) => theme.spacing(theme.tokens.spacing.md),
          paddingInlineEnd: (theme) => theme.spacing(theme.tokens.spacing.md),
          paddingBlockStart: (theme) => theme.spacing(theme.tokens.spacing.xs),
          paddingBlockEnd: (theme) => theme.spacing(theme.tokens.spacing.xs)
        }}
      >
        <FormattedMessage defaultMessage="Change my journey" />
      </Button>
    </Box>
  )
}
