import { useStateMachine } from "#/components/context/stateMachine/StateMachineContext"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import React, { useContext, useEffect } from "react"
import { IlloUnderwaterPlants } from "../../../../views/atoms/Illustrations"
// eslint-disable-next-line no-restricted-imports
import { Container, FlexContainer, Stack } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Button, Typography, Box } from "@carrotfertility/carotene-core"
import { SendMessageFormContext } from "../../../../talk-to-carrot/send-message/SendMessage"
import { useFormContext } from "react-hook-form"
import { getHeap } from "../../../../../utils/heap"
import { useEnrollmentFlowModal } from "../../../EnrollmentModal"
import { FormattedMessage, useIntl } from "react-intl"
import { MessageSent } from "../../MessageSent"

export function PartnerConnectFailure(): JSX.Element {
  const { sent, employeeEmail, personalEmail } = useContext(SendMessageFormContext)
  const { register, setValue } = useFormContext()
  const { setNextStep } = useStateMachine(Steps.PARTNER_PROCESS_CONNECT_INSURANCE_FAIL, null)
  const email = personalEmail ?? employeeEmail

  function onClickContinue(): void {
    setNextStep(Steps.PARTNER_PROCESS_CONNECT_INSURANCE_FAIL, "")
  }

  useEffect(() => {
    setValue("defaultMessage", "An error occurred when this member tried connecting to their partner's health plan")
    setValue("email", email)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return sent ? (
    <MessageSent onClick={onClickContinue} />
  ) : (
    <div>
      <Box height={(theme) => theme.tokens.spacing.xxxl} />
      <FlexContainer justifyContent="center" padding="none" stack="huge">
        <IlloUnderwaterPlants />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="An unknown error occurred while trying to connect to their health insurance provider." />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="You can try again or let us know about the issue so that we can help resolve it for you." />
        </Typography>
      </Container>
      <input hidden {...register("email")} />
      <input hidden {...register("defaultMessage")} />
      <ButtonBar restartStep={Steps.PARTNER_INFO_CONNECT_INSURANCE} />
    </div>
  )
}

export function MemberConnectFailure(): JSX.Element {
  const { sent, employeeEmail, personalEmail } = useContext(SendMessageFormContext)
  const { register, setValue } = useFormContext()
  const { setNextStep } = useStateMachine(Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL, null)
  const email = personalEmail ?? employeeEmail

  function onClickContinue(): void {
    setNextStep(Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL, "")
  }

  useEffect(() => {
    setValue("defaultMessage", "An error occurred when this member tried connecting to their health plan")
    setValue("email", email)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return sent ? (
    <MessageSent onClick={onClickContinue} />
  ) : (
    <div>
      <Box height={(theme) => theme.tokens.spacing.xxxl} />
      <FlexContainer justifyContent="center" padding="none" stack="huge">
        <IlloUnderwaterPlants />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="An unknown error occurred while trying to connect to your health insurance provider." />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="You can try again or let us know about the issue so that we can help resolve it for you." />
        </Typography>
      </Container>
      <input hidden {...register("email")} />
      <input hidden {...register("defaultMessage")} />
      <ButtonBar restartStep={Steps.MEMBER_INFO_CONNECT_INSURANCE} />
    </div>
  )
}

export function MemberConnectKnownOutage(): JSX.Element {
  const { onExit } = useEnrollmentFlowModal()
  const { sent, employeeEmail, personalEmail } = useContext(SendMessageFormContext)
  const { register, setValue } = useFormContext()
  const { setNextStep } = useStateMachine(Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL, null)
  const email = personalEmail ?? employeeEmail

  function onClickContinue(): void {
    setNextStep(Steps.MEMBER_PROCESS_CONNECT_INSURANCE_FAIL, "")
  }

  useEffect(() => {
    setValue("defaultMessage", "An error occurred when this member tried connecting to their health plan")
    setValue("email", email)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  return sent ? (
    <MessageSent onClick={onClickContinue} />
  ) : (
    <div>
      <Box height={(theme) => theme.tokens.spacing.xxxl} />
      <FlexContainer justifyContent="center" padding="none" stack="huge">
        <IlloUnderwaterPlants />
      </FlexContainer>
      <Typography
        id="step-heading"
        variant="h2"
        color={(theme) => theme.palette.text.primary}
        paddingBottom={(theme) => theme.tokens.spacing.xs}
      >
        <FormattedMessage defaultMessage="We can't connect right now." />
      </Typography>
      <Container padding="none" stack="huge">
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="Your health insurance provider is unable to send us information about your plan. This issue usually resolves within 1–2 weeks, so please try again later." />
        </Typography>
        <br />
        <Typography color={(theme) => theme.palette.text.secondary}>
          <FormattedMessage defaultMessage="In the meantime, you can still submit reimbursement requests to Carrot. If you’ve made any payments toward your health insurance deductible that haven’t been credited to your Carrot account, upload a copy of your explanation of benefits (EOB) with your request." />
        </Typography>
      </Container>
      <input hidden {...register("email")} />
      <input hidden {...register("defaultMessage")} />
      <ButtonBarHome onExit={onExit} />
    </div>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'restartStep' implicitly has an 'a... Remove this comment to see the full error message
const ButtonBar = ({ restartStep }) => {
  const { goBackToSpecificStep } = useStateMachine()
  const { onSubmit } = useContext(SendMessageFormContext)
  const {
    formState: { isSubmitting }
  } = useFormContext()
  const intl = useIntl()

  const sendSupportRequest = (): void => {
    onSubmit()
    getHeap().track("EnrollmentFlowSupportRequest", { EventName: "Error occurred connecting to insurance" })
  }

  return (
    <Stack alignItems="center" spacing="small" direction={["column", null, "row"]} justifyContent="center">
      <Button variant="contained" color="primary" onClick={(): void => goBackToSpecificStep(restartStep)}>
        <FormattedMessage defaultMessage="Try connecting again" />
      </Button>
      <Button variant="outlined" color="secondary" onClick={sendSupportRequest}>
        {isSubmitting
          ? intl.formatMessage({ defaultMessage: "Sending ... ", description: "sending message in progress" })
          : intl.formatMessage({ defaultMessage: "Send us a message" })}
      </Button>
    </Stack>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'onExit' implicitly has an 'any' t... Remove this comment to see the full error message
const ButtonBarHome = ({ onExit }) => {
  const { onSubmit } = useContext(SendMessageFormContext)
  const intl = useIntl()

  const sendSupportRequest = (): void => {
    onSubmit()
    getHeap().track("EnrollmentFlowSupportRequest", { EventName: "Error occurred connecting to insurance" })
  }

  return (
    <Stack alignItems="center" spacing="small" direction={["column", null, "row"]} justifyContent="center">
      <Button variant="contained" color="primary" onClick={onExit}>
        <FormattedMessage defaultMessage="Return home" />
      </Button>
      <Button variant="outlined" color="secondary" onClick={sendSupportRequest}>
        {intl.formatMessage({ defaultMessage: "Send us a message" })}
      </Button>
    </Stack>
  )
}
