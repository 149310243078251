import React from "react"
import { useTrackView } from "#/utils/heap"
import { FormattedMessage, useIntl } from "react-intl"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { Link, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { RegistrationButtonFooter, ContinueButton } from "./RegistrationButtons"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { PiiText } from "#/services/tracking"

interface PersonalEmailVerificationProps {
  personalEmail: string
  onComplete: () => void
  employeeId: number
}

export function PersonalEmailVerification({
  personalEmail,
  onComplete,
  employeeId
}: PersonalEmailVerificationProps): JSX.Element {
  useTrackView(
    "PersonalEmailVerificationEmailSentRegFlow",
    "Reg flow 'One last thing...' notice to verify personal email"
  )
  const theme = useTheme()
  const intl = useIntl()
  const header = intl.formatMessage({ defaultMessage: "One last thing..." })

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={header}
        imageName="illo-paper-airplane-mail-slot"
        description={
          <Stack
            spacing={theme.spacing(theme.tokens.spacing.md)}
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
          >
            <Typography>
              <FormattedMessage
                defaultMessage="We sent a confirmation email to: {email}"
                values={{
                  email: (
                    <>
                      <br />
                      <Typography component={PiiText} fontWeight="bold">
                        {personalEmail}
                      </Typography>
                    </>
                  )
                }}
              />
            </Typography>
            <Typography>
              <FormattedMessage defaultMessage="Please check your inbox and click the verification link to enable us to communicate with you at this address." />
            </Typography>

            <Typography>
              <FormattedMessage
                defaultMessage="Want to edit the address above? Need us to resend the email? You can do both in <link>Account settings</link>."
                values={{
                  link: (text) => <Link href="/account#email-addresses">{text}</Link>
                }}
              />
            </Typography>
          </Stack>
        }
        interactiveElements={
          <RegistrationButtonFooter
            continueButton={
              <ContinueButton onClick={onComplete}>
                <FormattedMessage defaultMessage="I’ll verify my email later" />
              </ContinueButton>
            }
          />
        }
        employeeId={employeeId}
      />
    </>
  )
}
