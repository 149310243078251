import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Form } from "@carrotfertility/carotene-core-x"
import {
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { FormattedMessage, useIntl } from "react-intl"
import {
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle,
  StepLayout,
  useConvertTranslatedLabel
} from "#/components/carrot-plans/shared"
import { Stack, useTheme, Typography } from "@carrotfertility/carotene-core"
import { getShortJourneyMessage, isJourneySupported, useEnabledJourneyOptions } from "../../hooks/useJourneyOptions"
import useGetMemberBenefit from "../../hooks/useGetMemberBenefit"
import { CenteredLoadingIndicator } from "../../../views/molecules/Molecules"
import { FormRadioButtonGroup } from "#/services/common-forms"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { useOptionsForJourneyType } from "#/components/carrot-plans/hooks/useJourneyStageOptions"
import { ChangeJourneyButton } from "#/components/carrot-plans/shared/ChangeJourneyButtton"
import { OtherJourneyStageTextField } from "#/components/carrot-plans/shared/OtherJourneyStageTextField"

export function getStateMachineValue(selectedValue: string | null) {
  const valueIsPreservation = ["PRESERVATION_STARTED", "PRESERVATION_READY", "PRESERVATION_RECENTLY_RECEIVED"].includes(
    selectedValue
  )

  if (valueIsPreservation) {
    return selectedValue
  }

  return selectedValue ? "" : null
}

export function useGetHeadingCopy({
  memberBenefit,
  journey,
  numberOfChildren,
  journeyStageCheckInPostRegistration
}: {
  memberBenefit: Benefit
  journey: string
  numberOfChildren: string
  journeyStageCheckInPostRegistration?: boolean
}) {
  const intl = useIntl()
  const { journeyOptions } = useEnabledJourneyOptions(memberBenefit)
  const formattedJourneyMessage = isJourneySupported({ journey, options: journeyOptions })
    ? intl.formatMessage(getShortJourneyMessage(journey))
    : ""

  if (journeyStageCheckInPostRegistration) {
    return {
      header: (
        <span id="journeyStage-label">
          <FormattedMessage defaultMessage="Where are you in this journey now?" />
        </span>
      ),
      supportingCopy: null
    }
  }

  if (journey === "PARENTING" && numberOfChildren === "MULTIPLE_CHILDREN") {
    return {
      header: (
        <span id="journeyStage-label">
          <FormattedMessage defaultMessage="Please select the age range you’d like the most support for." />
        </span>
      ),
      supportingCopy: (
        <Typography>
          <FormattedMessage defaultMessage="Carrot Experts will use this information to personalize your initial Carrot Plan. You’ll still have access to all available resources for all of the age ranges below." />
        </Typography>
      )
    }
  }

  return {
    header: (
      <>
        <span id="personalizedJourneyMessage-label">{formattedJourneyMessage} </span>
        <span id="journeyStage-label">
          <FormattedMessage defaultMessage="Where are you in this journey?" />
        </span>
      </>
    ),
    supportingCopy: null
  }
}

function WhereAreYouInJourneyView({ memberBenefit }: { memberBenefit: Benefit }) {
  const intl = useIntl()
  const theme = useTheme()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.journeyStage
  const { send } = useStateMachine(getStateMachineValue(defaultValue))
  const { mutateAsync } = useUpdateRoutingInfo()
  const { stageOptions } = useOptionsForJourneyType(routingData?.journey)
  const radioOptions = useConvertTranslatedLabel(stageOptions).map(({ label, value }) => {
    return {
      label,
      value
    }
  })

  const { header, supportingCopy } = useGetHeadingCopy({
    memberBenefit,
    journey: routingData?.journey,
    numberOfChildren: routingData?.numberOfChildren
  })

  async function onClickContinue(formValues: { journeyStage: string; otherJourneyStage: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send(getStateMachineValue(formValues.journeyStage)) })
  }

  const title = intl.formatMessage({ defaultMessage: "Where are you in this journey?" })

  return (
    <>
      <RoutingFlowTitle title={title} />
      <StepLayout
        header={header}
        description={supportingCopy}
        formElements={
          <>
            <ChangeJourneyButton />
            <Form
              defaultValues={{ journeyStage: defaultValue, otherJourneyStage: routingData?.otherJourneyStage }}
              onSubmit={onClickContinue}
            >
              <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <FormRadioButtonGroup
                  name="journeyStage"
                  options={radioOptions}
                  controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
                  aria-labelledby="journeyStage-label"
                />
                <OtherJourneyStageTextField />
              </Stack>
              <ButtonFooterLayout
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Form>
          </>
        }
      />
    </>
  )
}

export default function WhereAreYouInJourneyStep(): JSX.Element {
  const { data: memberBenefit, isLoading: isMemberBenefitLoading } = useGetMemberBenefit()

  return isMemberBenefitLoading ? (
    <CenteredLoadingIndicator />
  ) : (
    <WhereAreYouInJourneyView memberBenefit={memberBenefit} />
  )
}
