import { Divider, Stack, useTheme } from "@carrotfertility/carotene-core"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { FormSelect, FormSelectOption, FormTextField, useFormContext, useWatch } from "@carrotfertility/carotene-core-x"
import { FeatureGate } from "#/utils/FeatureGate"
import { useGetSexOptions } from "#/services/common-forms/SexOptions"
import { useGetGenderIdentityOptions } from "#/services/common-forms/GenderIdentityOptions"
import { NoneNullOutOption } from "#/services/common-forms/NoneNullOutOption"

interface GenderIdentitySettingsProps {
  names: { genderIdentity: string; genderIdentityOther: string }
  shouldBeDisabled?: boolean
}

export function MemberGenderIdentitySettings({
  names,
  shouldBeDisabled = false
}: GenderIdentitySettingsProps): JSX.Element {
  const genderIdentityOptions = useGetGenderIdentityOptions()

  return (
    <GenderIdentitySettings
      names={names}
      shouldBeDisabled={shouldBeDisabled}
      genderIdentityOptions={genderIdentityOptions}
    />
  )
}

export function PartnerGenderIdentitySettings({
  names,
  shouldBeDisabled = false
}: GenderIdentitySettingsProps): JSX.Element {
  const genderIdentityOptions = useGetGenderIdentityOptions()
  genderIdentityOptions.unshift(NoneNullOutOption())

  return (
    <GenderIdentitySettings
      names={names}
      shouldBeDisabled={shouldBeDisabled}
      genderIdentityOptions={genderIdentityOptions}
    />
  )
}

function GenderIdentitySettings({
  names,
  shouldBeDisabled = false,
  genderIdentityOptions
}: GenderIdentitySettingsProps & { genderIdentityOptions: FormSelectOption[] }): JSX.Element {
  const genderIdentityValue = useWatch({ name: names.genderIdentity })
  const { setValue } = useFormContext()
  const theme = useTheme()
  const intl = useIntl()

  useEffect(() => {
    if (genderIdentityValue !== "ANOTHER_GENDER_IDENTITY") {
      setValue(names.genderIdentityOther, "nullOut")
    } else {
      setValue(names.genderIdentityOther, "")
    }
  }, [genderIdentityValue, names.genderIdentityOther, setValue])

  return (
    <>
      <FeatureGate filter={(c) => c.isLgtbqEnabled}>
        <FormSelect
          disabled={shouldBeDisabled}
          label={intl.formatMessage({ defaultMessage: "Gender Identity" })}
          name={names.genderIdentity}
          options={genderIdentityOptions}
        />
      </FeatureGate>
      {genderIdentityValue === "ANOTHER_GENDER_IDENTITY" ? (
        <Stack display="flex" direction="row" spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Divider orientation="vertical" flexItem />
          <FormTextField
            disabled={shouldBeDisabled}
            name={names.genderIdentityOther}
            placeholder={intl.formatMessage({ defaultMessage: "Optional details" })}
            label={intl.formatMessage({ defaultMessage: "Other" })}
            inputProps={{ maxLength: 200 }}
          />
        </Stack>
      ) : null}
    </>
  )
}

interface SexSettingsProps {
  name: string
  shouldBeDisabled?: boolean
}

export function MemberSexSettings({ name, shouldBeDisabled = false }: SexSettingsProps): JSX.Element {
  const sexOptions = useGetSexOptions()
  return <SexSettings name={name} shouldBeDisabled={shouldBeDisabled} sexOptions={sexOptions} />
}

export function PartnerSexSettings({ name, shouldBeDisabled = false }: SexSettingsProps): JSX.Element {
  const sexOptions = useGetSexOptions()
  sexOptions.unshift(NoneNullOutOption())

  return <SexSettings name={name} shouldBeDisabled={shouldBeDisabled} sexOptions={sexOptions} />
}

function SexSettings({
  name,
  shouldBeDisabled = false,
  sexOptions
}: SexSettingsProps & { sexOptions: FormSelectOption[] }): JSX.Element {
  const intl = useIntl()

  return (
    <FormSelect
      disabled={shouldBeDisabled}
      {...{ name }}
      label={intl.formatMessage({ defaultMessage: "Sex" })}
      options={sexOptions}
    />
  )
}
