import { CountryCode, CountryCodes } from "#/utils/CountryCode"
import { useIntlSort } from "#/utils/hooks/useIntlSort"
import { IntlShape, useIntl } from "react-intl"
import { getCountryCallingCode, isValidPhoneNumber } from "libphonenumber-js"

export const usePhoneCodeSelectOptions = () => {
  const { localeComparator } = useIntlSort()
  const intl = useIntl()

  const mappedCountryCodes = CountryCodes.map((countryCode) => {
    const formattedCountryCode = intl.formatDisplayName(countryCode, { type: "region" })
    const countryCallingCode = getCountryCallingCode(countryCode)
    return {
      value: countryCode,
      label: intl.formatMessage(
        { defaultMessage: "{countryCode} (+{phoneCode})" },
        { countryCode: formattedCountryCode, phoneCode: countryCallingCode }
      )
    }
  })
  return addCountryCodeEdgeCases(mappedCountryCodes, intl).sort((a, b) => localeComparator(a.label, b.label))
}

// WQ-2818: This code is a workaround until our phone library adds AQ & UM
// WQ-2897: This handles the edge case of an unknown country (countryCode "XX") by setting to an empty string (but not accepting this on submit)
function addCountryCodeEdgeCases(countryCodes: { value: CountryCode; label: any }[], intl: IntlShape) {
  countryCodes.push({
    value: "AQ" as CountryCode,
    label: intl.formatMessage(
      { defaultMessage: "{countryCode} (+{phoneCode})" },
      { countryCode: "Antarctica", phoneCode: "672" }
    )
  })
  countryCodes.push({
    value: "UM" as CountryCode,
    label: intl.formatMessage(
      { defaultMessage: "{countryCode} (+{phoneCode})" },
      { countryCode: "U.S. Outlying Islands", phoneCode: "1" }
    )
  })
  countryCodes.push({
    value: "XX" as CountryCode,
    label: ""
  })
  return countryCodes
}

// WQ-2818: This code is a workaround until our phone library adds AQ & UM
export function addCountryOptionsEdgeCases(countryOptions: { label: string; value: string }[], intl: IntlShape) {
  const aqLabel = new Intl.DisplayNames([intl.locale], { type: "region" }).of("AQ")
  countryOptions.push({
    value: "AQ" as CountryCode,
    label: aqLabel
  })
  const umLabel = new Intl.DisplayNames([intl.locale], { type: "region" }).of("UM")
  countryOptions.push({
    value: "UM" as CountryCode,
    label: umLabel
  })
  return countryOptions
}

// WQ-2818: This code is a workaround until our phone library adds AQ & UM
// WQ-2897: This handles the edge case of an unknown country (countryCode "XX") by leaving calling code blank
export function getCountryCallingCodeConsideringEdgeCases(countryCode: CountryCode) {
  let countryCallingCode
  if (countryCode === ("AQ" as CountryCode)) {
    countryCallingCode = "672"
  } else if (countryCode === ("UM" as CountryCode)) {
    countryCallingCode = "1"
  } else if (countryCode === ("XX" as CountryCode)) {
    countryCallingCode = null
  } else {
    countryCallingCode = getCountryCallingCode(countryCode)
  }
  return countryCallingCode
}

// WQ-2818: This code is a workaround until our phone library adds AQ & UM
// This is currently only used in our "booking experience"
// The logic is intentionally simple for these edge cases & based on Twilio validation from phone number collection
export function isValidPhoneNumberConsideringEdgeCases(phoneNumber: string, countryCode: CountryCode) {
  if (countryCode === ("AQ" as CountryCode)) {
    const phoneNumberCleaned = phoneNumber.replace(/[^a-zA-Z0-9]/g, "")
    return !(isNaN(Number(phoneNumberCleaned)) || phoneNumber.length !== 6)
  } else if (countryCode === ("UM" as CountryCode)) {
    const phoneNumberCleaned = phoneNumber.replace(/[^a-zA-Z0-9]/g, "")
    return !(isNaN(Number(phoneNumberCleaned)) || phoneNumber.length !== 10)
  } else {
    return isValidPhoneNumber(phoneNumber, countryCode)
  }
}
