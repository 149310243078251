import React from "react"
import useInsuranceDetails from "../context/useInsuranceDetails"
import InsurancePlanInformationTable from "./plan-information-table"
import { useCurrentUser } from "#/components/context/user/UserContext"
import InsuranceExpired from "./InsuranceExpired"
import NoInsurance from "./NoInsurance"
import { useToggle } from "#/utils/Hooks"
import { EraseYourAnswersModal } from "#/components/cmd-enrollment/EraseYourAnswersModal"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import {
  Box,
  Typography,
  UnorderedList,
  UnorderedListItem,
  Stack,
  Link,
  IconButton,
  InfoIcon,
  Dialog,
  CloseIcon,
  DialogTitle,
  DialogContentText,
  DialogContent,
  useTheme,
  InternalIcon
} from "@carrotfertility/carotene-core"

// @ts-expect-error TS(7031) FIXME: Binding element 'onExit' implicitly has an 'any' t... Remove this comment to see the full error message
const QleExplanationModal = ({ onExit }): JSX.Element => {
  const listOptions = [
    defineMessage({ defaultMessage: "Loss of coverage" }),
    defineMessage({ defaultMessage: "Household changes, such as getting married or gaining a dependent" }),
    defineMessage({ defaultMessage: "Changes in residence" }),
    defineMessage({ defaultMessage: "Other qualifying changes", description: "final list option" })
  ]
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Dialog aria-labelledby="qle-modal-title" onClose={onExit} open={true}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton aria-label={intl.formatMessage({ defaultMessage: "Close" })} onClick={onExit}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Stack spacing={theme.spacing(theme.tokens.spacing.xs)}>
        <DialogTitle
          id="qle-modal-title"
          component="h2"
          variant="h2"
          paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <FormattedMessage defaultMessage="Qualifying life event" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage defaultMessage="A qualifying life event (QLE) allows you to enroll in health insurance outside the open enrollment period. QLEs include:" />
          </DialogContentText>
          <UnorderedList>
            {listOptions.map((textItem, index) => (
              <UnorderedListItem key={index}>{intl.formatMessage(textItem)}</UnorderedListItem>
            ))}
          </UnorderedList>
        </DialogContent>
      </Stack>
    </Dialog>
  )
}

const QleResetMessage = (): JSX.Element => {
  const [showEraseAnswersModal, toggleEraseAnswersModal] = useToggle(false)
  const [showQleExplanationModal, toggleQleExplanationModal] = useToggle(false)
  const intl = useIntl()

  return (
    <Typography>
      <FormattedMessage
        defaultMessage="Your insurance coverage impacts taxes and deductibles that may apply to your Carrot funds.  
        If anything has changed due to a qualifying life event<tooltip></tooltip>, you can reset your answers. <link> Reset 
        your answers</link>"
        values={{
          tooltip: () => (
            <IconButton
              aria-label={intl.formatMessage({ defaultMessage: "Learn more about qualifying life events" })}
              onClick={toggleQleExplanationModal}
              color="secondary"
              size="small"
            >
              <InfoIcon />
            </IconButton>
          ),
          link: (linkContent) => (
            <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
              <Link
                color="inherit"
                endIcon={<InternalIcon fontSize="small" color="primary" />}
                component="button"
                onClick={toggleEraseAnswersModal}
              >
                {linkContent}
              </Link>
            </Box>
          )
        }}
      />

      {showEraseAnswersModal && <EraseYourAnswersModal onExit={toggleEraseAnswersModal} />}
      {showQleExplanationModal && <QleExplanationModal onExit={toggleQleExplanationModal} />}
    </Typography>
  )
}

export const InsuranceCoverageSection = (): JSX.Element => {
  const userInfo = useCurrentUser()
  const { needsBenefitEnrollment, insurancePlanDetailsData, insuranceExpired, memberHistory, hasInsurance } =
    useInsuranceDetails()
  const userHasPartner =
    insuranceExpired && memberHistory ? memberHistory.memberHasLegalPartner : userInfo.memberHasLegalPartner

  const isEnrolled = !needsBenefitEnrollment

  return (
    <>
      <Stack marginY={(theme) => theme.spacing(theme.tokens.spacing.xl)}>
        <Typography variant="h2">
          <FormattedMessage defaultMessage="Insurance coverage" />
        </Typography>
      </Stack>
      {!hasInsurance && <NoInsurance />}
      {insuranceExpired && <InsuranceExpired />}
      {isEnrolled && <QleResetMessage />}
      <InsurancePlanInformationTable
        hasPartner={userHasPartner}
        planData={insurancePlanDetailsData}
        insuranceExpired={insuranceExpired}
      />
    </>
  )
}
