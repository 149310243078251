import { DbgPaths } from "../../../../../utils/Paths"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Link, UnorderedListItem } from "@carrotfertility/carotene-core"
import { CategorySections } from "../PayingForCareModal"

const FertilityAndPreservationHeader = (): JSX.Element => {
  return (
    <p>
      <FormattedMessage defaultMessage="This category includes eligible expenses for the following journeys:" />
    </p>
  )
}

const FertilityAndPreservationListItems = (): JSX.Element => {
  return (
    <>
      <UnorderedListItem>
        <FormattedMessage
          defaultMessage="<link>Fertility care</link>"
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={DbgPaths.FERTILITY_COVERAGE}>
                {linkContent}
              </Link>
            )
          }}
        />
      </UnorderedListItem>
      <UnorderedListItem>
        <FormattedMessage
          defaultMessage="<link>Fertility preservation</link>"
          values={{
            link: (linkContent) => (
              <Link target="_blank" href={DbgPaths.PRESERVATION_COVERAGE}>
                {linkContent}
              </Link>
            )
          }}
        />
      </UnorderedListItem>
    </>
  )
}

export function getFertilityAndPreservationSections(): CategorySections {
  return {
    header: <FertilityAndPreservationHeader />,
    listItems: <FertilityAndPreservationListItems />
  }
}
