import { useStateMachine, StateMachineContextValues } from "#/components/context/stateMachine/StateMachineContext"
import { Steps } from "#/components/cmd-enrollment/workflow/steps"
import React, { useEffect } from "react"
import {
  FieldErrors,
  FieldValues,
  FormProvider,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReturn
} from "react-hook-form"
import { BenefitEnrollmentFlowModalBackButton, BenefitEnrollmentModalContinueButton } from "../../shared"
import useSubmitWithErrorCatch from "#/components/cmd-enrollment/hooks/useSubmitWithErrorCatch"
import { Form } from "#/components/views/atoms/forms/Form"
// eslint-disable-next-line no-restricted-imports
import { Container } from "@carrotfertility/carotene" // please do not import from @carrotfertility/carotene after eslint rule added on 3/20/25
import { Typography } from "@carrotfertility/carotene-core"
import { CompleteFormInput } from "../../../views/atoms/forms/FormInput"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { useProgressTracker } from "../../../views/modal/ProgressTracker"

type DefaultName = string | null
interface UsePartnerNameFormSetup {
  formMethods: UseFormReturn
  handleSubmit: UseFormHandleSubmit<FieldValues>
  register: UseFormRegister<FieldValues>
  errors: FieldErrors
  defaultFirstName: DefaultName
  defaultLastName: DefaultName
}

interface UseCmdFlowProgress {
  setNextStep: StateMachineContextValues["setNextStep"]
}

interface UsePartnerNameSubmit {
  onSubmit: (...args: any[]) => void
}

const FieldNameFirstName = "firstNameValue"
const FieldNameLastName = "lastNameValue"

function usePartnerNameFormSetup(): UsePartnerNameFormSetup {
  const formMethods = useForm()
  const {
    handleSubmit,
    setValue,
    register,
    formState: { errors }
  } = formMethods
  const { partnerFirstName: defaultFirstName, partnerLastName: defaultLastName } = useCurrentUser()
  useEffect(() => {
    if (defaultFirstName !== null) {
      setValue(FieldNameFirstName, defaultFirstName)
    }
    if (defaultLastName !== null) {
      setValue(FieldNameLastName, defaultLastName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])
  return {
    formMethods,
    handleSubmit,
    register,
    errors,
    defaultFirstName,
    defaultLastName
  }
}

function UseCmdFlowProgress(
  defaultPartnerFirstName: DefaultName,
  defaultPartnerLastName: DefaultName
): UseCmdFlowProgress {
  const shouldSkip = defaultPartnerFirstName && defaultPartnerLastName
  const { setNextStep } = useStateMachine(Steps.PARTNER_FIRST_LAST_NAME_CONNECT, shouldSkip ? "" : null)
  return {
    setNextStep
  }
}

function usePartnerNameSubmit(setNextStepFunc: StateMachineContextValues["setNextStep"]): UsePartnerNameSubmit {
  const { updateUserInfo } = useCurrentUser()
  // @ts-expect-error TS(7006) FIXME: Parameter 'firstName' implicitly has an 'any' type... Remove this comment to see the full error message
  function onUserUpdate(firstName, lastName): any {
    return updateUserInfo({
      partnerFirstName: firstName,
      partnerLastName: lastName
    })
  }
  // @ts-expect-error TS(7006) FIXME: Parameter 'args' implicitly has an 'any' type.
  const { onSubmit } = useSubmitWithErrorCatch(async (args) => {
    const firstNameVal = args[FieldNameFirstName]
    const lastNameVal = args[FieldNameLastName]
    await onUserUpdate(firstNameVal, lastNameVal)
    setNextStepFunc(Steps.PARTNER_FIRST_LAST_NAME_CONNECT, "")
  })
  return {
    onSubmit
  }
}

export default function PartnerFirstLastNameStep(): JSX.Element {
  const { formMethods, handleSubmit, register, errors, defaultFirstName, defaultLastName } = usePartnerNameFormSetup()
  const { setNextStep } = UseCmdFlowProgress(defaultFirstName, defaultLastName)
  const { onSubmit } = usePartnerNameSubmit(setNextStep)
  const intl = useIntl()

  const { showProgress } = useProgressTracker()
  useEffect(showProgress, [showProgress])

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Container padding="none" stack="huge">
          <BenefitEnrollmentFlowModalBackButton />
        </Container>
        <Typography
          id="step-heading"
          variant="h2"
          color={(theme) => theme.palette.text.primary}
          paddingTop={(theme) => theme.tokens.spacing.lg}
        >
          <FormattedMessage defaultMessage="Your partner's name, as stated on their health insurance plan" />
        </Typography>
        <Container padding="none" stack="large">
          <Typography
            id="first-name-label"
            variant="h4"
            color={(theme) => theme.palette.text.primary}
            paddingTop={(theme) => theme.tokens.spacing.xs}
          >
            <FormattedMessage defaultMessage="FIRST NAME" />
          </Typography>
          <CompleteFormInput
            aria-labelledby="first-name-label"
            defaultValue={defaultFirstName}
            errors={errors}
            {...register(FieldNameFirstName, { required: intl.formatMessage({ defaultMessage: "Required" }) })}
          />
        </Container>
        <Container padding="none" stack="large">
          <Typography
            id="last-name-label"
            variant="h4"
            color={(theme) => theme.palette.text.primary}
            paddingTop={(theme) => theme.tokens.spacing.xs}
          >
            <FormattedMessage defaultMessage="LAST NAME" />
          </Typography>
          <CompleteFormInput
            aria-labelledby="last-name-label"
            defaultValue={defaultLastName}
            errors={errors}
            {...register(FieldNameLastName, { required: intl.formatMessage({ defaultMessage: "Required" }) })}
          />
        </Container>
        <BenefitEnrollmentModalContinueButton onClick={handleSubmit(onSubmit)} />
      </Form>
    </FormProvider>
  )
}
