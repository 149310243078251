import { RegistrationContextProvider } from "#/components/views/register/RegistrationContext"
import RegisterWizard from "#/components/views/register/RegisterWizard"
import React from "react"

export const RegistrationFlow = (): JSX.Element => {
  return (
    <RegistrationContextProvider>
      <RegisterWizard />
    </RegistrationContextProvider>
  )
}
