import { MaybeLink, MaybeLinkProps } from "../links/MaybeLink"
import React, { FC } from "react"

export type ButtonProps = {
  fullWidth?: boolean
  outline?: boolean
  backgroundColor?: string
  hoverBackgroundColor?: string
  disabledBackgroundColor?: string
  horizontalPadding?: number
  bold?: boolean
  disabledColor?: string
} & MaybeLinkProps

/**
 * @deprecated Use `Button` from `@carrotfertility/carotene-core` instead.
 */
const Button: FC<ButtonProps> = ({
  children,
  className = "",
  fullWidth = false,
  outline = false,
  backgroundColor = "white",
  hoverBackgroundColor = "very-light-carrot-orange",
  disabledBackgroundColor = outline ? "white" : "very-light-carrot-orange",
  horizontalPadding = 4,
  bold = true,
  enabled = true,
  color = "black-80",
  disabledColor = outline ? "black-10" : "white",
  ...props
}) => {
  let outlineColor = "b--transparent"
  if (outline) {
    outlineColor = enabled ? "b--carrot-orange" : "black-10"
  }

  className = `
    dib gotham f6 animate-all pv2 lh-copy no-underline pointer-events
    ${enabled ? color : disabledColor}
    ${bold ? "b" : ""}
    ${enabled ? "pointer" : "not-allowed"}
    ba bw1 ${outlineColor}
    ${fullWidth ? "w-100 tc" : ""}
    ph${horizontalPadding}
    bg-${enabled ? backgroundColor : disabledBackgroundColor}
    ${enabled && hoverBackgroundColor ? `hover-bg-${hoverBackgroundColor}` : ""}
    ${className}
    `

  return (
    <div>
      <MaybeLink className={className} enabled={enabled} {...props} style={{ borderRadius: "24px" }}>
        {children}
      </MaybeLink>
    </div>
  )
}
export { Button }
