import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { usePartnerRegistrationFlow } from "../PartnerRegistrationFlow"
import { AcknowledgeTerms } from "../../../views/register/AcknowledgeTerms"
import { useGetRegisteringPartner } from "../../hooks/usePartnerAccess"

export function TermsConsentStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { guid, readPolicy, setReadPolicy, readTerms, setReadTerms } = usePartnerRegistrationFlow()
  const { registeringUser } = useGetRegisteringPartner(guid)

  return (
    <AcknowledgeTerms
      {...{
        firstName: registeringUser.firstName,
        preferredName: registeringUser.preferredName,
        readPolicy,
        setReadPolicy,
        readTerms,
        setReadTerms
      }}
      onBack={() => send("BACK")}
      onComplete={() => send("")}
      employeeId={registeringUser.employeeId}
    />
  )
}
