import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { EntrySkeletonType } from "contentful"
import { getEntryById } from "../contentfulClient"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { EntryWithoutUnresolvableLinks } from "#/utils/ContentTypes"

const CONTENTFUL_ENTRY_QUERY_KEY = "contentfulEntry"

export function useContentfulEntry<T extends EntrySkeletonType>(
  id: string,
  locale: string
): UseQueryResult<EntryWithoutUnresolvableLinks<T>, ResponseError> {
  return useQuery<EntryWithoutUnresolvableLinks<T>, ResponseError>(
    [CONTENTFUL_ENTRY_QUERY_KEY, id, locale],
    async () => {
      if (!id) {
        return null
      }
      const entry = await getEntryById(id, locale)
      return entry as EntryWithoutUnresolvableLinks<T>
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: buildCarrotApiRetryPolicy(3)
    }
  )
}
