import React from "react"
import { InsuranceCoverageSection } from "#/components/insurance-details/insurance-coverage-section"
import { InsuranceConnectionSection } from "#/components/insurance-details/insurance-connection-section"
import { DeductiblesSection } from "#/components/insurance-details/deductibles-section"
import { Box } from "@carrotfertility/carotene-core"

const InsuranceDetails = (): JSX.Element => {
  return (
    <Box maxWidth="640px">
      <InsuranceCoverageSection />
      <InsuranceConnectionSection />
      <DeductiblesSection />
    </Box>
  )
}

export default InsuranceDetails
