import { useCurrentUser } from "#/components/context/user/UserContext"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import useGetCustomNetworks from "#/pages/provider-finder/hooks/useGetCustomNetworks"
import { useFlags } from "launchdarkly-react-client-sdk"

function useShowCarrotMatch(): { isLoading: boolean; shouldShowCarrotMatch: boolean } {
  const { isUsa } = useCurrentUser()
  const { data: benefitData, isLoading: isBenefitLoading } = useBenefit()
  const { data: customNetworkData, isLoading: isCustomNetworksLoading } = useGetCustomNetworks()

  const isLoading = isBenefitLoading || isCustomNetworksLoading

  const shouldShowCarrotMatch =
    isUsa &&
    !isLoading &&
    (benefitData?.journeys.fertilityCare.isAppSupportEnabled ||
      benefitData?.journeys.fertilityPreservation.isAppSupportEnabled ||
      benefitData?.journeys.gestationalCarrier.isAppSupportEnabled) &&
    benefitData?.providerFinder.showFertilityClinics &&
    !customNetworkData?.isInFertilityNetwork

  return { isLoading, shouldShowCarrotMatch }
}

export function useCarrotMatchEnabled(): { isLoading: boolean; isCarrotMatchEnabled: boolean } {
  const { isLoading, shouldShowCarrotMatch } = useShowCarrotMatch()
  const { showCarrotMatch: showCarrotMatchLdFlag } = useFlags()

  return {
    isLoading,
    isCarrotMatchEnabled: showCarrotMatchLdFlag && shouldShowCarrotMatch
  }
}
