import { Box, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import React, { useEffect } from "react"
import {
  WhatKindOfJourneyHeaderText,
  JustForNowLiteText,
  JustForNowProText,
  AccessCareText
} from "../../carrot-plans/steps/journey/WhatKindOfJourneyStep"
import { RegistrationFlowLayout } from "./RegistrationLayouts"
import { SkipButton, ContinueButton, RegistrationButtonFooter } from "./RegistrationButtons"
import { getHeap, useTrackView } from "#/utils/heap"
import { Form } from "@carrotfertility/carotene-core-x"
import { useConvertTranslatedLabel } from "#/services/common-forms/useConvertTranslatedLabel"
import { RegistrationFlowTitle } from "./RegistrationFlowTitle"
import { FormRadioButtonGroup } from "#/services/common-forms"
import ConditionalSomethingElseTextField from "../../carrot-plans/shared/ConditionalSomethingElseTextField"

interface WhatKindOfJourneyProps {
  journey: string | null
  somethingElseJourney: string | null
  journeyOptions: { label: MessageDescriptor; value: string; journeyMessage: MessageDescriptor }[]
  isCarrotLite: boolean
  onBack: () => void
  onComplete: ({
    selectedJourney,
    somethingElseJourney,
    skipped
  }: {
    selectedJourney: string | null
    somethingElseJourney: string | null
    skipped: boolean
  }) => void
  employeeId: number
}

function WhatKindOfJourneyInformationalText(isCarrotLite: boolean) {
  const intl = useIntl()
  const theme = useTheme()

  let justForNowText = JustForNowProText
  if (isCarrotLite) {
    justForNowText = JustForNowLiteText
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
      <Typography>{intl.formatMessage(justForNowText)}</Typography>
      <Typography>{intl.formatMessage(AccessCareText)}</Typography>
    </Stack>
  )
}

export function WhatKindOfJourney({
  journeyOptions,
  isCarrotLite,
  onBack,
  onComplete,
  journey,
  somethingElseJourney,
  employeeId
}: WhatKindOfJourneyProps): JSX.Element {
  useTrackView("JourneySelectionRegFlow", "Registration journey selection screen")
  const theme = useTheme()
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(journeyOptions).map(({ value, label }) => ({ value, label }))

  useEffect(() => {
    getHeap().track("What kind of Journey")
  }, [])

  const onSubmit = (formValues: { journey?: any; somethingElseJourney?: any }) => {
    getHeap().track("JourneySelectionRegFlow")
    onComplete({
      selectedJourney: formValues?.journey,
      somethingElseJourney: formValues?.somethingElseJourney,
      skipped: false
    })
  }

  const header = intl.formatMessage(WhatKindOfJourneyHeaderText)

  return (
    <>
      <RegistrationFlowTitle title={header} />
      <RegistrationFlowLayout
        header={<span id="what-journey-label">{intl.formatMessage(WhatKindOfJourneyHeaderText)}</span>}
        description={WhatKindOfJourneyInformationalText(isCarrotLite)}
        interactiveElements={
          <Form onSubmit={(formValues) => onSubmit(formValues)} defaultValues={{ journey, somethingElseJourney }}>
            <Box marginTop={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
              <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
                <FormRadioButtonGroup name="journey" options={radioOptions} aria-labelledby="what-journey-label" />
                <ConditionalSomethingElseTextField />
              </Stack>
              <RegistrationButtonFooter
                onBack={onBack}
                skipButton={
                  <SkipButton
                    onClick={() => onComplete({ selectedJourney: null, somethingElseJourney: null, skipped: true })}
                  >
                    <FormattedMessage defaultMessage="Skip for now" />
                  </SkipButton>
                }
                continueButton={
                  <ContinueButton>
                    <FormattedMessage defaultMessage="Continue" />
                  </ContinueButton>
                }
              />
            </Box>
          </Form>
        }
        employeeId={employeeId}
      />
    </>
  )
}
