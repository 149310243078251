import { STEPS, useRegistration } from "#/components/views/register/RegistrationContext"
import { MemberActions } from "#/services/memberActionTracking/memberActions"
import { carrotClient } from "#/utils/CarrotClient"
import { Paths } from "#/utils/Paths"
import useUpdateRegisteringMemberActionTracking from "#/services/memberActionTracking/useUpdateRegisteringMemberActionTracking"
import { useTrackExperimentEvent } from "#/components/views/register/hooks/useExperiments"
import { getIsDefaultEmailOptIn } from "#/components/views/register/EmailOptIn"
import { useGetIsExcludedFromMedicalRecordsConsent } from "#/services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { RegisteringUser } from "#/components/views/register/useFetchRegisteringUser"
import { useCreateUser } from "#/components/views/register/hooks/useCreateUser"
import { useIntl } from "react-intl"
import { useFlags } from "launchdarkly-react-client-sdk"

type UseRegistrationNavigation = {
  // STEPS.INTRO
  onCompleteIntro: () => Promise<void>
  // STEPS.SET_PASSWORD
  onBackSetPassword: () => void
  onCompleteSetPassword: (password: string) => void
  // STEPS.PERSONAL_EMAIL
  onBackPersonalEmail: () => void
  onCompletePersonalEmail: (email: string | null) => void
  // STEPS.PREFERRED_NAME
  onBackPreferredName: () => void
  onCompletePreferredName: () => void
  // STEPS.PHONE_NUMBER_COLLECTION
  onBackPhoneNumberCollection: () => void
  onCompletePhoneNumberCollection: () => void
  // STEPS.ACKNOWLEDGE_TERMS
  onBackAcknowledgeTerms: () => void
  onCompleteAcknowledgeTerms: () => void
  // STEPS.HEALTH_CONSENT
  onBackHealthConsent: () => void
  onCompleteHealthConsent: () => void
  // STEPS.EMAIL_OPT_IN
  onBackEmailOptIn: () => void
  onCompleteEmailOptIn: () => void
  // STEPS.WHAT_KIND_OF_JOURNEY
  onBackJourney: () => void
  onCompleteJourney: ({
    selectedJourney,
    somethingElseJourney,
    skipped
  }: {
    selectedJourney: string
    somethingElseJourney: string
    skipped: boolean
  }) => void
  // STEPS.NUMBER_OF_CHILDREN
  onBackNumberOfChildren: () => void
  onCompleteNumberOfChildren: (formValues: { numberOfChildren: string }) => void
  // STEPS.WHERE_ARE_YOU_IN_JOURNEY
  onBackJourneyStage: (journey: string) => void
  onCompleteJourneyStage: ({
    selectedJourneyStage,
    otherJourneyStage
  }: {
    selectedJourneyStage: string | null
    otherJourneyStage: string | null
  }) => void
  // STEPS.PREGNANCY_MEDICAL_RECORDS_COLLECTION
  onCompletePregnancyMedicalRecordsCollection: () => void
  // STEPS.PERSONAL_EMAIL_VERIFICATION
  onCompleteVerifyPersonalEmail: () => void
}

export const useRegistrationNavigation = (registeringUser: RegisteringUser): UseRegistrationNavigation => {
  const { mutate: updateRegistrationMemberActions } = useUpdateRegisteringMemberActionTracking()
  const { trackExperimentEvent } = useTrackExperimentEvent()
  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  const intl = useIntl()
  const { locale } = intl
  const createUserMutation = useCreateUser()
  const { journeyStageScreenInReg } = useFlags()

  const { hasSaml, ssoOnly, countryCode, isUsa, companyId, parentCompanyId } = registeringUser || {}
  const {
    setStep,
    setPersonalEmail,
    preferredName,
    setJourney,
    setSomethingElseJourney,
    setSkippedJourney,
    setJourneyStage,
    setOtherJourneyStage,
    setNumberOfChildren,
    setPassword,
    journey,
    personalEmail,
    setIsSubmitting,
    guid,
    password,
    emailOptIn,
    readTerms,
    readPolicy,
    healthConsent,
    dbFormattedPhoneNumber,
    isSubmitting,
    journeyStage,
    otherJourneyStage,
    somethingElseJourney,
    numberOfChildren,
    skippedJourney,
    phoneNumberCountryCode,
    allowsMarketingTexts
  } = useRegistration()

  // STEPS.INTRO
  const setFirstAccountCreationStep = (): void => {
    setStep(hasSaml && ssoOnly ? STEPS.PERSONAL_EMAIL : STEPS.SET_PASSWORD)
  }
  const onCompleteIntro = async (): Promise<void> => {
    await trackExperimentEvent("registration-welcome-continue")
    updateRegistrationMemberActions({
      registrationGuid: guid,
      memberActionTaken: MemberActions.REGISTRATION_WELCOME_CONTINUE
    })
    setFirstAccountCreationStep()
  }

  // STEPS.SET_PASSWORD
  const onBackSetPassword = (): void => {
    setStep(STEPS.INTRO)
  }

  const onCompleteSetPassword = (password: string): void => {
    setPassword(password)
    setStep(STEPS.PERSONAL_EMAIL)
  }

  // STEPS.PERSONAL_EMAIL
  const onBackPersonalEmail = (): void => {
    if (!hasSaml || !ssoOnly) {
      setStep(STEPS.SET_PASSWORD)
    } else {
      setStep(STEPS.INTRO)
    }
  }

  const onCompletePersonalEmail = (email: string | null): void => {
    setPersonalEmail(email)
    !preferredName ? setStep(STEPS.PREFERRED_NAME) : setStep(STEPS.PHONE_NUMBER_COLLECTION)
  }
  // STEPS.PREFERRED_NAME
  const onBackPreferredName = (): void => {
    setStep(STEPS.PERSONAL_EMAIL)
  }

  const onCompletePreferredName = (): void => {
    setStep(STEPS.PHONE_NUMBER_COLLECTION)
  }

  // STEPS.PHONE_NUMBER_COLLECTION
  const onBackPhoneNumberCollection = (): void => {
    setStep(STEPS.PREFERRED_NAME)
  }

  const onCompletePhoneNumberCollection = (): void => {
    setStep(STEPS.WHAT_KIND_OF_JOURNEY)
  }

  // STEPS.ACKNOWLEDGE_TERMS
  const onBackAcknowledgeTerms = (): void => {
    const shouldShowJourneyStage = journeyStageScreenInReg && journey && journey !== "SOMETHING_ELSE"
    setStep(shouldShowJourneyStage ? STEPS.WHERE_ARE_YOU_IN_JOURNEY : STEPS.WHAT_KIND_OF_JOURNEY)
  }

  const onCompleteAcknowledgeTerms = (): void => {
    setStep(STEPS.HEALTH_CONSENT)
  }

  // STEPS.HEALTH_CONSENT
  const sendVerificationEmailAndSetStep = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      await carrotClient.sendPersonalEmailVerificationEmail()
    } catch {
      window.location.href = Paths.HOME
    }
    setStep(STEPS.PERSONAL_EMAIL_VERIFICATION)
  }

  const onBackHealthConsent = (): void => {
    setStep(STEPS.ACKNOWLEDGE_TERMS)
  }

  const onCompleteHealthConsent = (): void => {
    if (getIsDefaultEmailOptIn(countryCode)) {
      handleCreateUser()
    } else {
      setStep(STEPS.EMAIL_OPT_IN)
    }
  }

  // STEPS.EMAIL_OPT_IN
  const onBackEmailOptIn = (): void => {
    setStep(STEPS.HEALTH_CONSENT)
  }

  const onCompleteEmailOptIn = (): void => {
    handleCreateUser()
  }

  // STEPS.WHAT_KIND_OF_JOURNEY
  const onBackJourney = (): void => {
    setStep(STEPS.PHONE_NUMBER_COLLECTION)
  }

  const clearOutIrrelevantJourneyAnswers = (): void => {
    setJourneyStage(null)
    setOtherJourneyStage(null)
    setNumberOfChildren(null)
  }

  const clearOutAllJourneyState = (): void => {
    setJourney(null)
    setSomethingElseJourney(null)
    clearOutIrrelevantJourneyAnswers()
  }

  const onSkipJourney = (): void => {
    clearOutAllJourneyState()
    setSkippedJourney(true)
  }

  const changeJourney = (selectedJourney: string): void => {
    setJourney(selectedJourney)
    setSomethingElseJourney(null)
    clearOutIrrelevantJourneyAnswers()
    setSkippedJourney(false)
  }

  const setSomethingElseJourneyState = (selectedJourney: string, somethingElseJourney: string): void => {
    setJourney(selectedJourney)
    setSomethingElseJourney(somethingElseJourney)
    clearOutIrrelevantJourneyAnswers()
    setSkippedJourney(false)
  }

  const setStepAfterJourney = (journey: string | null): void => {
    if (journeyStageScreenInReg && journey) {
      switch (journey) {
        case "PARENTING":
          setStep(STEPS.NUMBER_OF_CHILDREN)
          break
        case "SOMETHING_ELSE":
          setStep(STEPS.ACKNOWLEDGE_TERMS)
          break
        default:
          setStep(STEPS.WHERE_ARE_YOU_IN_JOURNEY)
      }
    } else {
      setStep(STEPS.ACKNOWLEDGE_TERMS)
    }
  }

  const onCompleteJourney = ({
    selectedJourney,
    somethingElseJourney,
    skipped
  }: {
    selectedJourney: string
    somethingElseJourney: string
    skipped: boolean
  }): void => {
    const journeyChanged = selectedJourney !== journey
    const journeyIsSomethingElse = somethingElseJourney && selectedJourney === "SOMETHING_ELSE"

    if (skipped) {
      onSkipJourney()
    } else if (journeyChanged && !journeyIsSomethingElse) {
      changeJourney(selectedJourney)
    } else if (journeyIsSomethingElse) {
      setSomethingElseJourneyState(selectedJourney, somethingElseJourney)
    }
    setStepAfterJourney(selectedJourney)
  }

  // STEPS.NUMBER_OF_CHILDREN
  const onBackNumberOfChildren = (): void => {
    setStep(STEPS.WHAT_KIND_OF_JOURNEY)
  }

  const onCompleteNumberOfChildren = (formValues: { numberOfChildren: string }): void => {
    setNumberOfChildren(formValues.numberOfChildren)
    setStep(STEPS.WHERE_ARE_YOU_IN_JOURNEY)
  }

  // STEPS.WHERE_ARE_YOU_IN_JOURNEY
  const onBackJourneyStage = (journey: string): void => {
    journey === "PARENTING" ? setStep(STEPS.NUMBER_OF_CHILDREN) : setStep(STEPS.WHAT_KIND_OF_JOURNEY)
  }

  const onCompleteJourneyStage = ({
    selectedJourneyStage,
    otherJourneyStage
  }: {
    selectedJourneyStage: string | null
    otherJourneyStage: string | null
  }): void => {
    if (selectedJourneyStage !== journeyStage || !selectedJourneyStage) {
      setOtherJourneyStage(null)
    }
    setJourneyStage(selectedJourneyStage)
    setOtherJourneyStage(otherJourneyStage)
    setStep(STEPS.ACKNOWLEDGE_TERMS)
  }

  // STEPS.PREGNANCY_MEDICAL_RECORDS_COLLECTION
  const onCompletePregnancyMedicalRecordsCollection = (): void => {
    setPersonalEmailVerificationOrRegistrationComplete()
  }

  // STEPS.PERSONAL_EMAIL_VERIFICATION
  const onCompleteVerifyPersonalEmail = (): void => {
    setStep(STEPS.REGISTRATION_COMPLETED)
  }

  // Create the user
  const setPersonalEmailVerificationOrRegistrationComplete = async (): Promise<void> => {
    if (!personalEmail) {
      setStep(STEPS.REGISTRATION_COMPLETED)
    } else {
      await sendVerificationEmailAndSetStep()
    }
  }

  const handleCreateUser = async (): Promise<void> => {
    if (isSubmitting) return
    setIsSubmitting(true)

    createUserMutation.mutate(
      {
        guid,
        password,
        emailOptIn,
        agreeToTerms: readTerms,
        agreeToPolicy: readPolicy,
        agreeToHealthData: healthConsent,
        personalEmail,
        preferredName,
        registrationJourney: {
          journeyType: journey,
          somethingElseJourney,
          journeyStage,
          otherJourneyStage,
          numberOfChildren,
          skipped: skippedJourney
        },
        phoneNumber: dbFormattedPhoneNumber,
        phoneNumberCountryCode,
        allowsMarketingTexts,
        // eslint-disable-next-line no-restricted-syntax -- We include the user's preferredLocale in the request to create their account. Carrot-app's LocaleService will then determine if they can use the selected language once authenticated.
        preferredLocale: locale
      },
      {
        onSuccess: () => {
          if (
            isUsa &&
            journey === "PREGNANT" &&
            !getIsExcludedFromMedicalRecordsConsent({ companyId, parentCompanyId })
          ) {
            setStep(STEPS.PREGNANCY_MEDICAL_RECORDS_COLLECTION)
          } else {
            setPersonalEmailVerificationOrRegistrationComplete()
          }
        }
      }
    )
  }

  return {
    onCompleteIntro,
    onBackSetPassword,
    onCompleteSetPassword,
    onBackPersonalEmail,
    onCompletePersonalEmail,
    onBackPreferredName,
    onCompletePreferredName,
    onBackPhoneNumberCollection,
    onCompletePhoneNumberCollection,
    onBackAcknowledgeTerms,
    onCompleteAcknowledgeTerms,
    onBackHealthConsent,
    onCompleteHealthConsent,
    onBackEmailOptIn,
    onCompleteEmailOptIn,
    onBackJourney,
    onCompleteJourney,
    onBackNumberOfChildren,
    onCompleteNumberOfChildren,
    onBackJourneyStage,
    onCompleteJourneyStage,
    onCompletePregnancyMedicalRecordsCollection,
    onCompleteVerifyPersonalEmail
  }
}
