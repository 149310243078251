import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { carrotClient } from "#/utils/CarrotClient"
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"

interface TelemedicineConsent {
  telemedicineConsentTimeUtc: string
}

const TELEMEDICINE_CONSENT_QUERY_KEY = ["telemedicineConsent"]

export function useTelemedicineConsents(): UseQueryResult<TelemedicineConsent, ResponseError> {
  return useQuery({
    queryKey: TELEMEDICINE_CONSENT_QUERY_KEY,
    queryFn: () => carrotClient.getTelemedicineConsentTime(),
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })
}

export function useTelemedicineConsentsUpdate(): UseMutationResult {
  const queryClient = useQueryClient()
  return useMutation(() => carrotClient.createOrUpdateTelemedicineConsentTime(), {
    retry: false,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: TELEMEDICINE_CONSENT_QUERY_KEY })
  })
}
